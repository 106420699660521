import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pickBy, pickAll, includes } from "ramda";
import "./MobileFilter.scss";
import { listToObject } from "../../../../helpers/utils";
import PriceFilter from "../PriceFilter/PriceFilter";
import { find, propEq } from "ramda";
import { setActiveProductList, clearSearchFilter, setSearchFilterAndSearch } from "../../../../actions/product";
import { clearSelectedCategories } from "../../../../actions/category";
import { ReactComponent as ArrowIcon } from "../../../../images/right-arrow.svg";
import Button from "../../../commons/Button/Button";
import { productFilters, productFilterNames } from "../../../../constants";
import { productsCountAsString } from "../../../../helpers/stringOperations";
import { values } from "ramda";
import Modal from "../../../commons/Modal/Modal";
import SupplierFilter from "../SupplierFilter/SupplierFilter";
import classNames from "classnames";
import PropertyFilter from "../PropertyFilter/PropertyFilter";
import ScrollBar from "../../../commons/ScrollBar/ScrollBar";
const { env } = global.config;
const isDacnhik = env === "dachnik";


class MobileFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainScreen: true,
      filterIndex: null,
      filtersActive: {},
      attributesActive: {},
      filterText: "",
      suppliers: {},
    };
  }

  componentDidMount() {
    const { searchParams } = this.props;
    this.setFilters(searchParams);
  }

  componentDidUpdate(prevProps, prevState) {
    const { active, searchParams } = this.props;

    if (active !== prevProps.active) {
      this.setActiveFilterIndex(null);
    }

    const filterAttributresUpdated = searchParams.attributes !== prevProps.searchParams.attributes;
    const filtersUpdated = productFilterNames.reduce((hasChanges, name) => searchParams[name] !== prevProps.searchParams[name] || hasChanges, false);
    if (filtersUpdated || filterAttributresUpdated) {
      this.setFilters(searchParams);
    }

    if (searchParams.ownerIds !== prevProps.searchParams.ownerIds) {
      this.getSelectedSuppliers();
    }
  }

  setFilters = (searchParams) => {
    const attributesActive = searchParams.attributes || {};
    const filtersActive = pickBy((val, key) => includes(key, productFilterNames), searchParams);
    this.setState({ attributesActive, filtersActive });
  };

  setActiveFilterIndex = (searchIndex) => {
    const { filterIndex } = this.state;
    if (searchIndex === filterIndex) {
      this.setState({ filterIndex: null });
    } else this.setState({ filterIndex: searchIndex });
  };

  setSearchFilter = (text) => {
    this.setState({ filterText: text });
  };

  makePriceSearch = (searchData) => {
    this.setState({ minPrice: searchData.minPrice, maxPrice: searchData.maxPrice });
    this.makePriceSearch(searchData);
  };

  makeSearchProduct = (searchData) => {
    const { searchQuery, setSearchFilterAndSearch } = this.props;
    const { minPrice, maxPrice } = this.state;
    if (searchQuery) {
      searchData.title = searchQuery;
    }

    if (minPrice) searchData.minPrice = minPrice;
    if (maxPrice) searchData.maxPrice = maxPrice;

    setSearchFilterAndSearch(searchData);
  };

  getSelectedSuppliers = () => {
    const { activeSuppliers, searchParams } = this.props;
    const filterOwnerIds = searchParams.ownerIds || [];
    if (filterOwnerIds && activeSuppliers.length > 0) {
      const suppliers = Object.fromEntries(
        filterOwnerIds.map((ownerId) => {
          const supplier = find(propEq("id", ownerId))(activeSuppliers);
          const { id, legalName } = supplier;
          return [id, legalName];
        })
      );

      this.setState({ suppliers });
    }
  };

  resetFilters = () => {
    const { clearSearchFilter } = this.props;
    this.setState({ filtersActive: {}, attributesActive: {} });
    clearSearchFilter();
    this.makeSearchProduct({});
  };

  renderMainScreen = () => {
    const { categoriesByLevel, attributes, availableFilters, searchParams } = this.props;
    const { filtersActive, attributesActive, filterIndex, filterText } = this.state;
    const { maxPrice, minPrice, ownerIds } = searchParams;
    const ownerSelected = ownerIds?.length > 0;
    const priceSetted = minPrice || maxPrice;
    const hasLevel3 = values(categoriesByLevel).length > 2;
    const filters = !isDacnhik ? productFilters : productFilters.filter((item) => item.name !== "transportPackingTypes");

    const renderFilterSelectedItems = (index, isAttrib = false) => {
      const name = !isAttrib ? index : attributes[index].title;
      const obj = !isAttrib ? listToObject(availableFilters[index] || []) : attributes[index].params;
      const selected = isAttrib ? attributesActive[name] : filtersActive[name];

      if (!selected) {
        return null;
      }

      const selectedItems = pickAll(selected, obj);

      return <span className="selected">{Object.values(selectedItems).join(", ")}</span>;
    };

    const checkSelectedItems = (index) => {
      const obj = listToObject(availableFilters[index] || []);
      const selected = filtersActive[index];
      if (!selected) {
        return null;
      }
      const selectedItems = pickAll(selected, obj);
      if (Object.keys(selectedItems).length > 0) {
        return true;
      } else return false;
    };

    return (
      <ul className="filter-categories-list">
        <li className={classNames(" filter-categories-item", { selected: filterIndex === "price" })} onClick={() => this.setActiveFilterIndex("price")}>
          <h3 className={classNames("title", { checked: priceSetted })}>Цена</h3>
          <ArrowIcon />
          {priceSetted && <span className="selected">{`${minPrice || availableFilters.minPrice}₽ - ${maxPrice || availableFilters.maxPrice}`}</span>}
        </li>
        {filterIndex === "price" && (
          <div className="filter-box">
           <PriceFilter search={(data) => this.makeSearchProduct(data)} mobileFilter />
          </div>
        )}
        {/* <li className={classNames(" filter-categories-item", { selected: filterIndex === "supplier" })} onClick={() => this.setActiveFilterIndex("supplier")}>
          <h3 className={classNames("title", { checked: ownerSelected })}>Продавец</h3>
          <ArrowIcon />
          {ownerSelected && Object.values(this.state.suppliers).map((supplier) => <span className="selected">{supplier}</span>)}
        </li>
        {filterIndex === "supplier" && (
          <div className="filter-box">
            <SupplierFilter search={(data) => this.makeSearchProduct(data)} setSearchFilter={this.setSearchFilter} filterText={filterText} defalutStyle={false} mobileFilter />
          </div>
        )}*/}
        {filters.map((f, index) => (
          <li key={`category_${index}`}>
            <div
              className={classNames(" filter-categories-item", { selected: filterIndex === f.name })}
              key={`category_${index}`}
              onClick={() => this.setActiveFilterIndex(f.name)}
            >
              <h3 className={classNames("title", { checked: checkSelectedItems(f.name) })}>{f.title}</h3>
              <ArrowIcon />
              {renderFilterSelectedItems(f.name)}
            </div>
            {filterIndex === f.name && (
              <PropertyFilter
                key={`property_fliter_${index}`}
                title={f.title}
                filterKey={f.name}
                search={(data) => this.makeSearchProduct(data)}
                mobileFilter
                defaultStyle={false}
              />
            )}
          </li>
        ))}

        {hasLevel3 &&
          !!attributes &&
          attributes.map((attribute, index) => (
            <div key={`category_${index}`} onClick={() => this.setActiveFilterIndex(index)}>
              <h3 className="title">
                {attribute.title}
                {renderFilterSelectedItems(index, true)}
              </h3>
              <ArrowIcon />
            </div>
          ))}
      </ul>
    );
  };

  render() {
    const { showFilter, onClose, searchResults } = this.props;
    const resultsQuantity = `${searchResults?.itemsCount} ${productsCountAsString(searchResults?.itemsCount)}`;

    return (
      <div className="mobile-filter">
        <Modal onClose={() => onClose()} visible={showFilter}>
          <h2 className="caption">Фильтры</h2>
          <ScrollBar>{this.renderMainScreen()}</ScrollBar>
          <div className="bottom-bar">
            <div className="reset-button">
            
              <Button buttonSize="large" buttonType="secondary" title="Сброс" onClick={() => this.resetFilters()} width="125px" />
            </div>
            <Button buttonSize="large" buttonType="primary" title={`Показать ${resultsQuantity}`} onClick={() => onClose()} width="100%" />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterAttributes: state.products.filterAttributes,
    attributes: state.products.availableFilters.attributes,
    categoriesByLevel: state.categories.categoriesByLevel,
    availableFilters: state.products.availableFilters,
    searchParams: state.products.searchParams,
    activeSuppliers: state.cart.activeSuppliers,
    searchResults: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelectedCategories: clearSelectedCategories,
      setActiveProductList: setActiveProductList,
      clearSearchFilter: clearSearchFilter,
      setSearchFilterAndSearch: setSearchFilterAndSearch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MobileFilter);
