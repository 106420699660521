import React from 'react'
import Button from '../../../components/commons/Button/Button'
import { Link } from "react-router-dom";
import { Gap } from '../../../components/commons/Gap';
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./SuppliersLandingBanner.scss"

const SuppliersLandingBanner = () => {
  return (
    <section className='suppliers-landing-banner'>
      <div className='suppliers-landing-banner-content'>
        <h1 className='suppliers-landing-banner-title'>Начните продавать свою
          продукцию напрямую
          в B2B</h1>
        <Gap size={10} />
        <span className='suppliers-landing-banner-description'>Расширьте свой рынок и увеличьте продажи, став
          поставщиком на
          <span className='suppliers-landing-banner-accent'>русагро.маркет</span>
        </span>

        <AnchorLink href="#application-form" offset="50">
          <Button title="Стать поставщиком" buttonSize="large" buttonType="primary" width="auto" />
        </AnchorLink>
      </div>
    </section>
  )
}
export default SuppliersLandingBanner