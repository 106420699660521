import * as React from "react";
import classNames from "classnames";
import {ReactComponent as CheckIcon} from "../../../../images/round-check.svg";
import {ReactComponent as CloseIcon} from "../../../../images/round-close.svg";
import {ReactComponent as ReturnIcon} from "../../../../images/return-circle.svg";
import {ReactComponent as TimeIcon} from "../../../../images/time.svg";

import "./OrderItemStatus.scss";

const icons = {
  InProgress: <TimeIcon />,
  Returned: <ReturnIcon />,
  NotReceived: <CloseIcon />,
  OutOfStock: <CloseIcon />,
  Received: <CheckIcon />,
  Assembled: <CheckIcon />,
};

const OrderItemStatus = ({status}) => {
  return (
    <div
      className={classNames("order-item-status", {
        [status.code]: true,
      })}
    >
      {status.code === "NotReceived" && <span>-</span>}

      {status.code !== "NotReceived" && (
        <span>
          {icons[status.code]} <span>{status.value}</span>
        </span>
      )}
    </div>
  );
};

export default OrderItemStatus;
