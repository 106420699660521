import * as React from "react";
import Scrollbar from "react-scrollbars-custom";
import "./ScrollBar.scss";

const ScrollBar = (props) => {
  const { scrollFix, customRef } = props;
  return (
    <Scrollbar
      style={props.style}
      wrapperProps={{
        renderer: (props) => {
          if (scrollFix) {
            props.style.right = 0;
          }
          const { elementRef, ...restProps } = props;
          return <span {...restProps} ref={elementRef} />;
        },
      }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          if (scrollFix) {
            props.style.top = 0;
            props.style.height = "calc(100%)";
          }
          return <span {...restProps} ref={elementRef} className="ScrollbarsCustom-Track ScrollbarsCustom-TrackY" />;
        },
      }}
      ref={customRef}
    >
      {props.children}
    </Scrollbar>
  );
};
export default ScrollBar;
