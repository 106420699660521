export const replaceAt = (str, index, char) => {
  let a = str.split("");
  a[index] = char;
  return a.join("");
};

export const wrapStrOrEmpty = (str, prefix = "", postfix = "") => {
  if (!str) return "";
  return `${prefix}${str}${postfix}`;
};

export const addressToString = (address, short = false) => {
  if (!address) return null;

  const { interphone, region, entrance, street, number, locality, floor, apartment } = address;
  const baseAddress = `${wrapStrOrEmpty(locality || region || "", "", ", ")}${wrapStrOrEmpty(street, " ")} ${number || ""}`;
  if (short) {
    return baseAddress;
  }

  // Формат: Новосибирск, Ленина 1, кв/оф. 2, подъезд 2, код домофона 54, этаж 5
  return `${baseAddress}${wrapStrOrEmpty(apartment, ", кв. ")}${wrapStrOrEmpty(entrance, ", подъезд ")}${wrapStrOrEmpty(interphone, ", код домофона ")}${wrapStrOrEmpty(
    floor,
    ", этаж "
  )}`;
};

export const parseNumbers = (value, signsQuantity) => {
  let newString = "";
  const pos = value.indexOf(",");
  if (pos === -1) {
    newString = "," + "0".repeat(signsQuantity);
  } else {
    const digitsNumber = value.length - pos - 1;
    const digitsToAdd = signsQuantity - digitsNumber;
    newString = "0".repeat(digitsToAdd);
  }
  return value + newString;
};

export const separateThousands = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const stripHtml = (str) => (str ? str.replace(/<[^>]*>?/gm, "") : null);

export const truncNumber = (value) => Math.trunc(value);
export const decimalPartNumber = (value) => (value % 1).toFixed(2).slice(1);

export function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

export const productsCountAsString = (n) => declOfNum(n, ["товар", "товара", "товаров"]);

export const inclinePackTypes = (packType) => {
  switch (packType) {
    case "AluminumBox":
    case "FoamBox":
    case "PlasticBox":
    case "WoodenBox":
      return "В ящике"

    case "Bag":
      return "В мешке"

    case "Barrel":
      return "В бочке"

    case "Bucket":
      return "В ведре"

    case "Can":
      return "В бидоне"

    case "Carton":
      return "В коробке"

    case "CraftBag":
      return "В крафтмешке"

    case "Flask":
      return "Во фляге"

    case "StringBag":
      return "В мешке сетке"

    case "Tank":
      return "В цистерне"

  }
}

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

//Убираем организационную форму из названия
export const removeLegalPrefix = (name) => {
  return name.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  // return name
  //   .replace(/[\"\`\«\»]/g, "")
  //   .split(" ")
  //   .slice(1)
  //   .join(" ");
};

//Получаем аббревиатуру, которая будет показана, если у поставщика нет лого
export const getAbbreviation = (name) => {
  return removeLegalPrefix(name)
    .replace("-", " ")
    .replace("\"", " ")
    .toUpperCase()
    .split(/\s/)
    .reduce((accumulator, word) => accumulator + word.charAt(0), "");
};

export const stringToColour = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i2 = 0; i2 < 3; i2++) {
    var value = (hash >> (i2 * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};


export const renderDayName = (day) => {
  switch (day) {
    case 0:
      return "ВС";
    case 1:
      return "ПН";
    case 2:
      return "ВТ";
    case 3:
      return "СР";
    case 4:
      return "ЧТ";
    case 5:
      return "ПТ";
    case 6:
      return "СБ";
  }
}