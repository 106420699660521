const initialState = {
  subscriptions: null,

};


export default function subscriptions(state = initialState, action) {
  switch (action.type) {

    case "RECEIVE_SUBSCRIPTIONS":
      return {
        ...state,
        subscriptions: action.subscriptions,
      };


    default:
      return state;
  }
}
