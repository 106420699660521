import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/commons/Breadcrumbs";
import HelpCard from "../../components/Help/HelpCard";
import "./Help.scss";
import NavigationBlock from "../../components/Help/NavigationBlock";
import { helpSetData } from "../../actions/help";
import { groupByCategory, assingHelpDataType } from "../../helpers/help";
import { helpJson } from "../../static/help";
import { helpDachnikJson } from "../../static/helpDachnik";

const breadcrumbsList = [
  {
    id: 0,
    to: "/",
    text: "Главная",
  },
  {
    id: 1,
    to: "/help",
    text: "Помощь",
  },
];

const { env } = global.config;
const isDacnhik = env === "dachnik";
const data = isDacnhik ? helpDachnikJson : helpJson;

const Help = () => {
  const dispatch = useDispatch();
  const helpData = useSelector(({ help }) => help);
  const isMobile = useSelector((state) => state.api.isMobile);

  useEffect(() => {
    document.title = "Помощь – rusagro.market";
    if (helpData.status === "success") {
      return;
    }

    dispatch(helpSetData(groupByCategory([...assingHelpDataType(data.dataBuyers, "buyers"), ...assingHelpDataType(data.dataSuppliers, "suppliers")])));
  }, [dispatch, helpData.status]);

  useEffect(() =>  window.scrollTo(0, 0));

  return (
    <>
      {isMobile ? (
        <div className="helpRoot--mobile">
          <h1 className="helpRoot__title-mobile">Помощь</h1>
          <div className="container">
            {helpData.status === "success" &&
              Object.keys(helpData.data).map((keyName, index) => <NavigationBlock key={keyName} list={helpData.data[keyName]} categoryIndex={index} />)}
          </div>
        </div>
      ) : (
        <div className="helpRoot">
          <div className="container">
            <Breadcrumbs items={breadcrumbsList} />
            <h1 className="helpRoot__title">Помощь</h1>
            <div className="helpRoot__grid">
              {helpData.status === "success" && Object.keys(helpData.data).map((keyName, index) => <HelpCard key={keyName} list={helpData.data[keyName]} categoryIndex={index} />)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Help;
