import demo1 from "../src/images/demo1.jpeg";
import demo2 from "../src/images/demo2.jpeg";
import demo3 from "../src/images/demo3.jpeg";
import demo4 from "../src/images/demo4.jpeg";
import cert from "../src/images/mockCertificates/cert.jpg";
import cert1 from "../src/images/mockCertificates/cert1.png";
import cerPdf from "../src/images/mockCertificates/cert-pdf.pdf";
import ava1 from "../src/images/mockCertificates/ava1.jpg";
import ava2 from "../src/images/mockCertificates/ava2.jpg";
import ava3 from "../src/images/mockCertificates/ava3.jpg";

export const mockData = {
  galleryImages: [
    {
      id: 1,

      link: demo1,
    },
    {
      id: 2,

      link: demo2,
    },
    {
      id: 3,

      link: demo3,
    },
    {
      id: 4,

      link: demo4,
    },
    {
      id: 5,

      link: demo1,
    },
    {
      id: 6,

      link: demo2,
    },
    {
      id: 7,

      link: demo3,
    },
    {
      id: 8,

      link: demo4,
    },
    {
      id: 9,

      link: demo1,
    },
    {
      id: 10,

      link: demo2,
    },
  ],
  supplierVideo: null,
  // "https://www.youtube.com/embed/z2kq6IoZd8k?autoplay=1&mute=1",

  certs: [
    {
      id: 1,
      title: "Сертификат один",
      date: "28 июля 988 в 11:01",
      link: cert,
    },
    {
      id: 2,
      title: "Ещё один серт",
      date: "28 июля 988 в 11:02",
      link: cert1,
    },
    {
      id: 3,
      title: "Сертификат в пдф",
      date: "28 июля 988 в 11:03",
      link: cerPdf,
    },
    {
      id: 4,
      title: "Сертификат один",
      date: "28 июля 988 в 11:04",
      link: cert,
    },
    {
      id: 5,
      title: "Ещё один серт",
      date: "28 июля 988 в 11:05",
      link: cert1,
    },
    {
      id: 6,
      title: "Сертификат в пдф",
      date: "28 июля 988 в 11:06",
      link: cerPdf,
    },
  ],

  reviews: [
    {
      id: 1,
      avatar: ava1,
      name: "Полиграф Полиграфыч Шариков",
      date: "28 июня 2019",
      rating: 4,
      text: `Рыба так себе, в ГЛАВРЫБЕ лучше. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.`,
      galleryImages: [
        {
          id: 1,

          link: demo1,
        },
        {
          id: 2,

          link: demo2,
        },
        {
          id: 3,

          link: demo3,
        },
        {
          id: 4,

          link: demo4,
        },
        {
          id: 5,

          link: demo1,
        },
        {
          id: 6,

          link: demo2,
        },
        {
          id: 7,

          link: demo3,
        },
        {
          id: 8,

          link: demo4,
        },
        {
          id: 9,

          link: demo1,
        },
        {
          id: 10,

          link: demo2,
        },
      ],
    },
    {
      id: 2,
      avatar: ava2,
      name: "Richard III",
      date: " 22 августа 1485",
      rating: 4,
      text: `Конина норм. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.`,
    },
    {
      id: 3,
      avatar: ava3,
      name: "Иванка",
      date: "28 июня 2021 ",
      rating: 3,
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.`,
    },
    {
      id: 4,
      avatar: null,
      name: "Никто Не",
      date: "01 января 2021",
      rating: 5,
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.`,
      galleryImages: [
        {
          id: 1,

          link: demo1,
        },
        {
          id: 2,

          link: demo2,
        },
        {
          id: 3,

          link: demo3,
        },
        {
          id: 4,

          link: demo4,
        },
        {
          id: 5,

          link: demo1,
        },
      ],
    },

    {
      id: 5,
      avatar: ava2,
      name: "Richard III",
      date: " 22 августа 1485",
      rating: 4,
      text: `Конина норм. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.`,
    },
    {
      id: 6,
      avatar: ava3,
      name: "Иванка",
      date: "28 июня 2021 ",
      rating: 3,
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.`,
    },
    {
      id: 7,
      avatar: null,
      name: "Никто Не",
      date: "01 января 2021",
      rating: 5,
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam accumsan, lacus non tristique eleifend, risus urna facilisis ligula, nec tempor ex odio eu turpis.`,
      galleryImages: [
        {
          id: 1,

          link: demo1,
        },
        {
          id: 2,

          link: demo2,
        },
        {
          id: 3,

          link: demo3,
        },
        {
          id: 4,

          link: demo4,
        },
        {
          id: 5,

          link: demo1,
        },
      ],
    },
  ],

  desc: ` Богата российская земля на людей идейных: программист Олег Сирота переехал в деревню, чтобы стать сыроваром с нуля... И у него это блестяще получилось! Его продукция славится невероятным вкусом и пользуется огромным спросом среди наших покупателей. Попробуйте и убедитесь сами!Богата российская земля на людей идейных: программист Олег Сирота переехал в деревню, чтобы стать сыроваром с нуля... И у него это блестяще получилось! Его продукция славится невероятным вкусом и пользуется огромным спросом среди наших покупателей. Попробуйте и убедитесь сами!

  Богата российская земля на людей идейных: программист Олег Сирота переехал в деревню, чтобы стать сыроваром с нуля... И у него это блестяще получилось! Его продукция славится невероятным вкусом и пользуется огромным спросом среди наших покупателей. Попробуйте и убедитесь сами!Богата российская земля на людей идейных: программист Олег Сирота переехал в деревню, чтобы стать сыроваром с нуля... И у него это блестяще получилось! Его продукция славится невероятным вкусом и пользуется огромным спросом среди наших покупателей. Попробуйте и убедитесь сами!`,
};
