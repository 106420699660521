import React, { useState, useEffect } from "react";
import cn from "classnames";
import { ReactComponent as Minus } from "../../../../images/cart-minus.svg";
import { ReactComponent as Plus } from "../../../../images/cart-plus.svg";

const FaqItem = ({ title, content, opened }) => {
  const [isOpened, setIsOpened] = useState(opened);

  useEffect(() => {
    if (title === "Какая зона доставки?") {
      const script = document.createElement("script");

      script.src = "/delivery-map.js";
      script.async = true;

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [title]);

  const handleClick = () => {
    setIsOpened(!isOpened);
  };

  return (
    <li className={cn("faq-item", { active: isOpened })}>
      <div className="faq-header" onClick={handleClick}>
        <span>{title}</span>
        <span className="state-icon">{isOpened ? <Minus /> : <Plus />}</span>
      </div>
      <div className={cn("content", { active: isOpened })}>{content}</div>
    </li>
  );
};

export default FaqItem;
