import React from "react";
import "./SimpleModal.scss";
import Modal from "../../commons/Modal/Modal";
import Button from "../../commons/Button/Button";

const SimpleModal = ({ visible, onClose, header, message }) => {
  if (!visible) return null;

  return (
    <div className="simple-modal">
      <Modal onClose={onClose} visible={visible}>
        <h2>{header}</h2>
        <div className="sub-header">
          <span>{message}</span>
        </div>

        <div className="dialog-buttons">
          <Button title="Хорошо" buttonSize="medium" buttonType="primary" onClick={onClose} />
        </div>
      </Modal>
    </div>
  );
};

export default SimpleModal;
