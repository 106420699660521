import React from "react";
import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import cn from "classnames";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import "./NavigationBlock.scss";

const NavigationBlock = ({ list, categoryIndex }) => {
  const params = useParams();
  const [isOpened, setIsOpened] = useState(params.categoryId === categoryIndex.toString());

  const handleClick = () => {
    setIsOpened(!isOpened);
  };

  return list[0].link ? (
    <div
      className={cn("navigation-block", {
        "navigation-block--active-block": isOpened,
      })}
    >
      <p onClick={handleClick}>
        {list[0].section} <Arrow />
      </p>
      {isOpened && (
        <div className="navigation-block__questions">

            <Link
              to={`/help/${categoryIndex}/3`}
              className={cn(categoryIndex.toString() === params.categoryId && "3" === params.questionsId ? "active" : "")}
            >
              {list[0].section}
            </Link>

        </div>
      )}
    </div>
  ) : (
    <div
      className={cn("navigation-block", {
        "navigation-block--active-block": isOpened,
      })}
    >
      <p onClick={handleClick}>
        {list[0].section} <Arrow />
      </p>
      {isOpened && (
        <div className="navigation-block__questions">
          {list.map((item, index) => (
            <Link
              key={index}
              to={`/help/${categoryIndex}/${index}`}
              className={cn(categoryIndex.toString() === params.categoryId && index.toString() === params.questionsId ? "active" : "")}
            >
              {item.question}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavigationBlock;
