import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./MobileCategories.scss";
import { fetchCategoryTree, toggleCategoriesMenu, fetchCategoryByLevel } from "../../../actions/category";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Scrollbar from "../../commons/ScrollBar/ScrollBar";
import Button from "../../commons/Button/Button";
import { ReactComponent as ArrowIcon } from "../../../images/right-arrow.svg";
import { ReactComponent as BackArrowIcon } from "../../../images/arrow-back2.svg";
import TopBar from "../TopBar/TopBar";
import { contains } from "ramda";
import { fetchGetBackend } from "../../../helpers/rest";


class MobileCategoriesMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      categoryId1: null,
      categoryId2: null,
      categoryId3: null,
      categoryTitle1: null,
      categoryTitle2: null,
      searchPromise: null,
      categoryTree: [],
    };
  }

  componentDidMount() {
    this.handleFetchCategories(2);
  }

  componentDidUpdate(prevProps, prevState) {
    const { showCategoriesMenu } = this.props;
    const {categories} = this.state;
    if (showCategoriesMenu !== prevProps.showCategoriesMenu) {
      if (showCategoriesMenu) {
        this.setState({
          categoryId1: null,
          categoryId2: null,
          categoryId3: null,
          categoryTitle1: null,
          categoryTitle2: null,
        });
      }
    }

    
    if (categories !== prevState.categories && showCategoriesMenu) {
      this.handleFetchCategories(2);
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }


  handleFetchCategories = (level) => {
    const {fetchCategoryByLevel} = this.props;
    fetchCategoryByLevel(level, false).then((res) => {
        const filteredCategories = res.results.filter((item) => item.productsCount && item.id !== "8be99310-9b2a-4330-9c7b-a2157c6030a0" && item.id !== "bfc8072f-4152-45ad-9d7c-0fbc693da6c3"); //remove empty, bonus and retail categories
      this.setState({ categories: filteredCategories })
    })
  }

  onClickCategory(category, level, title, rootPaths) {
    const { toggleCategoriesMenu } = this.props;
    const level1 = rootPaths && rootPaths[0]?.l1;

    if (level === 1) {
      this.setState({
        categoryId1: level1,
        categoryTitle1: title,
        categoryTitle2: category.title,
        categoryId2: category.id,
        categoryId3: null,
      });
      if (this.state.searchPromise) {
        this.state.searchPromise.cancel();
      }

      const searchPromise = fetchCategoryTree(category.id)
        .then((categoryTree) => {
          this.setState({ categoryTree });
        })
        .catch(() => {});
      this.setState({ searchPromise });
    } else if (level === 2) {
      this.setState({
        categoryId3: null,
        categoryTitle2: category.title,
      });

      this.setState({ [`categoryId${level}`]: category.id });
    } else if (level === 3) {
      toggleCategoriesMenu(false);
    }
  }

  onBack(level) {
    if (level === 1) {
      this.setState({
        categoryTitle1: null,
        categoryTitle2: null,
        categoryId1: null,
        categoryId2: null,
      });
    } else if (level === 2) {
      this.setState({
        categoryTitle2: null,
        categoryId2: null,
      });
    }
  }

  render() {
    const { showCategoriesMenu, toggleCategoriesMenu } = this.props;
    const { categoryId1, categoryId2, categoryTitle1, categoryTitle2, categoryTree, categories} = this.state;

    if (!showCategoriesMenu) {
      return null;
    }

    const getCategories = (parentCategoryId) => categoryTree.filter((c) => contains(parentCategoryId, c.parentCategoryIds));

    const getCategory1Image = (categoryId) => categories.filter((c) => c.id === categoryId)[0].gallery[0].url;

    const categories3 = categoryId2 ? getCategories(categoryId2) : null;

    const renderAllButton = () => {
      const title = categoryTitle2 ? categoryTitle2 : categoryTitle1 ? categoryTitle1 : "Все товары";

      const url = categoryId2 ? `/catalog/customerProducts/${categoryId1}/${categoryId2}` : categoryId1 ? `/catalog/customerProducts/${categoryId1}` : "/catalog/customerProducts/";

      return (
        <Link to={url} onClick={() => toggleCategoriesMenu(false)}>
          <Button title={title} buttonSize="small" buttonType="primary" disabled={false} />
        </Link>
      );
    };

    const isRootCategory = !categoryTitle1;

    const renderInfo = () => (
      <div className="info">
        <TopBar column={true} isMobile={true} />
      </div>
    );

    return (
      <div ref={this.setWrapperRef} className="categories-menu-mobile">
        <div className={classNames({ "scroll-wrapper": true, "full-height": isRootCategory })}>
          {isRootCategory && (
            <div className="categories">
              <Scrollbar scrollFix={false}>
                {!!categories?.length &&
                  categories.map((category, index) => (
                    <div key={`category1_item_${index}`} className="menu-item" onClick={() => this.onClickCategory(category, 1, category.title, category.rootPaths)}>
                      <span className="title">
                        <img className="category-img" src={category.gallery[0].previewUrl} alt={category.title} />
                        <span>{category.title}</span>
                      </span>
                      <ArrowIcon />
                    </div>
                  ))}
              </Scrollbar>
              {renderInfo()}
            </div>
          )}
          {categoryId2 && (
            <div className="categories">
              <div className="selected-categories">
                <div className="menu-item active" onClick={() => this.onBack(1)}>
                  <span className="title">
                    <span className="back">
                      <BackArrowIcon />
                    </span>
                    <img className="category-img" src={getCategory1Image(categoryId2)} alt={categoryTitle2} />
                    <span>{categoryTitle2}</span>
                  </span>
                </div>
              </div>
              <Scrollbar scrollFix={false}>
                {categories3.map((category, index) => (
                  <div key={`category3_item_${index}`} className="menu-item">
                    <Link className="title" to={`/catalog/customerProducts/${categoryId1}/${categoryId2}/${category.id}`} onClick={() => this.onClickCategory(category, 3, null,  category.rootPaths)}>
                      <span>{category.title}</span>
                    </Link>
                    <ArrowIcon />
                  </div>
                ))}
              </Scrollbar>
            </div>
          )}
        </div>
        {!isRootCategory && <div className="bottom-bar">{renderAllButton()}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categoryTree: state.categories.categoryTree,
  showCategoriesMenu: state.categories.showCategoriesMenu,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleCategoriesMenu: toggleCategoriesMenu,
      fetchCategoryByLevel:fetchCategoryByLevel
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MobileCategoriesMenu);
