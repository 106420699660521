import * as React from "react";
import "./SupplierPage.scss";
import { bindActionCreators } from "redux";
import { fetchActiveSupplier, setSearchFilterAndSearch, setPageExtraItemsCount, setPageOffset, clearSearchFilter } from "../../../actions/product";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
// import ReviewRating from "../../commons/Reviews/ReviewRating/ReviewRating";
import { range } from "../../../helpers/math.js";
import { ReactComponent as LocationIcon } from "../../../images/location.svg";
import { ReactComponent as FileIcon } from "../../../images/file-icon.svg";
import { ReactComponent as ReviewIcon } from "../../../images/review-icon.svg";
import { ReactComponent as ReviewIconBlue } from "../../../images/review-icon-blue.svg";
import { ReactComponent as NoPhotoIcon } from "../../../images/no-photo.svg";
import { ReactComponent as PhoneIcon } from "../../../images/phone.svg";
import { ReactComponent as EmailIcon } from "../../../images/email.svg";
import { ReactComponent as RoundCheckIcon } from "../../../images/round-check.svg";
import PlayIcon from "../../../images/play.png";
import starFull from "../../../images/star-full.svg";
import starEmpty from "../../../images/star-empty.svg";
import Badge from "./Badge/Badge";
import Tabs from "./Tabs/Tabs";
import SideBar from "./SideBar/SideBar";
import Card from "../Card/Card";
import SwiperCore, { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PagingBar from "../PagingBar/PagingBar";
import queryString from "query-string";
import moment from "moment";
import { removeLegalPrefix, getAbbreviation } from "../../../helpers/stringOperations";
import SliderButton from "../../commons/SliderButton/SliderButton";
import Button from "../../commons/Button/Button";
import { Gap } from "../../commons/Gap";
import classNames from "classnames";
import FavoriteButton from "../FavoriteButton/FavoriteButton";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import ZoomModal from "../../commons/ZoomModal/ZoomModal";

// install Swiper components
SwiperCore.use([Navigation, A11y]);

class SupplierPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      zoom: false,
      galleryIndex: 0,
      galleryItems: [],
      showVideo: false,
    };
  }
  componentDidMount() {
    const { fetchActiveSupplier, match, clearSearchFilter } = this.props;
    const { supplierId } = match.params;

    clearSearchFilter();
    fetchActiveSupplier(supplierId).then(() => this.setState({ isLoading: false }));
    this.initProducts();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const query = queryString.parse(this.props.location.search);
    const prevQuery = queryString.parse(prevProps.location.search);

    if (query.offset !== prevQuery.offset) {
      const { setPageExtraItemsCount } = this.props;
      setPageExtraItemsCount(0);
    }

    if (this.props.location !== prevProps.location) {
      this.initProducts();
    }
  }

  initProducts() {
    const { location, setPageOffset, match, fetchProductsByOwnerId } = this.props;
    const query = queryString.parse(location.search);
    setPageOffset(query.offset);

    this.makeSearch();
  }

  makeSearch(params = {}) {
    const { setSearchFilterAndSearch, match } = this.props;
    const { supplierId } = match.params;
    params.ownerIds = [supplierId];
    setSearchFilterAndSearch(params);
  }

  render() {
    const { products, itemsCount, pageOffset, extraItemsCount, currentSupplier, isMobile, isTablet, isDesktop } = this.props;
    const { isLoading, showVideo } = this.state;

    if (isLoading) return null;

    const { legalName, iconUrl, createdDate, phoneNumber, email } = currentSupplier;

    const categoriesByLevel = {
      1: { id: `catalog/suppliers`, title: "Поставщики" },
    };

    const dateFormat = moment(createdDate).format("DD MMM YYYY").replace(/\./g, "");

    const handleZoomModal = () => {
      this.setState({ zoom: !this.state.zoom });
    };

    const zoomCard = (index, items) => {
      handleZoomModal();
      this.setState({ galleryIndex: index });
      this.setState({ galleryItems: items });
    };

    const infoImagePlug = !!currentSupplier?.gallery && currentSupplier.gallery.find((item) => item.isMain);

    //Рендер слайдов галереи
    const renderSlides =
      currentSupplier?.gallery &&
      currentSupplier.gallery.map((item, i) => {
        return (
          <SwiperSlide key={i} onClick={() => zoomCard(i, currentSupplier.gallery)}>
            <img className="gallery-slide" src={item.url} alt={`Картинка галерии поставщика ${item.fileNameName}`} />
          </SwiperSlide>
        );
      });

    //Рендер баджей
    const renderBadges =
      currentSupplier?.tags &&
      currentSupplier.tags.map((item, i) => {
        return <Badge caption={item} key={i} />;
      });

    //Рендер торговых ячеек
    const renderLeasedBadges = currentSupplier.leasedObjects ? (
      currentSupplier.leasedObjects.map((item, i) => {
        return <Badge caption={`Здание ${item.building} - павильон ${item.number}`} key={i} look="ghost" icon={<LocationIcon />} />;
      })
    ) : (
      <Badge caption="Нет торговых ячеек в аренде" look="ghost" icon={<LocationIcon />} />
    );

    //Рендер карточек продуктов
    const renderTabProducts = () => (
      <div className="supplier-products">
        {isDesktop && <SideBar search={(params) => this.makeSearch(params)} />}
        <div>
          <div
            className={classNames("card-container", {
              "single-card": itemsCount === 1,
            })}
          >
            {products && products.map((product, index) => <Card key={`${product.id}_${index}`} product={product} isMobile={isMobile} isTablet={isTablet} rowsView={true} />)}
          </div>
          <PagingBar itemsCount={itemsCount} offset={pageOffset} setPageExtraItemsCount={setPageExtraItemsCount} extraItemsCount={extraItemsCount} isMobile={isMobile} />
        </div>
      </div>
    );

    //Массив сертификатов картинками
    const certImages =
      currentSupplier?.certificates &&
      currentSupplier.certificates.filter(
        (cert) =>
          cert.fileName.split(".").pop() === "jpg" ||
          cert.fileName.split(".").pop() === "jpeg" ||
          cert.fileName.split(".").pop() === "png" ||
          cert.fileName.split(".").pop() === "svg"
      );
    const getCertIndex = (fileName) => {
      return certImages.findIndex((cert) => cert.fileName === fileName);
    };

    //Рендер сертификатов
    const renderCertificates =
      currentSupplier?.certificates &&
      currentSupplier.certificates.map((cert) => {
        const { title, createdDate, fileName, url } = cert;
        const fileType = fileName.split(".").pop();
        const fileImage = fileType === "jpg" || fileType === "jpeg" || fileType === "png" || fileType === "svg";
        const date = createdDate ? moment(createdDate).format("lll").replace(/г.,/, "в") : "-";

        const zoomPdf = (url) => {
          window.open(url);
          return false;
        };

        return (
          <li className="supplier-certificate" key={fileName} onClick={() => (!fileImage ? zoomPdf(url) : zoomCard(getCertIndex(fileName), certImages))}>
            {fileImage ? (
              <img className="supplier-certificate-image" src={url} alt={title} />
            ) : (
              <div className="supplier-certificate-icon-wrapper">
                <FileIcon />
              </div>
            )}
            <div className="supplier-certificate-info">
              <h4 className="supplier-certificate-title">{title}</h4>
              <p className="supplier-certificate-date">{date}</p>
            </div>
            <span className="supplier-certificate-label">{fileType}</span>
          </li>
        );
      });

    //Рендер отзывов
    const renderReviews =
      currentSupplier?.reviews &&
      currentSupplier.reviews.map((review) => {
        const { id, avatar, name, date, rating, text } = review;
        const stars = range(1, 5).map((i) =>
          i <= rating ? <img src={starFull} alt="Иконка звёздочка" key={`star_${i}`} /> : <img src={starEmpty} alt="Иконка пустая звёздочка" key={`star_${i}`} />
        );

        return (
          <li className="supplier-review" key={id}>
            <div className="supplier-review-content">
              {avatar ? (
                <img className="supplier-review-avatar" src={avatar} alt={`аватар ${name}`} />
              ) : (
                <span className="supplier-review-avatar-plug">{getAbbreviation(name)}</span>
              )}
              <div className="supplier-review-info">
                <h4 className="supplier-review-title">{name}</h4>
                <p className="supplier-review-date">{date}</p>
              </div>
            </div>
            <div className="supplier-review-stars">{stars}</div>
            <p className="supplier-review-text">{text}</p>
          </li>
        );
      });

    const tabs = [
      {
        name: "products",
        title: `Продукция (${products.length || 0})`,
        content: renderTabProducts(),
      },
      !!currentSupplier?.certificates && currentSupplier.certificates.length
        ? {
          name: "certificates",
          title: currentSupplier.certificates.length ? `Сертификаты (${currentSupplier.certificates.length})` : "",
          content: currentSupplier.certificates && <ul className="supplier-certificates">{renderCertificates}</ul>,
        }
        : null,
      !!currentSupplier?.reviews && currentSupplier.reviews.length
        ? {
          name: "reviews",
          title: `Отзывы  (${currentSupplier.reviews.length})`,
          content: (
            <div className="supplier-reviews">
              <header className="supplier-reviews-header">
                <Button title="Написать отзыв" icon={<ReviewIcon />} buttonSize="medium" buttonType="primary" width="168px" />
              </header>
              {currentSupplier.reviews.length ? (
                <ul className="supplier-reviews-list">{renderReviews}</ul>
              ) : (
                <div className="supplier-reviews-empty">
                  <div className="supplier-reviews-empty-icon">
                    <ReviewIconBlue />
                  </div>

                  <p className="supplier-reviews-text">Отзывов ещё нет — ваш может стать первым</p>
                </div>
              )}
            </div>
          ),
        }
        : null,
      currentSupplier?.description
        ? {
          name: "about",
          title: "О Поставщике",
          content: (
            <div className="supplier-decsription">
              <p className="supplier-decsription-text">{currentSupplier.description}</p>
              <Gap size={25} />
              {currentSupplier?.ogrn && (
                <>
                  <div className="supplier-decsription-header">
                    <h3 className="supplier-decsription-caption">Реквизиты поставщика</h3>
                    <div className="supplier-decsription-approve">
                      <div className="supplier-decsription-approve-icon">
                        <RoundCheckIcon />
                      </div>
                      <span className="supplier-decsription-approve-text">Данные проверены</span>
                    </div>
                  </div>
                  <Gap size={25} />
                </>
              )}
              <ul className="supplier-decsription-requisites-list">
                <li className="supplier-decsription-requisites-row">
                  {currentSupplier?.inn && (
                    <div className="supplier-decsription-requisites-item">
                      <span className="supplier-decsription-requisites-title">ИНН</span>
                      <span className="supplier-decsription-requisites-text">{currentSupplier.inn}</span>
                    </div>
                  )}
                  {currentSupplier?.ogrn && (
                    <div className="supplier-decsription-requisites-item">
                      <span className="supplier-decsription-requisites-title">ОГРН</span>
                      <span className="supplier-decsription-requisites-text">{currentSupplier.ogrn}</span>
                    </div>
                  )}
                </li>
                <Gap size={20} />
                <li className="supplier-decsription-requisites-row">
                  {currentSupplier?.kpp && (
                    <div className="supplier-decsription-requisites-item">
                      <span className="supplier-decsription-requisites-title">КПП</span>
                      <span className="supplier-decsription-requisites-text">{currentSupplier.kpp}</span>
                    </div>
                  )}
                  {currentSupplier?.legalName && (
                    <div className="supplier-decsription-requisites-item">
                      <span className="supplier-decsription-requisites-title">Юридическое наиминование</span>
                      <span className="supplier-decsription-requisites-text">{currentSupplier.legalName}</span>
                    </div>
                  )}
                </li>
                <Gap size={20} />
                <li className="supplier-decsription-requisites-row">
                  {currentSupplier?.legalAddress && (
                    <div className="supplier-decsription-requisites-item">
                      <span className="supplier-decsription-requisites-title">Адрес местонахождения</span>
                      <span className="supplier-decsription-requisites-text">{currentSupplier.legalAddress}</span>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          ),
        }
        : null,
    ];
    const regLink = /(www|http:|https:)+[^\s]+[\w]/;
    const hasVideo = !!currentSupplier?.videoUrl && regLink.test(currentSupplier?.videoUrl);
    const hasGallery = !!currentSupplier?.gallery?.length;

    return (
      <div className="supplier-page ">
        {isDesktop && <Breadcrumbs categoriesByLevel={categoriesByLevel} title={legalName} baseUri={"/"} />}
        <div className="supplier-info">
          <div className="supplier-info-intro">
            <div className="supplier-info-image-wrap">
              <div className="supplier-logo">
                {iconUrl ? (
                  <img className="supplier-info-logo" src={iconUrl} alt={`Логотип ${legalName}`} />
                ) : (
                  <span className="supplier-info-abbr">{getAbbreviation(removeLegalPrefix(legalName))}</span>
                )}
              </div>
              {!showVideo && hasVideo && (
                <div className="supplier-info-play-icon-wrap">
                  <div className="supplier-info-play-icon" onClick={() => this.setState({ showVideo: true })}>
                    <img src={PlayIcon} alt="" />
                  </div>
                </div>
              )}

              {!showVideo && !hasVideo && !hasGallery && (
                <div className="supplier-info-image-plug">
                  <NoPhotoIcon />
                </div>
              )}

              {!showVideo && hasGallery && <img className="supplier-info-image" src={infoImagePlug?.url || currentSupplier?.gallery[0].url} alt={legalName} />}

              {showVideo && hasVideo && (
                <iframe
                  className="supplier-info-image"
                  src={`${currentSupplier.videoUrl}?autoplay=1&mute=1`}
                  title="YouTube video player"
                  frameBorder="0"
                  autoPlay="1"
                  allowFullScreen
                ></iframe>
              )}
            </div>

            <div className="description">
              <div className="supplier-info-profile">
                <FavoriteButton supplier={currentSupplier} squared />
                <div>
                  <h2 className="supplier-info-title">{legalName}</h2>
                  <p className="supplier-info-date">На платформе с {dateFormat}</p>
                </div>
              </div>
              {/* <div className="supplier-info-buttons">
                <a className="supplier-info-phone" href={`tel:${phoneNumber}`}>
                  <Button title={phoneNumber} icon={<PhoneIcon />} buttonSize="medium" buttonType="primary" width="auto" />
                </a>

                <a className="supplier-info-email" href={`mailto:${email}`}>
                  <EmailIcon />
                </a>
              </div> */}

              {/* <Gap size={15} /> */}

              {!isTablet && <div className="introduction">{!!currentSupplier?.shortDescription ? currentSupplier.shortDescription : "Описание отстуствует"}</div>}
              {!!currentSupplier?.tags && <div className="badges">{renderBadges}</div>}
              {/* <div className="badges">{renderLeasedBadges}</div> */}
            </div>
          </div>
          {hasVideo && <div className="supplier-info-gap" />}

          {isTablet && <div className="introduction">{!!currentSupplier?.shortDescription ? currentSupplier.shortDescription : "Описание отстуствует"}</div>}

          {!!currentSupplier?.gallery?.length && (
            <div className="gallery-wrapper">
              <div className="gallery-header">
                <h3 className="gallery-title">Галерея</h3>

                {isDesktop && (
                  <div className="gallery-buttons">
                    <SliderButton buttonType="prev" buttonColor="blue" />
                    <SliderButton buttonType="next" buttonColor="blue" />
                  </div>
                )}
              </div>
              <Swiper
                navigation={{
                  prevEl: ".slider-btn-prev",
                  nextEl: ".slider-btn-next",
                }}
                spaceBetween={20}
                slidesPerView={1.2}
                breakpoints={{
                  375: {
                    width: 375,
                    slidesPerView: 1.2,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2.8,
                  },
                  1024: {
                    width: 1024,
                    slidesPerView: 3.5,
                  },
                  1140: {
                    width: 1140,
                    slidesPerView: 4,
                  },
                }}
              >
                {renderSlides}
              </Swiper>
            </div>
          )}
        </div>
        <Tabs tabs={tabs} />
        {this.state.zoom && <ZoomModal index={this.state.galleryIndex} items={this.state.galleryItems} close={handleZoomModal} isDesktop={isDesktop} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSupplier: state.products.currentSupplier,
   
    countriesOfOrigin: state.products.availableFilters.countriesOfOrigin,
    products: state.products.products.results,
    itemsCount: state.products.products.itemsCount,
    pageOffset: state.products.pageOffset,
    extraItemsCount: state.products.extraItemsCount,
    isMobile: state.api.isMobile,
    isTablet: state.api.isTablet,
    isDesktop: state.api.isDesktop,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchActiveSupplier: fetchActiveSupplier,
      setSearchFilterAndSearch: setSearchFilterAndSearch,
      setPageExtraItemsCount: setPageExtraItemsCount,
      setPageOffset: setPageOffset,
      clearSearchFilter: clearSearchFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierPage));
