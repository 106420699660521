import React from "react";

import "./ProductsGallery.scss";
import Card from "../../Card/Card";
import SliderButton from "../../../commons/SliderButton/SliderButton";
import { Swiper, SwiperSlide } from "swiper/react";
const CONTAINER_WIDTH = 1140;

const ProductsGallery = ({ title, products, isDesktop, index }) => {
  const isTabletOrMobile = !isDesktop;
  const containerStyle = { marginLeft: "auto", marginRight: "auto", maxWidth: CONTAINER_WIDTH };

  return (
    <div className="products-gallery block container" style={containerStyle}>
      <div className="title-bar">
        <h2>{title}</h2>

        {isDesktop && (
          <div className="buttons">
            <SliderButton buttonType="prev" buttonColor="gray" index={index} hide={products.length < 4 ? true : false} />
            <SliderButton buttonType="next" buttonColor="gray" index={index} hide={products.length < 4 ? true : false} />
          </div>
        )}
      </div>
      <div className="products-gallery-items">
        <Swiper
          spaceBetween={5}
          threshold={2}
          slidesPerView={2.1}
          navigation={{
            prevEl: `.slider-btn-prev_${index}`,
            nextEl: `.slider-btn-next_${index}`,
          }}
          breakpoints={{
            440: {
              width: 440,
              slidesPerView: 2.5,
              spaceBetween: 5,
            },
            768: {
              width: 768,
              slidesPerView: 3.1,
              spaceBetween: 10,
            },
            1024: {
              width: 1140,
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {products.map((product, idx) => (
            <SwiperSlide key={`cart_${idx}`}>
              <Card product={product} isMobile={isTabletOrMobile} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ProductsGallery;
