import React from 'react';
import "./SuppliersLandingFeatures.scss";
import supplyChain from "../../../images/supply_chain_icon.png"
import newClients from "../../../images/new_clients_icon.png"
import getPayment from "../../../images/get_payment_icon.png"
import clientService from "../../../images/client_service_icon.png"
import promoInfo from "../../../images/promo_info_icon.png"
import SuppliersLandingTitle from '../SuppliersLandingTitle';

const SuppliersLandingFeatures = () => {
  return (
    <section className='suppliers-landing-features'>
      <SuppliersLandingTitle title="Возможности для поставщиков" />
      <div className='suppliers-landing-features-content'>
        <ul className='suppliers-landing-features-list'>
          <li className='suppliers-landing-features-item'>
            <img src={supplyChain} alt="Иконка Получайте полный Supply Chain сервис" className="suppliers-landing-features-image" />
            <span className="suppliers-landing-features-caption">Получайте полный
              Supply Chain сервис</span>
            <p className="suppliers-landing-features-text">Привозите товар на склад, а мы возьмем на себя хранение, логистику и документооборот с покупателем</p>
          </li>
          <li className='suppliers-landing-features-item'>
            <img src={newClients} alt="Иконка Привлекайте новых клиентов" className="suppliers-landing-features-image" />
            <span className="suppliers-landing-features-caption">Привлекайте новых клиентов</span>
            <p className="suppliers-landing-features-text">А также переводите свою клиентскую базу на прием заказов онлайн. Мы предоставим вам удобную аналитику по продажам</p>
          </li>
          <li className='suppliers-landing-features-item'>
            <img src={getPayment} alt="Иконка Получайте решение для приема платежей " className="suppliers-landing-features-image" />
            <span className="suppliers-landing-features-caption">Получайте решение для приема платежей</span>
            <p className="suppliers-landing-features-text">Прием платежей с карт без регистрации онлайн кассы и регистраций в налоговой. Быстрое получение статуса оплат по счетам</p>
          </li>
          <li className='suppliers-landing-features-item'>
            <img src={clientService} alt="Иконка Оптимизируйте клиентский сервис" className="suppliers-landing-features-image" />
            <span className="suppliers-landing-features-caption">Оптимизируйте клиентский сервис</span>
            <p className="suppliers-landing-features-text">Обработка заказов, связь с клиентами, ответы на вопросы на нашей стороне</p>
          </li>
          <li className='suppliers-landing-features-item'>
            <img src={promoInfo} alt="Иконка Информируйте об акциях" className="suppliers-landing-features-image" />
            <span className="suppliers-landing-features-caption">Информируйте об акциях</span>
            <p className="suppliers-landing-features-text">Быстрый запуск новинок и промо на текущую и новую аудиторию</p>
          </li>
          <li className="suppliers-landing-features-item">
          </li>
        </ul>
      </div>
    </section>
  )
}

export default SuppliersLandingFeatures