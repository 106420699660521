import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeFromCart } from "../../../actions/cart";
import { groupItemsBySupplier } from "../../../helpers/orders";

import "../CartPage.scss";

import SupplierItem from "./SupplierItem";

class SupplierItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      cartGroups: [],
      totalSum: 0,
      totalItems: 0,
      totalVolume: 0,
      totalWeight: 0,
    };
  }

  init() {
    const { cartItems } = this.props;
    if (!cartItems || !cartItems.items) return;

    const { cartGroups } = groupItemsBySupplier(cartItems.items);

    this.setState({ cartGroups });
  }
  componentDidMount() {
    const { cartItems } = this.props;
    if (cartItems) {
      this.init();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { cartItems } = this.props;

    if (cartItems !== prevProps.cartItems) {
      this.init();
    }
  }

  removeFromCart = (item) => {
    const { removeFromCart } = this.props;
    const { productId } = item;

    removeFromCart({ productId: productId });
  };

  render() {
    const { removeFromCart, isMobile, mini, handleCartIsUpdating, cartIsUpdating } = this.props;
    const { cartGroups } = this.state;

    return (
      <div className="supplier-items">
        {cartGroups.map((groupItem, index) => (
          <SupplierItem key={`cart_group_${index}`} groupItem={groupItem} removeFromCart={removeFromCart} isMobile={isMobile} mini={mini} handleCartIsUpdating={handleCartIsUpdating} cartIsUpdating={cartIsUpdating} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products.products,
  isProductListFetching: state.products.isProductListFetching,
  currentProduct: state.products.currentProduct,
  templateUrl: state.priceList.templateUrl,
  showSuccessMessage: state.priceList.showSuccessMessage,
  showFailMessage: state.priceList.showFailMessage,
  cartItems: state.cart.cartItems,
  loggedIn: state.profile.loggedIn,
  isMobile: state.api.isMobile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeFromCart: removeFromCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SupplierItems);
