import { last, propEq, find } from "ramda";
import { setSearchFilterCategories, clearSearchFilter } from "./product";
import { emptyPromise } from "../helpers/utils";
import { cancelableFetch, fetchGetBackend } from "../helpers/rest";
import queryString from "query-string";

export function receiveCategories(categories) {

  return {
    type: "RECEIVE_CATEGORIES",
    categories: categories.results.filter((item) => item.id !== "95d5477b-86b2-4e85-a76f-92d1c68536f2" && item.id !== "f17cb926-7f2b-4af2-abd3-653c3d4969d5"), //filter bonus and retail categories
  };
}

export function setLoading(isLoading) {
  return {
    type: "LOADING_CATEGORIES",
    isLoading,
  };
}

export function receiveCategoryById(category) {
  return {
    type: "RECEIVE_CATEGORY",
    category,
  };
}

export function toggleCategoriesMenu(showCategoriesMenu) {
  return {
    type: "TOGGLE_CATEGORIES_MENU",
    showCategoriesMenu,
  };
}
export function receiveCategoryTree(categoryTree) {
  return {
    type: "RECEIVE_CATEGORY_TREE",
    categoryTree,
  };
}

export function setCategoryLevel(category) {
  return {
    type: "SET_CATEGORY_LEVEL",
    category,
  };
}

export function clearSelectedCategories() {
  return {
    type: "CLEAR_SELECTED_CATEGORIES",
  };
}

export function fetchCategories(params) {
  return (dispatch) => {
    const levelFilter = `level==${params.level}`;
    const ownerIdFilter = `OwnerId==${params.ownerId}`

    let filterParams = {};

    if (params.level) {
      filterParams.Filter = levelFilter;
    }
    if (params.ownerId) {
      filterParams.Filter = `${ownerIdFilter},${levelFilter}`
    }

    delete params.level;
    delete params.ownerId;

    const qs = queryString.stringify(filterParams)

    return fetchGetBackend(`/api/public/v2.0/catalog/categories?${qs}`, null)
      .then((res) => {

        dispatch(receiveCategories(res))
        return res
      })
  };
}

export function fetchCategoryByLevel(level, setStore = true) {
  return (dispatch) => {
    // dispatch(fetchProductsPending());
    return fetchGetBackend(`/api/public/v2.0/catalog/categories?Filter=Level==${level}`, null)
      .then((res) => {
        setStore && dispatch(receiveCategories(res))
        return res
      })
  };
}

export function fetchCategoryById(id) {
  return (dispatch) => {
    // dispatch(fetchProductsPending());
    return fetchGetBackend(`/api/public/v2.0/catalog/categories/${id}`, null)
      .then((json) => {
        dispatch(receiveCategoryById(json))
        return json
      })
      .catch((error) => {
        // dispatch(fetchProductsError(error));
      });
  };
}

export function fetchCategoryTree(id) {
  return cancelableFetch(`/api/public/v2.0/catalog/categories?Filter=ParentCategoryId==${id},isEmpty==false`).then((json) => {
    return json.results
      .filter((c) => c.productsCount > 0)
      .map((item) => {
        const parentCategoryIds = item.level === 1 ? [] : item.level === 2 ? item.rootPaths.map((p) => p["l1"]) : item.rootPaths.map((p) => p["l2"]);
        return {
          id: item.id,
          level: item.level,
          title: item.title,
          parentCategoryIds,
          productsCount: item.productsCount,
        };
      });
  });
}

export function getCategoryTree(rootCategoryId, childCategoryIds = []) {
 
  return (dispatch) => {
    if (!rootCategoryId) {
      dispatch(clearSelectedCategories());
      dispatch(clearSearchFilter());
      return emptyPromise();
    } else {
      dispatch(setLoading(true));

      return fetchCategoryTree(rootCategoryId)
        .then((categoryTree) => {
          dispatch(receiveCategoryTree(categoryTree));
          const childIds = childCategoryIds.filter((v) => !!v);
          dispatch(fetchCategoryById(rootCategoryId)).then((res) => {
            dispatch(setCategoryLevel(res))
            childIds.forEach((categoryId) => {
              const childCategory = find(propEq("id", categoryId), categoryTree);
              dispatch(setCategoryLevel(childCategory));
            });
          });
          const activeCategoryId = childIds.length > 0 ? last(childIds) : rootCategoryId;
          dispatch(setSearchFilterCategories([activeCategoryId]));
          dispatch(setLoading(false));

        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  };
}
