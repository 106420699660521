import React from "react";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";
import "./RadioGroup.scss";
import classNames from "classnames";
import CheckBoxItem from "../CheckBoxItem/CheckBoxItem";

const RadioGroup = ({ value, onChange, selectValues }) => {
  return (
    <div className="radio-group">
      {selectValues.map((v, index) => (
        <div key={`radio_item_${index}`} className={classNames("radio-item", { selected: v.value === value })} onClick={() => onChange(v.value)}>
          <CheckBoxItem checked={v.value === value} />
          <span>{v.title}</span>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
