import * as React from "react";
import Pagination from "../../commons/Pagination/Pagination";
import { withRouter } from "react-router";
import Button from "../../commons/Button/Button";
import "./PagingBar.scss";
import { ReactComponent as RefreshIcon } from "../../../images/refresh.svg";

// class PagingBar extends React.Component {

const PagingBar = (props) => {
  if (!props.totalCount || props.totalCount <= global.config.pagination.pageSize) {
    return null;
  }
  const onLoadMore = () => {
    const { extraItemsCount, setPageExtraItemsCount } = props;
    const pageSize = global.config.pagination.pageSize;
    setPageExtraItemsCount(extraItemsCount + pageSize);
    const pathname = props.location.pathname;
    props.history.push(`${pathname}${props.location.search}`);
  };
  return (
    <div className="paging-bar">
      <Button title="Показать еще" buttonSize="large" buttonType="primary" icon={<RefreshIcon />} onClick={() => onLoadMore()} />
      {!props.isMobile && (
        <div className="paging">
          <Pagination {...props} />
        </div>
      )}
    </div>
  );
};

export default withRouter(PagingBar);
