import * as React from "react";
import "./MobileHeader.scss";
import { ReactComponent as NotificationIcon } from "../../../images/notification.svg";
import { ReactComponent as CartIcon } from "../../../images/shopping-cart.svg";
import { ReactComponent as Logo } from "../../../images/new_logo.svg";
import dachnik_logo from "../../../images/dachnik_logo.png"
import ActiveBar from "../ActiveBar/ActiveBar";
import AccountType from "../AccountType/AccountType";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ProfileDropDown from "../ProfileDropDown/ProfileDropDown";
import NotificationButton from "../NotificationButton/NotificationButton";
import { setAccountType, setAccountTypeSelectVisible } from "../../../actions/profile";
import { toggleCategoriesMenu } from "../../../actions/category";
import { checkAddressExist } from "../../../actions/cart";
import LoginButton from "../ActiveBar/LoginButton";
import SideBar from "../../commons/SideBar/SideBar";
import MobileCategoriesMenu from "../categories/MobileCategoriesMenu";
import AccountTypeSelect from "./AccountTypeSelect";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import classNames from "classnames";
import { withRouter } from "react-router";
import MyCompanies from "../MyCompanies/MyCompanies";


class Mobile extends React.Component {
 
  onCardClick = () => {
    const { history, checkAddressExist } = this.props;

    if (checkAddressExist()) {
      history.push("/cart");
    }
  };

  toggleShowCategoriesMenu = () => {
    const { showCategoriesMenu, toggleCategoriesMenu } = this.props;
    toggleCategoriesMenu(!showCategoriesMenu);
  };

  onSelectAccountType = (accountType) => {
    const { setAccountType } = this.props;
    setAccountType(accountType).then(() => window.location.reload());
  };

  render() {
    const {
      cartItems,
      loggedIn,
      showCategoriesMenu,
      categories,
      accountType,
      accountTypeSelectVisible,
      shown,
      notificationCount,
      onClick,
      isMobile,
      isTablet,
      isDesktop,
      setAccountTypeSelectVisible,
    } = this.props;
    const { env } = global.config;

    return (
      <div className={classNames({ "mobile-header": true, [accountType]: true })}>
        <div className="top">
          <div className="items">
            <a className="logo" href={"/"}>
            {env !== "dachnik" ? <Logo /> : <img src={dachnik_logo} alt="Логотип Дачник"/>}
            </a>
          </div>
          <div className="items">
            {loggedIn && (
              <NotificationButton icon={<NotificationIcon />} link_to="#" accountType={accountType} notificationCount={notificationCount} shown={shown} onClick={onClick} />
            )}
            <NotificationButton icon={<CartIcon />} notificationCount={cartItems ? cartItems.itemsCount : 0} accountType={accountType} onClick={() => this.onCardClick()} />
            {loggedIn && <ProfileDropDown isMobile={true} />}
            {!loggedIn && <LoginButton isMobile={true} />}
          </div>
        </div>
        <ActiveBar isMobile={true} />
        {!isDesktop && loggedIn && <MyCompanies accountType={accountType} isMobile={true} />}

        {!loggedIn && <AccountTypeSelect onSelectAccountType={this.onSelectAccountType} visible={accountTypeSelectVisible} />}
        <SideBar active={showCategoriesMenu} toggleSideBar={() => this.toggleShowCategoriesMenu()}>
          <header className="sidebar-menu-header">

            <h1 className="sidebar-menu-title">Категории</h1>
            {(isMobile || isTablet) && (
              <AccountType
                accountType={accountType}
                setAccountType={setAccountType}
                accountTypeSelectVisible={accountTypeSelectVisible}
                setAccountTypeSelectVisible={setAccountTypeSelectVisible}
                loggedIn={loggedIn}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            )}
          </header>

          <MobileCategoriesMenu categories={categories} />
        </SideBar>
        {shown && <NotificationPopup onClick={onClick} />}
        {shown && <div className="notification-popup__underlay" />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
  showCategoriesMenu: state.categories.showCategoriesMenu,
  showProfileMenu: state.profile.showProfileMenu,
  loggedIn: state.profile.loggedIn,
  accountType: state.profile.accountType,
  accountTypeSelectVisible: state.profile.accountTypeSelectVisible,
  isMobile: state.api.isMobile,
  isTablet: state.api.isTablet,
  isDesktop: state.api.isDesktop,
  addressData: state.cart.registration.addressData,
  addressModalVisible: state.cart.addressModalVisible,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleCategoriesMenu: toggleCategoriesMenu,
      setAccountType: setAccountType,
      setAccountTypeSelectVisible: setAccountTypeSelectVisible,
      checkAddressExist: checkAddressExist,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Mobile));
