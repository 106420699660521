import React from "react";
import "./NotificationPopup.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import bell from "../../../images/bell_empty.svg";
import Notifications from "../Notifications/Notifications";
import { withRouter } from "react-router-dom";
import { readAllNotifications, getNotifications } from "../../../actions/notifications";
import { isBusinessAccount } from "../../../helpers/products";
import PopupModal from "../../commons/PopupModal/PopupModal";

function NotificationPopup({ onClick, notifications, readAllNotifications, getNotifications, userId, notificationsPageSize, accountId, accountType, shown }) {
    const handleReadAll = () => {
        readAllNotifications().then(() =>
            getNotifications(notificationsPageSize)
        );
    };

    const extraHeader = !!notifications.totalCount ? (
        <button className="popup-modal__button" onClick={handleReadAll}>
            Прочитать все
        </button>
    ) : null;

    return (
        <PopupModal empty={!notifications.totalCount} title="Уведомления" extraHeader={extraHeader} onClose={onClick}>
            {notifications.totalCount ? (
                <Notifications />
            ) : (
                <div className="popup-modal__empty-plug">
                    <img className="popup-modal__empty-plug-icon" src={bell} alt="Иконка колокольчик-заглушка" />
                    <p className="popup-modal__text">Здесь будут уведомления по вашим заказам, акциям.</p>
                </div>
            )}
        </PopupModal>
    );
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            readAllNotifications: readAllNotifications,
            getNotifications: getNotifications,
        },
        dispatch
    );

export default connect(
    (state) => ({
        categories: state.categories.categories,
        loggedIn: state.profile.loggedIn,
        accountType: state.profile.accountType,
        isMobile: state.api.isMobile,
        isTablet: state.api.isTablet,
        notifications: state.notifications.notifications,
        notificationsPageSize: state.notifications.notificationsPageSize,
        userId: state.profile.userId,
        accountId: state.profile.accountId,
    }),
    mapDispatchToProps
)(withRouter(NotificationPopup));
