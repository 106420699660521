import React, { useState } from 'react';
import "./SideBarFilter.scss";
import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import { productsSortItems } from "../../../../constants";

const SideBarFilter = ({ setSorting, sortingName }) => {
  const [ showOptions, setShowOptions ] = useState(false);
  const iconClass = showOptions ? "side-bar-filter-icon rotated" : "side-bar-filter-icon";
  const filterContentClass = showOptions ? "side-bar-filter opened" : "side-bar-filter";

  const handleSelect = (name) => {
    setSorting(name);
    setShowOptions(false);
  };

  const hanldeShowOptions = () => {
    setShowOptions(!showOptions);
  }

  const renderOptions = () => {
    return Object.keys(productsSortItems).map((sortType, index) => {
      return (
        <li key={`sort__item_${index}`} className="side-bar-filter-option" onClick={() => handleSelect(sortType)}>
          {productsSortItems[sortType].text}
        </li>
      );
    });
  };

  return (
    <div className={filterContentClass}>
      <div className="side-bar-filter-content" onClick={() => hanldeShowOptions()}> <span className='side-bar-filter-selected'>{productsSortItems[sortingName].text}</span>
        <div className={iconClass} >
          <Arrow />
        </div>

      </div>
      <ul className='side-bar-filter-options'>
        {showOptions && renderOptions()}
      </ul>
    </div>
  )
}

export default SideBarFilter;