import React from "react";
import "./InputWithHint.scss";
import Hint from "../../commons/Hint/Hint";
import Input from "../../commons/RamInput/Input";
import classNames from "classnames";
import { pickBy } from "ramda";

const InputWithHint = ({ text, errors = {}, hasError, errorText, ...props }) => {
  const classList = { "hint-input-wrap": true };

  if (props.customClass) {
    classList[props.customClass] = true;
  }

  const errorMsg = errors[props.name];
  return (
    <div className={classNames(classList)}>
      {!!text && <Hint text={text} side={props.hintSide || "top"} icon={props.rightImg} hasError={!!errorMsg} />}
      <Input {...pickBy((val, key) => key !== "rightImg", props)} hasError={!!errorMsg || hasError} max={props.max} errorText={errorMsg || errorText} />
    </div>
  );
};

export default InputWithHint;
