import React from "react";
import Dropzone from "react-dropzone-uploader";
import { ReactComponent as UploadIcon } from "../../../../images/upload.svg";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { getHeaders } from "../../../../helpers/rest";
import { Gap } from "../../Gap";
import "./ReviewImageUpload.scss";

const ReviewImageUpload = ({ iconUrl, userId, getUser }) => {

  const Input =
    (iconUrl, uploadMode) =>
    ({ accept, onFiles, files, getFilesFromEvent }) => {
      return (
        <div className="review-image-upload">
          <label>
            <div className="review-image-upload-content drop-zone-wrapper">
              <div className="review-image-upload-drop-zone drop-zone">
                <UploadIcon />
                <Gap size={10} />
                <h3 className="review-image-upload-title">Выберите изображения</h3>
                <Gap size={15} />
                <div className="review-image-upload-button">Выберите файл</div>

                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    getFilesFromEvent(e).then((chosenFiles) => {
                      onFiles(chosenFiles);
                    });
                  }}
                />
              </div>
            </div>
          </label>

          {uploadMode && (
            <div className="spinner">
              <div className="preloader">
                <div></div>
              </div>
            </div>
          )}
          {iconUrl && !uploadMode && <img src={iconUrl} className="photo" alt={"текущее фото"} />}
        </div>
      );
    };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const onChangeStatus = (fileWithMeta, status) => {
    console.log("-chs", fileWithMeta, status);
    if (status === "done") {
      getUser().then(() => fileWithMeta.remove());
    }
  };

  return (
    <Dropzone
      accept="image/*"
      getUploadParams={(data) => {
        const body = new FormData();
        body.append("file", data.file);
        return { body, url: `/api/buyer/v2.0/user-profile/users/${userId}/icon.upload`, headers: getHeaders(true, null, true) };
      }}
      onChangeStatus={onChangeStatus}
      maxFiles={1}
      autoUpload={true}
      InputComponent={Input(iconUrl)}
      SubmitButtonComponent={null}
      PreviewComponent={Input(null, true)}
      getFilesFromEvent={getFilesFromEvent}
    />
  );
};

export default ReviewImageUpload;
