import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../commons/Button/Button';
import "./OrderListItem.scss";
import moment from "moment";
import { separateThousands } from "../../../../../helpers/stringOperations";
import { addItemsFromOrder } from "../../../../../actions/cart";
import OrderStatus from '../../OrderStatus/OrderStatus';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { DELIVERY_TYPES } from '../../../../../constants';

const ITEMS_TO_SHOW = 3;

const OrderListItem = ({ order }) => {
  const [showMorePics, setShowMorePics] = useState(false);
  const [addingItemsToCart, setAddingItemsToCart] = useState(false)
  const { number, changedDate, totalSum, buyerStatus, items, id, deliveryDate, deliveryType, paymentStatus, payBefore, approveBefore, pickupInfo
  } = order;
  const isMobile = useSelector((state) => state.api.isMobile);
 const history = useHistory();
  const dispatch = useDispatch();

  const morePicsQuntity = items.length - ITEMS_TO_SHOW;
  const itemsToShow = showMorePics ? items : items.slice(0, ITEMS_TO_SHOW);
  const renderedPics = itemsToShow.map((i) => (i.mainImage ? <li key={`items-col_${i.title + i.productId}`}> <img className="order-list-item-pic" src={i.mainImage.previewUrl} alt={i.title} /> </li> : null))
  const paymentStatusClass = paymentStatus.code === "Paid" ? "order-list-item-payment-status paid" : "order-list-item-payment-status";
  const isDelivery = deliveryType.code === DELIVERY_TYPES[0];
  const pickupDate = pickupInfo && `${moment(pickupInfo?.pickupFrom).format("DD MMMM")} c ${moment.utc(pickupInfo?.pickupFrom).format("LT")} до ${moment.utc(pickupInfo?.pickupBefore).format("LT")}`
  const dateOfDelivery = isDelivery ? `${moment(deliveryDate).format("DD MMM").replace(".", "")}, с 08:00 до 20:00` : pickupDate;
  const deliveryTitle = isDelivery ? "Доставка" : " Самовывоз";
  
  
  
  const hanleShowMorePics = () => {
    setShowMorePics(true);
  };

  const handleRepeatOrder = (orderId) => {
    setAddingItemsToCart(true);
    dispatch(addItemsFromOrder(orderId)).then((res) => {
      setAddingItemsToCart(false);
      res.isUpdated === true && history.push("/cart");
    });
  };

  const renderPaymentStatus = () => {
    if (paymentStatus.code === "Paid") {
      return "Оплачен"
    } else if ((paymentStatus.code !== "Paid" && !payBefore) || buyerStatus.code === "Cancelled") {
      return ""
    } else if (paymentStatus.code !== "Paid" && payBefore && !buyerStatus.code !== "Cancelled" && !buyerStatus.code === "PendingApproval") {
      return `Оплатить до ${moment(payBefore).format("DD MMM hh:mm")}`
    } else if (buyerStatus.code === "PendingApproval") {
      return `Согласовать до ${moment(approveBefore).format("DD MMM hh:mm")}`
    }
  };

  return (
    <li className='order-list-item' key={id}>
      <div className='order-list-item-content'>
        <div className='order-list-item-info-wrap'>
          <div className='order-list-item-info'>
            <Link to={`?orderId=${id}`}>
              <h3 className="order-list-item-number">
                № {number} от  <span className="date">{moment(changedDate).format("DD MMM")} </span>
              </h3>
            </Link>
            <span className='order-list-item-delivery'>{deliveryTitle}: {dateOfDelivery}</span>
          </div>
          <div className="order-list-item-status">
            <OrderStatus status={buyerStatus} small={true} />
          </div>
        </div>

        <div className="order-list-item-sum-wrap">
          <span className="order-list-item-sum">{separateThousands(totalSum.toFixed(2))} ₽</span>
          <span className={paymentStatusClass}>{renderPaymentStatus()}</span>
        </div>
      </div>

      <div className="order-list-item-actions">
        <div className="order-list-item-pics">
          <ul className="order-list-item-pics-list">
            {renderedPics}
          </ul>
          {!showMorePics && morePicsQuntity > 0 && !isMobile && <span className="order-list-item-pics-more" onClick={hanleShowMorePics} >ещё {morePicsQuntity}</span>}
        </div>
        {!isMobile && <div className="order-list-item-button">
          <Button title="Повторить заказ" buttonSize="medium" buttonType="secondary" onClick={() => handleRepeatOrder(id)} loading={addingItemsToCart} />
        </div>}
      </div>
    </li>
  )
}

export default OrderListItem