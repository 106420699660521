import React, { useState } from "react";
import "./CategoriesList.scss";
import { last, values } from "ramda";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Scrollbar from "../../../commons/ScrollBar/ScrollBar";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setSearchFilterAndSearch } from "../../../../actions/product";
import { bindActionCreators } from "redux";

const CategoriesList = ({ categoriesByLevel, categoriesToShow, flat = false, setSearchFilterAndSearch, currentSupplier }) => {
  const [activeCategory, setActiveCategory] = useState("");
  // const { categoriesByLevel, categoriesToShow, categoryTree } = props;
  const levelCount = values(categoriesByLevel).length;
  const lastCategory = last(values(categoriesByLevel));
  const hasLevel1 = levelCount > 0;
  const hasLevel2 = levelCount > 1;
  const hasLevel3 = levelCount > 2;

  const renderCategoriesToShow = (linkToFunc, levelNum) => {
    if (!categoriesToShow) return;
    return categoriesToShow.map((category, index) => (
      <Link className={`level${levelNum}`} key={`cat_to_show_${index}`} to={linkToFunc(category)}>
        {category.title}
      </Link>
    ));
  };
  //

  const handleCategoryFilter = (params, id) => {
    makeSearch(params);
    setActiveCategory(id);
  };

  const renderFlatCategoriesToShow = () => {
    if (!categoriesToShow) return;
    return categoriesToShow.map((category, index) => (
      <li
        className={classNames({
          active: activeCategory === category.id,
          fixed: true,
          level1: true,
        })}
        key={`cat_to_show_${index}`}
        onClick={() => handleCategoryFilter({ categoryIds: [category.id] }, category.id)}
      >
        {category.title}
      </li>
    ));
  };

  const makeSearch = (params = {}) => {
    setSearchFilterAndSearch(params);
  };

  if (flat) {
    const itemsHeight = categoriesToShow.length * 32 + 52;

    const sideBarHeight = itemsHeight > 220 ? 220 : itemsHeight;

    return (
      <div className="sidebar-categories">
        <Scrollbar style={{ width: 214, height: sideBarHeight }}>
          <ul
            className={classNames({
              "categories-list": true,
            })}
          >
            <li
              className={classNames({
                active: activeCategory === "",
                level1: true,
              })}
              onClick={() => handleCategoryFilter({ ownerIds: [currentSupplier.id], categoryIds: [] }, "")}
            >
              Все
            </li>
            {renderFlatCategoriesToShow()}
          </ul>
        </Scrollbar>
      </div>
    );
  }

  if (!hasLevel1) {
    return null;
  }

  const intemsCount = (categoriesToShow ? categoriesToShow.length : 0) + levelCount;
  const sideBarHeight = intemsCount > 5 ? 205 : intemsCount > 3 ? 175 : 125;

  const getCategoryURI = (category) => {
    let uriBase = `${categoriesByLevel[1].id}`;
    if (category.level === 2) {
      uriBase = `${uriBase}/${category.id}`;
    } else if (category.level === 3) {
      uriBase = `${uriBase}/${categoriesByLevel[2].id}/${category.id}`;
    }
    return `/catalog/customerProducts/${uriBase}`;
  };

  return (
    <div className="sidebar-categories">
      <Scrollbar style={{ width: 214, height: sideBarHeight }}>
        <div
          className={classNames({
            "categories-list": true,
            [`active-level${levelCount}`]: true,
          })}
        >
          <Link
            className={classNames({
              active: !hasLevel2,
              fixed: true,
              level1: true,
            })}
            to={`/catalog/customerProducts/${categoriesByLevel[1].id}`}
          >
            {categoriesByLevel[1].title}
          </Link>
          {hasLevel2 && (
            <Link
              className={classNames({
                active: !hasLevel3,
                fixed: true,
                level2: true,
              })}
              to={`/catalog/customerProducts/${categoriesByLevel[1].id}/${categoriesByLevel[2].id}`}
            >
              {categoriesByLevel[2].title}
            </Link>
          )}
          {hasLevel3 && (
            <div
              className={classNames({
                active: true,
                fixed: true,
                level3: true,
              })}
            >
              {lastCategory.title}
            </div>
          )}
          {renderCategoriesToShow((category) => getCategoryURI(category), levelCount + 1)}
        </div>
      </Scrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.products.products.results,
    currentSupplier: state.products.currentSupplier,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearchFilterAndSearch: setSearchFilterAndSearch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoriesList));
