import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddressModalVisible, setRegistrationField, setAddressListModalVisible, detectMyAddressByIp, setDeliveryType, setPickupPoint, getPickupPoints, changeDeliveryType } from "../../../actions/cart";
import { addAddressToUser, updateUserAddress, setDefaultAddress } from "../../../actions/order";
import Modal from "../../commons/Modal/Modal";
import Button from "../../commons/Button/Button";
import "./AddressModal.scss";
import CheckBoxItem from "../../commons/CheckBoxItem/CheckBoxItem";
import { Gap } from "../../commons/Gap";
import PickUpPointsList from "../PickUpPointsList/PickUpPointsList";
import DeliveryAddressContent from "../DeliveryAddressContent/DeliveryAddressContent";
import { DELIVERY_TYPES } from "../../../constants";
import { addVirtualDeliveryAddress, loadVirtualDeliveryAddress } from "../../../helpers/virtualAddress";
import { saveVirtualDeliveryType } from "../../../helpers/virtualCard";


const AddressModal = () => {
  const [validContacts, setValidContacts] = useState({ recipientFullName: true, recipientPhone: true, recipientEmail: true });
  const [selectedPoint, setSelectedPoint] = useState(null);
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.cart.addressModalVisible);
  const showContacts = useSelector((state) => state.cart.addressContactsVisible);
  const { addressId, ...registration } = useSelector((state) => state.cart.registration);
  const addressData = useSelector((state) => state.cart.registration.addressData);
  const pickupPoint = useSelector((state) => state.cart.pickupPoint);
  const pickupPointsList = useSelector((state) => state.cart.pickupPointsList);
  const deliveryType = useSelector((state) => state.cart.deliveryType);
  const userData = useSelector((state) => state.profile.user);
  const isLogged = useSelector((state) => state.profile.loggedIn);
  const disabledSubmit = (deliveryType === DELIVERY_TYPES[0] && !addressData) || (deliveryType === DELIVERY_TYPES[1] && !selectedPoint);
  const { env } = global.config;

  useEffect(() => {
    deliveryType === DELIVERY_TYPES[0] && dispatch(detectMyAddressByIp());
    setSelectedPoint(pickupPoint || { id: pickupPointsList?.results[0]?.id, name: pickupPointsList?.results[0]?.name, address: pickupPointsList?.results[0]?.address, link: pickupPointsList?.results[0]?.link })
  }, [deliveryType]);

  const onClose = () => {
    dispatch(setAddressModalVisible(false));
  };

  const setUserInfoInputs = () => {
    const { email, phoneNumber, firstName, middleName, lastName } = userData;
    const fullName = `${lastName || ""} ${firstName || ""} ${middleName || ""}`;
    const phoneCodeIsRight = phoneNumber.includes("(");

    const formatedPhoneNumber = !phoneCodeIsRight
      ? phoneNumber.slice(0, 3) + "(" + phoneNumber.slice(3, 6) + ")" + phoneNumber.slice(6, 16)
      : phoneNumber;

    !registration.recipientFullName && dispatch(setRegistrationField("recipientFullName", fullName.trim()));
    !registration.recipientPhone && dispatch(setRegistrationField("recipientPhone", formatedPhoneNumber));
    !registration.recipientEmail && dispatch(setRegistrationField("recipientEmail", email));
  }

  const handleFieldChange = (fieldName) => (value) => {
    const trimmedValue = value.trim();
    dispatch(setRegistrationField(fieldName, value));
    validateContacts(fieldName, trimmedValue);
  };

  const saveAddress = () => {
    dispatch(addressId ? updateUserAddress(addressId, registration) : addAddressToUser())
      .then((res) => {
        isLogged && setUserInfoInputs();
        isLogged && dispatch(setDefaultAddress(addressId || res));
        onClose();
        dispatch(setAddressListModalVisible(false));
      })
      .catch((message) => {
        console.log("---address error msg", message);
      });
  };

  const validateContacts = (fieldName, value) => {
    if (fieldName === "recipientFullName") {
      const reg = /^(([А-ЯЁ][а-яё]+([-][А-ЯЁ][а-яё]+)?)(\s(?!$))*){1,3}$/;
      value === "" || reg.test(value) ? setValidContacts({ ...validContacts, recipientFullName: true }) : setValidContacts({ ...validContacts, recipientFullName: false });
    } else if (fieldName === "recipientEmail") {
      value === "" || value.includes("@") ? setValidContacts({ ...validContacts, recipientEmail: true }) : setValidContacts({ ...validContacts, recipientEmail: false });
    } else if (fieldName === "recipientPhone") {
      const reg = /^\+7\s\(\d{3}\)\s\d{3}(-\d{2}){2}$/;
      value === "" || reg.test(value) ? setValidContacts({ ...validContacts, recipientPhone: true }) : setValidContacts({ ...validContacts, recipientPhone: false });
    }
  };

  const handleSubmit = () => {
    if (deliveryType === DELIVERY_TYPES[0]) {
      validateContacts("recipientFullName", registration.recipientFullName.trim());
      setTimeout(() => {
        validateContacts("recipientPhone", registration.recipientPhone);
      }, 10);
      setTimeout(() => {
        validateContacts("recipientEmail", registration.recipientEmail);
      }, 20);
      setTimeout(() => {
        const isValid = validContacts.recipientEmail && validContacts.recipientFullName && validContacts.recipientPhone;
        isValid ? saveAddress() : console.log("Введите корректные данные в поля ввода");
      }, 30);
      saveVirtualDeliveryType(DELIVERY_TYPES[0])
    } else {
      isLogged && dispatch(setDefaultAddress("", selectedPoint.id, DELIVERY_TYPES[1]))
      dispatch(setPickupPoint(selectedPoint));
      addVirtualDeliveryAddress({ ...selectedPoint, defaultDeliveryType: DELIVERY_TYPES[1] })
      saveVirtualDeliveryType(DELIVERY_TYPES[1])
      onClose();
      dispatch(setAddressListModalVisible(false));
    }
  };

  const handleSwitchType = (value) => {
    dispatch(changeDeliveryType(value));
  }

  const handleSetPickupPoint = (e, point) => {
    e.preventDefault();
    e.stopPropagation()
    setSelectedPoint(point)
  }


  return (
    <div className="address-modal">
      <Modal onClose={() => onClose()} visible={visible}>
        <h2>Способ получения</h2>
        <div className="address-modal-types">
          <div className={`address-modal-type ${deliveryType === DELIVERY_TYPES[0] ? "selected" : ""}`} onClick={() => handleSwitchType(DELIVERY_TYPES[0])} ><CheckBoxItem checked={deliveryType === DELIVERY_TYPES[0]} onClick={() => handleSwitchType(DELIVERY_TYPES[0])} />
            <span>Доставка</span>
          </div>
          {env !== "dachnik" && <div className={`address-modal-type ${deliveryType === DELIVERY_TYPES[1] ? "selected" : ""}`} onClick={() => handleSwitchType(DELIVERY_TYPES[1])}><CheckBoxItem checked={deliveryType === DELIVERY_TYPES[1]} onClick={() => handleSwitchType(DELIVERY_TYPES[1])} /> <span>Самовывоз</span></div>}
        </div>
        <Gap size={20} />


        <div className="content">
          {deliveryType === DELIVERY_TYPES[0] && <DeliveryAddressContent addressData={addressData} onChange={handleFieldChange} isLogge={isLogged} showContacts={showContacts} registration={registration} validContacts={validContacts} />}
          {deliveryType === DELIVERY_TYPES[1] && <PickUpPointsList selectedPoint={selectedPoint} selectPoint={handleSetPickupPoint} pointsList={pickupPointsList} />}
        </div>
        {(!!addressData || !!pickupPoint || !!selectedPoint) && (
          <div className="dialog-buttons">
            <Button title="Подтвердить" buttonSize="medium" buttonType="primary" onClick={() => handleSubmit()} disabled={disabledSubmit} />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AddressModal;
