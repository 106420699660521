const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj, index) {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const groupByCategory = (data) => {
  return groupBy(data, 'groupField');
};

export const assingHelpDataType = (data, type) => {
  return data.map((item) => ({
    ...item,
    groupField: `${item.section}-${type}`,
    type,
  }));
};
