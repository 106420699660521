import { downloadBlobAsFile, fetchGetBlobBackend } from "../helpers/rest";

export function downloadPriceList(date) {
  return (dispatch, getState) => {
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";

    return fetchGetBlobBackend(`/api/${prefix}/v2.0/catalog/products/display-window.export`, isLoggedIn)
      .then((res) => {
        !!res.size && downloadBlobAsFile(res, `Прайс-лист за ${date}.xlsx`)
      });
  };
}

function uploadPriceListSuccess() {
  return {
    type: 'UPLOAD_PRICE_LIST'
  }
}

function uploadPriseListError() {
  return {
    type: 'UPLOAD_PRICE_LIST_ERROR'
  }
}

export function uploadPriceList(ownerId, file) {
  return dispatch => {
    return fetch(
      '/api/catalog/v1.0/pricelist/upload/xslx/' + ownerId,
      {
        credentials: "same-origin",
        method: "POST",
        headers: {
          "contentType": false,
          "processData": false,
        },
        body: file,
      }
    )
      .then(response => response.json())
      .then(json => {
        if (json.status === 400) {
          dispatch(uploadPriseListError())
        } else {
          dispatch(uploadPriceListSuccess());
        }
      });
  };
}

export function fetchPriceList(ownerId) {
  return dispatch => {
    // dispatch(fetchProductsPending());
    fetch('/api/catalog/v1.0/pricelist/' + ownerId)
      .then(res => res.json())
      .then(json => {
        // dispatch(setCurrentProduct(json));
      })
      .then(res => {
        if (res.error) {
          throw (res.error);
        }
        // dispatch(fetchProductsSuccess(res.products);
      }).then(json => console.log(json))
      .catch(error => {
        console.log('error')
        // dispatch(fetchProductsError(error));
      })
  }
}

export function removeMessages() {
  return {
    type: 'REMOVE_MESSAGES'
  }
}