import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as Minus } from "../../../../images/cart-minus.svg";
import { ReactComponent as Plus } from "../../../../images/cart-plus.svg";
import "./NoresidentModalEdit.scss";
import { roundTo } from "../../../../helpers/math";
import classNames from "classnames";

class NoresidentModalEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
      deliveryIncrement: 0,
      minimumDeliveryQuantity: 0,
      totalPrice: 0,
    };
  }

  componentDidMount() {
    const { deliveryIncrement, minimumDeliveryQuantity } = this.props.product;
    this.setState({
      deliveryIncrement,
      minimumDeliveryQuantity,
      quantity: minimumDeliveryQuantity,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { setTotalPrice, product } = this.props;
    const { quantity } = this.state;

    if (prevState.quantity !== quantity) {
      setTotalPrice(quantity * product.price);
    }
  }

  decreaseValue = (e) => {
    e.stopPropagation();
    const { deliveryIncrement, minimumDeliveryQuantity } = this.state;
    const { quantity } = this.state;
    const newQuantity = quantity - deliveryIncrement;

    if (newQuantity >= minimumDeliveryQuantity) {
      this.changeQuantity(newQuantity);
    }
  };

  increaseValue = (e) => {
    e.stopPropagation();
    const { quantity, deliveryIncrement } = this.state;
    const newQuantity = quantity + deliveryIncrement;
    this.changeQuantity(newQuantity);
  };

  handleChange = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      const newQuantity = parseInt(e.target.value || 0, 0);
      this.setState({ quantity: newQuantity });
    }
  };

  handleBlur = () => {
    const { quantity, deliveryIncrement, minimumDeliveryQuantity } = this.state;
    const newQuantity = () => {
      if (minimumDeliveryQuantity && quantity < minimumDeliveryQuantity) {
        return minimumDeliveryQuantity;
      } else if (minimumDeliveryQuantity && quantity < minimumDeliveryQuantity && minimumDeliveryQuantity < deliveryIncrement) {
        return deliveryIncrement;
      } else return roundTo(quantity, deliveryIncrement);
    };

    if (quantity !== newQuantity()) {
      this.changeQuantity(newQuantity());
    }
  };

  changeQuantity = (quantity) => {
    this.setState({ quantity });
  };

  render() {
    const { quantity, deliveryIncrement, minimumDeliveryQuantity } = this.state;
    const {
      product: { saleUnit },
      simple = false,
    } = this.props;
    const saleUnitTitle = saleUnit.shortTitle || saleUnit;
    const renderBtn = (onClick, icon) => (
      <div className="quantity-btn" onClick={onClick}>
        <div className="btn-tooltip">
          Продаётся по {deliveryIncrement} {saleUnitTitle}
        </div>
        {icon}
      </div>
    );
    return (
      <div className={classNames({ "item-edit": true, simple: simple })}>
        {renderBtn(this.decreaseValue, <Minus />)}
        <div className="input-wrapper">
          <input min={minimumDeliveryQuantity} value={quantity} onBlur={this.handleBlur} onChange={this.handleChange} onClick={(e) => e.stopPropagation()} />
          <div className="measure" onClick={(e) => e.stopPropagation()}>
            {saleUnitTitle}
          </div>
        </div>
        {renderBtn(this.increaseValue, <Plus />)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NoresidentModalEdit);
