import { saveAccountType, loadAuthData } from "../helpers/cookies";
import { EMPTY_RESULTS, REQUIRED_FIELDS, REQUIRED_FIELDS_SELF_EMP } from "../constants";

const isAccountFilled = (account) => {
  const { companyType } = account;
  const requiredFields = companyType.code === "SelfEmployedPerson" ? REQUIRED_FIELDS_SELF_EMP : REQUIRED_FIELDS;
  const hasValidationError = requiredFields.reduce((errAcc, fieldName) => !account[fieldName] || errAcc, false);
  return !hasValidationError;
};

const extractAccountData = (account) => {
  const status = account["http://rusagro.market/claims/accountstatus"];
  const accountId = account["http://rusagro.market/claims/accountid"];
  const accountName = account["http://rusagro.market/claims/accountname"];
  const iconUrl = account["http://rusagro.market/claims/iconurl"];
  const companyType = account["http://rusagro.market/claims/companytype"];
  return { status, accountId, accountName, iconUrl, companyType };
};

const tokenInfo = loadAuthData()?.jwt || null;
const initialAccountType = tokenInfo && tokenInfo["http://rusagro.market/claims/companytype"] === "naturalperson" ? "private" : "business";
const isNotProd = !global.config.isProd;

const EMPTY_DATA = {
  fullName: null,
  iconUrl: null,
  given_name: null,
  middle_name: null,
  family_name: null,
  email: null,
  phone: null,
};

const initialState = {
  loggedIn: false,
  status: null,
  data: EMPTY_DATA,
  user: null,
  account: null,
  userIconUrl: null,
  accountId: null,
  userId: null,
  section: null,
  tokenExpireAt: null,
  accountFilled: false,
  accountNotVerified: true,
  accountNotVerifiedModalVisible: false,
  isCompany: null,
  loaded: new Set(),
  accountTypeSelectVisible: isNotProd,
  accountType: initialAccountType,
  deferredPaymentInfo: null,
  myCompanies: EMPTY_RESULTS,
  myCompanyDefaultId: null,
  edoInfo: null
};

export default function profile(state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      //&& action.refreshToken
      const token = action.jwt;
      if (token) {
        //TODO: REMOVE
        const { status, accountId, accountName, iconUrl, companyType } = extractAccountData(token);
        ///

        const userId = token["sub"];
        const { given_name, middle_name, family_name, email } = token;
        const fullName = accountName ?? `${given_name} ${middle_name} ${family_name}`;
        const accountType = companyType === "naturalperson" ? "private" : "business";
        const data = {
          status,
          iconUrl,
          fullName,
          firstName: given_name,
          middleName: middle_name,
          lastName: family_name,
          email,
        };
        return {
          ...state,
          accountId,
          loggedIn: true,
          userId,
          isCompany: state.account?.companyType.code !== "NaturalPerson",
          accountType: accountType,
          accountTypeSelectVisible: false,
          data,
        };
      } else {
        return {
          ...state,
          loggedIn: false,
        };
      }
    case "LOGOUT":
      return {
        ...state,
        loggedIn: false,
        section: null,
        accountId: null,
        userId: null,
        data: EMPTY_DATA,
        user: null,
        isCompany: false,
        isLoaded: false,
        accountTypeSelectVisible: true,
        myCompanies: EMPTY_RESULTS,
        myCompanyDefaultId: null,
      };

    case "SET_USER_DATA":
      return {
        ...state,
        loaded: state.loaded.add("user"),
        user: action.user,
      };

    case "SET_DEFFERED_PAYMENT_INFO":
      return {
        ...state,
        deferredPaymentInfo: action.deferredPaymentInfo,
      };

    case "SET_ACCOUNT_DATA":
      return {
        ...state,
        account: action.account,
        accountFilled: isAccountFilled(action.account),
        loaded: state.loaded.add("account"),
        isCompany: action.account?.companyType.code !== "NaturalPerson",
      };

    case "SET_USER_INFO":
      const { status, accountId, accountName, iconUrl } = extractAccountData(action.userInfo);

      return {
        ...state,
        status,
        accountId,
        accountName,
        iconUrl,
        accountNotVerified: action.userInfo["http://rusagro.market/claims/userstatus"] === "awaiting_confirmation",
      };

    case "SET_SECTION":
      return {
        ...state,
        section: action.name,
      };
    case "SET_ACCOUNT_TYPE":
      saveAccountType(action.accountType);

      return {
        ...state,
        accountType: action.accountType,
        accountTypeSelectVisible: !action.accountType,
      };

    case "SET_TYPE_SELECT_VISIBLE":
      return {
        ...state,
        accountTypeSelectVisible: action.accountTypeSelectVisible,
      };

    case "SET_ACCOUNT_NOT_VERIFIED_MODAL_VISIBLE":
      return {
        ...state,
        accountNotVerifiedModalVisible: action.visible,
      };
    case "SET_MY_COMPANIES":
      return {
        ...state,
        myCompanies: action.myCompanies,
      };
    case "SET_MY_COMPANY_DEFAULT":
      return {
        ...state,
        myCompanyDefaultId: action.myCompanyDefaultId,
      };
    case "SET_MY_COMPANY_EDO_INFO":
      return {
        ...state,
        edoInfo: action.edoInfo,
      };
    default:
      return state;
  }
}
