import React, { useState } from "react";

import Modal from "../../commons/Modal/Modal";
import * as phonePng from "../../../images/phone-change.png";
import * as emailPng from "../../../images/email-change.png";
import PhoneInput from "../../commons/RamInput/PhoneInput";
import EmailInput from "../../commons/RamInput/EmailInput";
import Button from "../../commons/Button/Button";
import SecurityCodeInput from "./SecurityCodeInput";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";

const steps = {
  0: {
    phone: {
      header: "Изменение телефона",
      subheader: "Для совершения заказа необходимо подтвердить номер вашего мобильного телефона.",
    },
    email: {
      header: "Изменение электронной почты",
      subheader: "Для входа по электронной почте её необходимо подтвердить.",
    },
    save: {
      header: "Изменения сохранены",
    },
  },
  1: {
    phone: {
      header: "Подтверждение телефона",
      subheader: "Мы отправили код подтверждения на номер:",
    },
    email: {
      header: "Подтверждение электронной почты",
      subheader: "Мы отправили код подтверждения на почту:",
    },
    save: {
      header: "Изменения сохранены",
    },
  },
  2: {
    phone: {
      header: "Изменения сохранены",
    },
    email: {
      header: "Изменения сохранены",
    },
    save: {
      header: "Изменения сохранены",
    },
  },
};

const ConfirmationDialog = ({ type, onClose, visible, value, onConfirm, onSubmitPhoneOrEmail }) => {
  const [step, setStep] = useState(0);
  const [currentValue, setCurrentValue] = useState(value);

  const isPhone = type === "phone";
  const image = isPhone ? phonePng : emailPng;
  const mainInput = isPhone ? (
    <PhoneInput title="Телефон" mobileNumber={currentValue} onPhoneChange={(v) => onChange(v)} autofocus={true} />
  ) : (
    <EmailInput title="E-mail" value={currentValue} onChange={(v) => onChange(v)} />
  );
  const data = steps[step][type];
  const close = () => {

    if (step === 2) {
      onClose()
    } else onClose(true)

  };
  const next = () => {
    if (step === 0) {
      setStep(1);
    }
  };

  const onChange = (v) => {
    setCurrentValue(v);
    onConfirm(v)
  };

  const onSuccess = () => {
    !onSubmitPhoneOrEmail ? setStep(2)
      :
      onSubmitPhoneOrEmail()
  };

  return type !== "save" ? (
    <Modal onClose={close} visible={visible}>
      {step !== 2 && (
        <div className="image">
          <img src={image} alt="" />
        </div>
      )}
      {step === 2 && (
        <div className="check-icon">
          <CheckIcon />
        </div>
      )}
      <h2>{data.header}</h2>
      {step !== 2 && (
        <div className="sub-header">
          <span>{data.subheader}</span>
          {step === 1 && (
            <div>
              <span className="value">{currentValue}</span>
              <span className="change-btn" onClick={() => setStep(0)}>
                (изменить)
              </span>
            </div>
          )}
        </div>
      )}
      {step === 1 && <SecurityCodeInput authType={type} value={currentValue} onSuccess={() => onSuccess()} />}
      {step === 0 && mainInput}
      {step === 0 && (
        <div className="dialog-buttons">
          <Button title="Отправить код" buttonSize="medium" buttonType="primary" onClick={() => next()} />
          <div className="cancel-button" onClick={() => close()}>
            Нет, спасибо
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="dialog-buttons slim">
          <Button title="Спасибо" buttonSize="medium" buttonType="primary" onClick={() => close()} width="136px" />
        </div>
      )}
    </Modal>
  ) : (
    <Modal onClose={close} visible={visible} type="save">
      <div className="check-icon">
        <CheckIcon />
      </div>
      <h2>{data.header}</h2>

      <div className="dialog-buttons slim">
        <Button title="Спасибо" buttonSize="medium" buttonType="primary" onClick={() => close()} width="136px" />
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
