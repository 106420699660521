const initialState =
  {
    templateUrl: null,
    showSuccessMessage: false,
    showFailMessage: false,
  };

export default function priceList(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_PRICE_LIST':
      return {
        ...state,
        templateUrl: action.templateUrl
      };
    case 'UPLOAD_PRICE_LIST':
      return {
        ...state,
        showSuccessMessage: true,
        showFailMessage: false,
      };
    case 'REMOVE_MESSAGES':
      return {
        ...state,
        showSuccessMessage: false,
        showFailMessage: false
      };
    case 'UPLOAD_PRICE_LIST_ERROR':
      return {
        ...state,
        showSuccessMessage: false,
        showFailMessage: true
      };
    default:
      return state
  }
}