const initialState = {
  notifications: null,
  notificationsPageSize: 10,
  isNotificationFetching: false,
  isLoading: false,
};

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case "NOTIFICATIONS_LIST_FETCHING":
      return {
        ...state,
        isNotificationFetching: true,
      };

    case "RECEIVE_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.notifications,
        isNotificationFetching: false,
      };

    case "LOADING_NOTIFICATIONS":
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case "SET_NOTIFICATIONS_PAGE_SIZE":
      return {
        ...state,
        notificationsPageSize: action.notificationsPageSize,
      };

    default:
      return state;
  }
}
