import { pickBy, values, contains } from "ramda";

const initialState = {
  categories: [],
  currentCategory: null,
  isCategoriesListFetching: false,
  isCategoryFetching: false,
  attributes: [],
  showCategoriesMenu: false,
  categoryTree: [],
  categoriesByLevel: null,
  categoriesToShowByLevel: {},
  categoriesToShow: null,
  catalogPageTitle: "Каталог",
  menuSelected: [],
  isLoading: false,
};

export default function categories(state = initialState, action) {
  switch (action.type) {
    case "RECEIVE_ATTRIBUTES":
      return {
        ...state,
        attributes: action.attributes,
      };
    case "RECEIVE_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
      };
    case "RECEIVE_CATEGORY":
      return {
        ...state,
        currentCategory: action.category,
      };
    case "CLEAR_PRODUCT_DATA":
      return {
        ...state,
        currentCategory: null,
        attributes: [],
      };
    case "TOGGLE_CATEGORIES_MENU":
      return {
        ...state,
        showCategoriesMenu: action.showCategoriesMenu,
      };
    case "RECEIVE_CATEGORY_TREE":
      const levelCount = values(state.categoriesByLevel).length;
      const levelNum = levelCount + 1;
      const categories = action.categoryTree.filter((c) => c.productsCount > 0);
      const categoriesToShow = 3 > levelCount > 0 ? categories.filter((category) => category.level === levelNum) : null;
      // const { categoriesToShowByLevel } = state; //action.categoryTree.filter(category => category.level > levelNum);
      // categoriesToShowByLevel[2] = action.categoryTree.filter(category => category.level === 2);
      // categoriesToShowByLevel[3] = action.categoryTree.filter(category => category.level === 3);

      // if (levelNum < 2) {
      //   delete categoriesToShowByLevel[levelNum - 1];
      // }

      return {
        ...state,
        categoryTree: categories,
        categoriesToShow,
      };
    case "SET_CATEGORY_LEVEL":
      const level = action.category.level;
      const parentLevel = level - 1;
      let categoriesByLevel = pickBy((val, key) => key <= level, state.categoriesByLevel);
      categoriesByLevel[level] = action.category;
      categoriesByLevel[level].siblings = state.categoryTree.filter((c) => c.level === level && parentLevel && contains(categoriesByLevel[parentLevel].id, c.parentCategoryIds));
      return {
        ...state,
        categoriesByLevel,
        categoriesToShow: state.categoryTree.filter((c) => contains(action.category.id, c.parentCategoryIds)),
        catalogPageTitle: action.category.title,
      };

    case "CLEAR_SELECTED_CATEGORIES":
      return {
        ...state,
        categoriesToShow: null,
        categoriesByLevel: {},
        categoriesToShowByLevel: {},
        catalogPageTitle: "Каталог",
      };
    case "LOADING_CATEGORIES":
      return {
        ...state,
        isLoading: action.isLoading,
      };

    default:
      return state;
  }
}
