import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./Dashboard.scss";
import { Link } from "react-router-dom";
import { fetchUserOrders } from "../../../actions/order";
import * as dashboardBg from "../../../images/dashboard2.png";
import { ReactComponent as MarketplaceIcon } from "../../../images/marketplace.svg";
import { ReactComponent as AppIcon } from "../../../images/app.svg";
import { ReactComponent as TgIcon } from "../../../images/tg.svg";
// import { ReactComponent as WhatsappIcon } from "../../../images/whatsapp.svg";
import OrderStatus from "../OrdersInfo/OrderStatus/OrderStatus";
import { productsCountAsString } from "../../../helpers/stringOperations";
import NoAvatar from "../../Header/ProfileDropDown/NoAvatar";
import classNames from "classnames";
import * as fagImg from "../../../images/faq.png";
import moment from "moment";
import { CONTACTS } from "../../../constants";

class Dashboard extends React.Component {
  state = { appOrders: [] };


  componentDidMount() {
    const { user, fetchUserOrders } = this.props;
    fetchUserOrders(2, 0)
  }

  render() {
    const { isMobile, account, user, orders } = this.props;
    const { appOrders } = this.state;
    const { env } = global.config;

    const contacts = {
      phone: env !== "dachnik" ? CONTACTS.PHONE.nsk : CONTACTS.PHONE.dachnik,
      phone_text: env !== "dachnik" ? CONTACTS.PHONE_TEXT.nsk : CONTACTS.PHONE_TEXT.dachnik
    }

    const renderBoxWithHeader = (caption, itemsCount = 0, renderItems, emptyContent, moreUrl = "#") => {
      const hasData = itemsCount > 0;

      return (
        <div className="dashboard-box">
          <div className="header">
            <div className="caption">{caption}</div>
          </div>
          {hasData && renderItems()}
          {!hasData && emptyContent}
          {hasData && caption === "Заказы онлайн" && (
            <Link to={moreUrl} className="see-all">
              Смотреть все
            </Link>
          )}
        </div>
      );
    };
    const renderOrderItems = (data) => {
      return (
        <div className="box-content">
          {data.map((item) => (
            <div className="item" key={item.id}>
              <div className="info light">
                <Link to={`/profile/orders?orderId=${item.id}`} className="see-all">
                  №{item.number}
                </Link>
                <div className="sub">
                  {item.itemsCount} {productsCountAsString(item.itemsCount)}
                </div>
              </div>
              <OrderStatus status={item.buyerStatus} small={true} />
            </div>
          ))}
        </div>
      );
    };

    const renderAppOrderItems = (data) => {
      return (
        <div className="box-content">
          {data.map((item) => (
            <div className="item" key={item.id}>
              <div className="info">
                <h4 className="box-content-title">{item.productOwner}</h4>
                <div className="sub">{moment(item.createDate).calendar().replace(",", "").toLowerCase()}</div>
              </div>
              <div className="label">{item.totalSum} ₽ </div>
            </div>
          ))}
        </div>
      );
    };

    const renderAvatar = () => {
      const { iconUrl, firstName, lastName } = user;
      const fullName = `${firstName} ${lastName || ""}`;
      return (
        <div className="avatar-wrap">
          {iconUrl && <img src={iconUrl} className="avatar" alt={fullName} />}
          {!iconUrl && <NoAvatar firstName={firstName} lastName={lastName} />}
        </div>
      );
    };

    const renderCardInfo = () => {
      const { firstName, phoneNumber, email, createdDate } = user;
      const bgStyle = !isMobile ? { backgroundImage: `url(${dashboardBg})` } : null;
      const accountName = account?.legalName ? account.legalName : "Частное лицо";
      return (
        <div className="client-card" style={bgStyle}>
          <div className="user-line">
            {renderAvatar()}
            <div className="client-info">
              <div className="label">
                {firstName}, {phoneNumber}
              </div>
              <div className="sub-label">{email}</div>
            </div>
          </div>
          {env !== "dachnik" && <div
            className={classNames({
              "company-type": true,
              person: !account,
            })}
          >
            {accountName}
          </div>}
          <div className="created-at">На платформе с {moment(createdDate).format("DD MMM YYYY")}</div>
        </div>
      );
    };

    const renderSupport = () => (
      <div className="dashboard-box">
        <div className="header">
          <div className="caption">Служба поддержки</div>
        </div>
        <div className={classNames({ simple: true, col: isMobile, ["full-width"]: env === "dachnik"})}>
          <div className="simple-content">
            {env === "dachnik" && <div>  Если у вас возникли вопросы, звоните по телефону   <a className="bottom" href={`tel:+74993933407`}> {contacts.phone_text}</a>. Мы всегда рады ответить на них
              с пн-пт с 9:00 до 18:00 по мск </div>}
            {env !== "dachnik" && <>
              <div>Напишите нам в мессенджер или позвоните по телефону</div>

              {!isMobile && (
                <a className="bottom" href={`tel:${contacts.phone}`}>
                  {contacts.phone_text}
                </a>
              )}
            </>}

          </div>
         {env !== "dachnik" && <div className="dashboard-box-contacts">
            <a className="chat-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.TELEGRAM}>
              <TgIcon />
            </a>
            {/* <a className="chat-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.WHATSAPP}>
              <WhatsappIcon />
            </a> */}
            {/* {isMobile && (
              <a href={`tel:${PHONE_NUMBER}`} className="chat-icon phone">
                <PhoneIcon />
                {PHONE_NUMBER}
              </a>
            )} */}
          </div>}
        </div>
      </div>
    );

    const helpInfo = () => (
      <div className="row">
        {renderSupport()}
        <div className="dashboard-box">
          <div className="header">
            <div className="caption">Помощь</div>
          </div>
          <div className="simple">
            <div className="simple-content">
              <div className="dark">Ответы на ключевые вопросы по работе с сервисом</div>
              <div className="bottom">
                <Link to={"/help"}>Перейти в раздел</Link>
              </div>
            </div>
            <img src={fagImg} alt={"Помощь"} className="help-img" />
          </div>
        </div>
      </div>
    );

    const ordersMarketplaceEmptyContent = (
      <div className="empty">
        <div className="caption">Вы еще не совершали покупок на маркетплейсе</div>
        <Link className="info" to="/catalog/customerProducts">
          <MarketplaceIcon />В каталог товаров
        </Link>
      </div>
    );

    const ordersAppEmptyContent = (
      <div className="empty">
        <div className="caption">Вы еще не совершали покупок на ОРЦ </div>
        <Link className="info" to="/app-links">
          <AppIcon />
          Скачать приложение
        </Link>
      </div>
    );

    return (
      <div className="dashboard">
        {renderCardInfo()}
        <div className="row">
          {renderBoxWithHeader("Заказы онлайн", orders.results.length, () => renderOrderItems(orders.results), ordersMarketplaceEmptyContent, "/profile/orders")}
          {/* {renderBoxWithHeader("Покупки на ОРЦ", appOrders.length, () => renderAppOrderItems(appOrders.slice(0, 2)), ordersAppEmptyContent)} */}
        </div>
        {helpInfo()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.profile.account,
  user: state.profile.user,
  orders: state.order.orders,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserOrders: fetchUserOrders,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
