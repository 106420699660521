import React from "react";
import { Gap } from "../components/commons/Gap";
import AnchorLink from "react-anchor-link-smooth-scroll";

const { documentLinkUrl, baseUrl } = global.config;
const url = baseUrl;

export const helpLandingJson = {
  data: [
    {
      orderNumber: 1,
      section: "Что нужно для регистрации, какие документы?",
      question: "Что нужно для регистрации, какие документы?",
      answer: (
        <div className="helpQARoot__answer">

          <p className="helpQARoot__answer-text">
            Оставить
            <AnchorLink href="#application-form" offset="50">
              <span className="helpQARoot__answer-ancor" >
                заявку
              </span>
            </AnchorLink>

            на подключение.
          </p>
          <Gap size={20} />
          <h3 className="helpQARoot__answer-title">Подготовить документы:</h3>
          <ul className="helpQARoot__answer-list ">
            <li className="helpQARoot__answer-item">
              справка о цепочке собственников контрагента, включая бенефициаров (в том числе конечных) применимо для крупных сделок и иных требующих одобрения участников/акционеров общества;
            </li>
            <li className="helpQARoot__answer-item"> Устав;</li>
            <li className="helpQARoot__answer-item">выписка из Единого государственного реестра юридических лиц (ЕГРЮЛ);</li>
            <li className="helpQARoot__answer-item">свидетельство (при наличии) или иной документ, подтверждающий внесение
              сведений о юридическом лице в Единый государственный реестр юридических лиц (ЕГРЮЛ);
            </li>
            <li className="helpQARoot__answer-item">выписка из Единого государственного реестра юридических лиц (ЕГРЮЛ);</li>
            <li className="helpQARoot__answer-item">справка о применяемой системе налогообложения;</li>
            <li className="helpQARoot__answer-item">решение об одобрении сделки (даче согласия на совершение сделки) в случае, если требование о необходимости такого одобрения (согласия) установлено законом или учредительными документами юридического лица;
            </li>
            <li className="helpQARoot__answer-item">копии лицензий, выписок из членов СРО или иных разрешений, если право на осуществление деятельности, которую осуществляет контрагент, должно, в соответствии с законодательством Российской Федерации, подтверждаться определенным документом;
            </li>
            <li className="helpQARoot__answer-item">для иностранных юридических лиц – выписка из торгового реестра содержащая актуальные сведения о юридическом лице (полученная не ранее 6 (шести) месяцев до дня предоставления в Компанию), сертификат о благонадежности или иные аналогичные документы;
            </li>
            <li className="helpQARoot__answer-item">документ, подтверждающий полномочия лица на подписание договора (в случае, если договор подписывается лицом, действующим на основании доверенности).
            </li>
          </ul>
          <Gap size={20} />

          <p className="helpQARoot__answer-text">
            После рассмотрения заявки вам на почту придет ссылка на личный кабинет, где вам необходимо принять условия:
          </p>
          <Gap size={20} />
          <ul className="helpQARoot__answer-list none-list-style">
            <li className="helpQARoot__answer-item">
              <a href={`${documentLinkUrl}/agency_contract_supplier/`} target="_blank" rel="noopener noreferrer">
                Договор-оферта
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${documentLinkUrl}/license_agreement_supplier/`} target="_blank" rel="noopener noreferrer">
                Пользовательское соглашение
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${documentLinkUrl}/privacy_policy`} target="_blank" rel="noopener noreferrer">
                Политика конфиденциальности
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${documentLinkUrl}/privacy_agreement`} target="_blank" rel="noopener noreferrer">
                Согласие на обработку персональных данных
              </a>
            </li>
          </ul>
         
        </div>
      ),
    },
    {
      orderNumber: 2,
      section: "Как завести товары на rusagro.market?",
      question: "Как завести товары на rusagro.market?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
          Завести карточки товара в личном кабинете. Процесс создания можно посмотреть на
            <a href="https://www.youtube.com/watch?v=qdbEtvJloE0&ab_channel=%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9%D0%9C%D0%BE%D0%B8%D1%81%D0%B5%D0%B5%D0%B2" target="_blank" rel="noopener noreferrer">
              видео.
            </a>
            Мы готовы сопроводить вас на данном этапе, обращайтесь, мы будем рады вам помочь! 
          </p>

        </div>
      ),
    },
    {
      orderNumber: 3,
      section: "Кто будет продавать товар и кому ?",
      question: "Кто будет продавать товар и кому ?",
      answer: (
        <div className="helpQARoot__answer">

          <span>
            Мы берем на себя продажи, но вы можете вместе с нами привлекать своих клиентов и торговать с ними через нашу платформу.
          </span>
          <Gap size={20} />
          <span>
            Наши основные покупатели: несетевая розница, HoReСa: рестораны, кафе, гостиницы, сельпо, мелко сетевые магазины. Крупные и мелкие дистрибьюторы, предприятия, а также физические лица.

          </span>

        </div>
      ),
    },
    {
      orderNumber: 4,
      section: "Что я могу продавать на rusagro.market ?",
      question: "Что я могу продавать на rusagro.market ?",
      answer: (
        <div className="helpQARoot__answer">
          <ul className="helpQARoot__answer-list ">
            <li className="helpQARoot__answer-item">
              Овощи, грибы, зелень
            </li>
            <li className="helpQARoot__answer-item">
              Фрукты и ягоды
            </li>
            <li className="helpQARoot__answer-item">
              Рыба и  морепродукты
            </li>
            <li className="helpQARoot__answer-item">
              Икра
            </li>
            <li className="helpQARoot__answer-item">
              Мясо и птица
            </li>
            <li className="helpQARoot__answer-item">
              Мясная гастрономия
            </li>
            <li className="helpQARoot__answer-item">
              Яйцо
            </li>
            <li className="helpQARoot__answer-item">
              Молоко и молочные продукты
            </li>
            <li className="helpQARoot__answer-item">
              Бакалея
            </li>
            <li className="helpQARoot__answer-item">
              Мед, орехи, сухофрукты
            </li>
            <li className="helpQARoot__answer-item">
              Хлеб и хлебопекарные изделия
            </li>
            <li className="helpQARoot__answer-item">
              Кондитерские изделия
            </li>
            <li className="helpQARoot__answer-item">
              Вода, соки, напитки
            </li>
            <li className="helpQARoot__answer-item">
              Чай, кофе, какао
            </li>
            <li className="helpQARoot__answer-item">
              Детское питание
            </li>
            <li className="helpQARoot__answer-item">
              Диетические продукты
            </li>
            <li className="helpQARoot__answer-item">
              Полуфабрикаты, готовые блюда
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderNumber: 5,
      section: 'Что входит в "Комиссия за складские услуги"?',
      question: 'Что входит в "Комиссия за складские услуги"?',
      answer: (
        <div className="helpQARoot__answer">
          <span>
            Разгрузка машины, сборка заказа, погрузка, и прочие складские манипуляции. Сюда не входит разгрузка машины когда товар приходит валом.
          </span>
        </div>
      ),
    },
    {
      orderNumber: 6,
      section: 'Кто платит за доставку ?',
      question: 'Кто платит за доставку ?',
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            За доставку платит покупатель, тарифы:<a href={`${url}/help/2/1`} target="_blank" rel="noopener noreferrer">
              {` ${url}/help/2/1`}
            </a>
          </p>
        </div>
      ),
    },
    {
      orderNumber: 7,
      section: 'Где можно почитать договор поставщика?',
      question: 'Где можно почитать договор поставщика?',
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            <a href={`${documentLinkUrl}/agency_contract_supplier/`} target="_blank" rel="noopener noreferrer">
              Договор-оферта
            </a>
          </p>
        </div>
      ),
    },
    {
      orderNumber: 8,
      section: 'Как привезти товар на склад Русагро.маркет?',
      question: 'Как привезти товар на склад Русагро.маркет?',
      answer: (
        <div className="helpQARoot__answer">
          <span>
            В Личном кабинете создать заявку на поставку, после чего оператор свяжется с вами для подтверждения.
          </span>
        </div>
      ),
    },
    {
      orderNumber: 9,
      section: 'Могу ли я забрать свой товар обратно?',
      question: 'Могу ли я забрать свой товар обратно?',
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Да, для того чтобы забрать товар нужно направить письменное уведомление на
            <a href="mailto: support@rusagro.market">
              {` support@rusagro.market `}
            </a>
            о желании забрать товар. Оператор сориентирует вас когда товар будет готов к выдаче.
          </p>
        </div>
      ),
    },
    {
      orderNumber: 10,
      section: 'В течении какого времени происходит расчет за проданный товар?',
      question: 'В течении какого времени происходит расчет за проданный товар?',
      answer: (
        <div className="helpQARoot__answer">
          <span>
            Расчёт происходит в течении 5 рабочих дней, после того как деньги поступают на наш распределительный счёт.
          </span>
        </div>
      ),
    },

  ],
};
