import * as React from "react";
import "./Tabs.scss";
import classNames from "classnames";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: null,
      categoriesByLevel: null,
    };
  }

  componentDidMount() {
    const { selectedTab, tabs } = this.props;
    this.setState({ currentTab: selectedTab || Object.values(tabs)[0].name });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedTab } = this.props;
    if (selectedTab !== prevState.selectedTab) {
      this.setState({ currentTab: selectedTab });
    }
  }

  changeTab = (currentTab) => {
    this.setState({ currentTab });
  };

  render() {
    const { currentTab } = this.state;
    const { tabs } = this.props;

    const renderTabTitle = (tab) => {
      return (
        <div
          key={`tab_${tab && tab.name}`}
          className={classNames({
            title: !!tab,
            active: tab && currentTab === tab.name,
          })}
          onClick={() => this.changeTab(tab && tab.name)}
        >
          <div className="name">{tab && tab.title}</div>
          <div className="line"></div>
        </div>
      );
    };

    const renderTab = (tab) => {
      if (tab && tab.name !== currentTab) return null;

      return <div key={`tab_${tab && tab.name}`}>{tab && tab.content}</div>;
    };

    return (
      <div className="supplier-tabs">
        <div className="titles">{tabs.map((t, i) => t && renderTabTitle(t))}</div>
        <div className="tab-body">{tabs.map((t, i) => t && renderTab(t))}</div>
      </div>
    );
  }
}

export default Tabs;

