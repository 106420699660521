const initialState = {
  status: 'pending',
  data: {},
  error: null,
};

export default function help(state = initialState, action) {
  switch (action.type) {
    case '@help/SET_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case '@help/SET_DATA':
      return {
        ...state,
        data: action.payload,
        status: 'success',
      };
    case '@help/SET_ERROR':
      return {
        ...state,
        error: action.payload,
        status: 'error',
      };

    default:
      return state;
  }
}
