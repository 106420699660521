import React from 'react'
import Button from '../../../components/commons/Button/Button'
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./SuppliersLandingSmallBanner.scss";

const SuppliersLandingSmallBanner = () => {
  return (
    <div className='supplier-landing-small-banner'>
      <h4 className='supplier-landing-small-banner-text'>
        Для новых партнеров мы предоставляем
        <span className='supplier-landing-small-banner-accent'>  хранение до 5 тонн по цене 1₽ </span>
        сроком на 3 месяца
      </h4>
      <div className='supplier-landing-small-banner-button'>
      <AnchorLink href="#application-form" offset="50"  alt="Стать поставщиком">
        <Button title="Стать поставщиком" buttonSize="large" buttonType="primary" width="auto" />
      </AnchorLink></div>

    </div>
  )
}

export default SuppliersLandingSmallBanner