import moment from "moment";
import localization from "moment/locale/ru";
moment.updateLocale("ru", localization);

window.moment = moment;
export function emptyPromise(val = null) {
  return new Promise((resolve) => {
    resolve(val);
  });
}

export const getDateOrNull = (date, format = null) => (date ? moment(date, format) : null);
// moment('20.10.1997', 'DD.MM.YYYY').format('DD.MM.YYYY')

export function getWindowHeight() {
  return (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight;
}

export function getWindowWidth() {
  //   return (document && document.documentElement && document.documentElement.clientWidth) || window.outerWidth;
  return (document && document.documentElement && document.documentElement.clientWidth) || window.innerWidth;
}

export const listToObject = (lst, key = "code", value = "value") =>
  lst.reduce(function (map, obj) {
    map[obj[key]] = obj[value];
    return map;
  }, {});

export const getUUID = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getCurrentTimestamp = () => Math.round(new Date().getTime() / 1000);

export async function waitFor(test, timeout_ms = 20 * 1000) {
  return new Promise(async (resolve, reject) => {
    if (typeof timeout_ms != "number") reject("Timeout argument not a number in waitFor(selector, timeout_ms)");
    var freq = 100;
    var result;
    // wait until the result is truthy, or timeout
    while (result === undefined || result === false || result === null || result.length === 0) {
      // for non arrays, length is undefined, so != 0
      if (timeout_ms % 1000 < freq) console.log(`%cwaiting for: ${test}`, "color:#809fff");
      if ((timeout_ms -= freq) < 0) {
        console.log(`%cTimeout ${test}`, "color:#cc2900");
        resolve(false);
        return;
      }
      await sleep(freq);
      result = test(); // run the test and update result variable
    }
    // return result if test passed
    // console.log("Passed: ", test);
    resolve(result);
  });
}

export const getGalleryMainImage = (gallery) => {
  const mainIndex = getGalleryMainImageIndex(gallery);
  return mainIndex >= 0 ? gallery[mainIndex]?.previewUrl : null;
};

export const getGalleryMainImageIndex = (gallery) => {
  if (gallery && gallery.length) {
    let mainIndex = gallery.findIndex((i) => i?.isMain);
    if (mainIndex === -1) {
      const firstImageFileName = gallery.filter((item) => item).sort((a, b) => a?.orderNumber - b?.orderNumber)[0]?.fileName; // gallery.findIndex((i) => i.orderNumber == 0);
      mainIndex = gallery?.findIndex((i) => i?.imageName === firstImageFileName);
    }

    return mainIndex === -1 ? 0 : mainIndex;
  }

  return -1;
};

export const orderGallery = (gallery) => {
  return gallery && gallery.length ? [...gallery].filter((item) => item).sort((a, b) => (a?.orderNumber > b?.orderNumber ? 1 : -1)) : [];
};

export function swapArrayElements(arr, a, b) {
  arr[a] = arr.splice(b, 1, arr[a])[0];
}
