import React, { useState, useRef, useEffect } from "react";
import Input from "../../commons/RamInput/Input";
import moment from "moment";
import "./DateInput.scss";
import { useSelector } from "react-redux";

function DateInput({ name, value, handleFieldChange }) {
  const [hasError, setHasError] = useState(false);
  const [focus, setFocus] = useState(false);
  const dataInputRef = useRef();
  const errors = useSelector((state) => state.api.systemErrors?.errors);
  const hasDateError = !!errors?.find((item) => item.key === "dateOfBirth");
  const dateErrorText = errors?.find((item) => item.key === "dateOfBirth")?.value || "";
  let inputEnd = 0;

  const onFocus = () => {
    setFocus(true);
  };

  const handleClickOutside = (e) => {
    if (!dataInputRef.current.contains(e.target)) {
      setFocus(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  //Переформат даты для отправки на сервер
  const reFormatDate = (date) => {
    return moment(date).format("L");
  };

  const validateField = (value) => {
    const date = reFormatDate(value);

    if (date === "Invalid date" && value !== "__/__/____") {
      setHasError(true);
    } else setHasError(false);
  };

  const handleChange = (value) => {
    handleFieldChange(value);
    value && validateField(value);
  };

  const setInputRef = (element) => {
    if (!dataInputRef.current) {
      dataInputRef.current = element;
    }
  };

  const beforeMaskedValueChange = (newState) => {
    let { value } = newState;
    let selection = newState.selection;
    const reg = /(\(\d)/gi;

    const valueLength =
      Math.max(
        ...value
          .split("")
          .map((item, i) => {
            if (item !== "+" && item !== "_" && item !== "(" && item !== ")" && item !== " " && item !== "-") return i;
            return null;
          })
          .filter((item) => item)
      ) + 1;
    const valueIsEntered = reg.test(value);

    inputEnd = valueIsEntered ? valueLength : newState.selection?.end;
    selection = { start: inputEnd, end: inputEnd };

    return {
      value,
      selection,
    };
  };

  return (
    <>
      <div className="date-input-wrap" ref={dataInputRef}>
        <Input
          title="Дата рождения"
          mask="99/99/9999"
          name={name}
          hasError={hasDateError}
          value={value}
          onFocus={onFocus}
          onChange={handleChange}
          customRef={setInputRef}
          beforeMaskedValueChange={beforeMaskedValueChange}
          errorText={dateErrorText || hasError}
        />
      </div>
    </>
  );
}

export default DateInput;
