import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./SupplierFilter.scss";
import Filter from "../../../Filter/Filter";
import { find, propEq } from "ramda";

class SupplierFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      suppliers: {},
    };
  }

  componentDidMount() {
    this.init();
  }
  componentDidUpdate(prevProps, prevState) {
    const { activeSuppliers, ownerIds } = this.props;

    if (activeSuppliers !== prevProps.activeSuppliers || ownerIds !== prevProps.ownerIds) {
      this.init();
    }
  }

  init() {
    const { activeSuppliers, ownerIds } = this.props;
    if (ownerIds && activeSuppliers.length > 0) {
      const suppliers = Object.fromEntries(
        ownerIds.map((ownerId) => {
          const supplier = find(propEq("id", ownerId))(activeSuppliers);
          const { id, legalName } = supplier;
          return [id, legalName];
        })
      );
      this.setState({ suppliers });
    }
  }

  changeFilterOwnerIds = (key, value) => {
    const { search } = this.props;
    search({ ownerIds: value });
  };

  render() {
    const { searchParams, setSearchFilter, filterText, defalutStyle, mobileFilter } = this.props;
    const { suppliers } = this.state;
    const filterOwnerIds = searchParams.ownerIds || [];
    return (
      <div className="supplier-filter">
        <Filter
          title={"Продавец"}
          setActiveProductList={this.changeFilterOwnerIds}
          filter={suppliers}
          filterKey={"ownerIds"}
          selected={filterOwnerIds}
          searchActive={true}
          filterText={filterText}
          setSearchFilter={setSearchFilter}
          defaultStyle={defalutStyle}
          mobileFilter={mobileFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterOwnerIds: state.products.filterOwnerIds,
    activeSuppliers: state.cart.activeSuppliers,
    searchParams: state.products.searchParams,
    ownerIds: state.products.availableFilters.ownerIds,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierFilter);
