import React from "react";
import "./ErrorBox.scss";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";
import { useSelector } from "react-redux";

const ErrorBox = () => {
  const errors = useSelector((state) => state.api.systemErrors);
  const list = errors?.errors || null;

  if (!errors) return null;

  return (

    <div className="error-box">
        <InfoIcon />
      <div className="error-box-info">
        <span className="error-box-title">{errors?.message}</span>
        {errors.action && <span className="error-box-description">{errors.action}</span>}
        {!!list && <span className="error-box-list"> {list.join(",")}</span>}
      </div>
    </div>
  );
};

export default ErrorBox;
