import React from "react";
import "./ErrorFallback.scss";
import Button from "../../commons/Button/Button";
import errorFallBack from "../../../images/error_fall_back.png";
import { Gap } from "../Gap";
import { CONTACTS } from "../../../constants";

const { env } = global.config;
const isDacnhik = env === "dachnik";

function ErrorFallback() {
  const onClick = () => {
    window.location = "/";
  };
  const phone = !isDacnhik ? "8 962 840 40 00" : CONTACTS.PHONE;
  const phoneText = !isDacnhik ? "8 962 840 40 00" : CONTACTS.PHONE_TEXT;

  return (
    <div role="alert" className="error-fallback">
      <img className="error-fall-back-image" src={errorFallBack} alt="Картинка сообщающая об ошибке" />
      <Gap size={30} />
      <h2 className="error-fall-back-title">Упс, похоже, что-то пошло не так</h2>
      <Gap size={15} />
      <span className="error-fall-back-text">Обратитесь в службу поддержки</span>
      <a className="error-fall-back-link" href={`tel: ${phone}`}>{phoneText}</a>
      <Gap size={25} />
      <Button title="Обновить страницу" onClick={onClick} width="auto" buttonSize="medium" buttonType="primary" />
    </div>
  );
}


export default ErrorFallback;
