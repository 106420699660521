import * as React from "react";
import "./AccountType.scss";
import classNames from "classnames";
import { ReactComponent as ExpandIcon } from "../../../images/expand.svg";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";
// import {setSection} from "../../../actions/profile";

const ACCOUNT_TYPES = {
  business: "Для бизнеса",
  private: "Для частных лиц",
};

const AccountType = ({ accountType = "private", onSelectAccountType, accountTypeSelectVisible, setAccountTypeSelectVisible, loggedIn, isMobile, isTablet }) => {
  const onChange = (accountType) => onSelectAccountType(accountType);

  const handleClick = () => {
    (isMobile || isTablet) && setAccountTypeSelectVisible(!accountTypeSelectVisible);
  };

  return null;

  if (global.config.businessAccountsOnly || !loggedIn) return null;
 
  return (
    <div className={classNames({ "account-type": true, [accountType]: true, label: loggedIn })} onClick={handleClick}>
      <div className="dropdown">
        <div className="button">
          <span>{ACCOUNT_TYPES[accountType]}</span>
          {!loggedIn && <ExpandIcon />}
        </div>
        {!loggedIn && !isMobile && !isTablet && (
          <div className="dropdown-content">
            <div className="inner">
              {Object.entries(ACCOUNT_TYPES).map((a) => (
                <div className={classNames({ "dropdown-item": true, selected: a[0] === accountType })} key={`acc_type_${a[0]}`} onClick={() => onChange(a[0])}>
                  <span className="icon-wrapper">
                    <CheckIcon />
                  </span>
                  <span>{a[1]}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountType;
