import * as React from "react";
import "./Product.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSearchFilterAndSearch, setPageOffset, setPageExtraItemsCount } from "../../actions/product";
import { getCategoryTree } from "../../actions/category";
import Card from "./Card/Card";
import CardSkeleton from "./Card/Skeleton/Card";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import SideBar from "./SideBar/SideBar";
import PagingBar from "./PagingBar/PagingBar";
import queryString from "query-string";
import { withRouter } from "react-router";
import classNames from "classnames";
import { productsCountAsString } from "../../helpers/stringOperations";
import { range } from "ramda";
import { TITLE_TAG_DELIVERY_ZONE_CAPTION } from "../../constants";
import FixedButtons from "./ProductPage/FixedButtons/FixedButtons";
import MobileFilter from "./SideBar/MobileFilter/MobileFilter";
import MobileSorting from "./SortBar/MobileSorting/MobileSorting";
import { setSorting } from "../../actions/product";
import BackButton from "../commons/BackButton/BackButton";
import CategoriesTagsList from "./SideBar/CategoriesList/CategoriesTagsList";
import SideBarFilter from "./SideBar/SideBarFilter/SideBarFilter";


class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: null,
      isLoaded: false,
      searchQuery: null,
      showMobileFilter: false,
      showMobileSorting: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { category1, category2, category3 } = match.params;
    this.init(category1, category2, category3);
  }

  componentDidUpdate(prevProps) {
    const { catalogPageTitle } = this.props;
    const { searchQuery } = this.state;
    const query = queryString.parse(this.props.location.search);
    const prevQuery = queryString.parse(prevProps.location.search);
    const siteTitle = () => {    
        return searchQuery ? `Результаты по запросу «${searchQuery}»` : `${catalogPageTitle} оптом с доставкой ${TITLE_TAG_DELIVERY_ZONE_CAPTION}`     
    }

    document.title = siteTitle();
    if (query.offset !== prevQuery.offset) {
      const { setPageExtraItemsCount } = this.props;
      setPageExtraItemsCount(0);
    }

    if (this.props.sortingName !== prevProps.sortingName) {
      this.setState({ isLoaded: false });
      this.makeSearch();
    }

    if (this.props.location !== prevProps.location) {
      const { match } = this.props;
      const { category1, category2, category3 } = match.params;
      const rootCategoryId = category1 !== prevProps.category1 ? category1 : prevProps.category1;
      this.init(rootCategoryId, category2, category3);
    }
  }

  makeSearch() {
    const { location, setSearchFilterAndSearch } = this.props;

    const searchQuery = queryString.parse(location.search).search;
    const searchPromotionQuery = queryString.parse(location.search).HasPromotion;
    const searchTagsQuery = queryString.parse(location.search).TagsId;
    this.setState({ searchQuery });
    const hasPromotion = searchPromotionQuery === "true";
   
    return setSearchFilterAndSearch({ title: searchQuery, hasPromotion: hasPromotion, tagsId: searchTagsQuery }).then(() => this.setState({ isLoaded: true }));
  }

  init(rootCategoryId, category2, category3) {
    const { location, getCategoryTree, setPageOffset, setSorting } = this.props;
    const query = queryString.parse(location.search);
    const searchNewProductsQuery = queryString.parse(location.search).NewProducts;
    this.setState({ isLoaded: false });

    setPageOffset(query.offset);
    getCategoryTree(rootCategoryId, [category2, category3]).then(() => this.makeSearch());

    searchNewProductsQuery && setSorting("createDate");
  }

  backButtonClick = () => {
    const { match, history } = this.props;
    const { category1, category2, category3 } = match.params;
    let backLink;

    if (!category3 && !category2) {
      backLink = "/";
    } else if (!category3 && category2) {
      backLink = `/catalog/customerProducts/${category1}`;
    } else backLink = `/catalog/customerProducts/${category1}/${category2}`;

    history.push(backLink);
  };

  handleShowFilter = (value) => {
    this.setState({ showMobileFilter: value });
  };
  handleShowSorting = (value) => {
    this.setState({ showMobileSorting: value });
  };

  render() {
    const { products, categoriesByLevel, catalogPageTitle, totalCount, pageOffset, setPageExtraItemsCount, extraItemsCount, isMobile, isDesktop, sortingName, setSorting, location } =
      this.props;
    const { searchQuery, showMobileFilter, showMobileSorting } = this.state;
    const isLoaded = this.state.isLoaded;
    const hasProducts = products && !!products.length;
    const searchNewProductsQuery = queryString.parse(location.search).NewProducts;
    const searchPromotionQuery = queryString.parse(location.search).HasPromotion;
    const title = searchNewProductsQuery ? "Новинки" : searchPromotionQuery? "Товары со скидкой" : searchQuery ? `Результаты по запросу «${searchQuery}»` : catalogPageTitle;
    const productsCount = totalCount || 0;
    const productsCountLabel = `${productsCount} ${productsCountAsString(productsCount)}`;
    const skeletonArray = products.length ? range(1, products.length) : range(1, 13);
   
    const renderCardItems = () => products.map((product, index) => <Card key={`cart_item_${index}`} product={product} isMobile={isMobile} viewType={"rows"} />);
    const renderCardItemsSkeleton = () => skeletonArray.map((index) => <CardSkeleton key={`cart_item_${index}`} isMobile={isMobile} />);

    return (
      <div className="product-list">
        <section className="container">
          {isDesktop && <Breadcrumbs categoriesByLevel={categoriesByLevel} title={searchQuery && "Результаты поиска"} />}
          {!isDesktop && (
            <div className="top">
              <BackButton label="Назад" onClick={() => this.backButtonClick()} />
            </div>
          )}
        </section>
        <section className="container column">
          <h1 className="product-list-title">
            <span>{title}</span>
            <span className="product-count">{productsCountLabel}</span>
          </h1>
          <CategoriesTagsList />
        </section>
        <section className="container products-content">
          {isDesktop && <div className="container products-content-left">
            <SideBarFilter sortingName={sortingName} setSorting={setSorting} />
            <SideBar searchQuery={searchQuery} />
          </div>}

          <MobileFilter showFilter={showMobileFilter} onClose={this.handleShowFilter} />
          <MobileSorting showMobileSorting={showMobileSorting} onClose={this.handleShowSorting} sortingName={sortingName} setSorting={setSorting} />
          <div className="search-page-container">
            {isLoaded && !hasProducts && <div className="empty-list-msg">Список товаров пуст</div>}
            <div
              className={classNames("card-container", {
                "single-card": totalCount === 1,
              })}
            >
              {!isLoaded && renderCardItemsSkeleton()}
              {isLoaded && hasProducts && renderCardItems()}
            </div>
            <PagingBar totalCount={totalCount} offset={pageOffset} setPageExtraItemsCount={setPageExtraItemsCount} extraItemsCount={extraItemsCount} isMobile={isMobile} />
          </div>
          {!isDesktop && <FixedButtons showFilter={this.handleShowFilter} showSorting={this.handleShowSorting} />}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.products.results,
    totalCount: state.products.products.totalCount,
    pageOffset: state.products.pageOffset,
    extraItemsCount: state.products.extraItemsCount,
    categories: state.categories.categories,
    categoriesByLevel: state.categories.categoriesByLevel,
    categoryTree: state.categories.categoryTree,
    categoriesToShow: state.categories.categoriesToShow,
    catalogPageTitle: state.categories.catalogPageTitle,
    productsToShow: state.products.productsToShow,
    isProductListFetching: state.products.isProductListFetching,
    viewType: state.products.viewType,
    sortingName: state.products.sortingName,
    isMobile: state.api.isMobile,
    isDesktop: state.api.isDesktop,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearchFilterAndSearch: setSearchFilterAndSearch,
      getCategoryTree: getCategoryTree,
      setPageOffset: setPageOffset,
      setPageExtraItemsCount: setPageExtraItemsCount,
      setSorting: setSorting,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductList));
