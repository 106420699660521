import React from "react";
import { Gap } from "../Gap";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";
import Modal from "../Modal/Modal";
import "./SuccessfullPostModal.scss";

function SuccessfullPostModal({ text, onClose, visible }) {
  return (
    <div className="success-modal">
      <Modal onClose={() => onClose()} visible={visible}  type="save">
        <div className="success-modal-icon">
          <CheckIcon />
        </div>
        <Gap size={15} />
        <h2 className="success-modal-title">{text}</h2>
      </Modal>
    </div>
  );
}

export default SuccessfullPostModal;
