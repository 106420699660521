import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as Minus } from "../../../../../images/cart-minus.svg";
import { ReactComponent as Plus } from "../../../../../images/cart-plus.svg";
import "./Edit.scss";
import { updateCart, removeFromCart } from "../../../../../actions/cart";
import { roundTo } from "../../../../../helpers/math";
import classNames from "classnames";

class EditProductInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
      deliveryIncrement: 0,
      minimumDeliveryQuantity: 0,
      updatePromise: null,
    };
  }

  componentDidMount() {
    const { handleRecounting } = this.props;
    this.init();
    setTimeout(() => {
      handleRecounting && this.handleRecount();
    }, 100);
  }

  init() {
    const { cartItem } = this.props;
    const { deliveryIncrement, minimumDeliveryQuantity, quantity } = cartItem;
    this.setState({
      deliveryIncrement,
      minimumDeliveryQuantity,
      quantity: quantity,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { cartItem } = this.props;
    if (cartItem !== prevProps.cartItem) {
      // this.init();
    }
  }

  decreaseValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { removeFromCart, cartItem } = this.props;
    const { deliveryIncrement, minimumDeliveryQuantity } = this.state;
    const { quantity } = this.state;
    const newQuantity = quantity - deliveryIncrement;
    // console.log("----decreaseValue", newQuantity, quantity, deliveryIncrement);

    if (newQuantity >= minimumDeliveryQuantity) {
      this.changeQuantity(newQuantity);
    }

    if (newQuantity < minimumDeliveryQuantity) {
      removeFromCart(cartItem);
    }
  };

  increaseValue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { quantity, deliveryIncrement } = this.state;
    const newQuantity = quantity + deliveryIncrement;
    this.changeQuantity(newQuantity);
  };

  handleRecount = () => {
    const { handleRecounting } = this.props;
    this.handleBlur();
    handleRecounting(false);

  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleBlur();
    }
  };

  handleChange = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      const newQuantity = parseInt(e.target.value || 0, 0);
      this.changeQuantity(newQuantity);
    }
  };

  handleRemoveItem = () => {
    const { removeFromCart, cartItem, handleCartIsUpdating } = this.props;
    handleCartIsUpdating && handleCartIsUpdating(true);
    removeFromCart(cartItem).then(() => handleCartIsUpdating && handleCartIsUpdating(false))
  }

  handleBlur = () => {
    const { quantity, deliveryIncrement, minimumDeliveryQuantity } = this.state;
    const { cartItem } = this.props;
    const { stockBalance } = cartItem;
    const newQuantity = () => {
      if (minimumDeliveryQuantity && quantity < minimumDeliveryQuantity) {
        return quantity === 0 ? 0 : minimumDeliveryQuantity;
      } else if (minimumDeliveryQuantity && quantity < minimumDeliveryQuantity && minimumDeliveryQuantity < deliveryIncrement) {
        return deliveryIncrement;
      } else if (quantity > stockBalance) {
        return roundTo(stockBalance, deliveryIncrement);
      } else return roundTo(quantity, deliveryIncrement);
    };

    if (newQuantity() === 0) {
      this.handleRemoveItem();
    } else if (quantity !== newQuantity()) {
      this.changeQuantity(newQuantity());
    };
  }



  changeQuantity = (quantity) => {
    const { cartItem, cartItems, updateCart, handleCartIsUpdating, loggedIn } = this.props;
    const { stockBalance } = cartItem;
    const maxQuantity = quantity >= stockBalance;
    if (maxQuantity) {
      this.setState({ quantity: stockBalance })
    }
    this.setState({ quantity });

    handleCartIsUpdating && handleCartIsUpdating(true);

    const newCartItemsList = cartItems.items.map((item) => {
      if (cartItem.productId === item.productId) {
        return { ...item, quantity: maxQuantity ? stockBalance : quantity, quantityBefore: item.quantity };
      } else {
        delete item.quantityBefore;
        return item;
      }
    });

    if (this.state.updatePromise && this.state.updatePromise.cancel) {
      this.state.updatePromise.cancel();
    }

    const updatePromise = updateCart(newCartItemsList)
      .then(() => {
        //loggedIn тут временно. Пока корзина не станет нормально работать для неавторизованных пользователей
        loggedIn && handleCartIsUpdating && handleCartIsUpdating(false);
      })


    this.setState({ updatePromise });
  };

  render() {
    const { quantity, deliveryIncrement } = this.state;
    const {
      cartItem: { saleUnit, stockBalance, sum },
      simple = false,
      large,
      galleryView,
      bonusBalance,
      bonusProduct
    } = this.props;

    const stockLimitOff = stockBalance && stockBalance <= quantity || stockBalance - quantity < deliveryIncrement;
    const bonusBalanceExeceeded = !!bonusProduct && bonusBalance <= sum;
    const buttonDisabled = stockLimitOff || bonusBalanceExeceeded;
    const saleUnitTitle = saleUnit?.shortTitle || saleUnit;

    const renderBtn = (onClick, type, disabled = false) => {
      const btnClass = disabled ? "quantity-btn disabled" : "quantity-btn";
      const decreaseType = type === "dec";
      const icon = decreaseType ? <Minus /> : <Plus />;

      const renderTooltip = () => {
        if (bonusBalanceExeceeded && !decreaseType) {
          return <span> Недостаточно перецкойнов </span>;
        };

        if (!stockLimitOff || (stockLimitOff && decreaseType)) {
          return (
            <span>
              Продаётся по {deliveryIncrement} {saleUnitTitle}
            </span>
          );
        } else if (stockLimitOff && !decreaseType) {
          return <span> В наличии больше нет </span>;
        }
      };
      return (
        <div className={btnClass} onClick={disabled ? null : onClick}>
          <div className="quantity-btn-tooltip">{renderTooltip()}</div>
          {icon}
        </div>
      );
    };
    return (
      <div className={classNames({ "item-edit": true, simple: simple, large, "max-width": galleryView })}>
        {renderBtn(this.decreaseValue, "dec")}
        <div className="input-wrapper">
          <input
            value={quantity}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={this.handleKeyDown}
          />
          <div className="measure" onClick={(e) => e.stopPropagation()}>
            {saleUnitTitle}
          </div>
        </div>
        {renderBtn(this.increaseValue, "inc", buttonDisabled)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.profile.loggedIn,
    bonusBalance: state.profile.account?.bonusBalance,
    cartItems: state.cart.cartItems,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCart: updateCart,
      removeFromCart: removeFromCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditProductInput);
