import { assingHelpDataType, groupByCategory } from '../helpers/help';
import { fetchGetBackend } from '../helpers/rest';

export const fetchHelpData = () => {
  return async (dispatch) => {
    return Promise.all([
      fetchGetBackend(`/api/references/v1.0/faq/list?companyrole=buyer`),
      fetchGetBackend(`/api/references/v1.0/faq/list?companyrole=supplier`),
    ])
      .then((result) => {
        const [buyers, suppliers] = result;
        dispatch(
          helpSetData(
            groupByCategory([
              ...assingHelpDataType(buyers, 'buyers'),
              ...assingHelpDataType(suppliers, 'suppliers'),
            ]),
          ),
        );
      })
      .catch((error) => {
        dispatch(helpSetData([]));
        dispatch(helpSetError(error));
      });
  };
};

export const helpSetStatus = (payload) => ({
  type: '@help/SET_STATUS',
  payload,
});

export const helpSetData = (payload) => ({
  type: '@help/SET_DATA',
  payload,
});

export const helpSetError = (payload) => ({
  type: '@help/SET_ERROR',
  payload,
});
