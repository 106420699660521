import { clone } from "ramda";
import { addVirtualCardItem, loadVirtualCardItems, updateVirtualCardItem, removeVirtualCardItem, productToCartItem } from "../helpers/virtualCard";
import { loadAccountType } from "../helpers/cookies";

const virtualCartItemsEmpty = {
  id: "virtual",
  accountType: loadAccountType(),
  items: [],
  itemsCount: 0,
  ownerId: null,
  status: "Virtual",
  statusChanged: null,
  totalSum: 0,
  totalVat: 0,
};

const registrationEmpty = {
  addressId: null,
  addressData: null,
  fullAddress: "",
  address: "",
  apartment: "",
  entrance: "",
  floor: "",
  details: "",
  detailsChanged: false,
  interphone: null,
  cashOnDelivery: false,
  deliveryDate: null,
  addressType: "Appartment",
  addressErrorMessage: null,
  recipientFullName: "",
  recipientPhone: "",
  recipientEmail: "",
  isDefault: false,
  paymentServiceId: "Tochka", //Qiwi
  paymentMethod: "",
  addressList: [],
  useAutoApproval: true,
};

const initialState = {
  cart: null,
  cartItems: { items: [] },
  cartProductsIds: [],
  activeSuppliers: [],
  isLoading: true,
  waitForAccountAccomplishFlag: false,
  addressModalVisible: false,
  addressListModalVisible: false,
  addressContactsVisible: false,
  registration: registrationEmpty,
  deliveryType: "DoorDelivery",
  activeStep: 0,
  pickupPoint: null,
  pickupPointsList: null,
};

export default function cart(state = initialState, action) {
  switch (action.type) {
    case "RECEIVE_CART":
      return {
        ...state,
        cart: action.cart,
      };

    case "REMOVE_CART":
      return {
        ...state,
        cart: null,
        cartItems: { items: [] },
      };

    case "RECEIVE_CART_ITEMS":
      return {
        ...state,
        cartItems: action.cartItems,
      };

    case "SET_ADDRESS_MODAL_VISIBLE":
      return {
        ...state,
        addressModalVisible: action.addressModalVisible,
      };

    case "SET_ADDRESS_LIST_MODAL_VISIBLE":

      return {
        ...state,
        addressListModalVisible: action.addressListModalVisible,
      };

    case "SET_ADDRESS_CONTACTS_VISIBLE":
      return {
        ...state,
        addressContactsVisible: action.addressContactsVisible,
      };

    case "SELECT_ACTIVE_SUPPLIERS":
      return {
        ...state,
        activeSuppliers: action.activeSuppliers,
      };

    case "LOADING_CART":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_REGISTRATION_FIELD":
      const registration = clone(state.registration);
      registration[action.name] = action.value;
      registration["detailsChanged"] = action.name === "details";

      return {
        ...state,
        registration,
      };

    case "SET_REGISTRATION_FULL":
      return {
        ...state,
        registration: { ...action.registration, detailsChanged: false, errorMessage: null },
      };

    case "RESET_REGISTRATION":
      return {
        ...state,
        registration: {
          ...state.registration,
          addressData: null,
          fullAddress: null,
          addressId: null,
          apartment: null,
          entrance: null,
          floor: null,
          interphone: null,
          details: null,
          detailsChanged: false,
          errorMessage: null,
        },
      };

    case "CLEAN_REGISTRATION":
      return {
        ...state,
        registration: { ...registrationEmpty, addressList: state.registration.addressList }, // { ...state.registration, addressData: null },
      };

    case "SET_DELIVERY_TYPE":
      return {
        ...state,
        deliveryType: action.deliveryType,
      };

    case "SET_PICKUP_POINT":
      return {
        ...state,
        pickupPoint: action.pickupPoint,
      };

    case "FETCH_PICKUP_POINTS":
      return {
        ...state,
        pickupPointsList: action.pickupPointsList
      };

    case "LOAD_VIRUAL_CART_ITEMS":
      const cartItems = loadVirtualCardItems() || virtualCartItemsEmpty;
      return {
        ...state,
        cartItems,
      };

    case "ADD_TO_VIRTUAL_CART":
      return {
        ...state,
        cartItems: addVirtualCardItem(state.cartItems, action.product),
      };

    case "REMOVE_FROM_VIRTUAL_CART":
      return {
        ...state,
        cartItems: removeVirtualCardItem(state.cartItems, action.product),
      };

    case "UPDATE_CART_ITEMS":
      const cartItemsItems = state.cartItems.items.map((item) => {
        const product = action.products.find((p) => p.productId === item.productId && p.productOwnerId === item.productOwnerId);
        return product ? { ...item, quantity: product.quantity } : item;
      });

      return {
        ...state,
        cartItems: { ...state.cartItems, items: cartItemsItems, itemsCount: cartItemsItems.length },
      };

    case "ADD_CART_ITEM":
      return {
        ...state,
        cartItems: { ...state.cartItems, itemsCount: state.cartItems.itemsCount + 1, items: [...state.cartItems.items, productToCartItem(action.product)] },
      };

    case "REMOVE_CART_ITEM":
      return {
        ...state,
        cartItems: { ...state.cartItems, itemsCount: state.cartItems.itemsCount - 1, items: state.cartItems.items.filter((i) => i.productId !== action.product.productId) },
      };

    case "UPDATE_VIRTUAL_CART":
      return {
        ...state,
        cartItems: updateVirtualCardItem(state.cartItems, action.products),
      };

    case "SET_WAIT_FOR_ACCOUNT_ACCOMPLSH_FLAG":
      return {
        ...state,
        waitForAccountAccomplishFlag: action.value,
      };

    case "SET_CART_ACTIVE_STEP":
      return {
        ...state,
        activeStep: action.step,
      };
    default:
      return state;
  }
}
