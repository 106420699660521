import React from "react";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import "./IsNotByuerModal.scss";
import { ReactComponent as Icon } from "../../../images/lightning.svg";
import { redirectToLogin } from "../../../helpers/cookies";
const { authUrlForSupplier } = global.config;

export const redirectToLK = () => {
  window.location.href = authUrlForSupplier.split('auth')[0];
};


const IsNotByuerModal = ({ onClose, visible }) => {
  return (
    <div className="not-buyer-modal">
      <Modal onClose={onClose} visible={visible}>
        <div className="not-buyer-modal-content">
          <h2 className="not-buyer-modal-title">Вы вошли как поставщик</h2>
          <div className="not-buyer-modal-icon-wrap">
            <div className="not-buyer-modal-icon"><Icon /></div>
          </div>
          <p className="not-buyer-modal-text">Данный функционал доступен для покупателей, пожалуйста, пройдите регистрацию как покупатель или вернитесь в личный кабинет поставщика.</p>
          <div className="not-buyer-modal-buttons">
            <Button title="Назад в ЛК" buttonSize="medium" buttonType="primary" onClick={redirectToLK} />
            <Button title="Регистрация" buttonSize="medium" buttonType="subtle" onClick={redirectToLogin} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IsNotByuerModal;
