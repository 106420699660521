import React, { useState } from "react";
import "./SupplierItems.scss";
import { ReactComponent as DeleteRoundIcon } from "../../../images/delete-round.svg";
import { ReactComponent as ArrowIcon } from "../../../images/arrow-forward.svg";
import Price from "../../Product/Card/Price/Price";
import Edit from "../../Product/Card/Actions/Edit/Edit";
import { Link } from "react-router-dom";
import { productsCountAsString } from "../../../helpers/stringOperations";
import OrderItemStatus from "./OrderItemStatus/OrderItemStatus";
import classNames from "classnames";
import ReviewsModal from "../../commons/Reviews/ReviewsModal/ReviewsModal";
import SupplierItemUnavailable from "./SupplierItemUnavailable/SupplierItemUnavailable";

const SupplierItem = ({ groupItem, removeFromCart, isMobile, readOnly, accountType, handleCartIsUpdating, cartIsUpdating, mini  }) => {
  const { productOwner, tradeName, items, totalSum } = groupItem;
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentItemSupplier, setCurrentItemSupplier] = useState(null);
  const [touchPosition, setTouchPosition] = useState(null);
  const [recounting, setRecounting] = useState(false);
  
  //temp sum, before cart v2 
  const totalSumWithoutBonuses = items.map((item) => {
    const promotedPrice = item?.prices?.filter((item) => !item.isSystem).map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value;
    const promotedProduct = item?.tags?.length > 0 && !!promotedPrice;
    if (!item.isBonusItem && !promotedProduct) {
      return item.sum
    } else if (promotedProduct || !item.isBonusItem && !item.sum) {

      const productPrice = item?.prices?.filter((item) => item.isSystem).map((p) => p.segments).flat().find((p) => p.minQuantity === 1)?.value;
      const promotedPrice = item?.prices?.filter((item) => !item.isSystem).map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value;
      const price = promotedProduct ? promotedPrice : productPrice;
      const tempSum = price * item.quantity;
      return +tempSum
    }
  })
    .reduce((a, b) => {
      return a + b
    }, 0)


  const handleShowReviewsModal = () => {
    setShowReviewsModal(!showReviewsModal);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleRecounting = (value) => {
    setRecounting(value)
  };

  const handleTouchMove = (e, id, supplierId) => {
    setCurrentItemId(id);
    setCurrentItemSupplier(supplierId);
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      if (currentItemIndex < 1) {
        setCurrentItemIndex(1);
      }
    }

    if (diff < -5) {
      if (currentItemIndex > 0) {
        setCurrentItemIndex(0);
      }
    }

    setTouchPosition(null);
  };

  const isItemUnavailible = (item) => {
    const { itemStatus, productStatus } = item;

    const itemNotActive = productStatus && productStatus.code !== "Active";
    const itemOutOfStock = itemStatus && itemStatus.code === "OutOfStock";
    return itemOutOfStock || itemNotActive;
  };

  const isItemBusinessOnly = (item) => item.price === 0 && accountType === "private";

  const renderWeightInfo = (item) => {
    const { saleUnit, originalQuantity, approvedQuantity } = item;

    return (
      <ul className="weight-info">
        <li
          className={classNames("weight-info-item", {
            original: originalQuantity !== approvedQuantity,
          })}
        >
          {originalQuantity || 0} {saleUnit && saleUnit.shortTitle}
          {originalQuantity !== approvedQuantity && <span className="weight-info-arrow"> &#8594;</span>}
        </li>
        {originalQuantity !== approvedQuantity && (
          <li className="weight-info-item">
            {approvedQuantity || 0} {saleUnit && saleUnit.shortTitle}
          </li>
        )}
      </ul>
    );
  };

  const renderTotal = (item) => {
    const { saleUnit, originalSum, sum } = item;

    const promotedProduct = item?.tags?.length > 0;
    const productPrice = item?.prices?.filter((item) => item.isSystem).map((p) => p.segments).flat().find((p) => p.minQuantity === 1)?.value;
    const promotedPrice = item?.prices?.filter((item) => !item.isSystem).map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value;
    const price = promotedProduct ? promotedPrice : productPrice;

    const tempSum = price * item.quantity;
    const productSum = promotedProduct ? tempSum : (originalSum || sum || tempSum);

    if (isItemUnavailible(item) && isItemBusinessOnly(item)) return null;
    const sumChanged = originalSum && originalSum !== sum;
    return (
      <ul className="total">
        <li
          className={classNames("total-item", {
            original: sumChanged,
          })}
        >
          <Price product={{ price: productSum, saleUnit: saleUnit && saleUnit.shortTitle }} decimal={true} unit={false} bonusProduct={item.isBonusItem} />
          {sumChanged && <span className="total-item-arrow"> &#8594;</span>}
        </li>
        {sumChanged && (
          <li className="total-item">
            <Price product={{ price: sum || tempSum, saleUnit: saleUnit && saleUnit.shortTitle }} decimal={true} unit={false} bonusProduct={item.isBonusItem} />
          </li>
        )}
      </ul>
    );
  };

  const renderPriceChange = (item) => {
    const { oldPrice, price, quantity } = item;
    const itemUnavailable = isItemUnavailible(item);
    const newPrice = ((price - oldPrice) * quantity).toFixed(2);
    const changedPrice = item.oldPrice !== 0 && item.oldPrice !== null && item.oldPrice !== item.price;
    const recountAvailable =  !itemUnavailable && item.quantity > item.stockBalance;

    if (!changedPrice || recountAvailable) return;
    if (newPrice > 0) {
      return (
        <span className="price-change inc">
          {`+${newPrice} ₽ `}
          <ArrowIcon />{" "}
        </span>
      );
    }
    if (newPrice < 0) {
      return (
        <span className="price-change">
          {`${newPrice} ₽ `}
          <ArrowIcon />{" "}
        </span>
      );
    }
  };

  const renderDeleteBtn = (item) => (
    <div className="delete-btn" onClick={() => removeFromCart(item)}>
      <DeleteRoundIcon />
    </div>
  );

  const renderEdit = (item) => {
    const itemUnavailable = isItemUnavailible(item);
    const businessOnly = isItemBusinessOnly(item);
    const recountAvailable = !recounting && !itemUnavailable && item.quantity > item.stockBalance;

    return (
      <div>
        {!readOnly && !businessOnly && !itemUnavailable && !recountAvailable && <Edit cartItem={item} simple={true} handleCartIsUpdating={handleCartIsUpdating} handleRecounting={recounting ? handleRecounting : null} bonusProduct={item.isBonusItem} />}
        {businessOnly && <SupplierItemUnavailable text="Товар доступен только для бизнес-аккаунтов" />}
        {itemUnavailable && <SupplierItemUnavailable text="Товара нет на складе" />}
        {recountAvailable && <SupplierItemUnavailable text="Недостаточно товара на складе" recount={recountAvailable} handleClick={handleRecounting} cartIsUpdating={cartIsUpdating} />}
      </div>
    );
  };

  const renderInfoArea = (item) => {
    const itemUnavailable = isItemUnavailible(item);
    const { title, price, saleUnit, mainImage, productOwnerId, productId } = item;
    const uri = `/catalog/product/${productId}?byId=true`;
    const promotedProduct = item?.tags?.length > 0;
    const basePrice = item?.prices?.filter((item) => item.isSystem).map((p) => p.segments).flat().find((p) => p.minQuantity === 1)?.value || price;
    const promotedPrice = item?.prices?.filter((item) => !item.isSystem).map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value;
    const productPrice = promotedProduct ? promotedPrice : basePrice;
   
    return (
      <div className="info-area">
        <img className="info-area-icon" src={mainImage && mainImage.previewUrl} alt={title} />
        <div
          className={classNames("title", {
            canceled: itemUnavailable,
          })}
        >
          <Link to={uri}>{title}</Link>
          <Price product={{ price: productPrice, saleUnit: saleUnit && saleUnit.shortTitle }} bonusProduct={item.isBonusItem} />
        </div>
      </div>
    );
  };

  const renderProductMobileItem = (item) => {

    const itemUnavailable = isItemUnavailible(item);
    const businessOnly = isItemBusinessOnly(item);
    const recountAvailable = !recounting && item.quantity > item.stockBalance;
    const showTotal = !itemUnavailable && !businessOnly && !recountAvailable;
    const itemMatched = currentItemId === item.productId && currentItemSupplier === item.productOwnerId;

    return (
      <div className="product-wrap" key={item.productId}>
        <div
          id={item.productId}
          className={classNames("product-content", {
            next: currentItemIndex === 1 && itemMatched,
            prev: currentItemIndex === 0 && itemMatched,
          })}
          onTouchStart={handleTouchStart}
          onTouchMove={(e) => handleTouchMove(e, item.productId, item.productOwnerId)}
        >
          <div
            className={classNames("product item mobile", {
              canceled: itemUnavailable,
            })}
          >
            {renderInfoArea(item)}
            <div className="edit-area">
              {readOnly && renderWeightInfo(item)}
              {!readOnly && renderEdit(item)}

              <div className={classNames("price-container", {
                unavailable: itemUnavailable,
              })}
              >
                {showTotal && renderTotal(item)}

                {renderPriceChange(item)}
              </div>

              <div className="right-part-status">
                {readOnly && <OrderItemStatus status={item.itemStatus} />}
              </div>

            </div>
          </div>
          <div className="product-delete">{renderDeleteBtn(item)}</div>

          {!!businessOnly && <div className="product-overlay" />}
        </div>
      </div>
    );
  };

  const renderProductItem = (item) => {
    const itemUnavailable = isItemUnavailible(item);
    const businessOnly = isItemBusinessOnly(item);
    const recountAvailable = !recounting && item.quantity > item.stockBalance;
    const showTotal = !itemUnavailable && !businessOnly && !recountAvailable;
    return (
      <div
        key={item.productId}
        id={`product_${item.productId}`}
        className={classNames("product item", {
          canceled: itemUnavailable,
        })}
      >
        <div className="left part">
          {renderInfoArea(item)}
          {renderEdit(item)}
          {readOnly && !itemUnavailable && renderWeightInfo(item)}
        </div>
        <div
          className={classNames("right part", {
            [`business-only`]: businessOnly,
          })}
        >
          <div className="price-container">
            {showTotal && renderTotal(item)}
            {renderPriceChange(item)}
          </div>

          {!readOnly && renderDeleteBtn(item)}
          <div className="right-part-status">
            {readOnly && <OrderItemStatus status={item.itemStatus} />}
          </div>
        </div>

        {!!businessOnly && <div className="product-overlay" />}
      </div>
    );
  };

  const renderProductMiniItem = (item) => {
    const itemUnavailable = isItemUnavailible(item);
    const businessOnly = isItemBusinessOnly(item);
    const recountAvailable = !recounting && item.quantity > item.stockBalance;
    const showTotal = !itemUnavailable && !businessOnly && !recountAvailable;

    return (
      <div
        key={item.productId}
        id={`product_${item.productId}`}
        className={classNames("product item", {
          canceled: itemUnavailable,
        })}
      >
        <div className="left part">
          {renderInfoArea(item)}
          {readOnly && !itemUnavailable && renderWeightInfo(item)}
          {!readOnly && renderDeleteBtn(item)}
        </div>
        <div className="right part">
          {renderEdit(item)}
          <div className="price-container">
            {showTotal && renderTotal(item)}
            {renderPriceChange(item)}
          </div>
        </div>

        {!!businessOnly && <div className="product-overlay" />}
      </div>
    );
  };

  return (
    <div className={classNames("cart-block", { mini })}>
      <div className="supplier item">
        <div className="left part">
          <div className="name">
            <div className="suppl-info">
              <div>{`${items.filter((item) => item && item.itemStatus && item.itemStatus.code !== "OutOfStock").length || items.length}  ${productsCountAsString(
                items.length
              )}`}</div>
            </div>
            <div className="suppl-name">{tradeName || productOwner}</div>
          </div>
        </div>
        <div className="right part">
          <div className="supplier-total">
            {!isMobile && <div className="label">Итого</div>}
            <span className="supplier-total--item">
              <Price product={{ price: totalSumWithoutBonuses }} decimal={true} unit={false}
              />
            </span>
          </div>
        </div>
      </div>
      {items.map((item) => (mini ? renderProductMiniItem(item) : isMobile ? renderProductMobileItem(item) : renderProductItem(item)))}
      <ReviewsModal onClose={handleShowReviewsModal} visible={showReviewsModal} />
    </div>
  );
};

export default SupplierItem;
