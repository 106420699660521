import React, { useEffect } from "react";
import { ReactComponent as ArrowRightIcon } from "../../../../images/right-arrow.svg";
import { uniq } from "ramda";
import classNames from "classnames";
import "./OrderListPagination.scss";

function OrderListPagination({ totalCount, pageSize, handleChangePage, handlePageNumber, pageNumber }) {
  const maxCount = global.config.pagination.maxPages;
  const totalPages = Math.ceil(totalCount / pageSize);
  const nextPage = pageNumber === totalPages ? 0 : 1;
  const prevPage = pageNumber === 1 ? 0 : -1;

  useEffect(() => {
    handleChangePage(pageNumber * 10 - pageSize);
  }, [pageNumber, handleChangePage, pageSize]);

  const range = (start, stop) => {
    let a = [start],
      b = start;
    while (b < stop) {
      a.push((b += 1));
    }
    return a;
  };

  const handleArrowClick = (num) => handlePageNumber(pageNumber + num);
  const handleNumberClick = (num) => handlePageNumber(num);

  let itemsRange = [];
  itemsRange = range(1, totalPages);
  if (totalPages <= maxCount) {
    itemsRange = range(1, totalPages);
  } else {
    const halfCount = maxCount / 2;
    let rangesLeft = range(1, halfCount + 2);
    let rangesRight = range(totalPages - halfCount, totalPages);

    if (pageNumber > halfCount + 1) {
      rangesLeft = pageNumber === totalPages ? [1, -1] : [1, -1].concat(range(pageNumber - halfCount + 1, pageNumber));
    }

    if (pageNumber < totalPages - halfCount) {
      rangesRight = range(pageNumber, pageNumber + halfCount - 1).concat([-10, totalPages]);
    }

    itemsRange = uniq(rangesLeft.concat(rangesRight));
  }

  return (
    <div className="order-list-pagination">
      <div className="order-list-pagination-content">
        <div
          className={classNames("order-list-pagination-icon prev", {
            disabled: pageNumber === 1,
          })}
          onClick={() => handleArrowClick(prevPage)}
        >
          <ArrowRightIcon />
        </div>
        <div className="order-list-pagination-list">
          {itemsRange.map((i) =>
            i > 0 ? (
              <span
                key={`page_${i}`}
                className={classNames("order-list-pagination-item", {
                  selected: pageNumber === i,
                })}
                onClick={() => handleNumberClick(i)}
              >
                {i}
              </span>
            ) : (
              <span key={`page_${i}`} className="order-list-pagination-item more">
                …
              </span>
            )
          )}
        </div>
        <div
          className={classNames("order-list-pagination-icon", {
            disabled: pageNumber === totalPages,
          })}
          onClick={() => handleArrowClick(nextPage)}
        >
          <ArrowRightIcon />
        </div>
      </div>
    </div>
  );
}

export default OrderListPagination;
