import React from "react";
import "./ApplicationsLinksPage.scss";
import * as logoImage from "../../../images/logo_market.png";
import * as AppStore from "../../../images/AppStore.png";
import * as GooglePlay from "../../../images/GooglePlay.png";

function ApplicationsLinksPage() {
  return (
    <section className="applications-page">
      <div className="applications-page-content">
        <img src={logoImage} alt="РусАгроМаркет" className="applications-page-logo" />
        <h2 className="applications-page-title">Приложение РусАгроМаркет</h2>
        <a href="https://apps.apple.com/ru/app/%D1%80%D1%83%D1%81%D0%B0%D0%B3%D1%80%D0%BE%D0%BC%D0%B0%D1%80%D0%BA%D0%B5%D1%82-%D0%BF%D0%BE%D0%BA%D1%83%D0%BF%D0%B0%D1%82%D0%B5%D0%BB%D1%8C/id1543718141">
          <img src={AppStore} alt="Ссылка на AppStore" className="applications-page-button" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=ru.ramcorp.customer">
          <img src={GooglePlay} alt="Ссылка на GooglePlay" className="applications-page-button" />
        </a>
      </div>
    </section>
  );
}

export default ApplicationsLinksPage;
