import { sortBy, pickBy, prop, replace } from "ramda";
import { fetchPostBackend, fetchGetBackend, fetchDeleteBackend } from "../helpers/rest";
import { productsSortItems } from "../constants";
import { getPriceListParams } from "../helpers/products";
import analytics from "../helpers/analytics";
import queryString from "query-string";
import { setSystemErrorMessage } from "./api";


function fetchProductsRequest() {
  return {
    type: "PRODUCT_LIST_FETCHING",
  };
}

export function setCurrentProduct(product) {
  return {
    type: "SELECT_PRODUCT",
    product,
  };
}


export function setCurrentSuplier(supplier) {
  return {
    type: "SELECT_SUPPLIER",
    supplier,
  };
}
export function setCurrentSuplierInfo(supplierInfo) {
  return {
    type: "GET_SUPPLIER_INFO",
    supplierInfo,
  };
}

export function clearProductData() {
  return {
    type: "CLEAR_PRODUCT_DATA",
  };
}

export function setSearchFilterCategories(categoryIds) {
  return {
    type: "SET_FILTER_CATEGORIES",
    categoryIds,
  };
}

export function clearSearchFilter() {
  return {
    type: "CLEAR_SEARCH_FILTER",
  };
}
export function clearFilterCategory() {
  return {
    type: "CLEAR_FILTER_CATEGORY",
  };
}

function receiveProducts(products) {
  return {
    type: "RECEIVE_PRODUCTS",
    products,
  };
}

function receiveAvailbleFilters(availableFilters) {
  return {
    type: "RECEIVE_AVAILIBLE_FILTERS",
    availableFilters,
  };
}

function setSearchParams(searchParams) {
  return {
    type: "SET_SEARCH_PARAMS",
    searchParams,
  };
}


function receiveFavoriteProducts(products) {
  return {
    type: "RECEIVE_FAVORITE_PRODUCTS",
    products,
  };
}

function updateFavoriteProducts(id, add) {
  return {
    type: "UPDATE_FAVORITE_PRODUCTS",
    id,
    add,
  };
}

export function clearFavoriteProducts() {
  return {
    type: "CLEAR_FAVORITE_PRODUCTS",
  };
}

function receiveFavoriteSuppliers(suppliers) {
  return {
    type: "RECEIVE_FAVORITE_SUPPLIERS",
    suppliers,
  };
}

function updateFavoriteSupplier(supplier, add) {
  return {
    type: "UPDATE_FAVORITE_SUPPLIERS",
    supplier,
    add,
  };
}

export function clearFavoriteSuppliers() {
  return {
    type: "CLEAR_FAVORITE_SUPPLIERS",
  };
}

export function setActiveProductList(productList) {
  return {
    type: "SET_ACTIVE_PRODUCT_LIST",
    productList,
  };
}

export function setPageOffset(pageOffset) {
  return {
    type: "SET_PAGE_OFFSET",
    pageOffset: parseInt(pageOffset, 0),
  };
}

export function setSorting(sortingName) {
  return {
    type: "SET_SORTING",
    sortingName,
  };
}

export function setViewType(viewType) {
  return {
    type: "SET_VIEW_TYPE",
    viewType,
  };
}

export function setPageExtraItemsCount(itemsCount = 0) {
  return {
    type: "SET_PAGE_EXTRA_ITEMS_COUNT",
    itemsCount,
  };
}

export function setLoading(isLoading) {
  return {
    type: "LOADING_PRODUCTS",
    isLoading,
  };
}
export function reserveUserId(id) {
  return {
    type: "RESERVE_USER_ID",
    reservedUserId: id,
  };
}

export function getProductByIdOrUrl(urlSegment, searchById, isLoggedIn) {
  const prefix = isLoggedIn ? "buyer" : "public";
  if (!searchById) {
    return fetchGetBackend(`/api/${prefix}/v2.0/catalog/products/display-window/${urlSegment}`, isLoggedIn);
  } else return fetchGetBackend(`/api/${prefix}/v2.0/catalog/products/displayed-product/${urlSegment}`, isLoggedIn);
}

export function fetchProductByIdOrUrl(urlSegment, searchById = false) {
  return (dispatch, getState) => {
    const isLoggedIn = getState().profile.loggedIn;
    return (
      getProductByIdOrUrl(urlSegment, searchById, isLoggedIn)
        .then((json) => {
          dispatch(setCurrentProduct(json));
          dispatch(fetchActiveSupplier(json.ownerId));
          analytics.productViewDetails(json);
          return json;
        })
        .catch((error) => {
          setSystemErrorMessage(error)
        })
    );
  };
}

export function fetchActiveSupplier(ownerId) {
  if (!ownerId) return;

  return (dispatch) => {
    return (
      fetchGetBackend(`/api/public/v2.0/business-profile/suppliers/${ownerId}`)
        .then((json) => {
          return dispatch(setCurrentSuplier(json));
        })
        .catch((error) => {
        })
    );
  };
}

export function getActiveSupplierInfo(ownerId) {
  if (!ownerId) return;

  return (dispatch, getState) => {

    return (
      fetchGetBackend(`/api/public/v2.0/business-profile/suppliers/${ownerId}`)
        .then((json) => {
          return dispatch(setCurrentSuplierInfo(json));
        })
        .catch((error) => {
        })
    );
  };
}

export function getNextDeliveryTime(paymentserviceid = "", paymentmethod, distributioncenterid = "") {
  return (dispatch) => {
    return paymentmethod
      ? fetchGetBackend(`/api/orders/v1.0/logistics/nextdelivery/?paymentserviceid=${paymentserviceid}&paymentmethod=${paymentmethod}`)
      : fetchGetBackend(`/api/orders/v1.0/logistics/nextdelivery/?paymentserviceid=${paymentserviceid}`);
  };
}

export function fetchFavoriteProducts() {
  return (dispatch, getState) => {
    return fetchGetBackend("/api/buyer/v2.0/users/favourites/products", true).then((json) => {
      dispatch(receiveFavoriteProducts(json.results));
    });
  };
}

export function fetchFavoriteSuppliers() {
  return (dispatch, getState) => {
    return fetchGetBackend(`/api/buyer/v2.0/users/favourites/suppliers`, true).then((json) => {
      dispatch(receiveFavoriteSuppliers(json.results));
    });
  };
}

export function addFavoriteProduct(product) {
  return (dispatch, getState) => {
    const { id } = product;
    return fetchPostBackend(`/api/buyer/v2.0/users/favourites/products/${id}`, null, true).then((json) => {
      dispatch(updateFavoriteProducts(id, true));
      dispatch(fetchFavoriteProducts());
    });
  };
}

export function addFavoriteSupplier(supplier) {
  return (dispatch, getState) => {
    // dispatch(setLoading(true));
    const { id } = supplier;
    return fetchPostBackend(`/api/buyer/v2.0/users/favourites/suppliers/${id}`, null, true).then((json) => {
      dispatch(updateFavoriteSupplier(id, true));
      dispatch(fetchFavoriteSuppliers());
    });
  };
}

export function removeFavoriteProduct(product) {
  return (dispatch, getState) => {
    // dispatch(setLoading(true));
    const { id } = product;

    return fetchDeleteBackend(`/api/buyer/v2.0/users/favourites/products/${id}`, true).then((json) => {
      dispatch(updateFavoriteProducts(id, false));
      dispatch(fetchFavoriteProducts());
    });
  };
}

export function removeFavoriteSupplier(product) {
  return (dispatch, getState) => {
    // dispatch(setLoading(true));
    const { id } = product;

    return fetchDeleteBackend(`/api/buyer/v2.0/users/favourites/suppliers/${id}`, true).then((json) => {
      dispatch(updateFavoriteSupplier(id, false));
      dispatch(fetchFavoriteSuppliers());
    });
  };
}

export function fetchProductsByOwnerId(ownerId) {
  return (dispatch, getState) => {
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";
    dispatch(fetchProductsRequest());
    return fetchGetBackend(`/api/${prefix}/v2.0/catalog/products/display-window.search?Filter=OwnerId==${ownerId}`, isLoggedIn)
      .then((json) => {
        dispatch(receiveProducts(json))
        return json
      })
      .catch((error) => {
        console.log("error");
      });
  };
}

export function fillSearchParams(products, searchParams) {
  const categoryIds = products.filterCategoryIds.length > 0 ? products.filterCategoryIds : searchParams.categoryIds;
  if (categoryIds?.length > 0) searchParams.CategoryId = categoryIds;

  searchParams = pickBy((val, key) => !!val && ((Array.isArray(val) && val.length > 0) || !Array.isArray(val)), searchParams);

  products.filterCategoryIds.length > 0 && searchParams.ownerIds && delete searchParams.ownerIds

  return searchParams;
}

function addSorting(sortingName, searchParams) {
  const { directions, sortByFields } = productsSortItems[sortingName];
  return { ...searchParams, directions, sortByFields };
}

export function getAvailbleFilters(searchParams) {
  return (dispatch, getState) => {



    return dispatch(receiveAvailbleFilters(searchParams));

  };
}

export function searchProductsPlain(specificParams) {
  return (dispatch, getState) => {

    const productsState = getState().products;
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";
    const offset = productsState.pageOffset;

    let searchParams = productsState.searchParams;
    searchParams.itemsCount = 12 + productsState.extraItemsCount;
    searchParams.offset = offset;

    if (specificParams.sortBy) {
      searchParams.sortByFields = specificParams.sortBy;
    }
    if (specificParams.direction) {
      searchParams.directions = specificParams.direction
    }

    if (specificParams.filter) {
      searchParams.Filter = specificParams.filter
    }
    const qs = queryString.stringify(searchParams)
    return fetchGetBackend(`/api/${prefix}/v2.0/catalog/products/display-window.search?${qs}`, isLoggedIn);
  };
}

export function setSearchFilterAndSearch(searchParams) {
  return (dispatch, getState) => {
    dispatch(setSearchParams(searchParams));
    return dispatch(searchProduct());
  };
}

export function searchProduct() {
  return (dispatch, getState) => {
    dispatch(setLoading(true));

    const productsState = getState().products;
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";

    let searchParams = productsState.searchParams;

    dispatch(setSearchParams(searchParams));
    searchParams = fillSearchParams(productsState, searchParams);
    const countries = searchParams.countriesOfOrigin?.join("|");
    const countryFilter = searchParams.countriesOfOrigin ? `,CountryOfOrigin==${countries}` : "";
    const ownerIdFilter = searchParams.ownerIds ? `,OwnerId==${searchParams.ownerIds}` : "";
    const titleFilter = searchParams.title ? `,Title@=*${searchParams.title}` : "";
    const hasPromotionFilter = searchParams.hasPromotion ? `,HasPromotion==${searchParams.hasPromotion}` : "";
    const tagFilter = searchParams.tagsId ? `${searchParams.tagsId}` : "";

    if (searchParams.CategoryId) {
      searchParams.Filter = `CategoryId==${searchParams.CategoryId},Price>${searchParams.minPrice < 1 || !searchParams.minPrice ? 0 : searchParams.minPrice}${searchParams.maxPrice ? `,Price<${searchParams.maxPrice}` : ""}${countryFilter}${ownerIdFilter}${titleFilter}${hasPromotionFilter}${tagFilter}`
    }
    else {
      searchParams.Filter = `${searchParams.minPrice ? `Price<${searchParams.minPrice}` : ""}${searchParams.maxPrice ? `,Price<${searchParams.maxPrice}` : ""}${countryFilter}${ownerIdFilter}${titleFilter}${hasPromotionFilter}${tagFilter}`
    }
    if ((searchParams.minPrice || searchParams.maxPrice) && !searchParams.CategoryId) {
      searchParams.Filter = `${searchParams.minPrice ? `Price<${searchParams.minPrice}` : ""}${searchParams.maxPrice ? `,Price<${searchParams.maxPrice}` : ""}${countryFilter}${ownerIdFilter}${titleFilter}${hasPromotionFilter}${tagFilter}`
    }
    if (searchParams.countriesOfOrigin && !searchParams.minPrice && !searchParams.maxPrice && !searchParams.CategoryId) {
      searchParams.Filter = `CountryOfOrigin==${countries}${ownerIdFilter}${titleFilter}${hasPromotionFilter}${tagFilter}`;
    }

    searchParams = addSorting(productsState.sortingName, searchParams);
    const offset = productsState.pageOffset;
    searchParams.offset = offset;
    searchParams.itemsCount = global.config.pagination.pageSize + productsState.extraItemsCount;
    searchParams.minPrice = searchParams.minPrice < 1 || !searchParams.minPrice ? 0 : searchParams.minPrice;

    delete searchParams.CategoryId
    delete searchParams.minPrice
    delete searchParams.maxPrice
    delete searchParams.attributes
    delete searchParams.countriesOfOrigin
    delete searchParams.ownerIds
    delete searchParams.categoryIds
    delete searchParams.title;
    delete searchParams.tagsId;

    const qs = queryString.stringify(searchParams)
    return fetchGetBackend(`/api/${prefix}/v2.0/catalog/products/display-window.search?${qs}`, isLoggedIn)
      .then((json) => {
        dispatch(receiveProducts(json));
        return json
      }).then((json) => {
        return dispatch(getAvailbleFilters(json.availableFilters))
      }).then(() => dispatch(setLoading(false)))
      .catch(() => dispatch(setLoading(false)));
  };
}



export function getRecomendedProducts() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchGetBackend(`/api/catalog/v1.0/product/recomendeted-products-for-buyer/${userId}`, true)
  };
}
