import React from "react";
import ReactDOM from "react-dom";
import "./config";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import ScrollToTop from "./components/ScrollToTop";

const multiActionMiddleware = (store) => (next) => (action) => {
  if (!Array.isArray(action)) return next(action);
  return action.map((a) => store.dispatch(a));
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();
function configureStore(initialState) {
  const logger = createLogger({
  predicate: (getState, action) => !action?.type?.includes("API_FETCH"),
  });
  const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk, logger, multiActionMiddleware)));

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
