import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserOrders, fetchUserOrderById } from "../../../actions/order";
import "./OrdersInfo.scss";
import OrdersList from "./OrdersList/OrdersList";
import EditOrder from "./EditOrder/EditOrder";
import queryString from "query-string";
import { withRouter } from "react-router";
import { Loader } from "../../commons/Loader/Loader";

const ORDER_REFRESH_SECONDS = 10 * 1000;

class OrdersInfo extends React.Component {
  state = { isLoading: true, isLoadingOrderList: false, isChanging: false, order: false, orderId: null, pageSize: 10, pageOffset: 0, ordersList: [], pageNumber: 1 };
  interval = null;
  componentDidMount() {
    this.init();
    this.interval = setInterval(() => {
      this.refresh(this.state.orderId, true);
    }, ORDER_REFRESH_SECONDS);
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const query = queryString.parse(this.props.location.search);
    const prevQuery = queryString.parse(prevProps.location.search);
    const { pageSize, pageOffset } = this.state;
    const { orders, accountId } = this.props;

    if (query.orderId !== prevQuery.orderId) {
      this.init();
    }
    if (prevState.pageSize !== pageSize) {
      this.loadOrders();
    }
    if (prevState.pageOffset !== pageOffset) {
      this.loadOrders();
    }
    if (prevProps.accountId !== accountId) {
      if (pageOffset !== 0) {
        this.setState({ pageOffset: 0 })
        this.handlePageNumber(1)
      } else {
        this.loadOrders();
      }

    }
  }

  init() {
    const orderId = queryString.parse(this.props.location.search).orderId;
    this.setState({ orderId });
    this.refresh(orderId);
    this.setState({ isLoading: false });
  }

  refresh(orderId, refreshedByTimer = false) {
    if (orderId) {
      this.loadOrder(orderId, true, refreshedByTimer);
    } else {
      this.setState({ order: null });
      this.loadOrders(refreshedByTimer);
    }
  }

  filteredList = (orders) => {
    // const { ordersList } = this.state;
    // const array = [...ordersList, ...orders];
    const arr = [];
    const sortedList = orders.sort((a, b) => b.number - a.number);
    for (let i = 0; i <= sortedList.length - 1; i++) {
      if (sortedList[i]?.id !== sortedList[i + 1]?.id) {
        arr.push(sortedList[i]);
      }
    }

    return arr;
  };

  loadOrders = (refreshedByTimer) => {
    const { fetchUserOrders } = this.props;
    const { pageSize, pageOffset } = this.state;
    !refreshedByTimer && this.setState({ isLoadingOrderList: true });
    fetchUserOrders(pageSize, pageOffset)
      .then((json) => {
        this.setState({ ordersList: this.filteredList(json.results), isLoadingOrderList: false });
      })
      .finally(() => {
        this.setState({ isLoadingOrderList: false });
      });
  };

  handleShowMoreOrders = () => {
    const { pageSize, pageOffset } = this.state;
    this.setState({ pageOffset: pageOffset + pageSize });
  };

  handlePageNumber = (value) => {
    this.setState({ pageNumber: value });
  };

  handleChangePage = (page) => {
    const { fetchUserOrders } = this.props;
    const { pageSize } = this.state;
    this.setState({ pageOffset: page });
    fetchUserOrders(pageSize, page).then((json) => {
      this.setState({ ordersList: json.results });
    });
  };

  loadOrder(orderId, silent = false, refreshedByTimer) {
    const { fetchUserOrderById } = this.props;
    !refreshedByTimer && this.setState({ isChanging: true });
    !silent && this.setState({ isLoading: true });

    fetchUserOrderById(orderId, true).then((order) => {
      this.setState({ order, isLoading: false, isChanging: false });
    });
  }

  render() {
    const { orders, isMobile, history } = this.props;
    const { isLoading, isChanging, order, orderId, pageSize, ordersList, pageNumber, isLoadingOrderList } = this.state;

    if (isLoading) {
      return <Loader />;
    } else {
      return (
        <section className="orders-page">
          {!orderId && !order && (
            <OrdersList
              orders={orders}
              isMobile={isMobile}
              handlePageSize={this.handlePageSize}
              handleChangePage={this.handleChangePage}
              pageSize={pageSize}
              handleShowMoreOrders={this.handleShowMoreOrders}
              ordersList={ordersList}
              handlePageNumber={this.handlePageNumber}
              pageNumber={pageNumber}
              isLoadingOrderList={isLoadingOrderList}
            />
          )}
          {order && <EditOrder order={order} isMobile={isMobile} reload={() => this.init()} orderIsLoadingOrder={isChanging} history={history} />}
        </section>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  accountId: state.profile.accountId,
  orders: state.order.orders,
  isMobile: state.api.isMobile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserOrders: fetchUserOrders,
      fetchUserOrderById: fetchUserOrderById,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdersInfo));
