import * as React from "react";
import "./WrappedIcon.scss";

const WrappedIcon = ({ onClick, icon }) => {
  const onItemClick = (e) => {
    e.stopPropagation();
    onClick && onClick(e);
    e.preventDefault();
  };
  return (
    <div className="wrapped-icon" onClick={onItemClick}>
      {icon}
    </div>
  );
};
export default WrappedIcon;
