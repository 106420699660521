import cookie from "react-cookies";
import jwt from "jwt-decode";
import config from "../config";

export const COOKIES_PARAMS = {
  domain: config.isLocalHost ? "localhost" : global.config.domain,
  path: "/",
  maxAge: 60 * 60 * 24 * 30,
};

export const AUTH_DATA = {
  accessToken: null,
  refreshToken: null,
  jwt: null,
  renewInProgressFlag: false,
};

export const loadAuthData = () => {
  AUTH_DATA.accessToken = cookie.load("access_token", COOKIES_PARAMS);
  AUTH_DATA.refreshToken = cookie.load("refresh_token", COOKIES_PARAMS);
  AUTH_DATA.jwt = AUTH_DATA.accessToken ? jwt(AUTH_DATA.accessToken) : null;

  return AUTH_DATA;
};

export const saveAuthData = (accessToken, refreshToken) => {
  cookie.save("access_token", accessToken, COOKIES_PARAMS);
  cookie.save("refresh_token", refreshToken, COOKIES_PARAMS);
  AUTH_DATA.accessToken = accessToken;
  AUTH_DATA.refreshToken = refreshToken;
  AUTH_DATA.jwt = accessToken ? jwt(accessToken) : null;

  return AUTH_DATA.jwt;
};

export const resetAuthData = () => {
  cookie.remove("access_token", COOKIES_PARAMS);
  cookie.remove("refresh_token", COOKIES_PARAMS);
  AUTH_DATA.accessToken = null;
  AUTH_DATA.refreshToken = null;
  AUTH_DATA.jwt = null;
};

export const redirectToLogin = () => {
  saveRedirectData(loadAccountType());
  window.location.href = global.config.authUrl;
};

export const saveRedirectData = (value) => {
  cookie.save("redirectToCart", value, COOKIES_PARAMS);
};

export const loadRedirectData = () => {
  return cookie.load("redirectToCart", COOKIES_PARAMS);
};

export const removeRedirectData = () => {
  cookie.remove("redirectToCart", COOKIES_PARAMS);
};

export const loadAccountType = () => {
  return global.config.businessAccountsOnly ? "business" : cookie.load("account_type", COOKIES_PARAMS);
};

export const saveAccountType = (accountType) => {
  const params = { ...COOKIES_PARAMS, maxAge: 60 * 60 * 24 * 7 };
  cookie.save("account_type", accountType, params);
};

export const loadCookiesAccepted = () => {
  return cookie.load("cookies-accepted", COOKIES_PARAMS);
};

export const saveCookiesAccepted = () => {
  const params = { ...COOKIES_PARAMS, maxAge: 60 * 60 * 24 * 365 * 10 };

  return cookie.save("cookies-accepted", true, params);
};
