import React from 'react';
import { ReactComponent as InfoIcon } from "../../../../images/info.svg";
import { ReactComponent as RefreshIcon } from "../../../../images/refresh.svg";
import { Loader } from '../../../commons/Loader/Loader';
import "./SupplierItemUnavailable.scss";

const SupplierItemUnavailable = ({ text, recount = false, handleClick, cartIsUpdating }) => {


  return (
    <div className="item-unavailable">
      <div className="item-unavailable-icon">
        <InfoIcon />
      </div>
      <p className="item-unavailable-text">{text}
        {recount && <span className='recount' onClick={() => handleClick ? handleClick(true) : null}>Пересчитать <RefreshIcon /></span>}
      </p>
      {cartIsUpdating && (
        <div className="item-unavailable-overlay">
          <Loader />
        </div>
      )}

    </div>
  )
}

export default SupplierItemUnavailable