import * as React from "react";
import "./Search.scss";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as ArrowIcon } from "../../../images/right-arrow.svg";
import { ReactComponent as LongArrowIcon } from "../../../images/long-right-arrow.svg";
import { clearSearchResults } from "../../../actions/search";
import { stripHtml } from "../../../helpers/stringOperations";
import { Link, withRouter } from "react-router-dom";
import { separateThousands, truncNumber, decimalPartNumber } from "../../../helpers/stringOperations";
import { productsCountAsString } from "../../../helpers/stringOperations";
import classNames from "classnames";

const DropDownProduct = (props) => {
  const { product, closeDropDown } = props;
  const price = product?.prices.segments.find((item) => item.minQuantity === 1).value;
  return (
    <Link to={`/catalog/product/${product.urlSegment}`} className="dropdown-item" onClick={() => closeDropDown(product)}>
      {product.previewUrl && <img alt={stripHtml(product.titleHtml)} src={product.previewUrl} className="product-image" />}
      {!product.previewUrl && <div className="product-image" />}
      <div className="description">
        <div className="name" dangerouslySetInnerHTML={{ __html: product.titleHtml }}></div>
        <div className="owner">{product.owner}</div>
      </div>
      <div className="price">
        {separateThousands(truncNumber(price))}
        <span className="decimal">{decimalPartNumber(price)}</span>
        {` ${product?.prices.currency?.symbol}/${product.saleUnit.value}`}
      </div>
      <span className="arrow">
        <ArrowIcon />
      </span>
    </Link>
  );
};

const DropDownCategory = (props) => {
  const { category, closeDropDown, history, isMobile } = props;


  const uriList = [
    `/catalog/customerProducts/${category.categoryId1}`,
    `/catalog/customerProducts/${category.categoryId1}/${category.categoryId2}`,
    `/catalog/customerProducts/${category.categoryId1}/${category.categoryId2}/${category.categoryId3}`,
  ];
  const itemClick = () => {
    const lastUri = category.categoryId3 ? uriList[2] : category.categoryId2 ? uriList[1] : uriList[0];
    history.push(lastUri);
    closeDropDown();
  };
  const categoryClick = (e) => {
    e.stopPropagation();
    closeDropDown();
  };

  if (isMobile) {
    return (
      <div className="dropdown-category-item" onClick={() => itemClick()}>
        <div className="description">
          <Link
            onClick={categoryClick}
            href="#"
            to={uriList[2]}
            className="sub-category"
            dangerouslySetInnerHTML={{ __html: category.title3Html || category.title2Html || category.title1Html }}
          ></Link>
        </div>
      </div>
    );
  }

  return (
    <div className="dropdown-category-item" onClick={() => itemClick()}>
      <div className="description">
        <Link onClick={categoryClick} href="#" to={uriList[0]} className="sub-category" dangerouslySetInnerHTML={{ __html: category.title1Html }}></Link>
        {!!category.title2Html && <ArrowIcon />}
        {!!category.title2Html && <Link onClick={categoryClick} href="#" to={uriList[1]} className="sub-category" dangerouslySetInnerHTML={{ __html: category.title2Html }}></Link>}
        {!!category.title3Html && <ArrowIcon />}
        {!!category.title3Html && <Link onClick={categoryClick} href="#" to={uriList[2]} className="sub-category" dangerouslySetInnerHTML={{ __html: category.title3Html }}></Link>}
      </div>
      {!isMobile && <div className="info">{category.productsCount} {productsCountAsString(category.productsCount)}</div>}
      {!isMobile && (
        <span className="arrow">
          <ArrowIcon />
        </span>
      )}
    </div>
  );
};

class SearchDropDown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { dropDownVisible, fixedHeader, focus } = this.props;
    const headerId = fixedHeader ? "fixed-header" : "header";
    if (dropDownVisible !== prevProps.dropDownVisible) {
      if (dropDownVisible) {
        document.body.style.position = "static";
        document.body.style.overflow = "hidden";
        const headerRect = document.getElementById(headerId)?.getBoundingClientRect();
        const top = `${headerRect?.top + headerRect?.height}px`;
        this.wrapperRef.style.top = top;
        // this.wrapperRef.style.height = `calc(100vh - ${top})`;
      }
    }

    if (focus !== prevProps.focus) {
      if (focus) {
        document.body.style.position = "static";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.position = "relative";
        document.body.style.overflow = "visible";
      }

    }

  }

  closeDropDown = () => {
    const { toggleSearchDropDown, toggleSearchBar } = this.props;
    toggleSearchDropDown(false);
    toggleSearchBar(false);
  };

  getTopPosition = () => {
    const headerRect = document.getElementById("header").getBoundingClientRect();
    const top = `${headerRect.top + headerRect.height}px`;
    return top;
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    const { searchResult, toggleSearchDropDown, dropDownVisible, history, isMobile, showAll } = this.props;

    if (!dropDownVisible) {
      return null;
    }

    const productItems = searchResult.products
      ? searchResult.products.map((product, i) => <DropDownProduct key={`search_item_${i}`} product={product} closeDropDown={() => this.closeDropDown()} />)
      : null;
    const categoryItems = searchResult.categories
      ? searchResult.categories.map((category, i) => (
        <DropDownCategory key={`search_cat_${i}`} category={category} closeDropDown={() => this.closeDropDown()} history={history} isMobile={isMobile} />
      ))
      : null;

    const className = classNames({
      "dropdown-content": true,
      container: true,
      active: dropDownVisible,
    });
    // style={{top: dropDownVisible ? this.getTopPosition() : 0}}
    return (
      <div className="dropdown-content-wrapper" ref={this.setWrapperRef}>
        <div className={className} onMouseEnter={() => toggleSearchDropDown(true)} onMouseLeave={() => toggleSearchDropDown(false)}>
          {!isMobile && categoryItems.length > 0 && <div className="category-title">Категории:</div>}
          <div className="categories">{categoryItems}</div>
          {productItems}
          <div className="more-results" onClick={() => showAll()}>
            <span>Показать все результаты</span>
            <LongArrowIcon />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchResult: state.search.searchResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSearchResults: clearSearchResults,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchDropDown));
