import React from "react";
import Modal from "../../../../commons/Modal/Modal";

import Button from "../../../../commons/Button/Button"
import "./modals.scss";

const CancelModal = ({onClose, visible}) => {
  return (
    <div className="order-modal">
      <Modal onClose={onClose} visible={visible}>
        <h2 className="order-modal-title">Вы уверены, что хотите отменить заказ ?</h2>
        <p className="sub-header">Данный заказ будет отменен без возможности восстановления.</p>
        <div className="dialog-buttons">

          <Button title="Отменить заказ" buttonSize="medium" buttonType="primary" onClick={() => onClose(true)}/>
          <div className="cancel-button" onClick={() => onClose(false)}>
            Не отменять
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelModal;
