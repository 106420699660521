import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import SliderButton from "../../commons/SliderButton/SliderButton";
import "./RegisterBannerSlider.scss";
import { useHistory } from "react-router";

SwiperCore.use([Pagination, Autoplay]);

const dummySlide = { skeleton: true };

function RegisterBannerSlider() {
  const data = useSelector((state) => state.api.banners?.results) || [];
  const isBannersDataLoaded = useSelector((state) => state.api.isBannersDataLoaded);
  const isDesktop = useSelector((state) => state.api.isDesktop)
  const history = useHistory();

  if (!isBannersDataLoaded) {
    data.push(dummySlide);
  }

  const [isHovered, setIsHovered] = useState(false);
  const hideSliderButtons = !isDesktop || (isDesktop && !isHovered) || data.length < 2;

  const handleHoverOn = () => {
    setIsHovered(true);
  };
  const handleHoverOff = () => {
    setTimeout(() => setIsHovered(false), 200);
  };

  const hanldeRedirect = (url) => {
    history.push(url)
  }

  return (
    <section className="register-banner container" onPointerEnter={handleHoverOn} onPointerLeave={handleHoverOff}>
      <SliderButton buttonType="prev-banner" buttonColor="gray" hide={hideSliderButtons} />

      <Swiper
        autoHeight={true}
        spaceBetween={isDesktop ? 20 : 10}
        slidesPerView={isDesktop ? 3 : "auto"}
        navigation={{
          prevEl: `.slider-btn-prev-banner`,
          nextEl: `.slider-btn-next-banner`,
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
        }}
        autoplay={{ delay: 6000 }}
        loop
      >
        {data &&
          data.map((item, index) => {
            const desktopBanner = item.gallery?.find((image) => image.type.code === "Desktop")?.url;

            return <SwiperSlide key={`reg_banner_slide-${index}`}>
              <div
                className="register-banner-slide"
                style={
                  !!item.skeleton
                    ? {}
                    : { backgroundImage: `url(${desktopBanner})` }
                }
                onClick={item.buttonUrl ? () => hanldeRedirect(item.buttonUrl) : () => { }}
              >
                <div className="register-banner-button">
                </div>
              </div>
            </SwiperSlide>
          })}
      </Swiper>

      {<SliderButton buttonType="next-banner" buttonColor="gray" hide={hideSliderButtons} />
      }
      {data.length > 1 && <div className="swiper-pagination" />}
    </section>
  );
}

export default RegisterBannerSlider;
