import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import "./AddressListItem.scss";

const AddressListItem = ({ address, isCompany, active, handleDelete, handleEdit, onClick }) => {
  const [showEditOptions, setShowEditOptions] = useState(false);
  const { locality, number, street, entrance, floor, apartment, id, recipientFullName, recipientPhone } = address;
  const defaultAddressId = useSelector((state) => state.cart.registration.addressId);
  const isDefault = defaultAddressId === id;
  const renderEntrance = entrance ? `подъезд ${entrance}, ` : "";
  const renderFloor = floor ? `этаж ${floor}` : "";
  const renderApartment = apartment ? `, ${isCompany ? "офис" : "кв"} ${apartment}` : "";
  const renderContacts = (recipientFullName) ? `${recipientFullName.split(" ")[0]} ${recipientPhone && `, ${recipientPhone}`}` : null;
  const listItemClass = isDefault ? 'address-list-item active' : 'address-list-item';


  const handleHoverOn = () => {
    setShowEditOptions(true);
  };

  const handleHoverOff = () => {
    setTimeout(() => {
      setShowEditOptions(false);
    }, 900);

  };

  return (
    <li className={listItemClass} onClick={(e) => onClick(e, id)}>
      <div className='address-list-item-content'  >
        <header className='address-list-item-header'>
          <h4>Доставка</h4>
          <div className='address-list-item-edit' onPointerEnter={handleHoverOn} onPointerLeave={handleHoverOff}>...
            {showEditOptions &&
              <ul className='address-list-item-edit-options'>
                <li className='address-list-item-edit-option' onClick={(e) => handleEdit(e, id)}>Редактировать</li>
                <li className='address-list-item-edit-option' onClick={(e) => handleDelete(e, id)}>Удалить</li>
              </ul>}
          </div>

        </header>

        <p className='address-list-item-street'>{locality}, {street}, {number}</p>
        <p className='address-list-item-entrance'>{renderEntrance} {renderFloor} {renderApartment}</p>
      </div>
     {renderContacts && <p className='address-list-item-contacts'>{renderContacts}</p>}
    </li>
  )
}

export default AddressListItem