import React, { useState } from 'react';
import Button from '../../commons/Button/Button';
import { downloadPriceList } from '../../../actions/priceList';

import "./PriceListBanner.scss";
import { useDispatch } from 'react-redux';
import moment from 'moment';

const PriceListBanner = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  const onClick = () => {
    setLoading(true);
    dispatch(downloadPriceList(moment().format("DD.MM.YYYY"))).then(() => setLoading(false));
  }

  return (
    <div className='price-list-banner'>
      <div className='price-list-banner-content'>
        <h3 className='price-list-banner-caption'>Скачать прайс-лист каталога в формате Excel</h3>
        <div className='price-list-banner-button'>
          <Button title="Скачать прайс-лист" onClick={() => onClick()} buttonSize="large" buttonType="primary" loading={loading} />
        </div>

      </div>
    </div>
  )
}

export default PriceListBanner