import React from "react";
import "./Badge.scss";
import classNames from "classnames";

const Badge = ({ caption, look, icon = null }) => {
  return (
    <div className={classNames({ "supplier-badge": true, [look]: true })}>
      {icon}
      <span>{caption}</span>
    </div>
  );
};

export default Badge;
