import React from "react";
import { ReactComponent as WarehouseIcon } from "../../../../images/warehouse.svg";
import { MAP_LINK_DC } from "../../../../constants";
import "./StockInfo.scss";

const StockInfo = ({ stockBalance, saleUnit, leasingCellNumber, buildingData }) => {
  const building = buildingData?.filter((item) => item.number == leasingCellNumber)[0];
  if (!building) return null;
  const buildingLabel = building.building?.split("").reverse().join("");

  return (
    <div className="stock-info">
      <div className="stock-info-content">
        <WarehouseIcon />
        <span className="stock-info-title">
          В наличии:
          <span className="stock-info-text">
            {stockBalance} {saleUnit}
          </span>
        </span>
      </div>
      <p className="stock-info-warehouse">
        ОРЦ Новосибирск, здание {buildingLabel}, ячейка №{leasingCellNumber}
      </p>
    </div>
  );
};

export default StockInfo;
