import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Summary.scss";
import { ReactComponent as WeightIcon } from "../../../images/weight.svg";
import Button from "../../commons/Button/Button";
import Price from "../../Product/Card/Price/Price";
import classNames from "classnames";
import { declOfNum, separateThousands, truncNumber } from "../../../helpers/stringOperations";
import { Gap } from "../../commons/Gap";
import ErrorBox from "../ErrorBox/ErrorBox";
import Disclamer from "../SubmitButton/Disclamer";
import { getMinimumOrderSum } from "../../../helpers/orders";
import { loadVirtualCartDiscount, loadVirtualCartServices, loadVirtualCartTotalNetWeight, loadVirtualCartTotalSum } from "../../../helpers/virtualCard";
import {  DELIVERY_TYPES, MAX_CART_ITEMS_WEIGHT } from "../../../constants";


const Summary = ({ buttonName = "Сделать заказ", buttonLoading, nextStep, step = 0, submitDisabled, handleCartIsUpdating }) => {
  const [totalDeliveryUnauthorised, setTotalDeliveryUnauthorised] = useState(null);
  const [totalDiscountUnauthorised, setTotalDiscountUnauthorised] = useState(0);
  const [totalNetWeightUnauthorised, setTotalNetWeightUnauthorised] = useState(0);
  const [totalSumUnauthorised, setTotalSumUnauthorised] = useState(0);
  const isDesktop = useSelector((state) => state.api.isDesktop);
  const user = useSelector((state) => state.profile.user);
  const isLoggedIn = useSelector((state) => state.profile.loggedIn);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartServices = useSelector((state) => state.cart.cartItems?.services);
  const totalDiscount = useSelector((state) => state.cart.cartItems?.totalDiscount);
  const deliveryType = useSelector((state) => state.cart.deliveryType);
  const hasError = useSelector((state) => !!state.cart.errorMessage);
  const isDelivery = deliveryType === DELIVERY_TYPES[0];

  const { itemsCount, totalSum, totalNetWeight } = cartItems;

  useEffect(() => {
    if (!isLoggedIn) {
      handleCartIsUpdating && handleCartIsUpdating(true)
      setTimeout(() => {
        setTotalDeliveryUnauthorised(loadVirtualCartServices())
        setTotalDiscountUnauthorised(loadVirtualCartDiscount())
        setTotalNetWeightUnauthorised(loadVirtualCartTotalNetWeight())
        setTotalSumUnauthorised(loadVirtualCartTotalSum())
        handleCartIsUpdating && handleCartIsUpdating(false)
      }, 700)
    }

  }, [itemsCount, totalSum, totalNetWeight, isLoggedIn, deliveryType])


  const totalOrderSum = isLoggedIn ? totalSum : totalSumUnauthorised
  const minimumOrderSum = getMinimumOrderSum(user);
  const minimimOrderSumLeft = (minimumOrderSum - totalOrderSum)?.toFixed(2);
  const minimumOrderPercents = Math.round((totalOrderSum / minimumOrderSum) * 100);

  const hasMinimumOrder = minimumOrderSum && totalOrderSum >= minimumOrderSum;

  const discount = isLoggedIn ? totalDiscount : totalDiscountUnauthorised;
  const totalWeight = isLoggedIn ? totalNetWeight : totalNetWeightUnauthorised;
  const deliveryPrice = isLoggedIn ? cartServices?.flat()?.find((serv) => serv?.type?.code === "Delivery")?.amount : totalDeliveryUnauthorised && totalDeliveryUnauthorised[0]?.amount;

  const deliveryLabel = () => {
    
    if (totalWeight >= MAX_CART_ITEMS_WEIGHT) {
      return "Индивидуально"
    } else if (deliveryPrice) {
      return `${separateThousands(deliveryPrice)} ₽`
    } else {
      return "Бесплатно"
    }
  }

  return (
    <div className={classNames("summary", { [`step${step}`]: true })}>
      <div className="info">
        <span className="quantity">{`${itemsCount} ${declOfNum(itemsCount, ["позиция", "позиции", "позиций"])}`}</span>

        <span className="weight">
          <WeightIcon /> {totalWeight?.toFixed(2)} кг
        </span>
      </div>
      {step === 0 && (
        <div className="delivery-info">
          {hasMinimumOrder && <div className="free-info">Готово к заказу</div>}
          {!hasMinimumOrder && (
            <div className="free-info">
              До заказа осталось <span className="bold">{minimimOrderSumLeft} ₽</span>
            </div>
          )}
          <span className="price"> Минимальный заказ — {minimumOrderSum} ₽</span>
          <Gap size={10} />

          <div className="price-info">
            {hasMinimumOrder && <span className="delimeter full"></span>}
            {!hasMinimumOrder && (
              <span className="delimeter">
                <span className="inner">
                  <span className="filled" style={{ width: `${minimumOrderPercents}%` }}></span>
                </span>
              </span>
            )}
          </div>
        </div>
      )}
      {step === 1 && (
        <>
          <div className="summary-divider" />
          <Gap size={15} />
        </>
        // <div className="delivery-info">
        //   <div className="line">
        //     <span>Товары ({itemsCount}):</span>
        //     <span className="value">{separateThousands(truncNumber(totalOrderSum))} ₽</span>
        //   </div>
        //   <div className="line">
        //     <span>Доставка:</span>
        //     <span className="value">{summaryWeight > 20000 ? "Индивидуально" : `${separateThousands(truncNumber(deliveryPrice(summaryWeight)))} ₽`}</span>
        //   </div>
        // </div>
      )}
      <div className="sum-info-wrap">
        <div className="header">
          <span className="caption">
            <span>{isDelivery ? "Доставка:" : "Самовывоз:"}</span>
          </span>
          <span
            className={classNames({
              free: hasMinimumOrder,
              label: true,
            })}
          >
            {deliveryLabel()}
          </span>
        </div>
        {!!discount && <div className="sum-info-discount">
          <span>Скидка на товары</span>
          <span>{separateThousands(truncNumber(-1 * discount))} ₽</span>
        </div>}
        <div className="sum-info">
          <div>
            <div className="caption">Итого</div>
            {/* <div className="sub">Общая стоимость с учетом доставки и НДС</div> */}
          </div>
          <div className="total">
            <Price
              product={{
                price:
                  //  summaryWeight > 20000 ? totalOrderSum :
                  totalOrderSum,
              }}
              decimal={true}
              unit={false}
            />
          </div>
        </div>
      </div>
      {step === 0 && (
        <div className="actions">
          <Button title={buttonName} buttonSize="medium" buttonType="primary" width="100%" onClick={() => nextStep()} disabled={submitDisabled} loading={buttonLoading} />
        </div>
      )}

      {step !== 0 && (
        <div className={classNames("actions cart-submit-btn", { error: hasError })}>
          <Button title={buttonName} buttonSize="medium" buttonType="primary" width="100%" onClick={() => nextStep()} disabled={submitDisabled} loading={buttonLoading}/>
          <ErrorBox />
          {isDesktop && <Disclamer />}
        </div>
      )}
    </div>
  );
};

export default Summary;
