import React from 'react';
import { addressToString } from '../../../helpers/stringOperations';
import { Gap } from '../../commons/Gap';

const PickUpPoint = ({ name, address, link, id, selected, selectPoint }) => {
  const fullAddress = addressToString(address)
    const onClickToMap = (e) => {
    e.stopPropagation();
  }

  return (
    <li className={`pick-up-point ${selected ? "selected" : ""}`} onClick={(e) => selectPoint(e, { id: id, name: name, address: address, link: link })}>
      <h4 className='pick-up-point-title'>{name}</h4>
      <Gap size={10} />
      <address className='pick-up-point-address'>{fullAddress}</address>
      <Gap size={15} />
      <a className='pick-up-point-link' href={link} target='_blank' rel="noopener noreferrer" onClick={(e) => onClickToMap(e)}>Показать на карте</a>
    </li>
  )
}

export default PickUpPoint