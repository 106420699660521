import React from 'react'
import { Gap } from '../../../components/commons/Gap';
import FaqItem from '../../../components/Home/About/Faq/FaqItem';
import { ReactComponent as TgIcon } from "../../../images/tg.svg";
import { ReactComponent as WhatsappIcon } from "../../../images/whatsapp.svg";
import SuppliersLandingTitle from '../SuppliersLandingTitle';
import { helpLandingJson } from '../../../static/helpLanding';
import "./SuppliersLandingFAQ.scss";

const faq = helpLandingJson;

const SuppliersLandingFAQ = () => {
  return (
    <section className='suppliers-landing-faq'>
      <SuppliersLandingTitle title="Часто задаваемые вопросы" />
      <div className='suppliers-landing-faq-content'>
        <div className='suppliers-landing-faq-content-left'>
          <span>Остались вопросы? Менеджер по работе с поставщиками будет рад помочь вам!</span>
          <Gap size={20} />
          <a className='suppliers-landing-faq-link' href="tel: 89628404000">8 962 840 40 00</a>
          <Gap size={10} />
          <ul className='suppliers-landing-faq-socials'>
            <li className='suppliers-landing-faq-social'>
              <a className="chat-icon" target="_blank" rel="noopener noreferrer" href="https://t.me/rusagro_market">
                <TgIcon />Telegram
              </a>

            </li>
            <li className='suppliers-landing-faq-social green'>
              <a className="chat-icon" target="_blank" rel="noopener noreferrer" href="https://wa.me/79628404000">
                <WhatsappIcon />WhatsApp
              </a>
            </li>

          </ul>
        </div>
        <div className='suppliers-landing-faq-content-right'>
          <FaqItem title={faq.data[0].question} content={faq.data[0].answer} />
          <FaqItem title={faq.data[1].question} content={faq.data[1].answer} />
          <FaqItem title={faq.data[2].question} content={faq.data[2].answer} />
          <FaqItem title={faq.data[3].question} content={faq.data[3].answer} />
          <FaqItem title={faq.data[4].question} content={faq.data[4].answer} />
          <FaqItem title={faq.data[5].question} content={faq.data[5].answer} />
          <FaqItem title={faq.data[6].question} content={faq.data[6].answer} />
          <FaqItem title={faq.data[7].question} content={faq.data[7].answer} />
          <FaqItem title={faq.data[8].question} content={faq.data[8].answer} />
        </div>
      </div>

    </section>
  )
}

export default SuppliersLandingFAQ