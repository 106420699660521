import React, { useState } from "react";
import Input from "../../commons/RamInput/Input";
import Button from "../../commons/Button/Button";
import { Gap } from "../../commons/Gap";
import Modal from "../../commons/Modal/Modal";
import Price from "../Card/Price/Price";
import "./NoresidentModal.scss";
import NoresidentModalEdit from "./NoresidentModalEdit/NoresidentModalEdit";
import { Loader } from "../../commons/Loader/Loader";
import SuccessfullPostModal from "../../commons/SuccessfullPostModal/SuccessfullPostModal";
import PhoneInput from "../../commons/RamInput/PhoneInput";
import Disclamer from "../../Cart/SubmitButton/Disclamer";
function NoresidentModal({ product, onClose, visible, isMobile }) {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const loading = false;
  const { price, saleUnit, gallery, title } = product;
  const image = gallery && gallery[0]?.previewUrl;

  const handleFieldChange = (value, setfunc) => {
    setfunc(value);
  };

  const handleShowSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  return (
    <div className="noresident-modal">
      <Modal onClose={() => onClose()} visible={visible}>
        <h2 className="noresident-modal-title">Заявка на поставку</h2>
        <Gap size={20} />
        <p className="noresident-modal-text">Данный поставщик не хранит товар на нашем складе, поэтому для заказа необходимо заполнить заявку на поставку.</p>
        <Gap size={20} />
        <div className="noresident-modal-content">
          <div className="noresident-modal-product">
            <img className="noresident-modal-product-pic" src={image} alt={title} />
            <div className="noresident-modal-product-data">
              <p className="noresident-modal-product-name">{price?.title}</p>
              <Price product={{ price: price, saleUnit: saleUnit && saleUnit.shortTitle }} />
            </div>
          </div>
          <Gap size={15} />
          {!!visible && (
            <div className="noresident-modal-product-actions">
              <NoresidentModalEdit product={product} setTotalPrice={setTotalPrice} />

              <p className="noresident-modal-product-total">
                Итого:
                <span className="noresident-modal-product-price">{totalPrice} ₽ </span>
              </p>
            </div>
          )}
        </div>
        <Gap size={30} />
        <form className="noresident-modal-form">
          <PhoneInput title="Номер телефона *" mobileNumber={phone} onPhoneChange={(value) => handleFieldChange(value, setPhone)} name="phone" />
          <Gap size={20} />
          <Input title="Ваше имя *" value={name} onChange={(value) => handleFieldChange(value, setName)} name="name" />
          <Gap size={20} />
          <Input title="Комментарий" value={comment} onChange={(value) => handleFieldChange(value, setComment)} name="comment" textarea={true} />
          <Gap size={20} />
        </form>
        <Disclamer />
        <Gap size={20} />
        <div className="noresident-modal-button">
          <Button title={loading ? "" : "Отправить"} buttonSize="medium" buttonType="primary" width={isMobile && "100%"} onClick={handleShowSuccessModal} />
          {loading && <Loader />}
        </div>
      </Modal>
      <SuccessfullPostModal text="Заявка успешно отправлена" onClose={() => handleShowSuccessModal()} visible={showSuccessModal} />
    </div>
  );
}

export default NoresidentModal;
