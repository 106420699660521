import * as React from "react";
import "./Hint.scss";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";
import { defaultCartHintText } from "../../../constants";
import classNames from "classnames";

const Hint = ({ text, side = null, isMobile, icon = <InfoIcon />, hasError = false, disabled = false }) => {
  const hintSide = side || (isMobile ? "top right" : "top");

  return (
    <div className={classNames("hint", { [hintSide]: true, error: hasError, disabled: disabled })}>
      {icon}
      <div className="hint-wrap">
        <div className="hint-content">
          <div className="content-wrap">{text || defaultCartHintText}</div>
        </div>
      </div>
    </div>
  );
};
export default Hint;
