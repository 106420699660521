import React, { useEffect, useState } from "react";
import "./Contacts.scss";
import Breadcrumbs from "../../components/commons/Breadcrumbs";
import Tabs from "../../components/commons/Tabs/Tabs.js";
import { ReactComponent as InfoIcon } from "../../images/service.svg";
import { ReactComponent as TgIcon } from "../../images/tg.svg";
// import { ReactComponent as WhatsappIcon } from "../../images/whatsapp.svg";
import { ReactComponent as Location } from "../../images/location.svg";
import Feedback from "../../components/commons/Feedback/Feedback";
import { CONTACTS } from "../../constants";

function Contacts() {
  const { env } = global.config;
  const [tab, setTab] = useState(env !== "dachnik" ? "nsk" : "msk");
  const deliveryMapSrc = "/delivery-map.js";
  const officeMapSrc = "/office-map.js";

  const contacts = {
    phone: env !== "dachnik" ? CONTACTS.PHONE.nsk : CONTACTS.PHONE.dachnik,
    phone_text: env !== "dachnik" ? CONTACTS.PHONE_TEXT.nsk : CONTACTS.PHONE_TEXT.dachnik,
    address: env !== "dachnik" ? CONTACTS.ADDRESS.msk : CONTACTS.ADDRESS.dachnik,
    email: env !== "dachnik" ? CONTACTS.EMAIL_INFO.market : CONTACTS.EMAIL_INFO.dachnik,
  }

  useEffect(() => {
    const script = document.createElement("script");

    script.src = tab === "nsk" ? deliveryMapSrc : officeMapSrc;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [tab]);

  useEffect(() => window.scrollTo(0, 0));

  const handleChangeTab = (tab) => {
    setTab(tab);
  };

  const renderNsk = () => {
    return (
      <div className="contacts-tab">
        <div className="contacts-left">
          <div className="contacts-info">
            <div className="contacts-info-icon">
              <InfoIcon />
            </div>
            <h3 className="contacts-info-caption">Поддержка онлайн-заказов</h3>
            <div className="contacts-data">
              <div className="contacts-data-texts">
                <a className="contacts-phone" href={`tel: ${CONTACTS.PHONE.nsk}`}>
                  {CONTACTS.PHONE_TEXT.nsk}
                </a>
                <span className="contacts-worktime">пн-пт 9:00 до 18:00</span>
              </div>
              <div className="contacts-data-icons">
                <a className="contacts-data-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.TELEGRAM}>
                  <TgIcon />
                </a>
                {/* <a className="contacts-data-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.WHATSAPP}>
                  <WhatsappIcon />
                </a> */}
              </div>
            </div>
            <a className="contacts-info-email" href="mailto:support@rusagro.market">
              support@rusagro.market
            </a>
          </div>
          <div className="contacts-info">
            <div className="contacts-info-icon location">
              <Location />
            </div>
            <h3 className="contacts-info-caption">ОРЦ РусАгроМаркет</h3>
            <p className="contacts-info-text">Новосибирская область, Обь городской округ, нп. 3307 км, 30кА1</p>
            <div className="contacts-data">
              <div className="contacts-data-texts">
                <a className="contacts-phone" href={`tel: ${CONTACTS.PHONE.nsk}`}>
                  {CONTACTS.PHONE_TEXT.nsk}
                </a>
                <span className="contacts-worktime">пн-пт 8:00 до 20:00</span>
              </div>
            </div>
            <a className="contacts-info-email" href="mailto:info@rosagromarket.ru">
              info@rosagromarket.ru
            </a>
          </div>
        </div>

        <div className="contacts-right">
          <iframe
            id="map_223302728"
            name="nsk"
            title="Карта"
            frameBorder="0"
            width="100%"
            height="519px"
            sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
          ></iframe>
        </div>
      </div>
    );
  };

  const renderMsk = () => {
    return (
      <div className="contacts-tab">
        <div className="contacts-left">

          {env === "dachnik" && <div className="contacts-info">
            <div className="contacts-info-icon">
              <InfoIcon />
            </div>
            <h3 className="contacts-info-caption">Поддержка онлайн-заказов</h3>
            <div className="contacts-data">
              <div className="contacts-data-texts">
                <a className="contacts-phone" href={`tel: ${contacts.phone}`}>
                  {contacts.phone_text}
                </a>
                <span className="contacts-worktime">пн-пт 9:00 до 18:00</span>
              </div>
            </div>
            <a className="contacts-info-email" href={`mailto:${CONTACTS.EMAIL_INFO.dachnik}`}>
              {CONTACTS.EMAIL_INFO.dachnik}
            </a>
          </div>}

          <div className="contacts-info">
            <div className="contacts-info-icon location">
              <Location />
            </div>
            <h3 className="contacts-info-caption">Центральный офис в Москве</h3>
            <p className="contacts-info-text">{contacts.address}</p>
            <div className="contacts-data">
              <div className="contacts-data-texts">
                <a className="contacts-phone" href={`tel: ${contacts.phone}`}>
                  {contacts.phone_text}
                </a>

                <span className="contacts-worktime">пн-пт 9:00 до 18:00 </span>
              </div>
            </div>
            <a className="contacts-info-email" href={`mailto:${contacts.email}`}>
              {contacts.email}
            </a>
          </div>
        </div>

        <div className="contacts-right">
          {env !== "dachnik" ? <iframe
            id="map_62414108"
            title="Карта"
            name="msk"
            frameBorder="0"
            width="100%"
            height="519px"
            sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
          ></iframe>
            : <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A72cb7c1f4ed9f80934edb2b8cdaee98df81e216b0a642e98806b067cbfa0bbf2&amp;source=constructor" width="100%"
              height="519px" frameborder="0" sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
          }
        </div>
      </div>
    );
  };

  const breadcrumbsList = [
    {
      id: 0,
      to: "/",
      text: "Главная",
    },
    {
      id: 1,
      to: "/contacts",
      text: "Контакты",
    },
  ];

  const tabs = [
    {
      name: "nsk",
      title: `Новосибириск `,
      content: renderNsk(),
    },
    {
      name: "msk",
      title: "Центральный офис в Москве",
      content: renderMsk(),
    },
  ];

  const tabsDachnik = [
    {
      name: "msk",
      title: "Центральный офис в Москве",
      content: renderMsk(),
    },
  ]

  return (
    <section className="contacts">
      <div className="contacts-content container">
        <Breadcrumbs items={breadcrumbsList} />
        <h2 className="contacts-title">Контакты</h2>
        <Tabs tabs={env !== "dachnik" ? tabs : tabsDachnik} handleChangeTab={handleChangeTab} />
        <Feedback />
      </div>
    </section>
  );
}

export default Contacts;
