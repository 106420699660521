import { clone, filter, findIndex, propEq } from "ramda";
import { getTotalSumAndDiscountForUnauthorisedUser } from "../actions/cart";
import { getProductByIdOrUrl } from "../actions/product";
import { emptyPromise } from "./utils";


const getTotalSumAndDiscount = (arr) => {
  const deliveryType = loadVirtualCartDeliveryType();
  if (!deliveryType) {
    return
  }
  const data = arr.map((item, i) => ({
    productOwnerId: item.productOwnerId,
    productId: item.productId,
    quantity: item.quantity,
    number: i,
    productOwner: item.productOwner,
    zoneId: item.zoneId,
  }))

  return getTotalSumAndDiscountForUnauthorisedUser(data, deliveryType)
}

export const loadVirtualCardItems = (zeroPricesInclude = false) => {

  const data = localStorage.getItem("virtual_cart_items");
  const virtualCard = data ? JSON.parse(data) : null;

  if (!virtualCard) return { items: [] };

  if (!zeroPricesInclude) {
    virtualCard.items = virtualCard.items.filter((i) => {
      const productPrice = i.prices?.map((p) => p.segments).flat().find((p) => p.minQuantity === 1)?.value
      return !!i.price || !!productPrice
    });
  }
  return updateVirtualCardItemsSummary(virtualCard, false);
};

export const loadVirtualCartServices = () => {
  const data = localStorage.getItem("virtual_cart_services");
  return JSON.parse(data);
};

export const loadVirtualCartDiscount = () => {
  const data = localStorage.getItem("virtual_cart_totalDiscount");
  return JSON.parse(data);
};

export const loadVirtualCartDeliveryType = () => {
  const data = localStorage.getItem("deliveryType");
  return data;
};
export const loadVirtualCartTotalNetWeight = () => {
  const data = localStorage.getItem("virtual_cart_totalNetWeight");
  return JSON.parse(data);
};
export const loadVirtualCartTotalSum = () => {
  const data = localStorage.getItem("virtual_cart_totalSum");
  return JSON.parse(data);
};

export const saveVirtualDeliveryType = (deliveryType) => {
  localStorage.setItem("deliveryType", deliveryType);
};

export const removeVirtualCart = () => localStorage.removeItem("virtual_cart_items");

export const addVirtualCardItem = (cartItems, product) => {
  const virtualCart = clone(cartItems);

  virtualCart.items.push(productToCartItem(product));

  return updateVirtualCardItemsSummary(virtualCart);
};

export const updateVirtualCardItem = (cartItems, products) => {
  const virtualCart = clone(cartItems);
  console.log("--update virtual");
  virtualCart.items = products.map((product) => productToCartItem(product));
  products &&
    products.items &&
    products.items.forEach((product) => {
      const itemIndex = findIndex(propEq("id", product.id))(virtualCart.items);
      console.log("--update virtual item", itemIndex, product);

      if (itemIndex > -1) {
        virtualCart.items[itemIndex] = productToCartItem(product);
      }
    });

  return updateVirtualCardItemsSummary(virtualCart);
};

export const removeVirtualCardItem = (cartItems, product) => {
  const virtualCart = clone(cartItems);
  virtualCart.items = filter((item) => item.id !== product.productId, virtualCart.items);
  return updateVirtualCardItemsSummary(virtualCart);
};

export const reloadVirtualCardItems = (accountType) => {
  const virtualCart = loadVirtualCardItems(true);

  if (virtualCart && accountType !== virtualCart.accountType) {
    let resultProducts = [];
    const promises = virtualCart.items.map(async (item) => {
      const { productId, productOwnerId } = item;

      const newProduct = await getProductByIdOrUrl(accountType, productOwnerId, productId);

      // if (!newProduct.price) {
      //   throw Error(`'${newProduct.title}' price is 0.`);
      // }
      item.price = newProduct.price;
      item.sum = item.quantity * newProduct.price;
      resultProducts.push(item);
    });
    return Promise.allSettled(promises).then(() => {
      virtualCart.items = resultProducts;
      virtualCart.accountType = accountType;
      updateVirtualCardItemsSummary(virtualCart); //, true, accountType
      return emptyPromise();
    });
  }

  return emptyPromise();
};

export const productToCartItem = (product) => {
  product.productId = product.id;
  product.productOwnerId = product.ownerId;
  product.productOwner = product.owner;

  if (!product.quantity) {
    product.quantity = product.deliveryIncrement;
  }

  if (product.minimumDeliveryQuantity || product.quantity) {
    product.quantity = product.minimumDeliveryQuantity > product.quantity ? product.minimumDeliveryQuantity : product.quantity;
  }

  const productPrice = product?.prices?.map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value;
  product.sum = product.quantity * productPrice;
  product.mainImage = product.gallery[0];
  return clone(product);
}

const updateVirtualCardItemsSummary = (cartItems, save = true, accountType = null) => {
  cartItems.itemsCount = cartItems.items.length;
  cartItems.totalSum = cartItems.items.reduce((sumAcc, { sum }) => sumAcc + sum, 0);
  getTotalSumAndDiscount(cartItems.items);

  const newCartItems = clone(cartItems);
  if (save) {
    localStorage.setItem("virtual_cart_items", JSON.stringify(newCartItems));
  }

  return newCartItems;


};
