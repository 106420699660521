import React from "react";
import whoCanOrderImage from "../../src/images/help_images/who_can_order.png";
import orderStatusImage from "../../src/images/help_images/order_status.png";
import howToPayImage from "../../src/images/help_images/how_to_pay.png";
import { ReactComponent as TgIcon } from "../../src/images/tg.svg";
import { ReactComponent as Logo } from "../../src/images/new_logo.svg";
// import { ReactComponent as WhatsappIcon } from "../../src/images/whatsapp.svg";
import logo_leaves from "../../src/images/logo_leaves.png";
import { Gap } from "../../src/components/commons/Gap";
import { CONTACTS, COMPANY_DETAILS } from "../constants";

const { env, baseUrl } = global.config;
const url = env !== "dev" ? baseUrl : "https://rusagro.market";
const contacts = {
  phone: CONTACTS.PHONE.dachnik,
  phone_text: CONTACTS.PHONE_TEXT.dachnik
}
const companyDetails = COMPANY_DETAILS[1];

export const helpDachnikJson = {
  dataBuyers: [
    {
      orderNumber: 1,
      section: "Общая информация",
      question: "О компании",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            dachnikrusagro.market – маркетплейс продуктов питания. Наш сервис осуществляет удаленное оформление и доставку свежих овощей и фруктов, выращенных садоводами, огородниками и владельцами личных подсобных хозяйств.
          </p>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Покупателям</h3>
          <p className="helpQARoot__answer-text">
            О товарах, представленных в нашем маркетплейсе, можно смело говорить – "Для себя выращивали!". На обычных прилавках вы не найдете именно такой продукции, "выращенной своими собственными руками"! С нами жители из разных регионов России имеют возможность приобрести свежие продукты местного производства от частных огородников и садоводов круглый год.
          </p>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Садоводам и огородникам</h3>
          <p className="helpQARoot__answer-text">
            1 января 2019 вступил в силу Закон о реализации сельскохозяйственной продукции (Федеральный закон № 217-ФЗ новая редакция с 2019 года). Благодаря ему жизнь садоводов, огородников и владельцев личных подсобных хозяйств изменилась к лучшему. Ведь теперь они имеют право продавать излишки урожая и не платить налог, если соответствуют требованиям:
          </p>
          <Gap size={20} />
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">продукцию вырастили на своем участке;</li>
            <li className="helpQARoot__answer-item">площадь участка не больше 0,5 га;</li>
            <li className="helpQARoot__answer-item">садовод не использует наемный труд при выращивании урожая.</li>
          </ul>
          <Gap size={20} />
          <p className="helpQARoot__answer-text">
            Таким образом, ваше желание извлечь выгоду из того, чем вы с успехом занимаетесь на своих дачах, огородах и в личных подсобных хозяйствах – выращиваете ли овощи и фрукты – получило законную поддержку на государственном уровне.
          </p>
          <Gap size={20} />
          <p className="helpQARoot__answer-text">
            Dachnikrusagro.market дает возможность садоводам и огородникам свободно продавать излишки урожая: зелень, овощи и фрукты. Мы предлагаем вам стабильный поток покупателей и логистическое обеспечение.
          </p>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Делитесь продукцией собственного производства открыто и с достоинством с Dachnikrusagro.market!
          </h3>
        </div>
      )
    },

    {
      orderNumber: 2,
      section: "Общая информация",
      question: "Как мы работаем ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            После прохождения модерации поставщики размещают свои товары на сервисе. Размещенные товары отображаются в нашем каталоге, и вы можете сделать по ним заказ.
          </p>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Как это происходит?</h3>
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">
              Вы регистрируетесь и указываете номер телефона, почту, имя. Выбираете товар(ы) в каталоге и добавляете в корзину.
            </li>
            <li className="helpQARoot__answer-item">Переходите к оформлению заказа, указываете адрес.</li>
            <li className="helpQARoot__answer-item">Поставщики подтверждают заказ и выставляют статусы по товарам.</li>
            <li className="helpQARoot__answer-item">Вы получаете уведомления о готовности отгрузки товаров.</li>
            <li className="helpQARoot__answer-item">Оплачиваете заказ.</li>
            <li className="helpQARoot__answer-item">Получаете товар на следующий день после поступления средств по вашему заказу.</li>
          </ul>
          <Gap size={20} />
          <a href="/help/2/0">*Подробнее об условиях доставки</a>
        </div>
      ),
    },
    {
      orderNumber: 3,
      section: "Общая информация",
      question: "Какие соглашения действуют в сервисе?",
      answer: (
        <div className="helpQARoot__answer">
          <h3 className="helpQARoot__answer-title">Пользователи соглашаются:</h3>
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">
              <a href={`${url}/license_agreement/`} target="_blank" rel="noopener noreferrer">
                Пользовательское соглашение маркетплейс покупатель
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${url}/license_agreement_supplier/`} target="_blank" rel="noopener noreferrer">
                Пользовательское соглашение маркетплейс поставщик
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${url}/privacy_policy`} target="_blank" rel="noopener noreferrer">
                Политика конфиденциальности
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${url}/privacy_agreement`} target="_blank" rel="noopener noreferrer">
                Согласие на обработку персональных данных
              </a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderNumber: 4,
      section: "Общая информация",
      question: "Реквизиты",
      answer: (
        <div className="helpQARoot__answer">
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Полное наименование организации </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.fullTitle}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Сокращенное наименование организации </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.shortTitle}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Местонахождение </h3>
              <span className="helpQARoot__requisites-text">
                {companyDetails.address}
              </span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Адрес для корреспонденции </h3>
              <span className="helpQARoot__requisites-text">
                {companyDetails.postalAddress}
              </span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">ИНН </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.inn}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">КПП </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.kpp}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">ОКПО </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.okpo}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">ОКВЭД </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.okved}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">ОГРН </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.ogrn}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Подписант (должность) </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.ceo}</span>
            </li>
          </ul>
          <Gap size={10} />
          <h2 className="helpQARoot__requisites-caption">Банковские реквизиты</h2>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Наименование банка </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.bankTitle}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Местонахождение банка </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.bankAddress}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Номер расчетного счета </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.bankAccount}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">БИК </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.bic}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Корреспондентский счет </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.corrAccount}</span>
            </li>
          </ul>
          <Gap size={10} />

          <h2 className="helpQARoot__requisites-caption">Контакты</h2>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Телефон</h3>
              <span className="helpQARoot__requisites-text">{companyDetails.tel}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">E-mail </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.email}</span>
            </li>
          </ul>
        </div>
      ),
    },

    // {
    //   orderNumber: 5,
    //   section: "Общая информация",
    //   question: "Контакты",
    //   answer: null,
    //   link: "/contacts",
    // },

    {
      orderNumber: 1,
      section: "Оформление заказа",
      question: "Кто может сделать заказ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            На нашем маркетплейсе заказ может сделать любое физическое лицо.
          </p>
        </div>
      ),
    },
    {
      orderNumber: 2,
      section: "Оформление заказа",
      question: "Как оформить заказ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Вы можете оформить заказ самостоятельно на нашем сайте в любое удобное для вас время. Для этого:
          </p>
          <Gap size={20} />
          <ol className="helpQARoot__answer-list numbers">
            <li className="helpQARoot__answer-item">зарегистрируйтесь на сайте, нажав кнопку «Войти»;
            </li>
            <li className="helpQARoot__answer-item">в каталоге товаров выберите, какое количество продукции вы желаете приобрести;</li>
            <li className="helpQARoot__answer-item">перейдите в корзину, проверьте выбранные позиции и их количество, нажмите кнопку сделать заказ;</li>
            <li className="helpQARoot__answer-item">выберите удобный вам способ оплаты, время получения и прочие нужные вам условия;
            </li>
            <li className="helpQARoot__answer-item">нажмите кнопку сделать заказ.
            </li>
          </ol>
        </div>

      )
    },
    {
      orderNumber: 3,
      section: "Оформление заказа",
      question: "Как происходит подтверждение и завершение заказа?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">После поступления денежных средств по вашему заказу.</p>
          {/* <p className="helpQARoot__answer-text">После того как вы оформили заказ, мы передаем всем поставщикам информацию с деталями заказа – статус заказа «В обработке».</p>
         <Gap size={30}/>
          <p className="helpQARoot__answer-text">Поставщики проверяют наличие товаров, в случае необходимости корректируют точный вес товара в заказе.</p>
         <Gap size={30}/>
          <h3 className="helpQARoot__answer-title">Поставщик должен подтвердить заказ в течение следующего времени:</h3>
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">Если заказ поступил с 00:00 до 15:00 поставщик должен подтвердить заказ до 16:00.</li>
            <li className="helpQARoot__answer-item">Если заказ поступил с 15:00-00:00 поставщик должен подтвердить заказ до 11:00 следующего дня.</li>
          </ul>
         <Gap size={30}/>
          <p className="helpQARoot__answer-text">
            Когда все поставщики в заказе выполнят обработку товаров, мы отправим вам уведомление на почту и в личный кабинет маркетплейс, статус заказа меняется на «Ожидает
            оплаты».
          </p>
         <Gap size={30}/>
          <h3 className="helpQARoot__answer-title">Далее вам нужно произвести оплату ранее выбранным способом:</h3>
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">Если способ оплаты «банковская карта», то заказ необходимо оплатить в течение 40 мин.</li>
            <li className="helpQARoot__answer-item">Если способ оплаты «безналичная оплата», то заказ необходимо оплатить до 15:00 следующего дня.</li>
          </ul>
         <Gap size={30}/>
          <p className="helpQARoot__answer-text">
            После поступления денежных средств на счет, статус заказа меняется на «Ожидает отгрузки», все поставщики в заказе передают товары на комплектацию в нашу логистику.
          </p>
         <Gap size={30}/>
          <p className="helpQARoot__answer-text">
            После того как товары переданы курьеру на доставку, статус заказа меняется на «Доставка», курьер связывается с вами и уточняет удобное время.
          </p>
         <Gap size={30}/>
          <p className="helpQARoot__answer-text">После принятия вами товаров со всеми сопровождающими документами, статус заказа меняется на «Вручен».</p> */}
        </div>
      ),
    },
    {
      orderNumber: 4,
      section: "Оформление заказа",
      question: "Как посмотреть статус заказа ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Посмотреть статус заказа можно в разделе <span className="helpQARoot__answer-accent">Заказы</span> в Личном кабинете.
          </p>
          <img className="helpQARoot__answer-img" src={orderStatusImage} alt="" />
          <h3 className="helpQARoot__answer-title">Обозначение статусов заказа:</h3>
          <span>
            <span className="helpQARoot__answer-accent">«В обработке»</span> – поставщик проверяет наличие или отсутствие товара.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Ожидает оплаты»</span> – поставщик ожидает оплаты товара покупателем.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Ожидает отгрузки»</span> – товар упакован и подготовлен к передаче перевозчику.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Доставка»</span> – товар находится у перевозчика в процессе осуществления доставки.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Вручен»</span> – покупатель принял все товары из заказа.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Вручен частично»</span> – заказ не будет выполнен, если его отменил поставщик или покупатель (заказ можно отменить до
            присвоения статуса <span className="helpQARoot__answer-accent">«Доставка»</span>).
          </span>
        </div>
      ),
    },
    {
      orderNumber: 1,
      section: "Доставка",
      question: "Какие сроки и время доставки ?",
      answer: (
        <div className="helpQARoot__answer">
          <ol className="helpQARoot__answer-list numbers">
            <li className="helpQARoot__answer-item">В случае поступления Заказа в период с 00 (нуля) часов 00 минут до 13 (тринадцати) часов 00 минут - доставка возможна в день оформления Заказа Покупателем, но не позднее двух дней с момента оформления Заказа Покупателем.
            </li>
            <li className="helpQARoot__answer-item">В случае поступления Заказа в период с 13 (тринадцати) часов 00 минут до 00 (нуля) часов 00 минут - не позднее двух дней с момента оформления Заказа Покупателем.</li>
          </ol>
        </div>
      )
    },
    {
      orderNumber: 2,
      section: "Доставка",
      question: "Стоимость доставки",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            При сумме заказа от 3000 руб. доставка - БЕСПЛАТНАЯ.
          </p>
          <p className="helpQARoot__answer-text">
            При заказе на сумму менее 3000 руб. стоимость доставки составляет 450 рублей.
          </p>
        </div>
      ),
    },
    {
      orderNumber: 3,
      section: "Доставка",
      question: "Как сделать заказ сразу у нескольких садоводов и огородников?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Вы оформляете свой заказ как обычно, а мы доставим его вам по частям в зависимости от количества продавцов.
          </p>
        </div>
      )
    },
    {
      orderNumber: 4,
      section: "Доставка",
      question: "Какая зона доставки?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Доставляем овощи по всем регионам России, однако покупатели могут заказать продукцию у огородников своего региона.
          </p>

        </div>
      )
    },
    {
      orderNumber: 1,
      section: "Оплата",
      question: "Какие есть способы оплаты ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">При оформлении заказа оплата осуществляется онлайн.</p>
          <Gap size={20} />
          <h3 className="helpQARoot__answer-title">К оплате принимаются банковские карты, у которых 16, 18, 19 цифр в номере:</h3>
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">VISA, MasterCard;</li>
            <li className="helpQARoot__answer-item">VISA Electron/Plus, Cirrus/Maestro, если у них есть код CVC2 и CVV2;</li>
            <li className="helpQARoot__answer-item">МИР.</li>
          </ul>
        </div>
      ),
    },
    {
      orderNumber: 2,
      section: "Оплата",
      question: "В какие сроки нужно оплатить заказ ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Оплата заказа должна осуществляться в течение 40 минут с момента получения подтверждения заказа от поставщиков (статус заказа «Ожидает оплаты»).
          </p>
          <span>* Вы оплачиваете только те товары, которые есть в наличии.</span>
        </div>
      ),
    },
    {
      orderNumber: 3,
      section: "Оплата",
      question: "Как осуществляются возвраты ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">Вы всегда можете отказаться заказа полностью или частично, если вас не устроили сроки годности или качество товаров.</p>
          <Gap size={30} />
          <p className="helpQARoot__answer-text">
            Если вы хотите вернуть товар при получении, формируется акт расхождения. После составления акта и возвращения товара, вы получаете возврат денежных средств в течение 10 рабочих дней.
          </p>
          <Gap size={30} />
          <span>* Возврат и обмен товара ненадлежащего качества осуществляется в сроки, установленные действующим Законодательством.</span>
          <span>* Вы оплачиваете только те товары, которые есть в наличии.</span>
          <span>* Не подлежат возврату или обмену продовольственные товары надлежащего качества.</span>
          <span>* При отказе Покупателя от товара, денежные средства за него будут автоматически возвращены на банковскую карту, с которой указанный товар был оплачен.</span>
        </div>
      ),
    },

    // {
    //   orderNumber: 1,
    //   section: "Юридические документы",
    //   question: "Пользовательское соглашение маркетплейс покупатель",
    //   answer: null,
    //   link: `${url}/license_agreement`,
    // },
    // {
    //   orderNumber: 2,
    //   section: "Юридические документы",
    //   question: "Пользовательское соглашение маркетплейс поставщик",
    //   answer: null,
    //   link: `${url}/privacy_policy/`,
    // },
    // {
    //   orderNumber: 3,
    //   section: "Юридические документы",
    //   question: "Пользовательское соглашение мобильное приложение покупатель",
    //   answer: null,
    //   link: `${url}/mobile_license_agreement/`,
    // },
    // {
    //   orderNumber: 4,
    //   section: "Юридические документы",
    //   question: "Политика конфиденциальности",
    //   answer: null,
    //   link: `${url}/privacy_policy`,
    // },
    // {
    //   orderNumber: 5,
    //   section: "Юридические документы",
    //   question: "Согласие на обработку персональных данных",
    //   answer: null,
    //   link: `${url}/privacy_agreement`,
    // },
    // {
    //   orderNumber: 6,
    //   section: "Юридические документы",
    //   question: "Смотреть все",
    //   answer: null,
    //   link: null,
    // },
  ],

  dataSuppliers: [
    {
      orderNumber: 1,
      section: "Поставщикам",
      question: "Как зарегистрироваться?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Чтобы зарегистрироваться в качестве поставщика вам необходимо оставить заявку нашему оператору по телефону 8 499 393 34 07. После обсуждение деталей, вам на почту будет отправлена ссылка на завершение регистрации, после чего сможете полноценно пользоваться кабинетом поставщика.
          </p>
        </div>
      ),
    },
    {
      orderNumber: 2,
      section: "Поставщикам",
      question: "Тарифы",
      answer: (
        <div className="helpQARoot__answer">
          <h3 className="helpQARoot__answer-title">На маркетплейсе Дачник установлены следующие комиссии для продавцов:</h3>
          <ul className="helpQARoot__answer-list ">
            <li className="helpQARoot__answer-item">
              комиссия за продажу _%*;
            </li>
            <li className="helpQARoot__answer-item">
              оплата за логистику 450 руб.
            </li>
          </ul>
          <Gap size={20} />
          <span>* Процентная ставка от стоимости заказа</span>
        </div>
      ),
    },
  ],
};
