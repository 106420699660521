import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { ReactComponent as LocationIcon } from "../../../images/location.svg";
import Button from "../../commons/Button/Button";
import { addressToString } from "../../../helpers/stringOperations";
import { setAddressModalVisible, checkAddressExist } from "../../../actions/cart";
import "./Address.scss";


const AddressButton = () => {
  const registration = useSelector((state) => state.cart.registration);
  const pickupPoint = useSelector((state) => state.cart.pickupPoint?.address);
  const accountType = useSelector((state) => state.profile.accountType);
  const deliveryType = useSelector((state) => state.cart.deliveryType);

  const { addressData } = registration;

  const fullAddress = addressToString(addressData, true);
  const pickupPointFullAddress = addressToString(pickupPoint, true);

  const dispatch = useDispatch();
  // checkAddressExist

  const onClick = () => {
    if (dispatch(checkAddressExist())) dispatch(setAddressModalVisible(true));
  };

  const getTitle = () => {
    if (deliveryType === "Pickup") {
      return pickupPointFullAddress || "Выберите способ получения"
    } else {
      return fullAddress || "Выберите способ получения"
    }
  }

  return (
    <div className={classNames("address-button", { [accountType]: true })}>
      <Button title={getTitle()} buttonSize="small" buttonType="link" icon={<LocationIcon />} onClick={onClick} />
    </div>
  );
};

export default AddressButton;
