import React from "react";
import "./MobileHeader.scss";
import { ReactComponent as UserIcon } from "../../../images/user.svg";
import { ReactComponent as BusinessIcon } from "../../../images/business.svg";

import classNames from "classnames";
import { useSelector } from "react-redux";

const AccountTypeSelect = ({ onSelectAccountType, visible = false }) => {
  const loggedIn = useSelector((state) => state.profile.loggedIn);

  const onClick = (accountType) => {
    onSelectAccountType(accountType);
  };

  if (global.config.businessAccountsOnly || !loggedIn) return null;

  return (
    <div>
      <div className={classNames({ [`account-type-select-underlay`]: true, active: visible })}></div>
      <div className={classNames({ "account-type-select": true, active: visible })}>
        <h2 className="account-type-title">Выберите, как вы планируете закупаться</h2>
        <div className="account-type-items">
          <div className="account-type-item" onClick={() => onClick("private")}>
            <div className="account-type-icon">
              <UserIcon />
            </div>
            <div className="account-type-description">
              <h3 className="account-type-subtitle">Как частное лицо</h3>
              <div className="info">Покупайте свежие продукты по оптовым ценам с доставкой напрямую со склада продавца.</div>
            </div>
          </div>
          <div className="account-type-item" onClick={() => onClick("business")}>
            <div className="account-type-icon business">
              <BusinessIcon />
            </div>
            <div className="account-type-description">
              <h3 className="account-type-subtitle">Как компания</h3>
              <div className="info">Мелкооптовые сборные закупки от проверенных поставщиков с нашей доставкой и отсрочкой платежа.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTypeSelect;
