import * as React from "react";
import "./SubmitButton.scss";
import Button from "../../commons/Button/Button";
import ErrorBox from "../ErrorBox/ErrorBox";
import Disclamer from "./Disclamer";

const SubmitButton = ({ nextStep, disabled, loading }) => (
  <div className="cart-submit-btn">
    <Button title="Оформить заказ" buttonSize="medium" buttonType="primary" width="100%" onClick={() => nextStep()} disabled={disabled} loading={loading} />
    {<ErrorBox />}
    <Disclamer />
  </div>
);

export default SubmitButton;
