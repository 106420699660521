import { fetchData, fetchMinimalOrderSum, fetchBanners } from "../helpers/rest";

export function startFetch(url) {
  return {
    type: "API_FETCH_START",
    url,
  };
}

export function doneFetch(url) {
  return {
    type: "API_FETCH_COMPLETE",
    url,
  };
}

export function setWindowWidth(width) {
  return {
    type: "SET_WINDOW_WIDTH",
    width,
  };
}

//

export function setPreload(preload) {
  return {
    type: "API_PRELOAD",
    preload,
  };
}

export function getBanners() {
  return (dispatch) => {
    fetchBanners().then((res) => {
      return dispatch({
        type: "API_FETCH_BANNERS",
        banners: res
      })
    })

  };
}

export function setMinimalOrderSum() {
  return (dispatch) => {
    fetchMinimalOrderSum().then((res) => {
      return dispatch({
        type: "API_FETCH_MINIMAL_OREDER_SUM",
        res,
      });
    });
  };
}

export function setSystemErrorMessage(errors = null) {
  return (dispatch) => {
    return dispatch({
      type: "SET_SYSTEM_ERROR_MESSAGE",
      errors,
    });
  };

}
