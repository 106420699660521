import React from 'react';
import "./OrderListSkeleton.scss";

const OrderListSkeleton = () => {
  return (
    <>
      <div className='order-list-item-sceleton-caption' />
      <ul className='order-list-item-list-sceleton-list'>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
        <li className='order-list-item-sceleton'>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-list'>
              <div className='order-list-item-sceleton-item' />
              <div className='order-list-item-sceleton-item' />
            </ul>
            <div className='order-list-item-sceleton-sum' />
          </div>
          <div className='order-list-item-sceleton-row'>
            <ul className='order-list-item-sceleton-pics'>
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
              <li className='order-list-item-sceleton-pic' />
            </ul>

            <div className='order-list-item-sceleton-button' />

          </div>
        </li>
      </ul>

    </>
  )
}

export default OrderListSkeleton