import * as React from "react";
import "./SubmitButton.scss";

const Disclamer = () => (
  <div className="disclaimer">
    Нажимая на кнопку, вы соглашаетесь с <a href="https://rusagro.market/license_agreement/">Пользовательским соглашением</a> и{" "}
    <a href="https://rusagro.market/privacy_policy/">Политикой обработки персональных данных</a>
  </div>
);

export default Disclamer;
