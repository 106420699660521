import { combineReducers } from 'redux';
import categories from './category';
import dictionary from './dictionary';
import products from './product';
import priceList from './priceList';
import cart from './cart';
import search from './search';
import profile from './profile';
import api from './api';
import order from './order';
import help from './help';
import notifications from './notifications';
import subscriptions from './subscriptions';


const rootReducer = combineReducers({
  categories,
  dictionary,
  products,
  priceList,
  cart,
  search,
  profile,
  api,
  order,
  help,
  notifications,
  subscriptions
});

export default rootReducer;
