import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Test extends React.Component {
  render() {
    return <div></div>;
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(() => ({}), mapDispatchToProps)(Test);
