import React from 'react'
import "./SuppliersLandingCooperation.scss"
import SuppliersLandingTitle from '../SuppliersLandingTitle'

const SuppliersLandingCooperation = () => {
  return (
    <section className='supplier-landing-cooperation'>
      <SuppliersLandingTitle title="Условия сотрудничества" />

      <table className='supplier-landing-cooperation-table'>
        <thead className='supplier-landing-cooperation-table-header'>
          <tr>
            <th className='supplier-landing-cooperation-table-heading '>Комиссия</th>
            <th className='supplier-landing-cooperation-table-heading'>Хранение</th>
            <th className='supplier-landing-cooperation-table-heading'>Складские услуги</th>
            <th className='supplier-landing-cooperation-table-heading right'>Доставка</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='supplier-landing-cooperation-table-cell'> <span className='supplier-landing-cooperation-accent' > 6%</span> – от стоимости врученного товара</td>
            <td className='supplier-landing-cooperation-table-cell'> <span> В режиме от +2°C до +15°C</span>
              <br />
              <span className='supplier-landing-cooperation-accent' >
                {` 31,6 руб палето-место/сутки `}
              </span>
            </td>
            <td className='supplier-landing-cooperation-table-cell'>Сборка заказа
              <span className='supplier-landing-cooperation-accent' >
                {`   3,87 руб/кг `}
              </span>
            </td>
            <td className='supplier-landing-cooperation-table-cell right'>Доставка до покупателя
              <span className='supplier-landing-cooperation-accent' >
                {`  не включена в стоимость `}
              </span>
              товара и оплачивается покупателем</td>
          </tr>
          <tr>
            <td className='supplier-landing-cooperation-table-cell bottom'></td>
            <td className='supplier-landing-cooperation-table-cell bottom '>
              <span> В режиме от -18°C до -22°C</span>
              <br />
              <span className='supplier-landing-cooperation-accent ' >
                {` 45,6 руб  палето-место/сутки `}
              </span>
            </td>
            <td className='supplier-landing-cooperation-table-cell bottom'></td>
            <td className='supplier-landing-cooperation-table-cell bottom right'></td>
          </tr>

        </tbody>
      </table>

      <ul className='supplier-landing-cooperation-table-mobile'>
        <li className='supplier-landing-cooperation-table-mobile-cell'>
          <span className='supplier-landing-cooperation-caption'>Комиссия</span>
          <span className='supplier-landing-cooperation-text'> <span className='supplier-landing-cooperation-accent' > 6%</span> – от стоимости врученного товара</span>

        </li>
        <li className='supplier-landing-cooperation-table-mobile-cell'>
          <span className='supplier-landing-cooperation-caption'>Хранение</span>
          <span className='supplier-landing-cooperation-text'>
            В режиме от +2°C до +15°C
            <br />
            <span className='supplier-landing-cooperation-accent' >
              {` 31,6 руб палето-место/сутки `}
            </span></span>
          <span className='supplier-landing-cooperation-text'>
            <span> В режиме от -18°C до -22°C</span>
            <br />
            <span className='supplier-landing-cooperation-accent ' >
              {` 45,6 руб  палето-место/сутки `}
            </span>
          </span>

        </li>
        <li className='supplier-landing-cooperation-table-mobile-cell'>
          <span className='supplier-landing-cooperation-caption'>Складские услуги</span>
          <span className='supplier-landing-cooperation-text'>
            Сборка заказа
            <span className='supplier-landing-cooperation-accent' >
              {`   3,87 руб/кг `}
            </span>
          </span>
        </li>

        <li className='supplier-landing-cooperation-table-mobile-cell'>
          <span className='supplier-landing-cooperation-caption'>Доставка</span>
          <span className='supplier-landing-cooperation-text'>
            Доставка до покупателя не
            включена в стоимость товара и оплачивается покупателем
          </span>
        </li>

      </ul>
    </section>
  )
}

export default SuppliersLandingCooperation