import React from "react";
import "./DialogModal.scss";
import Modal from "../../commons/Modal/Modal";
import Button from "../../commons/Button/Button";

const DialogModal = ({ visible, onClose, onSubmit, header, buttonOkTitle = "Хорошо", buttonOkDisabled = false, children, closeByClickOutside }) => {
  if (!visible) return null;

  return (
    <div className="dialog-modal">
      <Modal onClose={onClose} visible={visible} closeByClickOutside={closeByClickOutside}>
        <h2>{header}</h2>
        {children}
        <div className="dialog-buttons">
          <Button disabled={buttonOkDisabled} title={buttonOkTitle} buttonSize="medium" buttonType="primary" onClick={onSubmit} />
        </div>
      </Modal>
    </div>
  );
};

export default DialogModal;
