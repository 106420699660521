import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddMyCompanyModal.scss";
import DialogModal from "../../../Profile/DialogModal/DialogModal";
import SimpleModal from "../../../Profile/SimpleModal/SimpleModal";
import FillableDropDown from "../../../commons/FillableDropDown/FillableDropDown";
import { searchCompany, searchBankAccount, attachUserToCompany } from "../../../../actions/profile";
import Input from "../../../commons/RamInput/Input";
import { updateMyCompany, addMyCompany } from "../../../../actions/profile";
import { Gap } from "../../../commons/Gap";
import { setSystemErrorMessage } from "../../../../actions/api";

const renderCompanyDropDownText = (item) => (
  <div>
    <div className="main">{item.legalName}</div>
    <div>
      <span>ИНН: {item.inn}</span>
      {item.kpp && <span>, КПП: {item.kpp}</span>}
    </div>
  </div>
);

const renderBankDropDownText = (item) => (
  <div>
    <div className="main">{item.bankFullName}</div>
    <div>{item.bic}</div>
  </div>
);

const SAVE_COMPLETE_RESULT = {
  ADD: "ADD",
  UPDATE: "UPDATE",
};

const CompleteModal = ({ result, onClose }) => {
  const header = result == SAVE_COMPLETE_RESULT.ADD ? "Добавление компании" : "Данные обновленны";
  const message = result == SAVE_COMPLETE_RESULT.ADD ? "Компания упешно добавлена" : "Реквезиты компании успешно обновленны";
  return <SimpleModal visible={!!result} onClose={onClose} header={header} message={message} />;
};

const AddMyCompanyModal = ({ visible, onClose, editMode, company }) => {
  const dispatch = useDispatch();
  const [companyData, setCompanyData] = useState(company);
  const [bankAccountData, setBankAccountData] = useState(company);
  const [bankAccount, setBankAccount] = useState(company?.bankAccount);
  const [companyExist, setCompanyExist] = useState(false);
  const [saveCompleteResult, setSaveCompleteResult] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const systemErrors = useSelector((state) => state.api.systemErrors?.errors);
  const hasBankAccountSystemError = !!systemErrors?.find((item) => item.key === "bankAccount");
  const bankAccountSystemErrorText = systemErrors?.find((item) => item.key === "bankAccount")?.value || "";
  const hasBicSystemError = !!systemErrors?.find((item) => item.key === "bic");
  const bicSystemErrorText = systemErrors?.find((item) => item.key === "bic")?.value || "";
  const hasInnSystemError = !!systemErrors?.find((item) => item.key === "inn");
  const innSystemErrorText = systemErrors?.find((item) => item.key === "inn")?.value || "";

  useEffect(() => {
    setCompanyData(company);
    setBankAccountData(company);
    setBankAccount(company?.bankAccount);
    setCompanyExist(false);
  }, [visible]);

  const companySearchRequest = (value) => searchCompany(value);
  const bankAccountSearchRequest = (value) => searchBankAccount(value);

  const onChangeCompany = (selectName, data) => {
    setCompanyExist(false);
    setCompanyData(data);
  };

  const onChangeTradeName = (value) => {
    setCompanyData({ ...companyData, tradeName: value })
  }

  const onChangeBankAccount = (selectName, data) => {
    setBankAccountData(data);
  };

  const finishAction = (result) => {
    setSaveCompleteResult(result);
    onClose();
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false)
  }

  const onSubmitCompany = () => {
    const { bic } = bankAccountData;
    if (companyExist) {
      dispatch(attachUserToCompany(companyData.inn)).then((res) => {
        res?.isUpdated && finishAction(SAVE_COMPLETE_RESULT.ADD)
        res?.isCreated && dispatch(setSystemErrorMessage())
      });
    } else if (company) {
      dispatch(updateMyCompany(company.id, { bic, bankAccount })).then((res) => {
        res?.isUpdated && finishAction(SAVE_COMPLETE_RESULT.UPDATE)
        res?.isCreated && dispatch(setSystemErrorMessage())
      });
    } else {
      const { inn, kpp, legalName, tradeName } = companyData;
      setCompanyExist(false);
      dispatch(addMyCompany({ bic, bankAccount, inn, kpp, legalName, tradeName, CompanyRole: "Buyer", CompanyType: "LegalPerson" }))
        .then((res) => {
          res?.isCreated && finishAction(SAVE_COMPLETE_RESULT.ADD)
          res?.isCreated && dispatch(setSystemErrorMessage())
        })
        .catch(() => {
          setShowErrorModal(true)
        });
    }
  };

  const header = editMode ? "Изменить реквизиты" : "Добавление компании";
  const submitButtonTitle = editMode ? "Сохранить" : companyExist ? "Присоединиться" : "Добавить";
  const showBankAccount = editMode || companyData?.legalName;
  const submitDisabled = !companyExist && (!companyData?.legalName || !bankAccountData || !bankAccount || bankAccount.length !== 20);
  const errors = bankAccount && bankAccount?.length !== 20 ? { bankAccount: "Расчетный счет должен содержать 20 символов" } : {};
  const errorModalMessage = <span>Владелец компании не подтвердил заявку
    о присоединении к <span className="error-message-accent">{companyData?.legalName}</span> </span>
  return (
    <div className="add-my-company">
      <DialogModal visible={visible} onClose={onClose} onSubmit={onSubmitCompany} header={header} buttonOkDisabled={submitDisabled} buttonOkTitle={submitButtonTitle} closeByClickOutside={!companyExist}>
        {!editMode && (
          <FillableDropDown
            title="ИНН*"
            value={companyData?.inn}
            selectName="inn"
            searchRequest={companySearchRequest}
            onChange={onChangeCompany}
            renderItemText={renderCompanyDropDownText}
            max="10"
            withHint={false}
            hasError={errors.hasOwnProperty('inn') || hasInnSystemError}
            errorText={errors?.inn || innSystemErrorText}
          />
        )}

        {/* {companyExist && <InfoPanel message="Компания уже зарегистрирована, вы можете присоедиться к ней после верификации владельцем." />} */}

        {showBankAccount && !companyExist && (
          <FillableDropDown
            title="БИК банка*"
            value={bankAccountData?.bic}
            selectName="bic"
            searchRequest={bankAccountSearchRequest}
            onChange={onChangeBankAccount}
            renderItemText={renderBankDropDownText}
            max="9"
            withHint={false}
            hasError={errors.hasOwnProperty('bic') || hasBicSystemError}
            errorText={errors?.bic || bicSystemErrorText}
          />
        )}
        {showBankAccount && !companyExist && (
          <div>
            <Input type="digits" title="Расчетный счет*" value={bankAccount} onChange={(v) => setBankAccount(v)} max="20"
              hasError={errors.hasOwnProperty('bankAccount') || hasBankAccountSystemError}
              errorText={errors?.bankAccount || bankAccountSystemErrorText}
            />
            <Gap size={20} />
          </div>
        )}

        {!editMode && (
          <Input
            title="Торговое название компании"
            value={companyData?.tradeName}
            name="tradeName"
            onChange={onChangeTradeName}
            withHint={false}
          />
        )}

      </DialogModal>

      <SimpleModal visible={showErrorModal} onClose={handleCloseErrorModal} header="Заявка еще не одобрена" message={errorModalMessage} />
      <CompleteModal result={saveCompleteResult} onClose={() => setSaveCompleteResult(null)} />
    </div>
  );
};

export default AddMyCompanyModal;
