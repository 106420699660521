import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setRegistrationField, resetRegistration, searchAddress } from "../../../actions/cart";
import { ReactComponent as LocationIcon } from "../../../images/location.svg";
import FillableDropDown from "../../commons/FillableDropDown/FillableDropDown";
import { emptyPromise } from "../../../helpers/utils";
import { pick, isEmpty } from "ramda";
import "./Address.scss";

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      focusIn: false,
      hasError: false,
      hasAreaError: false,
      addressNotFound: false,
      timeOut: null,
    };

    this.addressInputRef = React.createRef();
  }

  onChangeInput(selectName, data) {
    const {
      registration: { addressData },
      resetRegistration,
    } = this.props;

    const { fullAddress } = data;

    if (addressData) {
      resetRegistration();
    }

    if (!fullAddress) {
      this.setState({ hasError: false });
    }

    this.selectItem(data);
  }

  searchAddressRequest = (address) => {
    return searchAddress(address).then((results) => {
      const addressNotFound = !results || results.length === 0;
      this.setState({ addressNotFound, hasAreaError: addressNotFound });
      return emptyPromise(results);
    });
  };

  selectItem(item) {
    const { setRegistrationField } = this.props;

    setRegistrationField("fullAddress", item.fullAddress || item.fullText);
    setRegistrationField("address", item.fullText);

    const hasError = (item.fullAddress || item.fullText) && this.validate(item);

    const hasAreaError = false;

    if (hasError || hasAreaError) {
      this.addressInputRef.current.focus();
    } else {
      const addressData = pick(["country", "locality", "number", "region", "street", "building"], item);
      setRegistrationField("addressData", isEmpty(addressData) ? null : addressData);
    }

    this.setState({ hasError, hasAreaError });
  }

  validate(item) {
    const { registration } = this.props;

    const shouldFilled = ["country", "locality", "number", "region", "street"];
    const validationItem = item || registration;

    const hasValidationError = shouldFilled.reduce((errAcc, fieldName) => !validationItem[fieldName] || errAcc, false);

    return hasValidationError;
  }

  onBlur() {
    // const timeOut = setTimeout(() => {
    //   const hasError = this.validate();
    //   this.setState({hasError});
    // }, 500);
    this.setState({ timeOut: null, focusIn: false });
  }

  onFocus() {
    // const { timeOut } = this.state;
    // if (timeOut) {
    //   clearTimeout(timeOut);
    //   this.setState({timeOut: null, focusIn: true});
    // }
    this.setState({ timeOut: null, focusIn: true });
  }

  render() {
    const { hasError, hasAreaError, focusIn } = this.state;
    const {
      registration: { fullAddress, addressErrorMessage },
    } = this.props;

    const renderSearchDropDownText = (item) => (
      <div>
        <div>{item.fullText}</div>
      </div>
    );

    const showError = (hasError || !!addressErrorMessage) && !focusIn;

    return (
      <div className="address-wrap">
        <FillableDropDown
          withHint={false}
          customRef={this.addressInputRef}
          hasError={hasError}
          classNames="addr"
          title="Город, улица, номер дома *"
          leftImg={<LocationIcon />}
          // rightImg={<SearchIcon />}
          value={fullAddress}
          onChange={(selectName, data) => this.onChangeInput(selectName, data)}
          searchRequest={this.searchAddressRequest}
          selectName="fullAddress"
          renderItemText={renderSearchDropDownText}
          onBlur={() => this.onBlur()}
          onFocus={() => this.onFocus()}
        />
        {!hasAreaError && showError && <div className="validation-msg">{addressErrorMessage || "Укажите адрес доставки вместе с номером дома"}</div>}
        {hasAreaError && <div className="validation-msg">Нас тут пока нет. Выберите другой адрес</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.profile.userId,
  registration: state.cart.registration,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegistrationField: setRegistrationField,
      resetRegistration: resetRegistration,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Address);
