import * as React from "react";
import "./OrdersList.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import EmptyOrders from "../EmptyOrders";
import Button from "../../../commons/Button/Button";
import { ReactComponent as RefreshIcon } from "../../../../images/refresh.svg";
import OrderListPagination from "./OrderListPagination";
import classNames from "classnames";
import OrderListItem from "./OrderListItem/OrderListItem";
import OrderListSkeleton from "./OrderListSkeleton/OrderListSkeleton";

const ACCOUNT_NOT_VERIFIED_HEADER = "Аккаунт ожидает подтверждения";
const ACCOUNT_NOT_VERIFIED_SUBHEADER =
  "Владельцу аккаунта организации необходимо подтвердить вашу регистрацию. После того как регистрация будет подтверждена вам будет доступно оформление заказа.";

class OrdersList extends React.Component {
  render() {
    const {
      orders,
      isMobile,
      totalCount,
      pageOffset,
      pageSize,
      handleChangePage,
      handleShowMoreOrders,
      ordersList,
      handlePageNumber,
      pageNumber,
      accountNotVerified,
      isLoadingOrderList,
    } = this.props;
    const monthes = ["12", "11", "10", "09", "08", "07", "06", "05", "04", "03", "02", "01"];
    const showButton = !!(orders && orders.totalCount > 10) && !!(orders && orders.results.length !== orders.totalCount);

    const sortByMonth = (month) => {
      return ordersList?.filter((order) => moment(order.createdDate).format("MM") === month);
    };
    const renderSortByMonths = () => {
      return monthes
        .map((e) => sortByMonth(e))
        .filter((order, i) => order.length > 0)
        .map((array, i) => {
          const monthAndYear = moment(array[0].createdDate).format("MMMM YYYY");
          return (
            <div key={monthAndYear}>
              <h3 className="orders-list-content-title">{monthAndYear}</h3>
              <ul className="orders-list-content">
                {array.map((order) => (
                  <OrderListItem order={order} key={order.id} />
                ))}
              </ul>
            </div>
          );
        });
    };

    const listSortedByYear = renderSortByMonths().sort((a, b) => b.key.slice(-4) - a.key.slice(-4));

    if (accountNotVerified && !isLoadingOrderList) {
      return <EmptyOrders isMobile={isMobile} header={ACCOUNT_NOT_VERIFIED_HEADER} subheader={ACCOUNT_NOT_VERIFIED_SUBHEADER} />;
    } else if ((!ordersList || ordersList.length === 0) && !isLoadingOrderList) {
      return <EmptyOrders isMobile={isMobile} />;
    } else {
      return (
        <section className="orders-list">
          {isLoadingOrderList && !listSortedByYear.length && <OrderListSkeleton />}
          {(listSortedByYear.length || !isLoadingOrderList) && listSortedByYear}
          <div
            className={classNames(`orders-list-navigation`, {
              mobile: isMobile,
            })}
          >
            {showButton && isMobile && (
              <div className="orders-list-navigation-button">
                <Button title="Показать ещё" buttonSize="large" buttonType="primary" icon={<RefreshIcon />} onClick={handleShowMoreOrders} />
              </div>
            )}
            {!isMobile && (
              <OrderListPagination
                totalCount={totalCount}
                offset={pageOffset}
                pageSize={pageSize}
                handleChangePage={handleChangePage}
                ordersList={ordersList}
                handlePageNumber={handlePageNumber}
                pageNumber={pageNumber}
              />
            )}
          </div>
        </section>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  totalCount: state.order.orders.totalCount,
  pageOffset: state.order.orders.offset,
  accountNotVerified: state.profile.accountNotVerified,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
