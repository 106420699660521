import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import SliderButton from "../../../commons/SliderButton/SliderButton";
import "swiper/swiper.less";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import { useSelector } from 'react-redux';
import moment from 'moment';
import "./DeliveryTimeSlots.scss"
import { Gap } from '../../../commons/Gap';
import { renderDayName } from '../../../../helpers/stringOperations';
import { getPickupSchedule } from '../../../../actions/order';
import { range } from 'ramda';
import { Loader } from '../../../commons/Loader/Loader';


SwiperCore.use([Navigation]);

const DeliveryTimeSlots = ({ selectedInterval, setSelectedInterval }) => {
  const [intervals, setIntervals] = useState(null);
  const [loading, setLoading] = useState(false);
  const [datesRange, setDatesRange] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [wrapperWidth, setWrapperWidth] = useState("");
  const windowWidth = useSelector((state) => state.api.windowWidth)
  const intRef = useRef(null);
  const slidesPerView = wrapperWidth / 115;
  const slidesPerGroup = Math.trunc(slidesPerView);
  const dummyIntervals = range(1, 17);
  const dummyDates = range(1, 3);
  const hideButtons = intervals?.length < slidesPerView;


  useEffect(() => {
    setLoading(true)
    getPickupSchedule().then((res) => {
      const dates = (res.slotsForDays?.map((item, i) => ({ title: formatedShipmentDate(item.date), date: item.date, id: i, slots: item.slots })));
      const selected = dates.find((item) => selectedInterval?.date === item.date);
      handleSetDate(selected || dates[0])
      setDatesRange(dates)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!selectedInterval && selectedDate) {
      const id = intervals && `${moment.utc(intervals[0]?.start).format("L")}_${0}`
      intervals && handelSetInterval(id, intervals[0]?.start, intervals[0]?.end)
    }
  }, [selectedInterval, intervals, selectedDate])

  useEffect(() => {
    setWrapperWidth(intRef.current?.offsetWidth)
  }, [windowWidth])

  const formatedShipmentDate = (date) => {
    if ((moment(date).isSame(moment(), "day"))) {
      return "Сегодня"
    }
    else if (moment(date).isSame(moment().add(1, 'day'), "day")) {
      return "Завтра"

    } else return renderDayName(moment(date).day());
  }

  const handelSetInterval = (id, startTime, endTime) => {
    setSelectedInterval({ id: id, date: selectedDate, start: startTime, end: endTime })

  }


  const renderDummySlides = dummyIntervals.map((item, i) => {
    return <SwiperSlide key={i}> <div className="delivery-dummy-slot" >
      <span className='delivery-time-slot-text'>Дата</span>
      <div className='delivery-dummy-slot-plug' />
    </div>
    </SwiperSlide>
  })

  const renderDummyDates = dummyDates.map((item, i) => {
    return <li className="delivery-time-dummy-date" key={i} >
      <span />
      <span />
    </li>
  })

  const renderSlides = intervals?.map((item, i) => {
    const id = `${moment.utc(item.start).format("L")}_${i}`
    const selected = selectedInterval?.id === id;

    return <SwiperSlide key={id} onClick={() => handelSetInterval(id, item.start, item.end)}>
      <div className={`delivery-time-slot ${selected ? "selected" : ""}`} >
        <span className='delivery-time-slot-text'>{moment(selectedDate).format("DD MMMM")}</span>
        <span className='delivery-time-slot-time'>{`${moment.utc(item.start).format("LT")} - ${moment.utc(item.end).format("LT")}`}</span>
      </div>
    </SwiperSlide>
  });

  const handleSetDate = (date) => {
    setSelectedDate(date.date)
    setIntervals(date?.slots)
  };

  const renderDates = () => {
    return datesRange?.map((item) => {
      return <li className={`delivery-time-slots-date 
       ${selectedDate === item?.date ? "selected" : ""}
      `} key={item.id} onClick={() => handleSetDate(item)}>
        <span>{item.title}</span>
        {moment(item.date).format("DD MMMM")}</li>
    })
  };

  return (
    <div className='delivery-time-slots-wrap' ref={intRef} >
      <ul className="delivery-time-slots-dates">{datesRange ? renderDates() : renderDummyDates}</ul>
      <Gap size={20} />
      <div className='delivery-time-slots' >
        <Swiper
          style={{ marginLeft: "0", marginRight: "0" }}
          spaceBetween={10}
          slidesPerView={slidesPerView}
          slidesPerGroup={slidesPerGroup}
          navigation={
            {
              prevEl: `.slider-btn-prev`,
              nextEl: `.slider-btn-next`,
            }

          }
        >
          {intervals ? renderSlides : renderDummySlides}
        </Swiper>
        {loading && <div className='delivery-time-slots-loader'><Loader /></div>}
      </div>
      <div className={`delivery-time-slot-buttons ${hideButtons ? "hidden" : ""}`}>
        <div className="delivery-time-slot-button">  <SliderButton buttonType="prev" buttonColor="blue" /></div>
        <div className="delivery-time-slot-button"> <SliderButton buttonType="next" buttonColor="blue" /></div>

      </div>

      <Gap size={20} />
    </div>
  )
}

export default DeliveryTimeSlots