import { fetchPostBackend, fetchGetBackend } from "../helpers/rest";

// function fetchNotificationsList() {
//   return {
//     type: "NOTIFICATIONS_LIST_FETCHING",
//   };
// }

function receiveNotifications(notifications) {
  return {
    type: "RECEIVE_NOTIFICATIONS",
    notifications,
  };
}

export function readNotification(id) {
  return (dispatch) => {
    return fetchPostBackend(`/api/buyer/v2.0/notifier/internal/site/notifications.mark-as-read?Filter=${id}`, null, true);
  };
}

export function readAllNotifications() {
  return (dispatch) => {
    return fetchPostBackend(`/api/buyer/v2.0/notifier/internal/site/notifications.mark-as-read`, null, true);
  };
}

export function setNotificationsPageSize(notificationsPageSize) {
  return {
    type: "SET_NOTIFICATIONS_PAGE_SIZE",
    notificationsPageSize: notificationsPageSize,
  };
}
export function getNotifications(itemsNum) {
  const itemsCount = itemsNum ? `&ItemsCount=${itemsNum}` : "";
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchGetBackend(`/api/buyer/v2.0/notifier/internal/site/notifications?Filter=SubscriberId==${userId},NotificationType==OrderCreatedForBuyer|OrderCancelledBySupplier|OrderPrePaid|OrderApproved|OrderDelivery&SortByFields=createdDate&Directions=desc${itemsCount}`, true)
      .then((json) => dispatch(receiveNotifications(json)))
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };
}
