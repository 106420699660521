import React from "react";
import "./CategoriesTagsList.scss";
import { last, values } from "ramda";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setSearchFilterAndSearch } from "../../../../actions/product";
import { bindActionCreators } from "redux";

const CategoriesTagsList = ({ categoriesByLevel, categoriesToShow }) => {
  const levelCount = values(categoriesByLevel).length;
  const lastCategory = last(values(categoriesByLevel));
  const hasLevel1 = levelCount > 0;
  const hasLevel2 = levelCount > 1;
  const hasLevel3 = levelCount > 2;

  if (!hasLevel1) {
    return null;
  }

  const getCategoryURI = (category) => {
    let uriBase = "/catalog/customerProducts/";

    if (category.level === 1) {
      uriBase += `${categoriesByLevel[1].id}`;
    } else if (category.level === 2) {
      uriBase += `${categoriesByLevel[1].id}/${category.id}`;
    } else if (category.level === 3) {
      uriBase += `${categoriesByLevel[1].id}/${categoriesByLevel[2].id}/${category.id}`;
    }

    return uriBase;
  };

  const parentUri = getCategoryURI(lastCategory);
  return (
    <div className="category-tags">
      {hasLevel1 && !hasLevel2 && (
        <Link className={classNames("category-tag", { active: !hasLevel2 })} to={parentUri}>
          Все
        </Link>
      )}
      {hasLevel2 && (
        <Link className={classNames("category-tag", { active: false })} to={getCategoryURI(categoriesByLevel[1])}>
          {categoriesByLevel[1].title}
        </Link>
      )}
      {hasLevel2 && (
        <Link className={classNames("category-tag", { active: !hasLevel3 })} to={getCategoryURI(categoriesByLevel[2])}>
          {categoriesByLevel[2].title}
        </Link>
      )}
      {!hasLevel3 &&
        categoriesToShow.map((c, i) => (
          <Link key={`category_tag_${i}`} className={classNames("category-tag", { active: false })} to={getCategoryURI(c)}>
            {c.title}
          </Link>
        ))}
      {hasLevel3 &&
        categoriesByLevel[3].siblings.map((c, i) => (
          <Link key={`category_tag_${i}`} className={classNames("category-tag", { active: c.id === lastCategory.id })} to={getCategoryURI(c)}>
            {c.title}
          </Link>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categoriesByLevel: state.categories.categoriesByLevel,
    categoriesToShow: state.categories.categoriesToShow,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearchFilterAndSearch: setSearchFilterAndSearch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoriesTagsList));
