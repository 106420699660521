import React, { useState } from "react";
import Modal from "../../../../commons/Modal/Modal";
import Button from "../../../../commons/Button/Button";
import "./modals.scss";
import Input from "../../../../commons/RamInput/Input";
import PhoneInput from "../../../../commons/RamInput/PhoneInput";
import EmailInput from "../../../../commons/RamInput/EmailInput";
import { clone } from "ramda";

const ChangeRecipientModal = ({ onClose, visible, contactPerson, contactPhone, contactEmail, updateContactInfo }) => {
  const [recipient, setRecipient] = useState({
    contactPerson,
    contactPhone,
    contactEmail,
  });

  const save = () => {
    updateContactInfo(recipient);
    onClose();
  };

  const handleRecipientChange = (name) => (value) => {
    const cloneRecipient = clone(recipient);
    cloneRecipient[name] = value;
    setRecipient(cloneRecipient);
  };

  return (
    <div className="order-modal">
      <Modal onClose={onClose} visible={visible}>
        <h2 className="order-modal-title">Изменение получателя</h2>
        <p className="sub-header">Изменение данных получателя станет недоступно после оплаты заказа.</p>
        <div className="content">
          <Input title="Имя" value={recipient.contactPerson} onChange={handleRecipientChange("contactPerson")} />
          <PhoneInput title="Телефон" mobileNumber={recipient.contactPhone} onPhoneChange={handleRecipientChange("contactPhone")} />
          <EmailInput title="E-mail для получения кассового чека" value={recipient.contactEmail} onChange={handleRecipientChange("contactEmail")} />
        </div>
        <div className="dialog-buttons">
          <Button title="Сохранить" buttonSize="medium" buttonType="primary" onClick={() => save()} />
        </div>
      </Modal>
    </div>
  );
};

export default ChangeRecipientModal;
