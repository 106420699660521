import React, { useState } from 'react';
import "./FoundCheaperModal.scss";
import Modal from '../../../commons/Modal/Modal';
import Input from "../../../commons/RamInput/Input";
import { Gap } from '../../../commons/Gap';
import Button from '../../../commons/Button/Button';
import { fetchPostBackend } from '../../../../helpers/rest';
import { Loader } from '../../../commons/Loader/Loader';

const FoundCheaperModal = ({ onClose, visible, productId }) => {
  const [price, setPrice] = useState("");
  const [link, setLink] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false)
  const activeButton = price && link;

  const hanldeChangePrice = (value) => {
    setPrice(value);
  }
  const hanldeChangeLink = (value) => {
    setLink(value);
  }

  const hanldeChangeComment = (value) => {
    setComment(value);
  }

  const hanldeSubmit = () => {
    const data = {
      productId: productId,
      validTill: null,
      competitorLink: link,
      desiredPrice: price,
      comments: comment
    }

    setLoading(true);

    return fetchPostBackend(`/api/public/v2.0/notifier/internal/site/notifications.send-lower-price-found-for-operator`, data, false).then(() => {
      setLoading(false);
      onClose();
    }).catch(() => {
      setLoading(false);
      onClose();
    });

  }

  return (
    <div className="found-cheaper-modal">
      <Modal onClose={onClose} visible={visible}>
        <div className="found-cheaper-modal-content">
          <h3 className="found-cheaper-modal-title">Нашли дешевле?</h3>
          <Gap size={20} />
          <span className="found-cheaper-modal-subtitle">Готовы рассмотреть новые условия</span>
          <Gap size={20} />
          <Input title="Желаемая цена" value={price} onChange={hanldeChangePrice} />
          <Gap size={20} />
          <Input title="Ссылка на товар или название поставщика" value={link} onChange={hanldeChangeLink} />
          <Gap size={20} />
          <Input title="Комментарий" value={comment} onChange={hanldeChangeComment} textarea={true} />
          <Gap size={20} />
          <Button title="Отправить" buttonSize="medium" buttonType="primary" disabled={!activeButton} onClick={hanldeSubmit} />
          {loading && <div className='found-cheaper-modal-content-loader'>
            <Loader />
          </div>}
        </div>
      </Modal>
    </div>
  )
}

export default FoundCheaperModal