import React from 'react'
import "./SuppliersLandingHowBecome.scss"
import SuppliersLandingTitle from '../SuppliersLandingTitle'
import { ReactComponent as CheckIcon } from "../../../images/check.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

const SuppliersLandingHowBecome = () => {
  return (
    <section className='supplier-landing-become'>
      <SuppliersLandingTitle title="Как стать поставщиком" color="white" />
      <ul className='supplier-landing-become-list'>
        <li className='supplier-landing-become-item'>
          <span className='supplier-landing-become-number'>1</span>
          <AnchorLink className='supplier-landing-become-link' href="#application-form" offset="50">Отправить заявку,</AnchorLink>
          учредительные документы
        </li>
        <li className='supplier-landing-become-item'>
          <span className='supplier-landing-become-number'>2</span>
          <span>  Подписать
            <a className='supplier-landing-become-link' href='https://rusagro.market/agency_contract_supplier' target='_blank' rel="noopener noreferrer" >{` договор оферту `}</a>
            в режиме онлайн</span>

        </li>
        <li className='supplier-landing-become-item'>
          <span className='supplier-landing-become-number'>3</span> Разместить товар на витрине и установить цены
        </li>
        <li className='supplier-landing-become-item'>
          <span className='supplier-landing-become-number'>4</span>
          Оформить заявку на поставку в ЛК и доставить товар c УПД-2 на склад ОРЦ
        </li>
        <li className='supplier-landing-become-item'>
          <span className='supplier-landing-become-number'>5</span>
          Мы размещаем товар на складе ОРЦ в нужных температурных режимах
        </li>
        <li className='supplier-landing-become-item'>
          <span className='supplier-landing-become-number'>6</span>
          Мы принимаем заказы от покупателей и отгружаем товар
        </li>
        <li className='supplier-landing-become-item'>
          <div className='supplier-landing-become-check'>
            <CheckIcon />
          </div>
          Теперь вы поставщик и мы осуществляем доставку ваших товаров покупателю
        </li>

      </ul>
    </section>
  )
}

export default SuppliersLandingHowBecome