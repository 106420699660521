import { fetchPostBackend, cancelableFetch, fetchGetBackend } from "../helpers/rest";
import { emptyPromise } from "../helpers/utils";
import { loadVirtualCardItems, removeVirtualCart, saveVirtualDeliveryType } from "../helpers/virtualCard";
import { loadVirtualDeliveryAddress, removeVirtualDeliveryAddress } from "../helpers/virtualAddress";
import { addAddressToUser, getDefaultAddress, setDefaultAddress } from "./order";
import { addressToString } from "../helpers/stringOperations";
import analytics from "../helpers/analytics";
import { isNil } from "ramda";
import { setAccountNotVerifiedModalVisible } from "./profile";
import { DELIVERY_TYPES } from "../constants";

function receiveCart(cart) {
  return {
    type: "RECEIVE_CART",
    cart,
  };
}

export function removeCart() {
  return {
    type: "REMOVE_CART",
  };
}

export function fetchCartItems() {
  return (dispatch, getState) => {
    const { userId, loggedIn } = getState().profile;
    const deliveryType = getState().cart.deliveryType;
    
    if (!loggedIn) {
      dispatch(loadVirtualCart());
      dispatch(setLoading(false));
      return emptyPromise();
    }
    dispatch(setLoading(true));

    return fetchPostBackend(`/api/orders/v1.0/cart/${userId}?deliveryType=${deliveryType}`, null, true)
      .then((json) => {
        dispatch(receiveCart(json));
        return dispatch(getCartItems(json.id)).finally(() => dispatch(setLoading(false)));
      })
      .catch(() => dispatch(setLoading(false)));
  };
}

export function addItemsFromOrder(orderId) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const cartId = getState().cart.cart?.id;

    return fetchPostBackend(`/api/orders/v1.0/cart/items/${userId}/${cartId}/add-items-from-order/${orderId}`, null, true).then((res) => {
      dispatch(getCartItems(cartId));
      return res;
    });
  };
}

function receiveCartItems(cartItems) {
  return {
    type: "RECEIVE_CART_ITEMS",
    cartItems,
  };
}

export function setRegistrationField(name, value) {
  return {
    type: "SET_REGISTRATION_FIELD",
    name,
    value,
  };
}

export function setDeliveryType(deliveryType) {
  return {
    type: "SET_DELIVERY_TYPE",
    deliveryType,
  };
}

export function setPickupPoint(pickupPoint) {
  return {
    type: "SET_PICKUP_POINT",
    pickupPoint,
  };
}
export function fetchPickupPoints(pickupPointsList) {
  return {
    type: "FETCH_PICKUP_POINTS",
    pickupPointsList,
  };
}

export function setAddressModalVisible(visible, id) {
  if (visible) analytics.reachGoal("open_location_popup");

  return {
    type: "SET_ADDRESS_MODAL_VISIBLE",
    addressModalVisible: visible,
  };
}

export function setAddressListModalVisible(visible) {
  if (visible) analytics.reachGoal("open_locations_popup");

  return {
    type: "SET_ADDRESS_LIST_MODAL_VISIBLE",
    addressListModalVisible: visible,
  };
}

export function setAddressContactsVisible(visible) {
  return {
    type: "SET_ADDRESS_CONTACTS_VISIBLE",
    addressContactsVisible: visible,
  };
}

var getCartItemsPromise = null;

export function getCartItems(cartId) {
  console.log("-----getCartItems", getCartItemsPromise);
  if (getCartItemsPromise && getCartItemsPromise.cancel) {
    getCartItemsPromise.cancel();
  }
  return (dispatch, getState) => {
    const { userId } = getState().profile;
    const { deliveryType } = getState().cart;

    getCartItemsPromise = cancelableFetch(`/api/orders/v1.0/cart/${userId}/${cartId}`, "GET", null, true).then((json) => {
      if (json.deliveryType.code !== deliveryType) {
        dispatch(changeDeliveryType(json.deliveryType.code))
      }
      dispatch(receiveCartItems(json));
      return dispatch(restoreFromVirtualCart());
    });
    return getCartItemsPromise;
  };
}

export function setActiveSupliers(activeSuppliers) {
  return {
    type: "SELECT_ACTIVE_SUPPLIERS",
    activeSuppliers,
  };
}

export function fetchActiveSuppliers() {
  return (dispatch, getState) => {
    const { activeSuppliers } = getState().cart;
    if (activeSuppliers.length > 0) return new emptyPromise(activeSuppliers);

    dispatch(setLoading(true));

    return fetchGetBackend(`/api/public/v2.0/business-profile/suppliers?Filter=Status==Active`)
      .then((json) => {
        dispatch(setActiveSupliers(json));
        dispatch(setLoading(false));
        return emptyPromise(json);
      })
      .catch((error) => {
        console.log("--error", error);
        dispatch(setLoading(false));
      });
  };
}

export function setLoading(isLoading) {
  return {
    type: "LOADING_CART",
    isLoading,
  };
}

export function setWaitForAccountAccomplishFlag(value) {
  return {
    type: "SET_WAIT_FOR_ACCOUNT_ACCOMPLSH_FLAG",
    value,
  };
}

export function addProductToCartItem(product) {
  return {
    type: "ADD_CART_ITEM",
    product,
  };
}

export function updateCartItems(products) {
  return {
    type: "UPDATE_CART_ITEMS",
    products,
  };
}

export function removeCartItem(product) {
  return {
    type: "REMOVE_CART_ITEM",
    product,
  };
}

export function addToCart(product) {
  return (dispatch, getState) => {
    const loggedIn = getState().profile.loggedIn;

    const quantity = product.minimumDeliveryQuantity > (product.quantity || 0) ? product.minimumDeliveryQuantity : product.quantity;
    const zoneId = product.zoneId;
    const productOwner = product.owner;

    if (loggedIn) {
      const userId = getState().profile.userId;
      const cartId = getState().cart.cart?.id;
      const productData = {
        productOwnerId: product.ownerId,
        productId: product.id,
        quantity,
        zoneId,
        productOwner,
      };

      dispatch(addProductToCartItem(product));

      return fetchPostBackend(`/api/orders/v1.0/cart/items/${userId}/${cartId}/add`, productData, true).then((json) => {
        analytics.productUpdateCart({ ...product, quantity }, 0, loggedIn);

        dispatch(getCartItems(cartId));
      });
    } else {
      analytics.productUpdateCart({ ...product, quantity }, 0, loggedIn);

      return dispatch(addToVirtualCart(product));
    }
  };
}

export function removeFromCart(product) {
  return (dispatch, getState) => {
    const loggedIn = getState().profile.loggedIn;

    if (loggedIn) {
      const userId = getState().profile.userId;
      const cartId = getState().cart.cart.id;

      dispatch(removeCartItem(product));

      return fetchPostBackend(`/api/orders/v1.0/cart/items/${userId}/${cartId}/delete`, product, true).then((json) => {
        analytics.productUpdateCart({ ...product, quantity: 0 }, product.quantity, loggedIn);
        return dispatch(getCartItems(cartId));
      });
    } else {
      analytics.productUpdateCart({ ...product, quantity: 0 }, product.quantity, loggedIn);
      return dispatch(removeFromVirtualCart(product));
    }
  };
}

export function updateCart(products, skipAnalytics = false) {
  return (dispatch, getState) => {
    const loggedIn = getState().profile.loggedIn;

    if (loggedIn) {
      const userId = getState().profile.userId;
      const cartId = getState().cart.cart.id;

      const productReq = products.map((p) => {
        const { productId, productOwnerId, quantity } = p;
        return { productId, productOwnerId, quantity };
      });

      //TODO: EXPEREMENTAL
      dispatch(updateCartItems(productReq));

      return cancelableFetch(`/api/orders/v1.0/cart/items/${userId}/${cartId}`, "POST", productReq, true).then((json) => {
        if (!skipAnalytics)
          products.filter((p) => !isNil(p.quantityBefore) && p.quantity !== p.quantityBefore).map((p) => analytics.productUpdateCart(p, p.quantityBefore, loggedIn));

        dispatch(receiveCartItems(json));
      });
    } else {
      products.filter((p) => !isNil(p.quantityBefore) && p.quantity !== p.quantityBefore).map((p) => analytics.productUpdateCart(p, p.quantityBefore, loggedIn));
      dispatch(updateVirtualCart(products));
      return emptyPromise();
    }
  };
}

export function searchAddress(query) {
  return cancelableFetch(`/api/orders/v1.0/logistics/addresshint/?query=${query}`, "GET", null, true);
}

export function loadVirtualCart() {
  return {
    type: "LOAD_VIRUAL_CART_ITEMS",
  };
}

function restoreFromVirtualCart() {
  return (dispatch, getState) => {

    const virtualCartItems = loadVirtualCardItems();
    if (virtualCartItems && virtualCartItems.items.length > 0) {
      return dispatch(updateCart(virtualCartItems.items, true)).then(() => {
        removeVirtualCart();
      });
    }
  };
}

export function addToVirtualCart(product) {
  return {
    type: "ADD_TO_VIRTUAL_CART",
    product,
  };
}

export function removeFromVirtualCart(product) {
  return {
    type: "REMOVE_FROM_VIRTUAL_CART",
    product,
  };
}

export function updateVirtualCart(products) {
  return {
    type: "UPDATE_VIRTUAL_CART",
    products,
  };
}

export function setRegistrationFull(registrationData, addressId = null) {
  const fullAddress = addressToString(registrationData.addressData, true);
  const registration = { ...registrationData, fullAddress, addressId };
  return {
    type: "SET_REGISTRATION_FULL",
    registration,
  };
}

export function resetRegistration() {
  return {
    type: "RESET_REGISTRATION",
  };
}

export function cleanRegistration() {
  return {
    type: "CLEAN_REGISTRATION",
  };
}

export function checkAddressExist() {
  return (dispatch, getState) => {
    const loggedIn = getState().profile.loggedIn;
    const accountNotVerified = getState().profile.accountNotVerified;
    const deliveryType = getState().cart.deliveryType;
    const isDelivery = deliveryType === DELIVERY_TYPES[0];
    const isPickUp = deliveryType === DELIVERY_TYPES[1];

    if (loggedIn && accountNotVerified) {
      dispatch(setAccountNotVerifiedModalVisible(true));
      return false;
    }

    const addressData = getState().cart.registration.addressData;
    const pickupPoint = getState().cart.pickupPoint;

    const deliveryAddressExist = isDelivery && addressData;
    const pickupPointExist = isPickUp && pickupPoint;

    const addressExist = !!deliveryAddressExist || !!pickupPointExist;
    if (!deliveryAddressExist && !pickupPointExist) dispatch(setAddressModalVisible(true));
    return addressExist;
  };
}

export function detectMyAddressByIp() {
  return (dispatch, getState) => {
    const { registration } = getState().cart;
    if (registration.fullAddress) return emptyPromise();
    //for test:?ip=178.64.168.126
    return fetchGetBackend("/api/orders/v1.0/logistics/addresshint-by-ip").then((addr) => {
      return dispatch(setRegistrationField("fullAddress", addr.locality));
    });
  };
}

export function fetchDeliveryAddress() {
  return (dispatch, getState) => {
    const { userId, loggedIn } = getState().profile;
    const { registration } = getState().cart;
    const virtualRegistration = loadVirtualDeliveryAddress();
    dispatch(getPickupPoints());

    if (!loggedIn) {
      if (virtualRegistration && virtualRegistration.defaultDeliveryType === DELIVERY_TYPES[1]) {
        dispatch(setPickupPoint(virtualRegistration))
        dispatch(changeDeliveryType(virtualRegistration.defaultDeliveryType))
      } else if (virtualRegistration) dispatch(setRegistrationFull(virtualRegistration));

      return emptyPromise();
    } else if (loggedIn && virtualRegistration) {
      const virtualRegistrationData = {
        ...virtualRegistration,
        recipientFullName: virtualRegistration.recipientFullName || registration.recipientFullName,
        recipientPhone: virtualRegistration.recipientPhone || registration.recipientPhone,
        recipientEmail: virtualRegistration.recipientEmail || registration.recipientEmail,
        details: virtualRegistration.details || registration.details
      }
      //Sync local stored address and set it as default

      if (virtualRegistration.defaultDeliveryType === DELIVERY_TYPES[1]) {
        dispatch(changeDeliveryType(virtualRegistration.defaultDeliveryType))
        dispatch(setDefaultAddress("", virtualRegistration.id, virtualRegistration.defaultDeliveryType)).then(() => removeVirtualDeliveryAddress());
        dispatch(setLoading(true));
        // return emptyPromise();
      } else {
        return dispatch(addAddressToUser(virtualRegistrationData, true)).then((addressId) => {
          dispatch(setDefaultAddress(addressId)).then(() => removeVirtualDeliveryAddress());
          dispatch(setLoading(true));
          // return emptyPromise();
        });
      }

    }

    dispatch(getDefaultAddress()).then((res) => {

      if (res.address) {
        const { country, locality, number, region, street, building } = res.address;
        const address = { ...registration, ...res.address, addressData: { country, locality, number, region, street, building } };
        res.address && dispatch(setRegistrationFull(address, res.address.id));
      }
      res.pickupPoint && dispatch(setPickupPoint(res.pickupPoint))
      res.deliveryType && dispatch(changeDeliveryType(res.deliveryType.code))

    }
    ).finally(() => dispatch(setLoading(false)));


    // dispatch(setLoading(true));

    // return fetchGetBackend("/api/orders/v1.0/logistics/addresses/" + userId, true)
    //   .then((json) => {
    //     console.log("DWEWQEWQEWQECQWEQ#QWB")
    //     const defaultAddress = json.find((item) => item.isDefault);
    //     const lastAddress = json.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))[0];
    //     const actualAddress = defaultAddress || lastAddress;

    //     const {
    //       interphone,
    //       apartment,
    //       entrance,
    //       floor,
    //       id,
    //       country,
    //       locality,
    //       number,
    //       region,
    //       street,
    //       building,
    //       isDefault,
    //     } = actualAddress;

    //     const addressData = { country, locality, number, region, street, building };

    //     const registrationData = {
    //       ...registration,
    //       interphone,
    //       apartment,
    //       entrance,
    //       floor,
    //       number,
    //       addressData,
    //       recipientFullName: actualAddress.recipientFullName || registration.recipientFullName,
    //       recipientPhone: actualAddress.recipientPhone || registration.recipientPhone,
    //       recipientEmail: actualAddress.recipientEmail || registration.recipientEmail,
    //       details: actualAddress.details || registration.details,
    //       isDefault,
    //       addressList: json,
    //     };
    //     dispatch(setRegistrationFull(registrationData, id));
    //     dispatch(setLoading(false));
    //   })
    //   .catch(() => dispatch(setLoading(false)));
  };
}

export function setActiveStep(step) {
  return {
    type: "SET_CART_ACTIVE_STEP",
    step,
  };
}


export const changeDeliveryType = (deliveryType) => {
  return (dispatch, getState) => {
    const loggedIn = getState().profile.loggedIn
    const userId = getState().profile.userId;
    const cartId = getState().cart.cart?.id;

    if (loggedIn && cartId) {
      return fetchPostBackend(`/api/orders/v1.0/cart/${userId}/${cartId}/change-delivery-type?deliveryType=${deliveryType}`, null, true).then((res) => {
        dispatch(setDeliveryType(deliveryType))
        saveVirtualDeliveryType(deliveryType)
        dispatch(getCartItems(cartId));
      });
    } else {
      dispatch(setDeliveryType(deliveryType));
      saveVirtualDeliveryType(deliveryType)
      loadVirtualCardItems();
    }

  }
}


export const getTotalSumAndDiscountForUnauthorisedUser = async (data, deliveryType) => {
  return fetchPostBackend(`/api/orders/v1.0/cart/items-without-saving/?isLegalPerson=true&deliveryType=${deliveryType}`, data).then((res) => {
    localStorage.setItem("virtual_cart_totalDiscount", JSON.stringify(res.totalDiscount));
    localStorage.setItem("virtual_cart_totalNetWeight", JSON.stringify(res.totalNetWeight));
    localStorage.setItem("virtual_cart_totalSum", JSON.stringify(res.totalSum));
    localStorage.setItem("virtual_cart_services", JSON.stringify(res.services));
  })
}

export function getPickupPoints() {
  return (dispatch) => {
    return fetchGetBackend(`/api/public/v2.0/delivery/internal/pickup/pickup-points`, false).then((res) => {
      dispatch(fetchPickupPoints(res))
      return res
    });
  }
}
