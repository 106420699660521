import React from "react";
import Input from "../../commons/RamInput/Input";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setRegistrationField } from "../../../actions/cart";

class AddressDetails extends React.Component {
  handleFieldChange = (fieldName) => (value) => {
    const { setRegistrationField } = this.props;
    // moment(value).format()
    setRegistrationField(fieldName, value);
  };

  render() {
    const { isMobile, registration } = this.props;

    return (
      <div className="address-details">
        {!isMobile && (
          <div className="row-line w25">
            <Input title="Квартира/Офис" value={registration.apartment} onChange={this.handleFieldChange("apartment")} />
            <Input title="Подъезд" value={registration.entrance} onChange={this.handleFieldChange("entrance")} />
            <Input title="Этаж" value={registration.floor} onChange={this.handleFieldChange("floor")} />
            <Input title="Домофон" value={registration.interphone} onChange={this.handleFieldChange("interphone")} />
          </div>
        )}
        {isMobile && (
          <div>
            <div className="row-line w50">
              <Input title="Квартира/Офис" value={registration.apartment} onChange={this.handleFieldChange("apartment")} />
              <Input title="Подъезд" value={registration.entrance} onChange={this.handleFieldChange("entrance")} />
            </div>
            <div className="row-line w50">
              <Input title="Этаж" value={registration.floor} onChange={this.handleFieldChange("floor")} />
              <Input title="Домофон" value={registration.interphone} onChange={this.handleFieldChange("interphone")} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  registration: state.cart.registration,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegistrationField: setRegistrationField,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddressDetails);
