import * as React from 'react';
import './DropDown.scss'
import { ReactComponent as ExpandIcon } from '../../../images/expand.svg';
import Input from '../RamInput/Input';
import { find, propEq } from 'ramda';

class DropDown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if ((this.wrapperRef && !this.wrapperRef.contains(event.target))) {
            this.toggleDropDown(false);
        }
    }

    toggleDropDown(active) {
        this.setState({ active })

    }
    onDropDownClick(itemValue) {
        const { onChange } = this.props;

        onChange(itemValue);
        this.toggleDropDown(false);
    }

    render() {
        const { title, value, selectValues, hasError, errorText } = this.props;
        const { active } = this.state;
        const selectedProp = find(propEq('value', value), selectValues);
        const text = selectedProp && selectedProp.text;
        return (<div className="drop-down" ref={this.setWrapperRef}>
            <Input rightImg={<ExpandIcon />} title={title} value={text} onClick={() => this.toggleDropDown(true)} hasError={hasError} errorText={errorText}  />
            {active && <div className="drop-down-content">
                {selectValues.map((item, i) => (<div className="drop-down-item" key={`dd_${i}`}
                    onClick={() => this.onDropDownClick(item.value)}>{item.text}
                </div>))}
            </div>}
        </div>)
    }
}

export default DropDown;