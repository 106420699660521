const emptyResults = {
  categories: [],
  products: [],
  hasMoreResults: false,
};
const initialState = {
  searchBarActive: false,
  searchResult: emptyResults,
  searchDropDownActive: false,
  searchText: null,
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        searchText: action.searchText,
      };

    case "RECEIVE_SEARCH_RESULTS":
      return {
        ...state,
        searchResult: action.result,
      };

    case "CLEAR_SEARCH_RESULTS":
      return {
        ...state,
        searchResult: emptyResults,
        searchDropDownActive: false,
        searchText: null,
      };

    default:
      return state;
  }
}
