import React from 'react';
import { ReactComponent as InfoIcon } from "../../../../../images/info.svg";
import "./EditOrderStatusInfo.scss";

const EditOrderStatusInfo = ({ paymentServiceId, cancellationReason, showPaymentDelayInfo, paymentStatus, orderStatus,
  leftToPay, deliverySum }) => {
  const orderCancelledBySupplier = cancellationReason.code === "CancelledBySupplier";
  const orderCancelledManually = cancellationReason.code === "CancelledByBuyer";
  const orderCancelledByTimer = cancellationReason.code === "CancelledForMissingPayment";

  const awaitingPayment = () => {
    if (paymentServiceId === "Qiwi" && orderCancelledByTimer) {
      return (
        <div className="edit-order-status-info warning">
          <InfoIcon />
          <div>Заказ был отменен, так как истек срок на оплату.</div>
        </div>
      );
    } else if (paymentServiceId === "Qiwi") {
      return (
        <div className="edit-order-status-info">
          <InfoIcon />
          Заказ собран, произведите оплату заказа.
        </div>
      );
    } else if (paymentServiceId === "Tochka") {

      return (
        <div className="edit-order-status-info">
          <InfoIcon />
          Заказ собран, произведите оплату по реквизитам из договор-счета.
        </div>
      );
    }

    else {
      return (
        <div className="edit-order-status-info">
          <InfoIcon />
          Заказ собран, произведите оплату заказа.
        </div>
      );
    }
  };

  if (["Draft", "Processing"].indexOf(orderStatus) !== -1) {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Ожидайте, пока поставщики подтвердят заказы</div>
      </div>
    );
  } else if (["AwaitingPayment", "Assembled"].indexOf(orderStatus) !== -1) return awaitingPayment();
  else if (orderStatus === "AwaitingShipment") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Заказ готов к отгрузке. Мы сообщим, когда заказ будет передан на доставку.</div>
      </div>
    );
  } else if (orderStatus === "Delivery") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Заказ передан курьеру. На контактный телефон вам придет ссылка для отслеживания заказа.</div>
      </div>
    );
  } else if (orderStatus === "Received") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Повторите Ваш заказ, мы будем рады привезти его для Вас!</div>
      </div>
    );
  } else if (orderStatus === "Received" && paymentStatus === "Processing") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Заказ доставлен, ожидается оплата.</div>
      </div>
    );
  }
  else if (orderStatus === "Completed") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Повторите Ваш заказ, мы будем рады привезти его для Вас!</div>
      </div>
    );
  } else if (orderStatus === "PartiallyReceived") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Повторите Ваш заказ, мы будем рады привезти его для Вас!</div>
      </div>
    );
  } else if (orderStatus === "PartiallyReceived" && showPaymentDelayInfo && paymentStatus === "AwaitingPayment") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Произведите оплату по договору-счету на сумму из корректирующего УКД.</div>
      </div>
    );
  } else if (orderStatus === "PartiallyReceived" && showPaymentDelayInfo && paymentStatus === "Paid") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Расскажите о своих впечатлениях о товарах в заказе с помощью кнопки “Оставить
          отзыв”.  Отзывы покупателей очень важны, потому что помогают другим сделать
          правильный выбор. </div>
      </div>
    );
  }
  // eslint-disable-next-line
  else if (orderStatus === "NotReceived" && paymentServiceId !== "Qiwi" && ((leftToPay && leftToPay !== deliverySum) || paymentStatus === "Paid")) {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Ожидайте возвращения средств в течение 10 дней</div>
      </div>
    );
  }
  // eslint-disable-next-line
  else if (orderStatus === "NotReceived" && paymentServiceId !== "Qiwi" && (!leftToPay || leftToPay === deliverySum) && orderStatus !== "Canceled") {
    return (<div className="edit-order-status-info">
      <InfoIcon />
      <div>Повторите ваш заказ, мы будем рады привезти его для вас!</div>
    </div>);
  }
  else if (orderStatus === "PendingApproval") {
    return (
      <div className="edit-order-status-info">
        <InfoIcon />
        <div>Необходимо согласовать заказ нажатием на кнопку “Заключить договор”.</div>
      </div>
    );
  }
  else if (orderStatus === "Cancelled" && orderCancelledManually) {
    return (
      <div className="edit-order-status-info warning">
        <InfoIcon />
        <div>Вы отменили заказ самостоятельно</div>
      </div>
    );
  } else if (orderStatus === "Cancelled" && orderCancelledBySupplier) {
    return (
      <div className="edit-order-status-info warning">
        <InfoIcon />
        <div>Заказ был отменен, так как все товары отсутствовали на складе</div>
      </div>
    );
  } else if (orderStatus === "Cancelled" && paymentServiceId === "Qiwi" && orderCancelledByTimer) {
    return (
      <div className="edit-order-status-info warning">
        <InfoIcon />
        <div>Заказ был отменен, так как истек срок на оплату.</div>
      </div>
    );

  } else if (orderStatus === "Cancelled" && paymentServiceId === "Tochka" && orderCancelledByTimer && !showPaymentDelayInfo) {
    return (
      <div className="edit-order-status-info warning">
        <InfoIcon />
        <div>Заказ был отменен, так как истек срок на оплату.</div>
      </div>
    );

  } else if (orderStatus === "Cancelled" && paymentServiceId === "Tochka" && orderCancelledByTimer && showPaymentDelayInfo) {
    return (
      <div className="edit-order-status-info warning">
        <InfoIcon />
        <div>Заказ был отменен, так как истек срок на согласование.</div>
      </div>
    );

  } else if (orderStatus === "Finished") {
    return (
      <div className="edit-order-status-info warning">
        <InfoIcon />
        <div>Расскажите о своих впечатлениях о товарах в заказе с помощью кнопки “Оставить
          отзыв”.  Отзывы покупателей очень важны, потому что помогают другим сделать
          правильный выбор. </div>
      </div>
    );
  }
}

export default EditOrderStatusInfo