import { fetchPostBackend, fetchGetBackend, fetchPatchBackend } from "../helpers/rest";
import { emptyPromise } from "../helpers/utils";
import { setRegistrationField, setRegistrationFull } from "./cart";
import { addVirtualDeliveryAddress } from "../helpers/virtualAddress";
import analytics from "../helpers/analytics";
import { DELIVERY_TYPES } from "../constants";
import moment from "moment";

function recieveOrders(orders) {
  return {
    type: "LOAD_USER_ORDERS",
    orders,
  };
}

function recieveOrder(order) {
  return {
    type: "SET_CURRENT_ORDER",
    order,
  };
}

// export function setAddressType(currentOrderAddressType) {
//   return {
//     type: "SET_CURRENT_ORDER_ADDRESS_TYPE",
//     currentOrderAddressType,
//   };
// }

export function fetchUserOrders(pageSize, pageOffset) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const itemsCount = getState().order.orders.itemsCount;
    const offset = getState().order.orders.offset;
    return fetchGetBackend(`/api/orders/v1.0/order/${userId}?ItemsCount=${pageSize ?? itemsCount}&Offset=${pageOffset ?? offset}`, true).then((json) => {
      dispatch(recieveOrders(json));
      return json;
    });
  };
}

export function fetchUserOrderById(orderId, readOnly = false) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchGetBackend(`/api/orders/v1.0/order/byid/${userId}/${orderId}`, true).then((json) => {
      if (readOnly) {
        return json;
      }

      dispatch(recieveOrder(json));
    });
  };
}

// export function fetchOrderById(orderId) {
//   return (dispatch, getState) => {
//     const userId = getState().profile.userId;

//     return fetchGetBackend(`/api/orders/v1.0/order/byid/${userId}/${orderId}`, true).then((json) => {
//       dispatch(recieveOrder(json));
//     });
//   };
// }

export function deletehOrderById(orderId) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;

    return fetchPostBackend(`/api/orders/v1.0/order/delete/${userId}/${orderId}`, null, true).then((json) => {
      dispatch(recieveOrder(null));
    });
  };
}
// /api/orders/v1.0/order/bycartid/{ownerid}/{cartid}

export function getOrderByCart() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const cartId = getState().cart.cart.id;

    return fetchGetBackend(`/api/orders/v1.0/order/bycartid/${userId}/${cartId}`, true).then((json) => {
      dispatch(recieveOrder(json));
    });
  };
}

export function makeOrderFromCart(deliveryDate, contactPerson, contactEmail, paymentServiceId, emailForReceipt, contactPhone, paymentMethod, useAutoApproval) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const cartId = getState().cart.cart.id;
    const cartItems = getState().cart.cartItems;

    const data = {
      cartId,
      deliveryDate,
      contactPerson,
      contactEmail,
      paymentServiceId,
      emailForReceipt,
      contactPhone,
      paymentMethod,
      useAutoApproval,
    };
    return fetchPostBackend(`/api/orders/v1.0/order/add/${userId}`, data, true)
      .then((json) => {
        analytics.cartPurchase(cartItems);
        analytics.reachGoal("place_an_order");
        dispatch(recieveOrder(json));
        return json;
      })
      .catch((error) => error);
  };
}

// export function makePaymentForOrder(
//   deliveryDate,
//   contactPerson,
//   contactEmail,
//   paymentServiceId,
//   emailForReceipt
// ) {
//   return (dispatch, getState) => {
//     const userId = getState().profile.userId;
//     const orderId = getState().order.currentOrder.id;

//     return fetchGetBackend(
//       `/api/orders/v1.0/order/add/${userId}/${orderId}`,
//       true
//     );
//     // .then((json) => {
//     //   return fetchUserOrderById(json.id);
//     // });
//   };
// }

export function addAddressToUser(registrationData, skipAnalytics = false) {
  return (dispatch, getState) => {
    const registration = registrationData || getState().cart.registration;
    // const {registration} = getState().cart;
    const { userId, loggedIn } = getState().profile;
    if (loggedIn) {
      const { interphone, apartment, entrance, floor, details, addressData, addressType, recipientFullName, recipientPhone, recipientEmail } = registration;
      const trimmedRecipientFullName = recipientFullName.trim();
      const data = {
        ...addressData,
        interphone,
        apartment,
        entrance,
        floor,
        details,
        addressType,
        recipientFullName: trimmedRecipientFullName,
        recipientPhone,
        recipientEmail,
      };
      return fetchPostBackend(`/api/orders/v1.0/logistics/add/${userId}`, data, true)
        .then((json) => {
          dispatch(setRegistrationFull(registration, json.id));
          if (!skipAnalytics) analytics.reachGoal("location_success");

          // dispatch(setRegistrationField("addressId", json.id));
          // dispatch(setRegistrationField("isValid", true));

          return emptyPromise(json.id);
        })
        .catch((e) => {
          // dispatch(setRegistrationField("isValid", false));
          dispatch(setRegistrationField("addressErrorMessage", e.message));
          return Promise.reject(e.message);
        });
    } else {
      addVirtualDeliveryAddress({...registration, defaultDeliveryType: DELIVERY_TYPES[0]});
      analytics.reachGoal("location_success");

      return emptyPromise();
    }
  };
}

export function updateUserAddress(addressId, registrationData) {
  return (dispatch, getState) => {
    // const {registration} = getState().cart;
    const { userId } = getState().profile;
    const { interphone, apartment, entrance, floor, details, addressData, addressType, recipientFullName, recipientPhone, recipientEmail, isDefault } = {
      ...getState().cart.registration,
      ...registrationData,
    };
    // const { interphone, apartment, entrance, floor, details, addressData, addressType } = registration;
    const trimmedRecipientFullName = recipientFullName.trim();
    const data = {
      id: addressId,
      ...addressData,
      interphone,
      apartment,
      entrance,
      floor,
      details,
      addressType,
      recipientFullName: trimmedRecipientFullName,
      recipientPhone,
      recipientEmail,
      isDefault,
    };

    return fetchPostBackend(`/api/orders/v1.0/logistics/update/${userId}`, data, true)
      .then((json) => {
        dispatch(setRegistrationFull(registrationData, json.id));
        // dispatch(setRegistrationField("isValid", true));
        analytics.reachGoal("location_success");
        return emptyPromise(json.id);
      })
      .catch((e) => {
        dispatch(setRegistrationField("addressErrorMessage", e.message));
        return Promise.reject(e.message);
      });
  };
}

export function getOrderAddress(addressId) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchGetBackend(`/api/orders/v1.0/logistics/address/${userId}/${addressId}`, true);
  };
}

export function deleteOrderAddress(addressId) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchPostBackend(`/api/orders/v1.0/logistics/delete/${userId}/${addressId}`, null, true);
  };
}

export function setOrderAddress(addressId, pickupFrom = null, pickupBefore = null) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const orderId = getState().order.currentOrder?.id;
    const deliveryType = getState().cart.deliveryType;

    let data = {
      id: addressId,
      deliveryType: deliveryType,
    }

    if (pickupFrom && pickupBefore) {
      data.pickupFrom = moment.utc(pickupFrom).format();
      data.pickupBefore = moment.utc(pickupBefore).format();
    }

    return fetchPostBackend(`/api/orders/v1.0/order/set-address/${userId}/${orderId}`, data, true);
  };
}

export function resetOrderAddress() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const orderId = getState().order.currentOrder.id;

    return fetchPostBackend(`/api/orders/v1.0/order/resetaddress/${userId}/${orderId}`, null, true);
  };
}

export function setDefaultAddress(defaultAddressId = null, defaultPickupPointId = null, defaultDeliveryType = null) {
  let data = {};

  if (defaultAddressId) {
    data.defaultAddressId = defaultAddressId
  }
  if (defaultPickupPointId) {
    data.defaultPickupPointId = defaultPickupPointId
  }
  if (defaultDeliveryType) {
    data.defaultDeliveryType = defaultDeliveryType
  }
  
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchPatchBackend(`/api/orders/v1.0/logistics/address/${userId}/set-default`, data, true);
  };
}

export function getDefaultAddress() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;

    return fetchGetBackend(`/api/orders/v1.0/logistics/addresses/${userId}/.get-default`, true);
  };
}

export function fetchUserAddress() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchGetBackend("/api/orders/v1.0/logistics/addresses/" + userId, true);
  };
}

export function updateContactInfo(orderId, data = { contactPerson: null, contactEmail: null, contactPhone: null }) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    // const orderId = getState().order.currentOrder.id;
    const urlParams = new URLSearchParams(data);
    return fetchPostBackend(`/api/orders/v1.0/order/updatecontactinfo/${userId}/${orderId}?${urlParams}`, null, true);
  };
}

export function cancelCurrentOrder(orderId) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const data = {
      buyerStatus: "Cancelled",
      cancellationReasonComment: "Отменён покупателем",
    };

    return fetchPostBackend(`/api/orders/v1.0/order/changestatus/${userId}/${orderId}/bybuyer`, data, true);
  };
}
export function changeOrderStatus(orderId, status) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const data = {
      buyerStatus: status,
    };

    return fetchPostBackend(`/api/orders/v1.0/order/changestatus/${userId}/${orderId}/bybuyer`, data, true);
  };
}

export function payCurrentOrder(ownerId, orderId, paymentServiceId, paymentid) {
  return (dispatch) => {
    return fetchGetBackend(`/api/orders/v1.0/paymentcallback?ownerid=${ownerId}&orderid=${orderId}&paymentserviceid=${paymentServiceId}&paymentstatus=Paid&paymentid=${paymentid}`);
  };
}

export function addPaymentCurrentOrder(ownerId, orderId) {
  return (dispatch) => {
    return fetchPostBackend(`/api/orders/v1.0/order/addpayment/${ownerId}/${orderId}`, null, true);
  };
}

export function downloadInvoice(buyerid, orderId) {
  return (dispatch) => {
    return fetchPostBackend(`/api/orders/v1.0/report/generateaccountagreement/${buyerid}/${orderId}`, null, true, true);
  };
}


export const getPickupSchedule = () => {
  return fetchGetBackend(`/api/buyer/v2.0/delivery/internal/pickup/get-schedule`, true);
}