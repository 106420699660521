import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./Actions.scss";
import { addToCart, checkAddressExist } from "../../../../actions/cart";
import Button from "../../../commons/Button/Button";
import Edit from "./Edit/Edit";
import { ReactComponent as CartIcon } from "../../../../images/cart.svg";
import { find, propEq } from "ramda";
import FavoriteButton from "../../FavoriteButton/FavoriteButton";
import classNames from "classnames";
import { PROMOTED_PRODUCTS } from "../../../../constants";

class ActionCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItem: null,
      productToAddLater: null,
      cartIsUpdating: false,
    };
  }
  componentDidMount() {
    this.setCartItem();
  }

  componentDidUpdate(prevProps, prevState) {
    const { product, cartItems, addToCart, addressData, addressModalVisible, pickupPoint } = this.props;
    const { productToAddLater, cartItem } = this.state;
    if (cartItems !== prevProps.cartItems || product !== prevProps.product) {
      this.setCartItem();
    }
    if (cartItem !== prevState.cartItem) {
      !cartItem && this.setState({ cartIsUpdating: false });
    }

    if (addressModalVisible !== prevProps.addressModalVisible && !addressModalVisible) {
      if ((addressData || pickupPoint) && productToAddLater) {
        addToCart(productToAddLater);
        this.setState({ productToAddLater: null });
      }
    }
  }

  setCartItem() {
    const { product, cartItems } = this.props;
    const cartItemData = cartItems ? find(propEq("productId", product.id), cartItems.items) : null;
    const cartItem = cartItemData ? { ...cartItemData, stockBalance: cartItemData?.stockBalance || product?.stockBalance } : null;

    this.setState({
      cartItem,
    });
  }

  onAddToCartClick = (e) => {
    const { addToCart, product, loggedIn, checkAddressExist } = this.props;
    this.setState({ cartIsUpdating: true });
    e.stopPropagation();
    e.preventDefault();
    if (checkAddressExist()) {
      loggedIn ? addToCart(product)
        .then(() => this.setState({ cartIsUpdating: false })) : addToCart(product)
          .catch(() => this.setState({ cartIsUpdating: false }));
    } else {
      this.setState({ productToAddLater: product, cartIsUpdating: false });
    }
  };

  render() {
    const { cartItem, cartIsUpdating } = this.state;
    const { buttonSize, buttonType, width, product, favoriteButton = "none", simple, galleryView, bonusBalance, bonusProduct } = this.props;
    const title = galleryView ? "+" : "В Корзину";
    const isLarge = buttonSize === "large";
    const promotedProduct = !!PROMOTED_PRODUCTS.find((item) => item === product.id);
    const productPrice = product?.prices?.map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value;
    const disableAddBonusProduct = bonusProduct && productPrice > bonusBalance;

    return (
      <div
        className={classNames({
          actions: true,
        })}
      >
        {favoriteButton === "left" && <FavoriteButton product={product} hasHint={simple} />}
        {!cartItem ? (
          <div className="action-buttons">
            <div className="action-button">
              {!galleryView ? (
                <div className="action-button-cart">
                  {/* {promotedProduct && <span className="action-button-tooltip pickup-cart">Товар доступен только для самовывоза со склада ОРЦ</span>} */}
                  <Button
                    title={title}
                    icon={<CartIcon />}
                    buttonSize={buttonSize}
                    buttonType={buttonType}
                    // disabled={promotedProduct}
                    onClick={this.onAddToCartClick}
                    width={width}
                    loading={cartIsUpdating}
                  />
                </div>
              ) : (
                <div className="action-button-add">
                  {disableAddBonusProduct && <span className="action-button-add-tooltip">Недостаточно перецкойнов</span>}
                  {/* {promotedProduct && <span className="action-button-tooltip pickup">Товар доступен только для самовывоза со склада ОРЦ</span>} */}
                  <Button title={title} buttonSize={buttonSize} buttonType={buttonType} disabled={disableAddBonusProduct} onClick={this.onAddToCartClick} width={40} loading={cartIsUpdating} />
                </div>
              )}
            </div>
          </div>
        ) : (
          <Edit cartItem={cartItem} large={isLarge} galleryView bonusProduct={bonusProduct} />
        )}
        {favoriteButton === "right" && <FavoriteButton product={product} squared={isLarge} hasHint={simple} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.profile.loggedIn,
    bonusBalance: state.profile.account?.bonusBalance,
    cartItems: state.cart.cartItems,
    isDesktop: state.api.isDesktop,
    addressData: state.cart.registration.addressData,
    pickupPoint: state.cart.pickupPoint,
    addressModalVisible: state.cart.addressModalVisible,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addToCart: addToCart,
      checkAddressExist: checkAddressExist,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionCart);
