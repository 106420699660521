import * as React from "react";
import "./Breadcrumbs.scss";
import { last, values } from "ramda";

import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
  const { categoriesByLevel, title, baseUri = "/catalog/customerProducts/", absoluteUrl = false } = props;
  const levelCount = values(categoriesByLevel).length;
  const lastCategory = last(values(categoriesByLevel));
  const hasLevel1 = levelCount > 0;
  const hasLevel2 = levelCount > 1;
  const hasLevel3 = levelCount > 2;


  const renderLastCategory = () =>
    title ? (
      <Link to={`${baseUri}${categoriesByLevel[1].id}/${categoriesByLevel[2].id}/${categoriesByLevel[3].id}`} className="link ">
        {categoriesByLevel[3].title}
      </Link>
    ) : (
      <span>{lastCategory.title}</span>
    );

  return (
    <ul className="breadcrumb">
      {/* <ol className="breadcrumb-item"><a className="link " href="/">Главная</a></ol> */}
      <ol className="breadcrumb-item">
        <Link to={"/"} className="link ">
          Главная
        </Link>
      </ol>
      {hasLevel1 && (
        <ol className="breadcrumb-item">
          <Link to={`${baseUri}${categoriesByLevel[1].id}`} className="link ">
            {categoriesByLevel[1].title}
          </Link>
        </ol>
      )}
      {hasLevel2 && (
        <ol className="breadcrumb-item">
          <Link to={absoluteUrl ? `${baseUri}${categoriesByLevel[2].id}` : `${baseUri}${categoriesByLevel[1].id}/${categoriesByLevel[2].id}`} className="link ">
            {categoriesByLevel[2].title}
          </Link>
        </ol>
      )}
      {/* hide 3d level category */}
      {/* {hasLevel3 && <ol className="breadcrumb-item">{renderLastCategory()}</ol>} */}
      {title && (
        <ol className="breadcrumb-item">
          <span>{title}</span>
        </ol>
      )}
    </ul>
  );
};
export default Breadcrumbs;
