import React from 'react';
import { Gap } from '../../../components/commons/Gap';
import SuppliersLandingTitle from '../SuppliersLandingTitle';
import "./SuppliersLandingAbout.scss";

const SuppliersLandingAbout = () => {
  return (
    <section className='suppliers-landing-about'>
      <div className='suppliers-landing-about-left'>
        <SuppliersLandingTitle title="О нас" />
        <ul className='suppliers-landing-about-description-list'>
          <li className='suppliers-landing-about-description'>
            <span className='suppliers-landing-about-accent'> FoodTech платформа русагро.маркет</span>
            – сервис удаленного оформления и доставки сборных оптовых заказов свежих продуктов питания.
          </li>
          <li className='suppliers-landing-about-description'>
            Платформа предоставляет комплекс эффективных решений для производителей, поставщиков и оптовых покупателей свежих продуктов питания.
          </li>
          <li className='suppliers-landing-about-description'>
            Наша экосистема построена на базе уникального системообразующего ОРЦ в Новосибирске, который является крупнейшим продовольственным хабом всего региона.
          </li>
        </ul>
        <Gap size={30} />
        <ul className='suppliers-landing-about-info-list'>
          <li className='suppliers-landing-about-info-item'>
            <span className='suppliers-landing-about-info-caption'>
              108
              <span> га</span>
            </span>
            <span className='suppliers-landing-about-info-text'>
              Площадь ОРЦ
            </span>
          </li>
          <li className='suppliers-landing-about-info-item'>
            <span className='suppliers-landing-about-info-caption'>
              28
              <span> тыс. м²</span>
            </span>
            <span className='suppliers-landing-about-info-text'>
              Площадь оптовых
              павильонов
            </span>
          </li>
        </ul>

        <Gap size={30} />

        <ul className='suppliers-landing-about-info-list'>
          <li className='suppliers-landing-about-info-item'>
            <span className='suppliers-landing-about-info-caption'>
              75
              <span> тыс. м²</span>
            </span>
            <span className='suppliers-landing-about-info-text'>
              Площадь зоны хранения
            </span>
          </li>
          <li className='suppliers-landing-about-info-item'>
            <span className='suppliers-landing-about-info-caption'>
              82
              <span> тыс. палетомест</span>
            </span>
            <span className='suppliers-landing-about-info-text'>
              Объем единовременного
              хранения
            </span>
          </li>
        </ul>


      </div>

    </section>
  )
}

export default SuppliersLandingAbout