import * as React from "react";
import "./BackButton.scss";
import { ReactComponent as BackIcon } from "../../../images/arrow-back.svg";

const BackButton = ({ label = "Назад", onClick }) => (
  <div className="back-button">
    <div onClick={onClick}>
      <BackIcon /> {label}
    </div>
  </div>
);

export default BackButton;
