import React, { useEffect, useRef } from "react";

import "./Modal.scss";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";

const Modal = (props) => {
  const { onClose, visible, children, type, maxSize = false, closeByClickOutside = true, preventClickOutside } = props;

  const modalRef = useRef();

  const handleClickOutside = (e) => {
    if (preventClickOutside) return;
    if (!modalRef.current.contains(e.target) && visible && closeByClickOutside) {
      !!onClose && onClose();
    }
  };

  useEffect(() => {
    if (!!onClose) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }
  });

  return (
    <div
      className={classNames("modal", {
        show: visible,
        "save-success": type === "save",
      })}
    >
      <div
        ref={modalRef}
        className={classNames("modal-content", {
          show: visible,
          "max-size": maxSize,
          "save-success": type === "save",
        })}
      >
        {!!onClose && <span className="close" onClick={() => onClose()}>
          <CloseIcon />
        </span>}
        {children}
      </div>
    </div>
  );
};

export default Modal;
