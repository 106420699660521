import React, { useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../commons/RamInput/Input";
import Button from "../../Button/Button";
import { Gap } from "../../Gap";
import { Loader } from "../../Loader/Loader";
import Modal from "../../Modal/Modal";
import SuccessfullPostModal from "../../SuccessfullPostModal/SuccessfullPostModal";
import ReviewImageUpload from "../ReviewImageUpload/ReviewImageUpload";
import ReviewStars from "../ReviewStars/ReviewStars";
import "./ReviewsModal.scss";

function ReviewsModal({ onClose, visible }) {
  const [comment, setComment] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const loading = false;
  const [editedRating, setEditedRating] = useState({
    service: 0,
    quality: 0,
    price: 0,
  });

  const showSubmitButton = editedRating.service === 0 || editedRating.quality === 0 || editedRating.price === 0;

  const isMobile = useSelector((state) => state.api.isMobile);

  const handleFieldChange = (value) => {
    setComment(value);
  };

  const handleShowSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const handleSendReview = () => {
    handleShowSuccessModal();
  };

  const handleChangeRating = (rating, key) => {
    setEditedRating({ ...editedRating, [key]: rating });
  };

  return (
    <div className="reviews-modal">
      <Modal onClose={() => onClose()} visible={visible}>
        <h2 className="reviews-modal-title">Оставить отзыв</h2>
        <Gap size={20} />
        <div className="reviews-modal-rating">
          <span className="reviews-modal-rating-text">Сервис:</span>
          <ReviewStars rating={editedRating.service} size="large" color="white" edit={{ key: "service", fnc: handleChangeRating }} />
        </div>
        <Gap size={20} />
        <div className="reviews-modal-rating">
          <span className="reviews-modal-rating-text">Качество товара:</span>
          <ReviewStars rating={editedRating.quality} size="large" color="white" edit={{ key: "quality", fnc: handleChangeRating }} />
        </div>
        <Gap size={20} />
        <div className="reviews-modal-rating">
          <span className="reviews-modal-rating-text">Цена:</span>
          <ReviewStars rating={editedRating.price} size="large" color="white" edit={{ key: "price", fnc: handleChangeRating }} />
        </div>
        <Gap size={20} />

        <form className="reviews-modal-form">
          <Input title="Комментарий" value={comment} onChange={(value) => handleFieldChange(value)} name="comment" textarea={true} />
        </form>

        <Gap size={30} />

        <h3 className="reviews-modal-subtitle">Фото</h3>
        <Gap size={20} />

        <ReviewImageUpload />
        <Gap size={30} />

        <div className="reviews-modal-button">
          <Button
            title={loading ? "" : "Отправить отзыв"}
            buttonSize="medium"
            buttonType="primary"
            width={isMobile && "100%"}
            onClick={handleSendReview}
            disabled={showSubmitButton}
          />
          {loading && <Loader />}
        </div>
      </Modal>
      <SuccessfullPostModal text="Спасибо, отзыв добавлен!" onClose={() => handleShowSuccessModal()} visible={showSuccessModal} />
    </div>
  );
}

export default ReviewsModal;
