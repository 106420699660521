import React, { useState, useEffect } from "react";
import { getAbbreviation } from "../../../../helpers/stringOperations";
import "./ReviewCard.scss";
import ReviewStars from "../ReviewStars/ReviewStars";
import { ReactComponent as RefreshIcon } from "../../../../images/refresh.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import ZoomModal from "../../ZoomModal/ZoomModal";
import Button from "../../../commons/Button/Button";

function ReviewCard({ reviews }) {
  const [zoomImage, setZoomImage] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [slicedReviews, setSlicedReviews] = useState(reviews);

  const showButton = !!(reviews.length > 3) && !!(slicedReviews.length !== reviews.length);

  useEffect(() => {
    setSlicedReviews(reviews.slice(0, pageSize));
  }, [pageSize, reviews]);

  const handleZoomModal = () => {
    setZoomImage(!zoomImage);
  };

  const zoomCard = (index, images) => {
    handleZoomModal();
    setGalleryIndex(index);
    setGalleryImages(images);
  };

  const handlePageSize = () => {
    setPageSize(pageSize + 3);
  };

  const renderReviews = slicedReviews.map((review) => {
    const { id, avatar, name, date, rating, text, galleryImages } = review;

    return (
      <li className="review-card" key={id}>
        <div className="review-card-content">
          <div className="review-card-content-wrap">
            {avatar ? <img className="review-card-avatar" src={avatar} alt={`аватар ${name}`} /> : <span className="review-card-avatar-plug">{getAbbreviation(name)}</span>}
            <div className="review-card-info">
              <h4 className="review-card-title">{name}</h4>
              <p className="review-card-date">{date}</p>
            </div>
          </div>

          <ReviewStars rating={rating} size="small" color="gray" />
        </div>

        <p className="review-card-text">{text}</p>
        {galleryImages && (
          <div className="review-card-gallery">
            <Swiper
              spaceBetween={10}
              threshold={2}
              slidesPerView={10}
              breakpoints={{
                375: {
                  width: 375,
                  slidesPerView: 4,
                },
                768: {
                  width: 768,
                  slidesPerView: 4,
                },
                1024: {
                  width: 1024,
                  slidesPerView: 10,
                },
                1140: {
                  width: 1140,
                  slidesPerView: 10,
                },
              }}
            >
              {galleryImages.map((image, i) => (
                <SwiperSlide key={image.id} onClick={() => zoomCard(i, galleryImages)}>
                  <img className="review-card-gallery-image" src={image.link} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </li>
    );
  });

  return (
    <>
      {renderReviews}
      {zoomImage && <ZoomModal index={galleryIndex} items={galleryImages} close={handleZoomModal} />}
      {showButton && <Button title="Показать ещё" buttonSize="large" buttonType="primary" icon={<RefreshIcon />} onClick={handlePageSize} />}
    </>
  );
}

export default ReviewCard;
