import * as React from "react";
import "./Input.scss";
import InputMask from "react-input-mask";

export default class Input extends React.Component {
  handleChange = (e) => {
    const { onChange, type } = this.props;

    if (type === "digits") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }

    onChange && onChange(e.target.value);
  };
  handleBlur = (e) => {
    const { onBlur } = this.props;
    onBlur && onBlur(e.target.value);
  };

  handleFocus = () => {
    const { onFocus } = this.props;

    onFocus && onFocus();
  };

  handleKeyDown = (e) => {
    const { onKeyDown } = this.props;

    onKeyDown && onKeyDown(e);
  };

  render() {
    const {
      max,
      customRef,
      leftImg,
      rightImg,
      placeholder,
      style,
      classNames,
      type,
      title,
      name,
      onKeyPress,
      hasError,
      value,
      mask,
      disabled,
      textarea,
      onClick,
      beforeMaskedValueChange,
      onFocus,
      autofocus,
      errorText,
    } = this.props;
    const disabledClass = disabled ? "disabled" : "";
    const customClasses = classNames ? classNames : "";
    const hasErrorClass = hasError ? "error" : "";
    const imgExistClass = !!leftImg && !!rightImg ? "all-img-exist" : !!leftImg ? "left-img-exist" : !!rightImg ? "right-img-exist" : "";
    const textAreaClass = textarea ? "textarea-input" : "";
    return (
      <div className={`Input ${disabledClass} ${customClasses} ${hasErrorClass} ${imgExistClass} ${textAreaClass}`} style={style}>
        {!!mask ? (
          <InputMask
            className={`input ${hasErrorClass}`}
            autoComplete={this.autoComplete}
            mask={mask}
            type={type ? type : "text"}
            autoFocus={autofocus}
            onChange={this.handleChange}
            onClick={onClick}
            value={value ? value : ""}
            inputRef={(el) => customRef(el)}
            onFocus={onFocus}
            required
            placeholder={""}
            beforeMaskedValueChange={beforeMaskedValueChange}
          />
        ) : textarea ? (
          <textarea
            name={name ? name : null}
            placeholder={placeholder}
            className={`input ${hasErrorClass}`}
            onChange={this.handleChange}
            onClick={onClick}
            onKeyPress={onKeyPress}
            value={value ? value : ""}
            disabled={disabled}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            required
            ref={customRef}
            maxLength={max}
          />
        ) : (
          <input
            name={name ? name : null}
            type={type ? type : "text"}
            placeholder={placeholder}
            className={`input ${hasErrorClass}`}
            onChange={this.handleChange}
            onClick={onClick}
            onBlur={this.handleBlur}
            onKeyPress={onKeyPress}
            value={value || value === 0 ? value : ""}
            disabled={disabled}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            required
            ref={customRef}
            maxLength={max}
          />
        )}
        {title && <div className="input-placeholder">{title}</div>}
        {leftImg && <div className="leftImg">{leftImg}</div>}
        {rightImg && <div className="rightImg">{rightImg}</div>}
        <div className="input-wrapper">{hasError && errorText && <span className="input-error">{errorText}</span>}</div>
      </div>
    );
  }
}
