import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import SliderButton from "../../commons/SliderButton/SliderButton";
import classNames from "classnames";
import "./ZoomModal.scss";

// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs } from "swiper/core";

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

function ZoomModal({ index, items, close }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [swiper, setSwiper] = React.useState(null);
  const isDesktop = useSelector((state) => state.api.isDesktop);
  const filteredItems = items.filter((item) => item);

  useEffect(() => {
    if (swiper) {
      const timer = setTimeout(() => {
        swiper.slideTo(index);
      }, 1);
      return () => clearTimeout(timer);
    }
  }, [swiper, index]);

  const sideSlides = filteredItems.map((item, i) => {
    return (
      <SwiperSlide
        className={classNames("zoom-modal__slider-side-slide ", {
          "zoom-modal__slide-active": filteredItems.length === 1,
        })}
        key={item.id || i}
      >
        <img className="zoom-modal__slider-side-image" src={item.link || item.url} alt="" />
      </SwiperSlide>
    );
  });
  const mainSlides = filteredItems.map((item, i) => {
    return (
      <SwiperSlide className="zoom-modal__slider-main-slide" key={item.id || i}>
        <img className="zoom-modal__slider-main-image" src={item.link || item.url} alt="" />
      </SwiperSlide>
    );
  });

  return (
    <div className="zoom-modal">
      <div className="zoom-modal__slider-side">
        {isDesktop && <span className="zoom-modal__slider-counter">{`Фото: ${filteredItems.length}`}</span>}
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesVisibility={true}
          watchSlidesProgress={true}
          threshold={2}
          direction={isDesktop ? "vertical" : "horizontal"}
          style={{ order: "2" }}
          breakpoints={{
            375: {
              width: 375,
              slidesPerView: 4,
            },

            1024: {
              width: 1024,
              slidesPerView: 10,
            },
          }}
        >
          {sideSlides}
        </Swiper>
      </div>
      {isDesktop && <SliderButton buttonType="prev" buttonColor="blue" />}

      <Swiper
        className="zoom-modal__slider-main"
        style={{ marginLeft: "0", marginRight: "0" }}
        slidesPerView={1}
        navigation={{
          prevEl: ".slider-btn-prev",
          nextEl: ".slider-btn-next",
        }}
        thumbs={{ swiper: thumbsSwiper }}
        onSwiper={(s) => setSwiper(s)}
      >
        {mainSlides}
      </Swiper>

      {isDesktop && <SliderButton buttonType="next" buttonColor="blue" />}

      <div className="zoom-modal__close" onClick={() => close()}>
        <CloseIcon />
      </div>
    </div>
  );
}

export default ZoomModal;
