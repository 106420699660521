import * as React from "react";

import classNames from "classnames";
import { ReactComponent as EllipseIcon } from "../../../../images/ellipse.svg";
import "./OrderStatus.scss";
import { findIndex, propEq } from "ramda";

const orderStatuses = [
  { code: "Processing", value: "В обработке" },
  { code: "AwaitingPayment", value: "Ожидает оплаты" },
  { code: "AwaitingShipment", value: "Ожидает отгрузки" },
  { code: "Delivery", value: "Доставка" },
  // { code: "SentForDelivery", value: "Передан в доставку" },
  { code: "Received", value: "Вручен" },
  {code: "PendingApproval", value: "Ожидает согласования"}
];

const OrderStatus = ({ status, small = false, type="order" }) => {
  const index = findIndex(propEq("code", status.code === "SentForDelivery" ? "Delivery" : status.code))(orderStatuses);

  const renderStatusItem = (itemStatus, itemIndex) => {
    return (
      <div
        className={classNames({
          "status-item": true,
          active: itemIndex <= index, //|| itemIndex < 1
        })}
        key={`status_item_${itemIndex}`}
      >
        {itemIndex > 0 && <div className="before-item"></div>}

        <div>
          <EllipseIcon /> {itemStatus.value}
        </div>

        {itemIndex < orderStatuses.length - 1 && <div className="after-item" />}
      </div>
    );
  };

  return (
    <div className="order-status-wrapper">
      <div
        className={classNames({
          "order-status": true,
          [status.code]: true,

        })}
      >
        <EllipseIcon />
        {(status.code === "SentForDelivery" ? "Доставка" : status.code === "Draft" ? "Ожидает оплаты" : status.value)}

      </div>
      {(!small && type === "order") && <div className="dropdown-content">{orderStatuses.map((item, index) => renderStatusItem(item, index))}</div>}
    </div>
  );
};

export default OrderStatus;
