import React, { useState, useEffect } from "react";

import "./PriceFilter.scss";
import "./slider.scss";

import Input from "../../../commons/RamInput/Input";
import { useSelector } from "react-redux";
import FilterBox from "../../../commons/FilterBox/FilterBox";
import Slider from "rc-slider";

const { Range } = Slider;

const PriceFilter = ({ search, filterMin, filterMax, mobileFilter }) => {
  const availableFilters = useSelector((state) => state.products.availableFilters);
  const minPrice = Math.round(availableFilters.minPrice);
  const maxPrice = Math.round(availableFilters.maxPrice);

  const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);

  useEffect(() => {
    setPriceRange([minPrice, maxPrice]);
  }, [filterMin, filterMax, minPrice, maxPrice]);

  if (!availableFilters) return null;

  const onSliderChange = (priceRange) => {
    setPriceRange(priceRange);
  };

  const onSliderAfterChange = () => {
    search({ minPrice: priceRange[0] === minPrice ? null : priceRange[0], maxPrice: priceRange[1] === maxPrice ? null : priceRange[1] });
  };

  const onMinInputChange = (v) => {
    let newValue = parseFloat(v);
    newValue = newValue >= priceRange[1] ? priceRange[1] : newValue < minPrice ? minPrice : newValue;
    setPriceRange([newValue, priceRange[1]]);
  };

  const onMaxInputChange = (v) => {
    let newValue = parseFloat(v);
    newValue = newValue <= priceRange[0] ? priceRange[0] : newValue > maxPrice ? maxPrice : newValue;
    setPriceRange([priceRange[0], newValue]);
  };

  const selectedText = priceRange[0] > minPrice || priceRange[1] < maxPrice ? `от ${priceRange[0]} до ${priceRange[1]}` : null;

  return (
    <FilterBox mobileFilter={mobileFilter} title={"Цена, ₽"} selectedText={selectedText}>
      <div className="price-filter">
        <div className="inputs">
          <Input title="От" type="number" value={priceRange[0]} onChange={(v) => onMinInputChange(v)} onBlur={onSliderAfterChange} />
          <Input title="До" type="number" value={priceRange[1]} onChange={(v) => onMaxInputChange(v)} onBlur={onSliderAfterChange} />
        </div>
        <div className="range">
          <Range value={priceRange} min={minPrice} max={maxPrice} allowCross={false} onChange={onSliderChange} onAfterChange={onSliderAfterChange} />
        </div>
      </div>
    </FilterBox>
  );
};

export default PriceFilter;
