import * as React from 'react';
import './CheckBox.scss';
import { ReactComponent as CheckIcon } from '../../../images/check.svg';

export default class CheckBox extends React.Component {
    state = {
        checked: this.props.defaultChecked || false
    };

    handleValueChange = (e) => {
        const { onChange } = this.props;

        const checked = !this.state.checked;
        this.setState({ checked });
        onChange && onChange(checked);
    };

    render() {
        const { label, children, checked, style } = this.props;
        const { checked: stateChecked } = this.state;
        const actualChecked = checked !== undefined ? checked : stateChecked;

        return (
            <div className="checkbox-wrapper" onClick={this.handleValueChange} style={style}>
                <div className={`checkbox-button ${!!actualChecked ? 'checked' : ''}`}>
                    {
                        !!actualChecked ? <CheckIcon /> : null
                    }
                </div>
                <div className="checkbox-title">
                    {
                        children ? children : label
                    }
                </div>
            </div>
        );
    }
}
