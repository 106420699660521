import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeFromCart, updateCart, fetchCartItems, setWaitForAccountAccomplishFlag, getCartItems, setActiveStep } from "../../actions/cart";
import { makeOrderFromCart, setOrderAddress, getOrderByCart, updateUserAddress } from "../../actions/order";
import { redirectToLogin, removeRedirectData, loadRedirectData, loadAccountType } from "../../helpers/cookies";
import "./CartPage.scss";
import EmptyCart from "./EmptyCart";
import { ReactComponent as DeleteIcon } from "../../images/delete.svg";
import Summary from "./Summary/Summary";
import Registration from "./Registration/Registration";
import classNames from "classnames";
import BackButton from "../commons/BackButton/BackButton";
import { withRouter } from "react-router";
import { emptyPromise } from "../../helpers/utils";
import CartNotification from "./CartNotification/CartNotification";
import { Loader } from "../commons/Loader/Loader";
import queryString from "query-string";
import PaymentDelayInfo from "../Profile/PaymentDelayInfo/PaymentDelayInfo";
import SupplierItems from "./SupplierItems/SupplierItems";
import AccountVerificationBox from "./AccountVerificationBox/AccountVerificationBox";
import { DELIVERY_TYPES, MAX_CART_ITEMS_WEIGHT } from "../../constants";
import ItemsUnavailableModal from "./ItemsUnavailableModal/ItemsUnavailableModal";
import { getMinimumOrderSum, getOrderItemsTotal, isEarlyOrder } from "../../helpers/orders";
import { setSystemErrorMessage } from "../../actions/api";

const CART_STEPS = [
  { name: "Корзина", buttonName: "Сделать заказ" },
  { name: "Оформление заказа", buttonName: "Оформить заказ" },
  { name: "Оплата", buttonName: "Оплатить заказ" },
];

class CartPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cartGroups: [],
      totalSum: 0,
      itemsCount: 0,
      totalVolume: 0,
      totalNetWeight: 0,
      isLoaded: false,
      showCartNotification: { price: false, overweight: false },
      cartIsUpdating: false,
      orderIsCreating: false,
      deliveryInterval: null, //used with puickupPoints
      validContacts: {
        recipientFullName: true,
        recipientPhone: true,
        recipientEmail: true,
      },
      showItemsUnavailableModal: false,
      unavailableItems: null,
      errorMessage: "Test message",
    };
  }

  componentDidMount() {
    this.init();
    const query = queryString.parse(this.props.location.search);
    query.finalizeOrder && this.props.setActiveStep(1);
  }

  componentWillUnmount() {
    this.props.setActiveStep(0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { cart, getOrderByCart, cartItems } = this.props;
    const { totalNetWeight, showCartNotification } = this.state;

    if (cart !== prevProps.cart) {
      getOrderByCart()
        .then(() => this.setState({ isLoaded: true }))
        .catch(() => this.setState({ isLoaded: true }));
    }
    if (cartItems !== prevProps.cartItems) {
      this.init();
    }
    if (totalNetWeight !== prevState.totalNetWeight) {
      totalNetWeight > MAX_CART_ITEMS_WEIGHT
        ? this.setState({ showCartNotification: { ...showCartNotification, overweight: true } })
        : this.setState({ showCartNotification: { ...showCartNotification, overweight: false } });
    }
  }

  init() {
    const { cartItems, user } = this.props;
    const accountTypeSame = loadRedirectData() === loadAccountType();
    this.checkPriceChange();
    this.checkForUnavailableItems();

    if (user && loadRedirectData() && loadAccountType() && accountTypeSame) {
      this.nextStep();
      removeRedirectData();
    } else if (user && loadRedirectData() && loadAccountType() && !accountTypeSame) {
      removeRedirectData();
    }

    if (!cartItems || !cartItems.items) return;

    const { itemsCount, totalSum, totalNetWeight, totalVolume } = cartItems;
    this.setState({
      isLoaded: true,
      itemsCount,
      totalSum,
      totalNetWeight,
      totalVolume,
    });
  }

  checkPriceChange = () => {
    const { cartItems } = this.props;
    const { showCartNotification } = this.state;
    const changedPrice = cartItems && cartItems.items?.find((item) => item.oldPrice && item.oldPrice !== 0 && item.oldPrice !== null && item.oldPrice !== item.price);
    this.setState({ showCartNotification: { ...showCartNotification, price: !!changedPrice } });
  };

  checkForUnavailableItems = () => {
    const { cartItems } = this.props;

    const unavailableItems =
      cartItems && cartItems.items?.filter((item) => item.productStatus && (item.productStatus.code === "OutOfStock" || item.productStatus.code !== "Active"));
    this.setState({ unavailableItems: unavailableItems });
  };

  handleSetDeliveryInterval = (interval) => [
    this.setState({ deliveryInterval: interval })
  ]

  handleShowItemsUnavailableModal = () => {
    const { showItemsUnavailableModal } = this.state;
    this.setState({ showItemsUnavailableModal: !showItemsUnavailableModal });
  };

  handleDeliveryAreaError = (hasAreaError) => {
    this.setState({ hasAreaError: hasAreaError });
  };

  handleShowCartNotification = (type) => {
    const { showCartNotification } = this.state;
    this.setState({ showCartNotification: { ...showCartNotification, [type]: !showCartNotification[type] } });
  };

  handleCartIsUpdating = (value) => {
    this.setState({ cartIsUpdating: value });
  };

  handleValidateContacts = (fieldName, value) => {
    const { validContacts } = this.state;
    if (fieldName === "recipientFullName") {
      // const reg = /^(([А-ЯЁ][а-яё]+([-][А-ЯЁ][а-яё]+)?)(\s(?!$))*){3}$/;
      // reg.test(value)
      //   ? this.setState({ validContacts: { ...validContacts, recipientFullName: true } })
      //   : this.setState({ validContacts: { ...validContacts, recipientFullName: false } });
      value.length > 0
        ? this.setState({ validContacts: { ...validContacts, recipientFullName: true } })
        : this.setState({ validContacts: { ...validContacts, recipientFullName: false } });
    } else if (fieldName === "recipientEmail") {
      value.includes("@")
        ? this.setState({ validContacts: { ...validContacts, recipientEmail: true } })
        : this.setState({ validContacts: { ...validContacts, recipientEmail: false } });
    } else if (fieldName === "recipientPhone") {
      const reg = /^\+7\s\(\d{3}\)\s\d{3}(-\d{2}){2}$/;
      reg.test(value)
        ? this.setState({ validContacts: { ...validContacts, recipientPhone: true } })
        : this.setState({ validContacts: { ...validContacts, recipientPhone: false } });
    }
  };

  resetValidation = () => {
    this.setState({ validContacts: { recipientFullName: true, recipientPhone: true, recipientEmail: true } });
  };

  handleSubmit = () => {
    const { registration } = this.props;

    this.handleValidateContacts("recipientFullName", registration.recipientFullName);
    setTimeout(() => {
      this.handleValidateContacts("recipientPhone", registration.recipientPhone);
    }, 100);
    setTimeout(() => {
      this.handleValidateContacts("recipientEmail", registration.recipientEmail);
    }, 200);

    setTimeout(() => {
      const { makeOrderFromCart, updateUserAddress, setOrderAddress, registration, fetchCartItems, history, addressId, getCartItems, cart, setSystemErrorMessage, deliveryType, pickupPoint } = this.props;
      const { detailsChanged, deliveryDate, recipientFullName, recipientEmail, paymentServiceId, recipientPhone, details, paymentMethod, useAutoApproval } = registration;
      const emailForReceipt = recipientEmail;
      const { validContacts, deliveryInterval } = this.state;
      const isValid = validContacts.recipientEmail && validContacts.recipientFullName && validContacts.recipientPhone;
      const isDeliveryToDoor = deliveryType === DELIVERY_TYPES[0];
      const newAddressId = isDeliveryToDoor ? addressId : pickupPoint?.id;
      isValid && this.setState({ orderIsCreating: true });
      isValid
        ? makeOrderFromCart(deliveryDate, recipientFullName, recipientEmail, paymentServiceId, emailForReceipt, recipientPhone, paymentMethod, useAutoApproval).then((o) => {
          if (o.code) {
            const errorCode = o.code;
            if (errorCode === "SomeCartItemWereChanged") {
              getCartItems(cart.id);
              this.handleShowItemsUnavailableModal();
            } else {
              setSystemErrorMessage({ message: o.message });
            }
            isValid && this.setState({ orderIsCreating: false });
            return;
          }

          setSystemErrorMessage(null);
          if (isDeliveryToDoor) {
            const addressPromise = detailsChanged ? updateUserAddress(newAddressId, { ...registration, details }) : emptyPromise();
            return addressPromise.then(() =>
              setOrderAddress(newAddressId).then(() => {
                updateUserAddress(newAddressId, { ...registration, details: null }); //remove details from address
                fetchCartItems(); //.then(() => );
                history.push(`/profile/orders?orderId=${o.id}`);
              })
            );
          } else {
            setOrderAddress(newAddressId, deliveryInterval?.start, deliveryInterval?.end).then(() => {
              fetchCartItems();
              history.push(`/profile/orders?orderId=${o.id}`);
            })
          }
        }).catch((errors) => setSystemErrorMessage(errors))
        : console.log("Введите корректные данные в поля ввода");
    }, 300);
  };

  nextStepClick = () => {
    const { loggedIn, setWaitForAccountAccomplishFlag, accountFilled, isCompany, activeStep } = this.props;
    const { unavailableItems } = this.state;

    if (!loggedIn) {
      redirectToLogin();

      return;
    }
    if (!this.state.isLoaded) {
      return null;
    }

    if (activeStep === 0) {
      if (!unavailableItems.length) {
        this.nextStep();
      } else {
        this.handleShowItemsUnavailableModal();
      }

      if (!accountFilled && isCompany) {
        setWaitForAccountAccomplishFlag(true);
        return;
      }
    } else if (activeStep === 1) {
      this.handleSubmit();
    }
  };

  removeFromCart = (item) => {
    const { removeFromCart } = this.props;
    return removeFromCart(item);
  };

  removeUnavailableItems = async () => {
    const { unavailableItems } = this.state;
    const { activeStep } = this.props;
    await unavailableItems.forEach((item) => this.removeFromCart(item));
    if (activeStep === 0) {
      this.nextStep();
    } else if (activeStep === 1) {
      this.handleSubmit();
    }

    setTimeout(() => {
      this.handleShowItemsUnavailableModal();
    }, 600);
  };

  clearCart = () => {
    const { updateCart } = this.props;
    updateCart([]).catch(() => { });
  };

  nextStep = () => {
    const { activeStep, setActiveStep, setSystemErrorMessage } = this.props;
    setSystemErrorMessage(null)
    if (activeStep < 2) {
      setActiveStep(activeStep + 1);
    }
  };

  prevStep = () => {
    const { activeStep, setActiveStep, setSystemErrorMessage } = this.props;
    setSystemErrorMessage(null)
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  render() {
    const {
      isMobile,
      isTablet,
      loggedIn,
      user,
      cartItems,
      accountFilled,
      accountNotVerified,
      waitForAccountAccomplishFlag,
      history,
      addressId,
      pickupPoint,
      isFirst100Order,
      registration,
      activeStep,
      deliveryType
    } = this.props;
    const { totalSum, showCartNotification, cartIsUpdating, showItemsUnavailableModal, deliveryInterval, orderIsCreating } = this.state;
    const { buttonName, name } = CART_STEPS[activeStep || 0];
    const shouldAccomplishOrder = !accountFilled && waitForAccountAccomplishFlag && activeStep === 1;
    const notVerifiedAccount = accountNotVerified && activeStep === 1;
    const showPaymentDelayInfo = registration.paymentMethod === "DeferredPayment";

    const minimunOrderSum = getMinimumOrderSum(user, isFirst100Order);
    const hasMinimumOrder = minimunOrderSum && totalSum >= minimunOrderSum;
    const isDeliveryToDoor = deliveryType === DELIVERY_TYPES[0];
    const noDeliveryAddress = loggedIn && isDeliveryToDoor && !addressId;
    const noPickupPointAddress = loggedIn && !isDeliveryToDoor && !pickupPoint;
    const requirementsFailed = noDeliveryAddress || noPickupPointAddress || !hasMinimumOrder;
    const submitDisabled = requirementsFailed || shouldAccomplishOrder || notVerifiedAccount;

    if (!cartItems || cartItems.itemsCount === 0) {
      return (
        <section className="container cart-page">
          <EmptyCart />
        </section>
      );
    }

    const renderStep1 = () => <SupplierItems handleCartIsUpdating={this.handleCartIsUpdating} cartIsUpdating={this.state.cartIsUpdating} />;

    const renderStep2 = () => {
      if (accountNotVerified) {
        return <AccountVerificationBox />;
      }

      return (
        <Registration
          nextStep={this.nextStepClick}
          isMobile={isMobile}
          isTablet={isTablet}
          shouldAccomplishOrder={shouldAccomplishOrder}
          history={history}
          submitDisabled={submitDisabled}
          validContacts={this.state.validContacts}
          resetValidation={this.resetValidation}
          handleValidateContacts={this.handleValidateContacts}
          deliveryInterval={deliveryInterval}
          setDeliveryInterval={this.handleSetDeliveryInterval}
          buttonLoading={orderIsCreating}
        />
      );
    };

    const backStepClick = () => {
      if (activeStep > 0) {
        this.prevStep();
      }
    };

    return (
      <section className="container cart-page">
        <div
          className={classNames({
            "main header-bar": true,
            horizontal: activeStep > 0,
          })}
        >
          {activeStep > 0 && (
            <div>
              <BackButton label="Вернуться в корзину" onClick={() => backStepClick()} />
            </div>
          )}

          <h2>{name}</h2>
          {activeStep === 0 && (
            <div className="delete-btn" onClick={() => this.clearCart()}>
              <DeleteIcon />
              <span>Очистить корзину</span>
            </div>
          )}
        </div>
        <div className="content">
          <div className="main">
            {/* {renderStep2()} */}
            {!!showCartNotification.price && <CartNotification onClose={this.handleShowCartNotification} type="price" />}
            {!!showCartNotification.overweight && <CartNotification onClose={this.handleShowCartNotification} type="overweight" />}

            {activeStep === 0 && renderStep1()}
            {(shouldAccomplishOrder || activeStep === 1) && renderStep2()}
          </div>

          <div className="content-side">
            {showPaymentDelayInfo && loggedIn && activeStep !== 0 && (
              <div className="payment-delay-wrap">
                <PaymentDelayInfo />
              </div>
            )}

            <div className="summary-wrap">
              {cartIsUpdating && (
                <div className="summary-wrap-overlay">
                  <Loader />
                </div>
              )}

              <Summary nextStep={this.nextStepClick} step={activeStep} buttonName={buttonName} buttonLoading={orderIsCreating} submitDisabled={submitDisabled} handleCartIsUpdating={this.handleCartIsUpdating} />
            </div>
          </div>
        </div>
        <ItemsUnavailableModal
          onClose={this.handleShowItemsUnavailableModal}
          visible={showItemsUnavailableModal}
          onSubmit={this.removeUnavailableItems}
          cartRedirect={backStepClick}
          activeStep={activeStep}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products.products,
  isProductListFetching: state.products.isProductListFetching,
  currentProduct: state.products.currentProduct,
  templateUrl: state.priceList.templateUrl,
  showSuccessMessage: state.priceList.showSuccessMessage,
  showFailMessage: state.priceList.showFailMessage,
  cartItems: state.cart.cartItems,
  addressId: state.cart.registration.addressId,
  pickupPoint: state.cart.pickupPoint,
  deliveryType: state.cart.deliveryType,
  loggedIn: state.profile.loggedIn,
  registration: state.cart.registration,
  isLoading: state.cart.isLoading,
  cart: state.cart.cart,
  isMobile: state.api.isMobile,
  isTablet: state.api.isTablet,
  waitForAccountAccomplishFlag: state.cart.waitForAccountAccomplishFlag,
  user: state.profile.user,
  accountFilled: state.profile.accountFilled,
  accountNotVerified: state.profile.accountNotVerified,
  isCompany: state.profile.isCompany,
  accountType: state.profile.accountType,
  isFirst100Order: isEarlyOrder(state.order.orders.totalCount),
  activeStep: state.cart.activeStep,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeFromCart: removeFromCart,
      updateCart: updateCart,
      makeOrderFromCart: makeOrderFromCart,
      setOrderAddress: setOrderAddress,
      getOrderByCart: getOrderByCart,
      fetchCartItems: fetchCartItems,
      getCartItems: getCartItems,
      setWaitForAccountAccomplishFlag: setWaitForAccountAccomplishFlag,
      updateUserAddress: updateUserAddress,
      setSystemErrorMessage: setSystemErrorMessage,
      setActiveStep: setActiveStep,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CartPage));
