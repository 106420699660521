const ADDRESS_STORAGE_NAME = "virtual_address";

export const loadVirtualDeliveryAddress = () => {
  const data = localStorage.getItem(ADDRESS_STORAGE_NAME);
  const registration = data ? JSON.parse(data) : null;

  return registration;
};

export const removeVirtualDeliveryAddress = () => {
  localStorage.removeItem(ADDRESS_STORAGE_NAME);
};

export const addVirtualDeliveryAddress = (registration) => {
  localStorage.setItem(ADDRESS_STORAGE_NAME, JSON.stringify(registration));

  return registration;
};
