import * as React from "react";
import "./Tabs.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as FileIcon } from "../../../../images/file-icon.svg";
import { ReactComponent as ReviewIcon } from "../../../../images/review-icon.svg";
import { ReactComponent as ReviewIconBlue } from "../../../../images/review-icon-blue.svg";
import { declOfNum } from "../../../../helpers/stringOperations";
import Button from "../../../commons/Button/Button";
import ReviewRating from "../../../commons/Reviews/ReviewRating/ReviewRating";
import moment from "moment";

//Временные данные
import { mockData } from "../../../../mockSupplierCardData";
import ReviewCard from "../../../commons/Reviews/ReviewCard/ReviewCard";
import ReviewsModal from "../../../commons/Reviews/ReviewsModal/ReviewsModal";

const { env } = global.config;

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "description",
      categoriesByLevel: null,
      showReviewsModal: false,
    };
  }
  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) { }

  changeTab = (currentTab) => {
    //TODO: uncomment to enable
    this.setState({ currentTab });
  };
  render() {
    const { currentTab, showReviewsModal } = this.state;
    const { currentSupplierInfo, loggedIn } = this.props;
    const {
      subtitle,
      attributes,
      periodType,
      categories,
      countryOfOrigin,
      transportPacking,
      maxTemperature,
      minTemperature,
      holdingPeriod,
      height,
      width,
      length,
      deliveryIncrement,
      grossWeight,
      saleUnit,
      manufacturer,
      unitWeight,
      packFactor,
      consumerPack,
      consumerPackUnit,
    } = this.props.product;
    const isDachnik = env === "dachnik";
    const category = categories[0];
    const { categoryId1, categoryId2, categoryId3 } = category;
    const categoryURI = `/catalog/customerProducts/${categoryId1}/${categoryId2}/${categoryId3}`;
    const periodTypesNames = (period) => {
      if (period === "Months") {
        return ["месяц", "месяца", "месяцев"];
      } else if (period === "Years") {
        return ["год", "года", "лет"];
      } else if (period === "Days") {
        return ["сутки", "суток", "суток"];
      } else if (period === "Hours") {
        return ["час", "часа", "часов"];
      }
    };
    const tabNames = [
      { name: "description", title: "О товаре", mobileTitle: "О товаре" },
      {
        name: "certificates",
        title: currentSupplierInfo && currentSupplierInfo.certificates.length ? `Сертификаты продавца (${currentSupplierInfo && currentSupplierInfo.certificates.length})` : "",
        mobileTitle: currentSupplierInfo && currentSupplierInfo.certificates.length ? "Сертификаты" : "",
      },
    ];

    const handleShowReviewsModal = () => {
      this.setState({ showReviewsModal: !this.state.showReviewsModal });
    };

    const renderTabtitle = (tab) => {
      return (
        <div
          key={`tab_${tab.name}`}
          className={classNames({
            title: true,
            active: currentTab === tab.name,
          })}
          onClick={() => this.changeTab(tab.name)}
        >
          <div className="name">{!this.props.isMobile ? tab.title : tab.mobileTitle}</div>
          <div className="line"></div>
        </div>
      );
    };

    const renderProducts = () => (
      <div className="description">
        <div className="subtitle">{subtitle}</div>
        <div className="info">
          <div className="left">
            <div className="attrs">
              <h2>Параметры товара</h2>
              <div className="attr">
                <span className="label small">Категория</span>
                <span></span>
                <span className="label text">
                  <Link to={categoryURI}>{category.title3}</Link>
                </span>
              </div>
              <div className="attr">
                <span className="label small">Единица измерения</span>
                <span></span>
                <span className="label text">{saleUnit.shortTitle}</span>
              </div>
              {unitWeight && (
                <div className="attr">
                  <span className="label small">Вес товара</span>
                  <span></span>
                  <span className="label text">
                    {unitWeight} {consumerPackUnit?.shortTitle}
                  </span>
                </div>
              )}
              {packFactor && (
                <div className="attr">
                  <span className="label small">Количество в упаковке</span>
                  <span></span>
                  <span className="label text">{packFactor} шт</span>
                </div>
              )}
              <div className="attr">
                <span className="label small">Страна происхождения</span>
                <span></span>
                <span className="label text">{countryOfOrigin.value}</span>
              </div>
              {!isDachnik && <div className="attr">
                <span className="label small">Производитель</span>
                <span></span>
                <span className="label text">{manufacturer}</span>
              </div>}
              {consumerPack && !!consumerPack.code && (
                <div className="attr">
                  <span className="label small">Тип упаковки</span>
                  <span></span>
                  <span className="label text">{consumerPack.value}</span>
                </div>
              )}
             
              {Object.entries(attributes).map((p, i) => (
                <div className="attr" key={`attr_${i}`}>
                  <span className="label small">{p[0]}</span>
                  <span></span>
                  <span className="label text">{p[1].includes('["') ? JSON.parse(p[1]).join(", ") : p[1]}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="right">
            <div className="attrs">
              <h2>Срок годности и температура хранения </h2>
              <div className="attr">
                <span className="label small">Температура хранения мин</span>
                <span></span>
                <span className="label text">{minTemperature} °C </span>
              </div>
              <div className="attr">
                <span className="label small">Температура хранения макс</span>
                <span></span>
                <span className="label text">{maxTemperature} °C </span>
              </div>
              {periodType && holdingPeriod && (
                <div className="attr">
                  <span className="label small">Срок хранения </span>
                  <span></span>
                  <span className="label text">
                    {holdingPeriod} {declOfNum(holdingPeriod, periodTypesNames(periodType.code))}
                  </span>
                </div>
              )}
            </div>
            {!isDachnik && <div className="attrs">
              <h2 className="attrs-title">Параметры поставки</h2>
              <div className="attr">
                <span className="label small">Транспортная упаковка</span>
                <span></span>
                <span className="label text">{transportPacking.value}</span>
              </div>
              <div className="attr">
                <span className="label small">Квант поставки </span>
                <span></span>
                <span className="label text">
                  {deliveryIncrement} {saleUnit?.shortTitle}
                </span>
              </div>
              <div className="attr">
                <span className="label small">Вес упаковки брутто</span>
                <span></span>
                <span className="label text">{grossWeight} кг</span>
              </div>
            </div>}
          </div>
        </div>
      </div>
    );

    //Массив сертификатов картинками
    const certImages =
      currentSupplierInfo &&
      currentSupplierInfo.certificates &&
      currentSupplierInfo.certificates.filter(
        (cert) =>
          cert.url.split(".").pop() === "jpg" || cert.fileName.split(".").pop() === "jpeg" || cert.fileName.split(".").pop() === "png" || cert.fileName.split(".").pop() === "svg"
      );
    const getCertIndex = (fileName) => {
      return certImages.findIndex((cert) => cert.fileName === fileName);
    };

    //Рендер сертификатов
    const renderCertificates =
      currentSupplierInfo &&
      currentSupplierInfo.certificates &&
      currentSupplierInfo.certificates.map((cert) => {
        const { title, createdDate, fileName, url } = cert;
        const fileType = fileName.split(".").pop();
        const fileImage = fileType === "jpg" || fileType === "jpeg" || fileType === "png" || fileType === "svg";
        const date = createdDate ? moment(createdDate).format("lll").replace(/г.,/, "в") : "-";

        const zoomPdf = (url) => {
          window.open(url);
          return false;
        };

        return (
          <li className="product-certificate" key={fileName} onClick={() => (!fileImage ? zoomPdf(url) : this.props.zoomCard(getCertIndex(fileName), certImages))}>
            {fileImage ? (
              <img className="product-certificate-image" src={url} alt={title} />
            ) : (
              <div className="product-certificate-icon-wrapper">
                <FileIcon />
              </div>
            )}
            <div className="product-certificate-info">
              <h4 className="product-certificate-title">{title}</h4>
              <p className="product-certificate-date">{date}</p>
            </div>
            <span className="product-certificate-label">{fileType}</span>
          </li>
        );
      });

    return (
      <div className="tabs">
        <div className="titles">{tabNames.map((t) => t && t.title && renderTabtitle(t))}</div>

        <div className="tab-body">
          {currentTab === "description" && renderProducts()}
          {currentTab === "reviews" && (
            <div className="product-reviews">
              <header className="product-reviews-header">
                <ReviewRating rating={2.5} reviewsCount={mockData.reviews.length} size="large" />
                {loggedIn && <Button title="Написать отзыв" icon={<ReviewIcon />} buttonSize="medium" buttonType="primary" width="168px" onClick={handleShowReviewsModal} />}
              </header>
              {mockData.reviews.length ? (
                <ul className="product-reviews-list">
                  <ReviewCard reviews={mockData.reviews} />
                </ul>
              ) : (
                <div className="product-reviews-empty">
                  <div className="product-reviews-empty-icon">
                    <ReviewIconBlue />
                  </div>

                  <p className="product-reviews-text">Отзывов ещё нет — ваш может стать первым</p>
                </div>
              )}

              <ReviewsModal onClose={handleShowReviewsModal} visible={showReviewsModal} />
            </div>
          )}
          {currentTab === "certificates" && <ul className="product-certificates">{renderCertificates}</ul>}
        </div>
      </div>
    );
  }
}

export default Tabs;
