import { productsSortItems } from "../constants";
import { merge } from "ramda";

const DEFAULT_AVAILABLE_FILTERS = {
  attributes: [],
};
const initialState = {
  products: { results: [] },
  favoriteProductIds: [],
  favoriteProducts: [],
  favoriteSupplierIds: [],
  favoriteSuppliers: [],
  pageOffset: 0,
  isProductListFetching: false,
  currentProduct: null,
  currentSupplier: null,
  currentSupplierInfo: null,
  searchFilter: null,
  searchResult: {},
  countriesAvailible: [],
  filterCategoryIds: [],
  filterPriceRange: null,
  sortingName: Object.keys(productsSortItems)[0],
  viewType: localStorage.getItem("viewType") || "rows",
  extraItemsCount: 0,
  availableFilters: DEFAULT_AVAILABLE_FILTERS,
  isLoading: false,
  searchParams: {},
  reservedUserId: ""
};

export default function products(state = initialState, action) {
  switch (action.type) {
    case "PRODUCT_LIST_FETCHING":
      return {
        ...state,
        isProductListFetching: true,
      };

    case "RECEIVE_PRODUCTS":
      return {
        ...state,
        products: action.products,
        isProductListFetching: false,
      };

    case "SELECT_PRODUCT":
      return {
        ...state,
        currentProduct: action.product,
      };
    case "SELECT_SUPPLIER":
      return {
        ...state,
        currentSupplier: action.supplier,
      };
    case "GET_SUPPLIER_INFO":
      return {
        ...state,
        currentSupplierInfo: action.supplierInfo,
      };

    case "DELETE_PRODUCT_SUCCESS":
      const newProductList = state.products.filter((product) => product.id !== action.productId);
      return {
        ...state,
        products: newProductList,
      };
    case "CLEAR_PRODUCT_DATA":
      return {
        ...state,
        currentProduct: null,
        currentSupplier: null,
        availableFilters: DEFAULT_AVAILABLE_FILTERS,
      };
    case "CLEAR_SEARCH_FILTER":
      return {
        ...state,
        searchParams: {},
        //filterCategoryIds: []
      };

    case "CLEAR_FILTER_CATEGORY":
      return {
        ...state,
        searchParams: {},
        filterCategoryIds: [],
      };
    case "SET_SEARCH_FILTER":
      return {
        ...state,
        searchFilter: action.filter,
      };

    case "SET_SORTING":
      return {
        ...state,
        sortingName: action.sortingName,
      };

    case "SET_SEARCH_PARAMS":
      return {
        ...state,
        searchParams: merge(state.searchParams, action.searchParams),
      };
    // case 'SET_FILTER_COUNTRIES':
    //   return {
    //     ...state,
    //     filterCountries: action.filterCountries,
    //   };
    case "SET_FILTER_CATEGORIES":
      return {
        ...state,
        filterCategoryIds: action.categoryIds,
      };

    case "SET_PAGE_OFFSET":
      return {
        ...state,
        pageOffset: action.pageOffset || 0,
      };
    case "SET_VIEW_TYPE":
      localStorage.setItem("viewType", action.viewType);

      return {
        ...state,
        viewType: action.viewType,
      };
    case "SET_PAGE_EXTRA_ITEMS_COUNT":
      return {
        ...state,
        extraItemsCount: action.itemsCount,
      };

    case "RECEIVE_AVAILIBLE_FILTERS":
      return {
        ...state,
        availableFilters: action.availableFilters,
      };

    case "LOADING_PRODUCTS":
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case "RECEIVE_FAVORITE_PRODUCTS":
      return {
        ...state,
        favoriteProductIds: action.products.map((p) => p.id),
        favoriteProducts: action.products,
      };
    case "UPDATE_FAVORITE_PRODUCTS":
      const newFavoriteProductsIds = action.add ? [...state.favoriteProductIds, action.id] : state.favoriteProductIds.filter((p) => p.id !== action.id);
      return {
        ...state,
        favoriteProductIds: newFavoriteProductsIds,
      };
    case "CLEAR_FAVORITE_PRODUCTS":
      return {
        ...state,
        favoriteProductIds: [],
        favoriteProducts: [],
      };
    case "RECEIVE_FAVORITE_SUPPLIERS":
      return {
        ...state,
        favoriteSupplierIds: action.suppliers.map((p) => p.id),
        favoriteSuppliers: action.suppliers,
      };
    case "UPDATE_FAVORITE_SUPPLIERS":
      const newFavoriteSuppliersIds = action.add ? [...state.favoriteSupplierIds, action.supplier] : state.favoriteSupplierIds.filter((p) => p.id !== action.id);
      return {
        ...state,
        favoriteSupplierIds: newFavoriteSuppliersIds.map((p) => p.id),
      };

    case "CLEAR_FAVORITE_SUPPLIERS":
      return {
        ...state,
        favoriteSupplierIds: [],
        favoriteSuppliers: [],
      };
    case "RESERVE_USER_ID":
      return {
        ...state,
        reservedUserId: action.reservedUserId,
      };
    default:
      return state;
  }
}
