import React, { Component } from "react";
import "./CatalogCategories.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { productsCountAsString } from "../../../helpers/stringOperations";
import { fetchCategoryTree } from "../../../actions/category";

const catalogCategories = {
  Бакалея: "bread",
  "Замороженные продукты": "frozen",
  "Овощи, фрукты, ягоды": "veget",
  "Мясная продукция": "meat",
  "Молочная продукция, яйцо": "dairy",
  "Рыба и морепродукты": "fish",
  Фрукты: "fruits",
  Зелень: "greens",
  Овощи: "veget",
  Салаты: "salads",
  Ягоды: "berries",
  Грибы: "mushrooms",
  ["Орехи, сухофрукты"]: "nuts",
  Птица: "poultry",
  Мясо: "meat"
};

const CATEGORY_TITLES = ["Фрукты", "Зелень", "Овощи", "Салаты", "Ягоды", "Грибы", "Орехи, сухофрукты", "Птица", "Мясо"];
const CATEGORY_TITLES_DACHNIK = ["Фрукты", "Зелень", "Овощи", "Салаты", "Ягоды"];
const { env } = global.config;

class CatalogCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesDachnik: [],

    };

  }

  renderSkeleton = (item) => {
    return (
      <div className="catalog-item-skeleton" key={item}>

        <div className="catalog-item-skeleton-caption" />
        <div className="catalog-item-skeleton-text" />

      </div>
    )
  }

  componentDidMount() {
    const isDacnhik = env === "dachnik";
    isDacnhik && fetchCategoryTree("2a4ac7ab-2e04-4bb2-85b0-4de7e93ed6ea").then((res) => {
      this.setState({ categoriesDachnik: res.filter((category) => category.level === 2) })
    })
  }


  render() {
    const { categories } = this.props;
    const { categoriesDachnik } = this.state;
    const isDacnhik = env === "dachnik";


    const titlesArray = isDacnhik ? CATEGORY_TITLES_DACHNIK : CATEGORY_TITLES.slice(0, 6);
    const categoriesArray = isDacnhik ? categoriesDachnik : categories;
    const catalogLink = isDacnhik ? `/catalog/customerProducts/2a4ac7ab-2e04-4bb2-85b0-4de7e93ed6ea/` : `/catalog/customerProducts/`;
    const showSkeleton = isDacnhik && !categoriesDachnik.length || !isDacnhik && !categories.length;

    const renderCatalogProduct = (caption, id, productsCount) => {
      return <a key={`category_${id}`} className={`catalog-item ${catalogCategories[caption]}`} href={`${catalogLink}${id}`}>
        <div className="catalog-item-info">
          <h3 className="catalog-item-caption">{caption}</h3>
          <span className="catalog-item-counter">{`${productsCount} ${productsCountAsString(productsCount)}`}</span>
        </div>
      </a>
    };

    return (
      <section className="catalog-categories">
        <h2 className="catalog-categories-title">Каталог продуктов</h2>
        <div className="catalog">
          {showSkeleton && titlesArray.map((item) => this.renderSkeleton(item))}
          {categories && categoriesArray.filter((item) => item.productsCount).map((category) => renderCatalogProduct(category.title, category.id, category.productsCount))}

        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogCategories);
