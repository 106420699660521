import * as React from "react";
import { ReactComponent as Arrow } from "../../../images/right-arrow.svg";
import { ReactComponent as EllipseIcon } from "../../../images/ellipse.svg";

import classNames from "classnames";
import "./FilterBox.scss";

class FilterBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: true,
    };
  }

  toggleOpen = () => {
    const { isOpened } = this.state;
    this.setState({ isOpened: !isOpened });
  };

  render() {
    const { title, selectedText, mobileFilter, children } = this.props;
    const { isOpened } = this.state;
    return (
      <div className={classNames("filter-box", { opened: isOpened })}>
        {!mobileFilter && (
          <div className={"title"} onClick={this.toggleOpen}>
            <div className="filter-box-header">
              <span>{title}</span>
              {!!selectedText && <EllipseIcon />}
            </div>
            <Arrow />
          </div>
        )}
        {!mobileFilter && <div className="selected-text">{selectedText}</div>}
        <div className={"content-wrapper"}>{children}</div>
      </div>
    );
  }
}
export default FilterBox;
