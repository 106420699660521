import * as React from "react";
import "./MiniCart.scss";
import { useLocation, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "../../commons/Button/Button";
import { separateThousands } from "../../../helpers/stringOperations";
import ScrollBar from "../../commons/ScrollBar/ScrollBar";
import classNames from "classnames";
import SupplierItems from "../SupplierItems/SupplierItems";
import { groupItemsBySupplier } from "../../../helpers/orders";

const CART_ITEM_HEIGHT = 213;

const MiniCart = ({ visible, onPointer, history }) => {
  const location = useLocation();
  const cartItems = useSelector((state) => state.cart.cartItems);

  if (location.pathname === "/cart" || !cartItems || cartItems.itemsCount === 0) return null;

  const redirectToCart = () => {
    history.push("/cart");
  };

  const { totalSum } = groupItemsBySupplier(cartItems.items);

  const scrollBarHeight = (cartItems.itemsCount > 2 ? 2 : cartItems.itemsCount) * CART_ITEM_HEIGHT;
  return (
    <div className={classNames("mini-cart", { visible })} onPointerEnter={() => onPointer(true)} onPointerLeave={() => onPointer(false)}>
      <div className="cart-items">
        <ScrollBar style={{ height: scrollBarHeight }}>
          <SupplierItems mini={true} />
        </ScrollBar>
      </div>
      <div className="bottom-panel">
        <div className="mini-cart-summary">
          <span>Итого</span>
          <span>{separateThousands(totalSum.toFixed(2))} ₽</span>
        </div>
        <Button title="В корзину" buttonSize="large" buttonType="subtle" width="100%" onClick={() => redirectToCart()} />
      </div>
    </div>
  );
};

export default withRouter(MiniCart);
