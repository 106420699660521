import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./SideBar.scss";
import { setSearchFilterAndSearch, setActiveProductList, clearSearchFilter } from "../../../actions/product";
import Filter from "../../Filter/Filter";
import { values } from "ramda";
import { clearSelectedCategories } from "../../../actions/category";

import Button from "../../commons/Button/Button";
import CategoriesList from "./CategoriesList/CategoriesList";
import SupplierFilter from "./SupplierFilter/SupplierFilter";
import PriceFilter from "./PriceFilter/PriceFilter";
import PropertyFilter from "./PropertyFilter/PropertyFilter";
import { productFilters } from "../../../constants";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      filterMin: "",
      filterMax: "",
      filterText: "",
    };
  }

  componentDidUpdate(prevProps) {
    const { availableFilters } = this.props;
    if (availableFilters.minPrice !== prevProps.availableFilters.minPrice || availableFilters.maxPrice !== prevProps.availableFilters.maxPrice) {
      this.setState({ filterMin: availableFilters.minPrice, filterMax: availableFilters.maxPrice, filterText: "" });
    }
  }

  setNewProductList = (productsToShow) => {
    this.setState({ productsToShow });
  };

  extendSearchData = (searchData) => {
    const { searchQuery } = this.props;
    if (searchQuery) {
      searchData.title = searchQuery;
    }
    return searchData;
  };

  clearFilters() {
    const { clearSearchFilter, availableFilters, searchParams } = this.props;

    clearSearchFilter();
    this.makeSearch({ title: "", minPrice: availableFilters.minPrice, maxPrice: availableFilters.maxPrice });
    this.setState({ filterMin: searchParams.minPrice, filterMax: searchParams.maxPrice, filterText: "" });
  }

  filterByAttribute = (title, value) => {
    const { searchParams } = this.props;
    const searchData = { attributes: searchParams.attributes || {} };
    searchData.attributes[title] = value;

    this.makeSearch(searchData);
  };

  setSearchFilter = (text) => {
    this.setState({ filterText: text });
  };

  makeSearch(searchData) {
    const { setSearchFilterAndSearch } = this.props;
    setSearchFilterAndSearch(this.extendSearchData(searchData));
  }

  render() {
    const { categoriesByLevel, categoriesToShow, attributes, searchParams, availableFilters } = this.props;
    const { filterMin, filterMax, filterText } = this.state;

    const levelCount = values(categoriesByLevel).length;
    const hasLevel3 = levelCount > 2;
    const searchAttributes = searchParams.attributes || {};
    return (
      <div className="sidebar">
        {!!categoriesByLevel && <CategoriesList categoriesByLevel={categoriesByLevel} categoriesToShow={categoriesToShow} />}
        <div className="clear-btn">
          <Button title="Очистить фильтры" buttonSize="medium" buttonType="secondary" width="100%" onClick={() => this.clearFilters()} />
        </div>
        {!!availableFilters.minPrice && <PriceFilter search={(data) => this.makeSearch(data)} filterMin={filterMin} filterMax={filterMax} />}
        <SupplierFilter search={(data) => this.makeSearch(data)} setSearchFilter={this.setSearchFilter} filterText={filterText} />
        {productFilters.map((f, index) => (
          <PropertyFilter key={`property_fliter_${index}`} title={f.title} filterKey={f.name} search={(data) => this.makeSearch(data)} />
        ))}
        {!!attributes &&
          hasLevel3 &&
          attributes.map((attribute, index) => (
            <Filter
              key={`filter_${index}`}
              title={attribute.title}
              setActiveProductList={this.filterByAttribute}
              filter={attribute.params}
              selected={searchAttributes[attribute.title] || []}
              filterKey={attribute.title}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterCountriesOfOrigin: state.products.filterCountriesOfOrigin,
    searchParams: state.products.searchParams,

    currentProduct: state.products.currentProduct,
    categories: state.categories.categories,
    categoriesByLevel: state.categories.categoriesByLevel,
    categoryTree: state.categories.categoryTree,
    categoriesToShow: state.categories.categoriesToShow,
    productsToShow: state.products.productsToShow,
    attributes: state.products.availableFilters.attributes,
    availableFilters: state.products.availableFilters,
    isProductListFetching: state.products.isProductListFetching,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSelectedCategories: clearSelectedCategories,
      setActiveProductList: setActiveProductList,
      clearSearchFilter: clearSearchFilter,
      setSearchFilterAndSearch: setSearchFilterAndSearch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
