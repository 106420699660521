import * as React from "react";
import "./EditOrder.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../../images/arrow-back.svg";
import OrderStatus from "../OrderStatus/OrderStatus";
import moment from "moment";
import { ReactComponent as PayIcon } from "../../../../images/pay2.svg";
import { ReactComponent as ReturnIcon } from "../../../../images/return-circle.svg";
import { ReactComponent as EditIcon } from "../../../../images/pencil.svg";
import { separateThousands, addressToString } from "../../../../helpers/stringOperations";
import Button from "../../../commons/Button/Button";
import { groupItemsBySupplier } from "../../../../helpers/orders";
import { fetchCartItems, addItemsFromOrder } from "../../../../actions/cart";
import { cancelCurrentOrder, updateContactInfo, addPaymentCurrentOrder, downloadInvoice, changeOrderStatus } from "../../../../actions/order";
import CancelModal from "./modals/CancelModal";
import ChangeRecipientModal from "./modals/ChangeRecipientModal";
import SupplierItem from "../../../Cart/SupplierItems/SupplierItem";
import analytics from "../../../../helpers/analytics";
import OrderDocsDownload from "../OrderDocsDownload/OrderDocsDownload";
import { Loader } from "../../../commons/Loader/Loader";
import EditOrderStatusInfo from "./EditOrderStatusInfo/EditOrderStatusInfo";
import { DELIVERY_TYPES, MAX_CART_ITEMS_WEIGHT } from "../../../../constants";

class EditOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartGroups: [],
      cancelModalVisible: false,
      changeRecipientModalVisible: false,
      paymentModalVisible: false,
      countdownTime: ` 0 мин 0 сек`,
      showPaymentDelayInfo: false,
      orderStatusChanging: false,
      showAllDocuments: false,
      addingItemsToCart: false,
    };
  }

  init() {
    const { order } = this.props;
    const { items, hasDeferredPayment } = order;

    const { cartGroups } = groupItemsBySupplier(items);
    this.setState({ cartGroups });
    hasDeferredPayment ? this.setState({ showPaymentDelayInfo: true }) : this.setState({ showPaymentDelayInfo: false });
  }

  componentDidMount() {
    const { buyerStatus } = this.props.order;
    this.init();
    (buyerStatus.code === "AwaitingPayment" || buyerStatus.code === "PendingApproval") && this.initCountdown();
  }

  componentDidUpdate(prevProps, prevState) {
    const { order } = this.props;
    const { countdownTime } = this.state;
    const { buyerStatus, paymentServiceId, hasDeferredPayment } = order;
    document.title = `Заказ № ${parseInt(order.number, 10)} – rusagro.market`;

    if (order !== prevProps.order) {
      this.init();
    }

    if (countdownTime !== prevState.countdownTime) {
      if (paymentServiceId === "Qiwi") {
        (countdownTime.includes("-") || buyerStatus.code === "Cancelled") && this.setState({ countdownTime: ` 0 мин 0 сек` });
      }

      if (paymentServiceId === "Tochka" && !hasDeferredPayment) {
        buyerStatus.code === "Cancelled" && this.setState({ countdownTime: ` 0 часов 0 мин` });
      }

      if (paymentServiceId === "Tochka" && hasDeferredPayment) {
        (countdownTime.includes("-") || buyerStatus.code === "Cancelled") && this.setState({ countdownTime: ` 0 мин 0 сек` });
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
    this.setState = (state, callback) => {
      return;
    };
  }

  renderProducts() {
    const { cartGroups } = this.state;
    const { isMobile } = this.props;

    return (
      <div className="supplier-items">
        {cartGroups.map((groupItem, index) => (
          <SupplierItem key={`cart_group_${index}`} groupItem={groupItem} removeFromCart={null} isMobile={isMobile} readOnly={true} />
        ))}
      </div>
    );
  }

  initCountdown = () => {
    const { order } = this.props;
    const { changedDate, paymentServiceId, approveBefore, hasDeferredPayment } = order;

    if (paymentServiceId === "Qiwi") {
      setInterval(() => {
        let diff = moment(changedDate).add(40, "minutes").utc().unix() - moment().utc().unix();
        const duration = moment.duration(diff, "seconds");
        const countdownTime = ` ${duration.minutes()} мин ${duration.seconds()} сек`;
        this.setState({ countdownTime });
      }, 1000);
    }

    if (paymentServiceId === "Tochka" && hasDeferredPayment) {
      setInterval(() => {
        let diff = moment(approveBefore).utc().unix() - moment().utc().unix();
        const duration = moment.duration(diff, "seconds");
        const countdownTime = ` ${duration.minutes()} мин ${duration.seconds()} сек`;
        this.setState({ countdownTime });
      }, 1000);
    }

    if (paymentServiceId === "Tochka" && !hasDeferredPayment) {
      const getDifference = (date) => {
        if (date.hours() > 10 && date.hours() < 17) {
          date.add(24, "hours");
          if (date.utc().unix() - moment().utc().unix() < 0) {
            return date.add(24, "hours").utc().unix() - moment().utc().unix();
          } else return date.utc().unix() - moment().utc().unix();
        } else if (date.hours() >= 1 && date.hours() <= 10) {
          date.set({ hour: 16, minute: 0, second: 0 });
          if (date.utc().unix() - moment().utc().unix() < 0) {
            return date.add(24, "hours").utc().unix() - moment().utc().unix();
          } else {
            return date.utc().unix() - moment().utc().unix();
          }
        } else if (date.hours() >= 17 && date.hours() < 24) {
          date.add(24, "hours").set("hour", 16);
          if (date.utc().unix() - moment().utc().unix() < 0) {
            return date.add(24, "hours").utc().unix() - moment().utc().unix();
          } else return date.utc().unix() - moment().utc().unix();
        }
      };

      setInterval(() => {
        let diff = getDifference(moment(changedDate));
        const duration = moment.duration(diff, "seconds");
        const countdownTime = ` ${duration.hours()} часов ${duration.minutes()} мин`;
        this.setState({ countdownTime });
      }, 1000);
    }
  };

  onCancelModalClose(shouldCancel = false) {
    if (shouldCancel) {
      const { cancelCurrentOrder, reload, order } = this.props;
      cancelCurrentOrder(order.id).then(() => reload());
    }

    this.setState({ cancelModalVisible: false });
  }

  showCancelModal = () => {
    this.setState({ cancelModalVisible: true });
  };

  onRecipientModalModalClose() {
    this.setState({ changeRecipientModalVisible: false });
  }

  showRecipientModalModal() {
    this.setState({ changeRecipientModalVisible: true });
  }

  onUpdateContactInfo(contactInfo) {
    const { order, updateContactInfo, reload } = this.props;

    updateContactInfo(order.id, contactInfo).then(() => reload());
  }

  handlePayQiwi = () => {
    analytics.reachGoal("pay_qiwi");
  };

  handleChangeOrderStatus = (status) => {
    const { changeOrderStatus, order, reload } = this.props;
    this.setState({ orderStatusChanging: true });
    changeOrderStatus(order.id, status)
      .then(() => this.setState({ orderStatusChanging: false }))
      .then(() => reload());
  };

  handleRepeatOrder = (orderId) => {
    const { addItemsFromOrder, history } = this.props;
    this.setState({ addingItemsToCart: true });
    addItemsFromOrder(orderId).then((res) => {
      this.setState({ addingItemsToCart: false });
      res.isUpdated === true && history.push("/cart");
    });
  };

  render() {
    const { order, isMobile, user, orderIsLoading } = this.props;
    const { cancelModalVisible, changeRecipientModalVisible, showPaymentDelayInfo, orderStatusChanging, showAllDocuments, addingItemsToCart } = this.state;
    const {
      number,
      createdDate,
      buyerStatus,
      contactEmail,
      contactPerson,
      deliverySum,
      totalSum,
      deliveryAddress,
      deliveryDate,
      deliveryType,
      contactPhone,
      paymentFormUrl,
      financialStatus,
      items,
      ownerLegalName,
      documents,
      leftToPay,
      paymentServiceId,
      cancellationReason,
      id,
      paymentStatus,
      totalDiscount,
      totalNetWeight,
      pickupInfo
    } = order;
    // const {country, region, locality, street, number: buildingNumber, room, floor} = deliveryAddress;
    const address = addressToString(deliveryAddress);
    const orderInProgress = ["Processing", "Draft"].indexOf(buyerStatus.code) !== -1;
    const waitingPayment = buyerStatus.code === "AwaitingPayment";
    const returnOrder = buyerStatus.code === "NotReceived" || buyerStatus.code === "PartiallyReceived";
    // const orderPaid = ["AwaitingShipment", "Delivery", "Received", "PartiallyReceived", "Completed"].indexOf(buyerStatus.code) !== -1;
    const orderPaidbutNotShiped = ["Delivery", "Received", "PartiallyReceived"].indexOf(buyerStatus.code) !== -1;
    const orderCanceled = buyerStatus.code === "Cancelled";
    const orderPendingApproval = buyerStatus.code === "PendingApproval";
    const phoneNumber = contactPhone || user.phoneNumber;
    const returnedItems = items.filter((item) => item.itemStatus.code === "Returned");
    const returnItemsSum = returnedItems.map((item) => item.sum).reduce((a, b) => a + b, 0);
    const paymentMethodCaption = order.paymentServiceId === "Qiwi" ? "Предоплата банковской картой" : showPaymentDelayInfo ? "Без % отсрочка по счету" : "Предоплата по счету";
    const hasPaymentDocuments = documents && documents.length > 0;
    const paymentCaption = orderPendingApproval ? "Время на согласование" : "Время на оплату";
    const orderPartiallyPaid = paymentStatus === "PartiallyPaid";
    const orderId = id;
    const isDelivery = deliveryType.code === DELIVERY_TYPES[0];
    const pickupDate = pickupInfo && `${moment(pickupInfo?.pickupFrom).format("DD MMMM")} c ${moment.utc(pickupInfo?.pickupFrom).format("LT")} до ${moment.utc(pickupInfo?.pickupBefore).format("LT")}`
    const dateOfDelivery = isDelivery ? `${moment(deliveryDate).format("DD MMM").replace(".", "")}, с 08:00 до 20:00` : pickupDate;
    const deliveryTitle = isDelivery ? "Доставка" : " Самовывоз";

    const getInvoiceUrl = () => {
      if (hasPaymentDocuments) {
        return documents[0].url === null ? `http://ram.s3mts.ru/catalog/${documents[0].fileName}` : documents[0].url;
      } else return "/";
    };

    const renderOrderDocuments = () => {
      const docs = showAllDocuments ? documents : documents.slice(0, 2);

      return (
        hasPaymentDocuments &&
        docs.map((document) => {
          const title = document.title ? document.title.split(".")[0] : document.fileName;
          const date = moment(document.createdDate).format("lll").replace(",", " в");
          const fileType = document.documentType.code === "InvoiceAgreement" ? "PDF" : "XLS";
          const url = document.url === null ? `http://ram.s3mts.ru/catalog/${document.fileName}` : document.url;
          return <OrderDocsDownload key={`${document.fileName}_${document.date}`} title={title} date={date} fileType={fileType} url={url} />;
        })
      );
    };

    const deliveryLabel = () => {
      if (totalNetWeight >= MAX_CART_ITEMS_WEIGHT) {
        return "Индивидуально"
      } else if (deliverySum) {
        return `${separateThousands(deliverySum)} ₽`
      } else {
        return "Бесплатно"
      }
    }
    const discountSum = `${separateThousands(-1 * totalDiscount)} ₽`;

    return (
      <section className="edit-order">
        <div className="order-info">
          <div className="top">
            <Link to="/profile/orders">
              <BackIcon /> К списку заказов
            </Link>
          </div>
          <div className="line justified">
            <div className="left-side">
              <div className="order-number">Заказ №{parseInt(number, 10)}</div>
              <div className="order-date">от {moment(createdDate).format("DD MMM YYYY, HH:mm").replace(".", "")}</div>
            </div>
            <div className="right-side">{ownerLegalName}</div>
          </div>
          <div className="line">
            <div className="status-info">
              {!isMobile && <div className="label">Статус заказа</div>}
              <OrderStatus status={buyerStatus} />
            </div>
            <EditOrderStatusInfo
              paymentServiceId={paymentServiceId}
              cancellationReason={cancellationReason}
              showPaymentDelayInfo={showPaymentDelayInfo}
              paymentStatus={paymentStatus?.code}
              orderStatus={buyerStatus.code}
              leftToPay={leftToPay}
              deliverySum={deliverySum}
            />
          </div>
          <div className="line last">
            <div className="left-side column">
              <div className="left-side-header">
                <h3 className="data-label">{deliveryTitle}</h3>
              </div>
              <div className="left-side-address">
                <span className="data-value">{address}</span>
                <span className="data-value">{dateOfDelivery}</span>
              </div>
              <div className="left-side-header">
                <h3 className="data-label">Получатель</h3>
                {(orderInProgress || waitingPayment || orderPendingApproval) && (
                  <div className="left-side-icon" onClick={() => this.showRecipientModalModal()}>
                    <EditIcon />
                  </div>
                )}
              </div>

              <span className="data-value">{contactPerson}</span>
              <div className="left-side-contacts">
                <span className="data-value">{phoneNumber}</span>
                <div className="data-divider" />
                <span className="data-value">{contactEmail}</span>
              </div>

              {/* {!orderCanceled && !orderPaid && (
                <div className="change-button" onClick={() => this.showRecipientModalModal()}>
                  Изменить получателя
                </div>
              )} */}
              <div className="left-side-buttons">
                <div className="add-to-cart-button">
                  <Button title="Повторить заказ" buttonSize="medium" buttonType="secondary" loading={addingItemsToCart} onClick={() => this.handleRepeatOrder(orderId)} />
                </div>

                {!orderCanceled && !orderPaidbutNotShiped && <Button title="Отменить" width="108px" buttonSize="medium" buttonType="subtle" onClick={this.showCancelModal} />}
              </div>

              <CancelModal onClose={(shouldCancel) => this.onCancelModalClose(shouldCancel)} visible={cancelModalVisible} />
              <ChangeRecipientModal
                onClose={() => this.onRecipientModalModalClose()}
                visible={changeRecipientModalVisible}
                contactPhone={phoneNumber}
                contactEmail={contactEmail}
                contactPerson={contactPerson}
                updateContactInfo={(contactInfo) => this.onUpdateContactInfo(contactInfo)}
              />
            </div>
            <div className="right-side w50">
              <div className="payment">
                {/* {order.paymentServiceId === "Qiwi" && (
                  <div className="payment-method">
                    <CardIcon /> Оплата картой
                  </div>
                )}
                {order.paymentServiceId === "Tochka" && (
                  <div className="payment-method">
                    <BankIcon /> Переводом на расчетный счёт
                  </div>
                )} */}

                <div className="pay-line">
                  <h3 className="pay-line-caption">Товары</h3>
                  <span className="pay-line-sum">{separateThousands((totalSum - deliverySum).toFixed(2))} ₽</span>
                </div>
                <div className="pay-line">
                  <h3 className="pay-line-caption">{deliveryTitle}</h3>
                  <span className="pay-line-sum">{deliveryLabel()}</span>
                </div>
                {!!totalDiscount && <div className="pay-line">
                  <h3 className="pay-line-caption">Скидка</h3>
                  <span className="pay-line-sum discount">{discountSum}</span>
                </div>}
                <div className="pay-line with-border">
                  <h3 className="pay-line-caption total ">Итого:</h3>
                  <span className="pay-line-sum total">{separateThousands(totalSum.toFixed(2))} ₽</span>
                </div>
                <div className="pay-line more-padding">
                  <h3 className="pay-line-caption">Оплата</h3>
                  <span className="pay-line-sum">{paymentMethodCaption}</span>
                </div>

                {waitingPayment ||
                  (orderPendingApproval && (
                    <div className="pay-line">
                      <h3 className="pay-line-caption">{paymentCaption}</h3>
                      <div className="pay-line-timer">{this.state.countdownTime}</div>
                    </div>
                  ))}

                {paymentStatus && !orderPendingApproval && (
                  <div className="pay-line more-padding">
                    <h3 className="pay-line-caption">Статус платежа</h3>
                    <span className="pay-line-sum">
                      <OrderStatus status={paymentStatus} type="payment" />
                    </span>
                  </div>
                )}

                {!!leftToPay && showPaymentDelayInfo && orderPartiallyPaid && (
                  <div className="pay-line">
                    <h3 className="pay-line-caption">Остаток к оплате</h3>
                    <span className="pay-line-sum"> {separateThousands(leftToPay.toFixed(2))} ₽</span>
                  </div>
                )}

                {waitingPayment && order.paymentServiceId === "Qiwi" && (
                  <a href={paymentFormUrl} target="_blank" rel="noopener noreferrer" className="payment-button">
                    <Button title="Оплата картой" width={!isMobile ? "177px" : "100%"} icon={<PayIcon />} buttonSize="medium" buttonType="primary" onClick={this.handlePayQiwi} />
                  </a>
                )}

                {orderPendingApproval && (
                  <div className="payment-agreement">
                    <Button
                      title="Заключить договор"
                      width={!isMobile ? "177px" : "100%"}
                      buttonSize="medium"
                      buttonType="primary"
                      disabled={orderInProgress}
                      onClick={() => this.handleChangeOrderStatus("AwaitingShipment")}
                    />
                    <p className="payment-disclaimer">
                      Я принимаю и соглашаюсь с условиями{" "}
                      <a href={getInvoiceUrl()} target="_blank" rel="noopener noreferrer">
                        Договора-счета{" "}
                      </a>{" "}
                      и обязуюсь совершить оплату.
                    </p>
                  </div>
                )}

                {order.paymentServiceId === "Tochka" && hasPaymentDocuments && (
                  <div className="payment-docs-wrap">
                    <ul className="payment-docs"> {renderOrderDocuments()}</ul>
                    {documents.length > 2 && !showAllDocuments && (
                      <div className="payment-docs-button">
                        <Button
                          title="Показать ещё"
                          // width={!isMobile ? "177px" : "100%"}
                          buttonSize="medium"
                          buttonType="primary"
                          onClick={() => this.setState({ showAllDocuments: true })}
                        />
                      </div>
                    )}
                  </div>
                )}

                {/* {orderPaid && !returnOrder && (
                  <div className="other-status">
                    <CheckIcon />
                    Оплачен
                  </div>
                )} */}

                {returnOrder && (
                  <>
                    <div className="other-status return">
                      <ReturnIcon />
                      Возврат
                    </div>

                    <div className="return-desc">
                      <h3 className="return-title">Информация о возврате:</h3>
                      {returnedItems.length > 0 && (
                        <div className="return-desc-row">
                          <span>Сумма возврата:</span>
                          <span className="return-accent">{returnItemsSum} ₽</span>
                        </div>
                      )}
                      <div className="return-desc-row">
                        <span>Статус возврата:</span>
                        <span className="return-accent">{financialStatus.value}</span>
                      </div>
                    </div>
                  </>
                )}

                {(orderStatusChanging || orderIsLoading) && (
                  <div className="payment-overlay">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <h2>Товары в заказе</h2>
        {this.renderProducts()}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
  user: state.profile.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCartItems: fetchCartItems,
      addItemsFromOrder: addItemsFromOrder,
      cancelCurrentOrder: cancelCurrentOrder,
      updateContactInfo: updateContactInfo,
      addPaymentCurrentOrder: addPaymentCurrentOrder,
      downloadInvoice: downloadInvoice,
      changeOrderStatus: changeOrderStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);
