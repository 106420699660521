import { groupBy } from "ramda";

export const groupItemsBySupplier = (items) => {
  const cartGroupBy = groupBy((c) => c.productOwnerId)(items);
  const cartGroups = Object.keys(cartGroupBy).map((productOwnerId) => {
    const items = cartGroupBy[productOwnerId];
    const productOwner = items[0].productOwner;
    const tradeName = items[0].tradeName || "";
    const totalSum = items.filter((item) => (item && item.itemStatus ? item.itemStatus.code !== "OutOfStock" : item)).reduce((sumAcc, { sum }) => sumAcc + sum, 0);
    return { productOwner, tradeName, items, totalSum };
  });

  const totalItems = items.length;
  const totalSum = items.reduce((sumAcc, { sum }) => sumAcc + sum, 0);

  return { cartGroups, totalItems, totalSum };
};

export const getOrderItemsTotal = (orderItems) => {
  const totalItems = orderItems?.length;
  const totalSum = orderItems?.reduce((sumAcc, { sum }) => sumAcc + sum, 0);
  const totalWeight = orderItems?.reduce((sumAcc, { grossWeight, quantity }) => sumAcc + (grossWeight * quantity), 0);
  const totalVolume = orderItems?.reduce((sumAcc, { grossVolume }) => sumAcc + grossVolume, 0);
  return {
    totalItems,
    totalSum,
    totalWeight,
    totalVolume,
  };
};

export const isTestUser = (user) =>
  user?.id === "9e64486e-12d7-4622-abad-807f27eec05a" ||
  user?.id === "305c7b48-b492-4cfd-8fb1-c535fe90425b" ||
  user?.id === "1815dca2-ac13-4c0f-865e-6aa7a5358b0d" ||
  user?.id === "cdc88e10-8251-4a92-bfed-a819141bdb5c" ||
  user?.id === "31d6bca7-ed7c-4f27-9627-7a68fbb7db77" ||
  user?.id === "ac07e73f-fdfa-409a-9f5c-8500b4f2c18b";

export const getMinimumOrderSum = (user) => (isTestUser(user) ? 10 : 7000);

export const isEarlyOrder = (orderaCount) => orderaCount <= 100;
