import * as React from 'react';
import Input from './Input';
import { ReactComponent as EmailIcon } from '../../../images/email.svg';

class EmailInput extends React.Component {
    render() {
        const { title, value, onChange, hasError, errorText } = this.props;
        return (<Input leftImg={<EmailIcon />} title={title} value={value} onChange={onChange} hasError={hasError} errorText={errorText} />
        )
    }
}

export default EmailInput;