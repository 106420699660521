import React, { useEffect, useState } from "react";
import { ReactComponent as CheckIcon } from "../../../../images/check.svg";
import LogoMarket from "../../../../images/new_logo.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { payCurrentOrder } from "../../../../actions/order";
import { withRouter } from "react-router";
import { ReactComponent as BackIcon } from "../../../../images/arrow-back.svg";
import { Link } from "react-router-dom";
import queryString from "query-string";
import "./PaymentSuccess.scss";
import moment from "moment";

function PaymentSuccess({ payCurrentOrder, location }) {
  const [number, setNumber] = useState();
  const [sum, setSum] = useState();
  const [orderDate, setOrderDate] = useState();
  const query = queryString.parse(location.search);

  useEffect(() => {
    const formattedDate = moment(query.date.replace("%20", "-").replace(".", ":"), 'DD.MM.YYYY HH:mm').format("lll");
    setNumber(query.ordernumber);
    setSum(query.totalsum);
    setOrderDate(formattedDate);
    payCurrentOrder(query.ownerid, query.orderid, query.paymentserviceid, query.paymentid);
  }, []);

  return (
    <div className="payment-success">
      <div className="payment-success__underlay">
        <div className="top">
          <Link to="/profile/orders">
            <BackIcon /> К списку заказов
          </Link>
          <img className="payment-success-logo" src={LogoMarket} alt="Логотип РусАгроМаркет" />
        </div>
        <div className="payment-success__content">
          <div className="payment-success__icon">
            <CheckIcon />
          </div>
          <h2 className="payment-success__title">Заказ успешно оплачен</h2>
          <div className="payment-success__divider" />
          <ul className="payment-success__info">
            <li className="payment-success__info-item">
              Номер заказа: <span className="payment-success__info-accent">№{number}</span>
            </li>
            <li className="payment-success__info-item">
              Сумма заказа:
              <span className="payment-success__info-accent">
                {sum} руб

              </span>
            </li>
            {/* <li className="payment-success__info-item">
              Время оплаты: <span className="payment-success__info-accent">{orderDate}</span>
            </li> */}
          </ul>
        </div>
        {/* <div className="payment-success__logos">
          <img src={LogoRam} alt="Логотип РусАгроМаркет" />
          <img src={LogoMarket} alt="Логотип РусАгроМаркет" />
        </div> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      payCurrentOrder: payCurrentOrder,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentSuccess));
