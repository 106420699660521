import React, { useState, useEffect } from "react";
import Modal from "../../../commons/Modal/Modal";
import Button from "../../../commons/Button/Button";
import { ReactComponent as MapIcon } from "../../../../images/map.svg";
import "./DeliveryArea.scss";

const { env } = global.config;
const isDacnhik = env === "dachnik";

const DeliveryArea = ({ showIcon = true, title = "Зона доставки", mapSrc = "/delivery-map.js" }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      const script = document.createElement("script");

      script.src = mapSrc;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isModalVisible, mapSrc]);

  const onClose = () => setModalVisible(false);
  return (
    <div className="delivery-area">
    {!isDacnhik && <Button icon={showIcon ? <MapIcon /> : null} buttonType="text" title={title} onClick={() => setModalVisible(true)} />}
      {isModalVisible && (
        <Modal onClose={onClose} visible={isModalVisible}>
       <h2 className="delivery-area-title">Зона доставки</h2>
          <iframe
            title={title}
            id="map_223302728"
            frameBorder="0"
            width="100%"
            height="600px"
            sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
          ></iframe>
        </Modal>
      )}
    </div>
  );
};

export default DeliveryArea;
