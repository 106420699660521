import React from "react";
import "./Feedback.scss";
import Input from "../../commons/RamInput/Input";
import { Gap } from "../../../components/commons/Gap";
import { ReactComponent as EmailIcon } from "../../../images/email.svg";
import { ReactComponent as UserIcon } from "../../../images/user.svg";
import CheckBox from "../CheckBox/CheckBox";
import Button from "../../commons/Button/Button";

function Feedback() {
  return (
    <section className="feedback">
      <div className="feedback-content">
        <div className="feedback-desc">
          <h2 className="feedback-title">Обратная связь</h2>
          <p className="feedback-text">Тут вы можете задать интересующие вас вопросы, выразить свое мнение о качестве продукта или оставить предложение.</p>
        </div>
        <form className="feedback-form">
          <Input
            title="Ваше имя"
            value=""
            leftImg={<UserIcon />}
            // onChange={this.handleFieldChange("firstName")}
            name="firstName"
          />
          <Gap size={20} />
          <Input
            title="E-mail"
            value=""
            leftImg={<EmailIcon />}
            // onChange={this.handleFieldChange("firstName")}
            name="firstName"
          />
          <Gap size={20} />
          <Input
            title="Сообщение"
            value=""
            textarea={true}
            // onChange={this.handleFieldChange("firstName")}
            name="firstName"
          />
          <Gap size={20} />

          <CheckBox>
            <div className="feedback-checkbox-text">
              <span>Я огласашаюсь на условия, определенные</span>
              <a href="https://rusagro.market/privacy_policy/" className="feedback-checkbox-link">
                Политикой конфиденциальности
              </a>
            </div>
          </CheckBox>
          <Gap size={20} />
          <Button title="Отправить" buttonSize="medium" buttonType="primary" />
        </form>
      </div>
    </section>
  );
}

export default Feedback;
