import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../commons/Modal/Modal";
import AddressListItem from "../AddressListItem/AddressListItem";
import { setAddressListModalVisible, setAddressModalVisible, setRegistrationField, fetchDeliveryAddress, setRegistrationFull, cleanRegistration, setAddressContactsVisible, getPickupPoints } from "../../../actions/cart";
import { deleteOrderAddress, fetchUserAddress, setDefaultAddress } from "../../../actions/order";
import "./AddressListModal.scss";
import { Loader } from "../../commons/Loader/Loader";
import { DELIVERY_TYPES } from "../../../constants";
import PickUpPointsList from "../PickUpPointsList/PickUpPointsList";
import { addressToString } from "../../../helpers/stringOperations";

const AddressListModal = () => {
  const [addressList, setAddressList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.cart.addressListModalVisible);
  const deliveryType = useSelector((state) => state.cart.deliveryType);
  const userData = useSelector((state) => state.profile.user);
  const paymentServiceId = useSelector((state) => state.cart.registration.paymentServiceId);
  const isCompany = useSelector((state) => state.api.isCompany);
  const addressId = useSelector((state) => state.cart.registration.addressId);
  const pickupPoint = useSelector((state) => state.cart.pickupPoint);
  const pickupPointsList = useSelector((state) => state.cart.pickupPointsList);

  useEffect(() => {
    getAddressList()
    const fullAddress = addressToString(pickupPointsList?.results[0]?.address)
    setSelectedPoint(pickupPoint || { id: pickupPointsList?.results[0]?.id, title: pickupPointsList?.results[0]?.name, addressData: pickupPointsList?.results[0]?.address, fullAddress: fullAddress, link: pickupPointsList?.results[0]?.link })
  }, [dispatch]);

  const onClose = () => {
    dispatch(setAddressListModalVisible(false));
  };

  const getAddressList = () => {
    setLoading(true)
    dispatch(fetchUserAddress()).then((res) => {
      if (res.length === 0 && addressId) {
        dispatch(setRegistrationField("addressId", null))
        dispatch(setRegistrationField("addressData", null))
        dispatch(setRegistrationField("fullAddress", null))
      }

      setAddressList(res)
    }).finally(() => setLoading(false))
  }

  const handleDeleteAddress = (e, addressId) => {
    e.stopPropagation();
    dispatch(deleteOrderAddress(addressId)).then(() => getAddressList());
  };

  const handleShowEditModal = (e, addressId = null) => {
    e.stopPropagation();
    setEditedAddress(addressId);
    dispatch(setAddressModalVisible(true));
    addressId ? dispatch(setAddressContactsVisible(true)) : dispatch(setAddressContactsVisible(false));
  };

  const handleChooseAddress = (e, addressId) => {
    e.stopPropagation();
    setEditedAddress(addressId);
    dispatch(setDefaultAddress(addressId));
    setUserInfoInputs(addressId);
    onClose();
  };

  const handleSetPickupPoint = (e, pickupPoint) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedPoint(pickupPoint);
    dispatch(setDefaultAddress(null, pickupPoint.id, DELIVERY_TYPES[1]));
    onClose();
  }

  const setEditedAddress = (addressId) => {
    const registrationData = getEditedAddress(addressId);

    if (registrationData) {

      const { interphone, apartment, entrance, floor, details, country, locality, number, region, street, building } = registrationData;
      const addressData = { country, locality, number, region, street, building };
      const data = { ...registrationData, interphone, apartment, entrance, number, floor, details, addressData, paymentServiceId, addressList };
      dispatch(setRegistrationFull(data, addressId));

    } else {
      dispatch(cleanRegistration());
    }
  };

  const setUserInfoInputs = (addressId) => {
    const registrationData = getEditedAddress(addressId);
    const { email, phoneNumber, firstName, middleName, lastName } = userData;
    const fullName = `${lastName || ""} ${firstName || ""} ${middleName || ""}`;
    const phoneCodeIsRight = phoneNumber.includes("(");

    const formatedPhoneNumber = !phoneCodeIsRight
      ? phoneNumber.slice(0, 3) + "(" + phoneNumber.slice(3, 6) + ")" + phoneNumber.slice(6, 16)
      : phoneNumber;

    !registrationData.recipientFullName && dispatch(setRegistrationField("recipientFullName", fullName.trim()));
    !registrationData.recipientPhone && dispatch(setRegistrationField("recipientPhone", formatedPhoneNumber));
    !registrationData.recipientEmail && dispatch(setRegistrationField("recipientEmail", email));
  }

  const getEditedAddress = (id) => {
    if (!id) return null;

    return addressList.find((item) => item.id === id);
  };

  const renderAddressList =
    addressList &&
    addressList
      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
      .map((item, i) => (
        <AddressListItem
          key={item.id}
          address={item}
          isCompany={isCompany}
          active={i === 0}
          handleDelete={(e, id) => handleDeleteAddress(e, id)}
          handleEdit={(e, id) => handleShowEditModal(e, id)}
          onClick={(e, id) => handleChooseAddress(e, id)}
        />
      ));

  return (
    <div className="address-list-modal">
      <Modal onClose={() => onClose()} visible={visible} closeByClickOutside={false}>
        <h2 className="address-list-modal-title">Выберите способ доставки</h2>
        {loading && (
          <div className="address-list-modal-loader">
            <Loader />
          </div>
        )}
        {deliveryType === DELIVERY_TYPES[0] ? <ul className="address-list">
          <li className="address-list-item add" onClick={(e) => handleShowEditModal(e, null)}>
            <span>+</span> Добавить адрес
          </li>
          {renderAddressList}
        </ul> : <PickUpPointsList selectedPoint={selectedPoint} selectPoint={handleSetPickupPoint} pointsList={pickupPointsList} />}
        <div className="address-list-modal-bottom" />
      </Modal>
    </div>
  );
};

export default AddressListModal;
