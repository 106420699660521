import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./Favorites.scss";
import "../../Product/Product.scss";
import { fetchFavoriteProducts } from "../../../actions/product";
import Card from "../../Product/Card/Card";
import { Loader } from "../../commons/Loader/Loader";
import EmptyProducts from "./EmptyProducts";

class FavoritesProducts extends React.Component {
  state = { isLoading: false };
  //TODO: disabled
  // componentDidMount() {
  //   this.setState({ isLoading: true });
  //   this.props.fetchFavoriteProducts().finally(() => this.setState({ isLoading: false }));
  // }
  render() {
    const { isDesktop, favoriteProducts } = this.props;
    const { isLoading } = this.state;
    const isTabletOrMobile = !isDesktop;

    if (isLoading)
      return (
        <div className="loader-wrapper">
          <Loader />
        </div>
      );

    if (favoriteProducts.length === 0) return <EmptyProducts />;

    return (
      <div className="favorites card-container">
        {favoriteProducts.map((product, index) => (
          <Card key={`${product.id}_${index}`} product={product} isMobile={isTabletOrMobile} rowsView={true} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isDesktop: state.api.isDesktop,
  favoriteProducts: state.products.favoriteProducts,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchFavoriteProducts: fetchFavoriteProducts }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesProducts);
