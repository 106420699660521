import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./PropertyFilter.scss";
import { setActiveProductList, clearSearchFilter } from "../../../../actions/product";
import Filter from "../../../Filter/Filter";
import { listToObject } from "../../../../helpers/utils";

const { env } = global.config;
const isDacnhik = env === "dachnik";

class PropertyFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }



  changeFilterOwnerIds = (key, value) => {
    const { search } = this.props;
    search({ [key]: value });
  };

  render() {
    const { title, filterKey, searchActive = false, searchParams, availableFilters, extra, defaultStyle, mobileFilter } = this.props;
    const selected = searchParams[filterKey] || [];
    if (isDacnhik) return null;
    if (!availableFilters[filterKey]) return null;

    const filterValues = listToObject(availableFilters[filterKey]);

    return (
      <div className="property-filter">
        <Filter
          title={title}
          setActiveProductList={this.changeFilterOwnerIds}
          filter={filterValues}
          filterKey={filterKey}
          selected={selected}
          searchActive={searchActive}
          extra={extra}
          defaultStyle={defaultStyle}
          mobileFilter={mobileFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    availableFilters: state.products.availableFilters,
    searchParams: state.products.searchParams,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveProductList: setActiveProductList,
      clearSearchFilter: clearSearchFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFilter);
