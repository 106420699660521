import React from 'react';
import './EditWrapper.scss';


const EditWrapper = ({ editMode, caption, value, children }) => {
    if (!editMode) {
        return (<div className="edit-wrapper">
            <div className="caption">{caption}</div>
            <div className="value">{value || "Не указан"}</div>
        </div>)
    }
    return (<div className="edit-wrapper active">{children}</div>);
}

export default EditWrapper;