import * as React from "react";
import "./MyCompanies.scss";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ItemStatus from "../../Cart/SupplierItems/ItemStatus/ItemStatus";
import moment from "moment";
import EditWrapper from "../EditWrapper/EditWrapper";
import { ReactComponent as BackIcon } from "../../../images/arrow-back.svg";
import { ReactComponent as RefreshIcon } from "../../../images/refresh.svg";
import { ReactComponent as EditIcon } from "../../../images/pencil.svg";
import WrappedIcon from "../../commons/WrappedIcon/WrappedIcon";

const EditMyCompany = (props) => {
  const {
    company: { id, status, inn, kpp, legalName, createdDate, ceoFullName, bankAccount = "нет", correspondentAccount = "нет", bic = "нет", bankFullName = "нет", },
    isMobile,
  } = props;

    
  const edoStatus = props.company.useEdo ? "Активен" : "Не подключён";
  const edoOperator = props.edoInfo?.providerType.value || "—";

  const renderDesktopView = () => (
    <div className="company-info-wrapper">
      <div className="emc-info">
        {isMobile && <ItemStatus status={status} />}
        <div>
          <EditWrapper caption="ИНН" value={inn} />
          <EditWrapper caption="Юридическое наиминование " value={legalName} />
        </div>
        <div>
          <EditWrapper caption="КПП" value={kpp} />
          <EditWrapper caption="Генеральный директор" value={ceoFullName} />
        </div>
      </div>
      <h4>
        <span>Банковские реквизиты</span> <WrappedIcon icon={<EditIcon />} onClick={() => props.onEdit(id, "bankInfo")} />
      </h4>
      <div className="emc-info">
        <div>
          <EditWrapper caption="Расчетный счет" value={bankAccount} />
          <EditWrapper caption="Корреспондентский счет" value={correspondentAccount} />
        </div>
        <div>
          <EditWrapper caption="БИК банка" value={bic}></EditWrapper>
          <EditWrapper caption="Название банка" value={bankFullName} />
        </div>
      </div>
      <h4>
        <span>Обмен по ЭДО</span> <WrappedIcon icon={<EditIcon />} onClick={() => props.onEdit(id, "edo")} />
      </h4>
      <div className="emc-info">
        <div>
          <EditWrapper caption="Статус" value={edoStatus}></EditWrapper>
        </div>
        <div>
          <EditWrapper caption="Оператор ЭДО" value={edoOperator} />
        </div>
      </div>
    </div>
  );

  const renderMobileView = () => (
    <div className="company-info-wrapper">
      {isMobile && <ItemStatus status={status} />}
      <div className="double-col">
        <EditWrapper caption="ИНН" value={inn} />
        <EditWrapper caption="КПП" value={kpp} />
      </div>
      <EditWrapper caption="Юридическое наиминование " value={legalName} />

      <EditWrapper caption="Генеральный директор" value={ceoFullName} />
      <h4>
        <span>Банковские реквизиты </span>
        <WrappedIcon icon={<EditIcon />} onClick={props.onEdit} />
      </h4>
      <EditWrapper caption="Расчетный счет" value={bankAccount} />
      <EditWrapper caption="Корреспондентский счет" value={correspondentAccount} />
    </div>
  );

  return (
    <section className="edit-my-company">
      <div className="top">
        <Link to="/profile/my-companies">
          <BackIcon /> К списку компаний
        </Link>
      </div>
      <div className="emc-header">
        <div className="emc-name">
          <div>
            <h3>{legalName}</h3>
            <div className="myc-date">{`На платформе с ${moment(createdDate).format("D MMMM YYYY")}`}</div>
          </div>
          <WrappedIcon icon={<RefreshIcon />} onClick={props.onRefresh} />
        </div>
        {!isMobile && <ItemStatus status={status} />}
      </div>
      {!isMobile ? renderDesktopView() : renderMobileView()}
    </section>
  );
};
const mapStateToProps = (state) => ({
  myCompanies: state.profile.myCompanies.results,
  edoInfo: state.profile.edoInfo?.results[0]
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EditMyCompany);
