import React from "react";
import grayStar from "../../../../images/star-gray.png";
import whiteStar from "../../../../images/star-white.png";

import classNames from "classnames";
import "./ReviewStars.scss";

function ReviewStars({ rating, size, color, edit }) {
  const underlayWidth = { width: `${(rating / 5) * 100}%` };
  const starColor = color === "gray" ? grayStar : whiteStar;

  const onChangeRating = ({ target }) => {
    edit.fnc(target.id, edit.key);
  };

  return (
    <div
      className={classNames("review-stars-content", {
        [size]: size,
      })}
    >
      <div
        className={classNames("review-stars-underlay", {
          [size]: size,
        })}
        style={underlayWidth}
      >
        <div className="review-stars">
          <img
            className={classNames("review-star", {
              [size]: size,
              edit: edit,
            })}
            id="1"
            src={starColor}
            onClick={(e) => onChangeRating(e)}
            alt="1 балл"
          />
          <div
            className={classNames("review-stars-divider", {
              [size]: size,
              gray: color === "gray",
            })}
          />
          <img
            className={classNames("review-star", {
              [size]: size,
              edit: edit,
            })}
            id="2"
            src={starColor}
            onClick={(e) => onChangeRating(e)}
            alt="2 балла"
          />
          <div
            className={classNames("review-stars-divider", {
              [size]: size,
              gray: color === "gray",
            })}
          />
          <img
            className={classNames("review-star", {
              [size]: size,
              edit: edit,
            })}
            id="3"
            src={starColor}
            onClick={(e) => onChangeRating(e)}
            alt="3 балла"
          />
          <div
            className={classNames("review-stars-divider", {
              [size]: size,
              gray: color === "gray",
            })}
          />
          <img
            className={classNames("review-star", {
              [size]: size,
              edit: edit,
            })}
            id="4"
            src={starColor}
            onClick={(e) => onChangeRating(e)}
            alt="4 балла"
          />
          <div
            className={classNames("review-stars-divider", {
              [size]: size,
              gray: color === "gray",
            })}
          />
          <img
            className={classNames("review-star", {
              [size]: size,
              edit: edit,
            })}
            id="5"
            src={starColor}
            onClick={(e) => onChangeRating(e)}
            alt="5 баллов"
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewStars;
