const initialState =
{
  countries: {},
  units: [],
  temperatureRanges: {},
  packingTypes: {},
  isLoaded: false
}

export default function dictionary(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_COUNTRIES':
      return {
        ...state,
        countries: action.countries
      }
    case 'RECEIVE_UNITS':
      return {
        ...state,
        units: action.units
      }
    case 'RECEIVE_TEMPERATURE_RANGES':
      return {
        ...state,
        temperatureRanges: action.temperatureRanges
      }
    case 'RECEIVE_PACKING_TYPES':
      return {
        ...state,
        packingTypes: action.packingTypes
      }
    case 'PRELOAD_DONE':
      return {
        ...state,
        isLoaded: true
      }
    default:
      return state
  }
}