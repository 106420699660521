import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./Header.scss";
import TopBar from "./TopBar/TopBar";
import ActiveBar from "./ActiveBar/ActiveBar";
import { fetchCategoryByLevel } from "../../actions/category";
import { fetchCartItems } from "../../actions/cart";
import { getUser, getAccount, setAccountType, fetchMyCompanies, getDefaultMyCompany } from "../../actions/profile";
import { fetchDeliveryAddress } from "../../actions/cart";
import { fetchFavoriteProducts, fetchFavoriteSuppliers } from "../../actions/product";
import { getUserSubscriptions } from "../../actions/subscriptions";
import { getNotifications } from "../../actions/notifications";
import MobileHeader from "./Mobile/MobileHeader";
import { withRouter } from "react-router-dom";
import CategoriesMenu from "./categories/CategoriesMenu";
import { isBusinessAccount } from "../../helpers/products";
import { setPreload, getBanners } from "../../actions/api";
import { Gap } from "../commons/Gap";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shown: false,
      scrollCoord: 0,
      scrollingDown: false,
    };

    this.headerRef = React.createRef();
  }

  onClick = () => {
    this.setState({ shown: !this.state.shown });
  };

  componentDidMount() {
    const { fetchCategoryByLevel, fetchCartItems, fetchDeliveryAddress, getBanners, setPreload } = this.props;
    setPreload(true);
    fetchCategoryByLevel(1);
   
    Promise.allSettled([fetchCartItems(), fetchDeliveryAddress(), getBanners()]).then(() => setPreload(false));
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      loggedIn,
      fetchCartItems,
      getUser,
      getAccount,
      getNotifications,
      getUserSubscriptions,
      accountType,
      userId,
      accountId,
      fetchDeliveryAddress,
      fetchFavoriteProducts,
      fetchFavoriteSuppliers,
      fetchMyCompanies,
      getDefaultMyCompany,
      setPreload,
    } = this.props;

    const { scrollCoord } = this.state;

    window.addEventListener("scroll", this.handleGetCoordinates);



    if (loggedIn !== prevProps.loggedIn) {
      if (loggedIn) {
        setPreload(true);
        fetchFavoriteProducts();
        fetchFavoriteSuppliers();
        getUser();
        Promise.allSettled([fetchCartItems(), fetchDeliveryAddress(), fetchMyCompanies(), getDefaultMyCompany()]).then(() => setPreload(false));
      }
    }

    if (accountId !== prevProps.accountId) {
      userId && getNotifications();
      userId && getUserSubscriptions();
    }

    if (userId !== prevProps.userId) {
      userId && getNotifications();
      userId && getUserSubscriptions();
    }

    if (prevState.scrollCoord !== scrollCoord) {
      prevState.scrollCoord < scrollCoord ? this.setState({ scrollingDown: true }) : this.setState({ scrollingDown: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleGetCoordinates);
    this.setState = (state, callback) => {
      return;
    };
  }

  handleGetCoordinates = () => {
    const scrollY = window.scrollY;
    this.setState({ scrollCoord: scrollY });
  };

  onSelectAccountType = (accountType) => {
    const { setAccountType } = this.props;
    setAccountType(accountType).then(() => window.location.reload());
  };

  render() {
    const { accountType, loggedIn, isMobile, isTablet, notifications } = this.props;
    const { shown, scrollCoord, scrollingDown } = this.state;
    const counter = notifications && notifications.unread;
    const headerHeight = this.headerRef.current?.clientHeight;
    const showFixedHeader = !scrollingDown && scrollCoord > headerHeight;
    const fixedHeaderClass = showFixedHeader ? `site-header fixed shown ${loggedIn && "logged"}` : `site-header fixed ${loggedIn && "logged"}`;

    const renderDesktopContent = () => (
      <div className="header-container" id="header">
        <TopBar isMobile={false} onSelectAccountType={this.onSelectAccountType} accountType={accountType} loggedIn={loggedIn} />
        <Gap size={15} />
        <ActiveBar notificationCount={counter} shown={shown} onClick={this.onClick} />
        {!showFixedHeader && <CategoriesMenu />}
      </div>
    );

    const renderFixedHeader = () => {
      return (
        <div className="header-container" id="fixed-header">
          <Gap size={15} />
          <ActiveBar notificationCount={counter} shown={shown} onClick={this.onClick} fixedHeader={showFixedHeader} />
          {showFixedHeader && <CategoriesMenu fixedHeader={true} />}
        </div>
      );
    };

    const renderMobileContent = () => {
      return (
        <div className="header-container">
          <MobileHeader notificationCount={counter} shown={shown} onClick={this.onClick} />
        </div>
      );
    };

    return (
      <>
        <div className={`site-header ${loggedIn && "logged"}`} ref={this.headerRef}>
          {isMobile || isTablet ? !showFixedHeader && renderMobileContent() : renderDesktopContent()}
        </div>
        <div className={fixedHeaderClass}> {isMobile || isTablet ? showFixedHeader && renderMobileContent() : renderFixedHeader()}</div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: getUser,
      getAccount: getAccount,
      getNotifications: getNotifications,
      fetchCategoryByLevel: fetchCategoryByLevel,
      fetchCartItems: fetchCartItems,
      setAccountType: setAccountType,
      fetchDeliveryAddress: fetchDeliveryAddress,
      fetchFavoriteProducts: fetchFavoriteProducts,
      fetchFavoriteSuppliers: fetchFavoriteSuppliers,
      getBanners: getBanners,
      setPreload: setPreload,
      fetchMyCompanies: fetchMyCompanies,
      getDefaultMyCompany: getDefaultMyCompany,
      getUserSubscriptions: getUserSubscriptions,

    },
    dispatch
  );

export default connect(
  (state) => ({
    categories: state.categories.categories,
    loggedIn: state.profile.loggedIn,
    userId: state.profile.userId,
    accountId: state.profile.accountId,
    accountType: state.profile.accountType,
    isMobile: state.api.isMobile,
    isTablet: state.api.isTablet,
    notifications: state.notifications.notifications,
  }),
  mapDispatchToProps
)(withRouter(Header));
