
import * as React from "react";
import classNames from "classnames";
import {ReactComponent as EllipseIcon} from '../../../../images/ellipse.svg';
import './ItemStatus.scss';
import {findIndex, propEq} from 'ramda';

// {
//     "InProgress": "В обработке",
//     "Returned": "Возврат",
//     "NotReceived": "Не получен",
//     "OutOfStock": "Отсутствует",
//     "Received": "Получен",
//     "Assembled": "Собран"
//   }
const ItemStatus = ({status}) =>
    (<div className={classNames({
        "item-status": true,
        [status.code]: true
    })}><EllipseIcon />{status.value}</div>)

export default ItemStatus;