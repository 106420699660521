import * as React from "react";
import "./AccountVerificationBox.scss";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";

const AccountVerificationBox = () => {
  return (
    <div className="verification-box">
      <div className="verification-box-inner">
        <div className="verification-header">
          <div className="icon">
            <InfoIcon />
          </div>
          <div>Аккаунт ожидает подтверждения</div>
        </div>
        <div className="verification-text">
          Владельцу аккаунта организации необходимо подтвердить вашу регистрацию. После того как регистрация будет подтверждена вам будет доступно оформление заказа.
        </div>
      </div>
    </div>
  );
};

export default AccountVerificationBox;
