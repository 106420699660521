import React, { useEffect, useState } from 'react'
import SuppliersLandingTitle from '../SuppliersLandingTitle'
import "./SuppliersLandingApplication.scss";
import Modal from '../../../components/commons/Modal/Modal'
import { Gap } from '../../../components/commons/Gap'

const SuppliersLandingApplication = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const script = document.createElement("script")
    script.type = "text/javascript";
    script.async = "async";
    script.src = "//cdn.qform.io/forms.js?v=" + new Date().getTime() / 1000;
    const scrInsert = document.getElementsByTagName("script")[0];
    scrInsert.parentNode.insertBefore(script, scrInsert);
   

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  
  const handleShowConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal)
  }

  return (
    <section className='supplier-landing-application' id="application-form">
      <div className='supplier-landing-application-left'>
        <SuppliersLandingTitle title="Оставьте заявку и начните продавать товар онлайн" />
        <p className='supplier-landing-application-description'>
         {`Приглашаем стать нашим поставщиком на `}
          <span className='supplier-landing-application-accent'>B2B FoodTech платформе русагро.маркет </span>
          и расширить свой бизнес с нами.
        </p>

        <div data-formid="form_64pTsHgAobQpv5yWWUd_pqsqgF7-FtYa"></div>

      </div>
      <div className='supplier-landing-application-right' />

      {<Modal onClose={() => handleShowConfirmModal()} visible={showConfirmModal} type="save">
        <Gap size={15} />
        <h2 className="success-modal-title">Заявка отправлена!</h2>
        <Gap size={10} />
        <span>Мы свяжемся с вами в ближайшее время!</span>
      </Modal>}
    </section>
  )
}

export default SuppliersLandingApplication