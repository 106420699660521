import React from "react";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";
import "./CartNotification.scss";

function CartNotification({ onClose, type }) {
  const handleText = (type) => {
    if (type === "price") {
      return "Поставщик(и) обновили цены по некоторым товарам.";
    } else if (type === "overweight") {
      return "Вес заказа больше 17 тонн. Менеджер индивидуально рассчитает для Вас стоимость доставки после оформления заказа.";
    }
  };

  return (
    <div className="cart-notification">
      <div className="cart-notification-content">
        <div className="cart-notification-info">
          <div className="cart-notification-icon">
            <InfoIcon />
          </div>
          <p className="cart-notification-text">{handleText(type)}</p>
        </div>

        <div className="cart-notification-close" onClick={() => onClose(type)}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );
}

export default CartNotification;
