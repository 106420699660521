const initialState = {
  orders: { results: [], itemsCount: 10, offset: 0 },
  currentOrder: null,
  // currentOrderAddressType: "Appartment"
};

export default function order(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_ORDER":
      return {
        ...state,
        currentOrder: action.order,
      };
    case "LOAD_USER_ORDERS":
      return {
        ...state,
        orders: action.orders,
      };
    // case "SET_CURRENT_ORDER_ADDRESS_TYPE":
    //   return {
    //     ...state,
    //     currentOrderAddressType: action.currentOrderAddressType,
    //   };

    default:
      return state;
  }
}
