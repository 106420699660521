import * as React from "react";
import "./CheckBoxItem.scss";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";

const CheckBoxItem = ({ checked, onClick }) => {
  const onItemClick = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick(e);
      e.preventDefault();
    }
  };
  return (
    <span className="check-box-item" onClick={onItemClick}>
      {checked && (
        <span className="bg">
          <CheckIcon />
        </span>
      )}
    </span>
  );
};
export default CheckBoxItem;
