import React, { Component } from "react";
import "./Home.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductsBanner from "./ProductsBanner/ProductsBanner";
import CatalogCategories from "./CatalogCategories/CatalogCategories";
import About from "./About/About";
import { searchProductsPlain, getRecomendedProducts } from "../../actions/product";
import { debounce, getWindowWidth } from "../../helpers/utils";
import SwiperCore, { Navigation, A11y } from "swiper";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import RegisterBannerSlider from "./RegisterBanner/RegisterBannerSlider";
import { Gap } from "../commons/Gap";
import HomePageLoader from "./RegisterBanner/HomePageLoader/HomePageLoader";
import { MAIN_PRODUCT_CATEGORIES } from "../../constants";
import PriceListBanner from "./PriceListBanner/PriceListBanner";

// install Swiper components
SwiperCore.use([Navigation, A11y]);
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      popularProducts: [],
      newProducts: [],
      recomenededProducts: [],
      bonusProducts: [],
      promotedProducts: [],
      popularProductsLoaded: false,
      newProductsLoaded: false,
      recomenededProductsLoaded: false,
      promotedProductsLoaded: false,
      bonusProductsLoaded: false,
      productsByCategories: [],
      productsByCategoriesLoaded: false,
      remainingCategories: [],
      remainingCategoriesProducts: [],
      remainingCategoriesProductsLoading: false,
      remainingCategoriesProductsLoaded: false,
      bottomProductPosition: null
    };
    this.productBannerRef = React.createRef();

  }


  componentDidMount() {
    const chat = document.querySelector(".b24-widget-button-wrapper");

    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
    chat && chat.classList.add("home-page")
  }


  componentDidUpdate(prevProps, prevState) {
    const { searchProductsPlain, getRecomendedProducts, userId, loggedIn, isPreloaded } = this.props;
    const { bottomProductPosition, remainingCategoriesProductsLoading, remainingCategoriesProductsLoaded, productsByCategoriesLoaded } = this.state;
    const chat = document.querySelector(".b24-widget-button-wrapper");

    if (prevProps.isPreloaded !== isPreloaded || prevProps.loggedIn !== loggedIn) {
      if (isPreloaded !== true) {
        searchProductsPlain({ sortBy: "changeddate", isBonusItem: false, direction: "Desc" })
          .then((json) => {
            this.setState({ newProducts: json.results })
          })
          .finally(() => this.setState({ newProductsLoaded: true }));
        searchProductsPlain({ filter: "HasPromotion==true", sortBy: "changeddate", isBonusItem: false, direction: "Desc" })
          .then((json) => this.setState({ promotedProducts: json.results }))
          .finally(() => this.setState({ promotedProductsLoaded: true }));
        searchProductsPlain({ filter: "CategoryId==95d5477b-86b2-4e85-a76f-92d1c68536f2", direction: "Desc", isBonusItem: true })
          .then((json) => this.setState({ bonusProducts: json.results }))
          .finally(() => this.setState({ bonusProductsLoaded: true }));

        const mainCategoriesPromises = MAIN_PRODUCT_CATEGORIES.map(async (categ) => {
          return await searchProductsPlain({ filter: `CategoryId==${categ.id}` })
            .then((json) => {
              const data = [
                ...this.state.productsByCategories,
                { title: categ.title, products: this.filterByCategory(categ.id, json.results), id: categ.id }
              ];
              this.setState({ productsByCategories: data })
            }
            )
        })

        Promise.allSettled(mainCategoriesPromises).finally(() => this.setState({ productsByCategoriesLoaded: true, remainingCategories: this.getRemaningCategories() }));

      }

    }



    chat && chat.classList.add("home-page")
    window.addEventListener("scroll", this.getBottomProductPosition);

    if (bottomProductPosition && bottomProductPosition <= window.innerHeight) {

      (!remainingCategoriesProductsLoading && !remainingCategoriesProductsLoaded && productsByCategoriesLoaded) && this.getRemaningCategoriesProducts()
    }
  }

  updateWindowWidth = () => {
    this.setState({ windowWidth: getWindowWidth() });
  };

  getBottomProductPosition = () => debounce(this.setState({ bottomProductPosition: this.productBannerRef.current?.getBoundingClientRect().bottom }), 500);

  componentWillUnmount() {
    const chat = document.querySelector(".b24-widget-button-wrapper");
    window.removeEventListener("resize", this.updateWindowWidth);
    window.removeEventListener("scroll", this.getBottomProductPosition);
    chat && chat.classList.remove("home-page")
  }

  filterByCategory = (id, arr) => {
    return arr.filter((item) => item.categories[0]?.categoryId1 === id)
  }

  getRemaningCategories = () => {
    const { categories } = this.props;
    const { productsByCategories } = this.state;
    return categories.filter((item) => item.id !== productsByCategories[0]?.id && item.id !== productsByCategories[1]?.id && item.id !== productsByCategories[2]?.id && item.id !== productsByCategories[3]?.id)
  }

  getRemaningCategoriesProducts = () => {
    const { searchProductsPlain } = this.props;
    const { remainingCategories } = this.state;
    this.setState({ remainingCategoriesProductsLoading: true })
    const remainingCategoriesPromises = remainingCategories.map(async (categ) => {
      return await searchProductsPlain({ filter: `CategoryId==${categ.id}` }).then((json) => {
        const data = [
          ...this.state.remainingCategoriesProducts,
          { title: categ.title, products: this.filterByCategory(categ.id, json.results), id: categ.id }
        ];
        this.setState({ remainingCategoriesProducts: data })
      })
    })
    Promise.allSettled(remainingCategoriesPromises).finally(() => this.setState({ remainingCategoriesProductsLoading: false, remainingCategoriesProductsLoaded: true }));
  }

  renderProductsByCategories = () => {
    const { remainingCategoriesProductsLoaded, windowWidth, remainingCategoriesProducts } = this.state;
    const { isMobile, isTablet, isDesktop } = this.props;
    return remainingCategoriesProducts.map((item, i) => {
      return item?.products.length > 0 ? <ProductsBanner
        title={item?.title}
        products={item?.products}
        isMobile={isMobile}
        isTablet={isTablet}
        isDesktop={isDesktop}
        index={5 + i + 1}
        width={windowWidth}
        loaded={remainingCategoriesProductsLoaded}
        key={item?.id}
        url={item?.id}
      /> : null
    })
  }

  render() {
    const { windowWidth, newProducts, bonusProducts, newProductsLoaded, recomenededProducts, recomenededProductsLoaded, bonusProductsLoaded, productsByCategories, productsByCategoriesLoaded, remainingCategoriesProducts, remainingCategoriesProductsLoaded, promotedProducts, promotedProductsLoaded } = this.state;
    const { isMobile, isTablet, isDesktop, loggedIn, bonusBalance } = this.props;
    const showRecomendedProducts = recomenededProducts.length > 3;
    const showBonusProducts = loggedIn && bonusProducts.length > 0;

    return (
      <main className="home-page">
        <Gap size={20} />
        <RegisterBannerSlider isTablet={isTablet} isDesktop={isDesktop} />
        <div className="block container">
        </div>
        <div className="block container">
          <CatalogCategories isMobile={isMobile} isTablet={isTablet} width={windowWidth} />
          {!!promotedProducts &&
            <ProductsBanner
              title="Товары со скидкой"
              products={promotedProducts}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              index="1"
              width={windowWidth}
              loaded={promotedProductsLoaded}
              promotedProducts={true}
              url="?HasPromotion=true"
            />}
          {!!showRecomendedProducts &&
            <ProductsBanner
              title="Персональные рекомендации"
              products={recomenededProducts}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              index="2"
              width={windowWidth}
              loaded={recomenededProductsLoaded}
            />}

          {showBonusProducts && <ProductsBanner
            title="Бонусные товары"
            products={bonusProducts}
            isMobile={isMobile}
            isTablet={isTablet}
            isDesktop={isDesktop}
            index="3"
            width={windowWidth}
            loaded={bonusProductsLoaded}
            isBonusProducts={showBonusProducts}
            bonusBalance={bonusBalance}
          />}
          <ProductsBanner
            title={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[0]?.id)?.title}
            products={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[0]?.id)?.products}
            isMobile={isMobile}
            isTablet={isTablet}
            isDesktop={isDesktop}
            index="4"
            width={windowWidth}
            loaded={productsByCategoriesLoaded}
            url={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[0]?.id)?.id}
          />

          <ProductsBanner
            title={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[1]?.id)?.title}
            products={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[1]?.id)?.products}
            isMobile={isMobile}
            isTablet={isTablet}
            isDesktop={isDesktop}
            index="5"
            width={windowWidth}
            loaded={productsByCategoriesLoaded}
            url={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[1]?.id)?.id}

          />
          <div ref={this.productBannerRef}>
            <ProductsBanner
              title={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[2]?.id)?.title}
              products={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[2]?.id)?.products}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              index="6"
              width={windowWidth}
              loaded={productsByCategoriesLoaded}
              url={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[2]?.id)?.id}
            />
          </div>
          <div ref={this.productBannerRef}>
            <ProductsBanner
              title={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[3]?.id)?.title}
              products={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[3]?.id)?.products}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              index="7"
              width={windowWidth}
              loaded={productsByCategoriesLoaded}
              url={productsByCategories?.find((item) => item?.id === MAIN_PRODUCT_CATEGORIES[3]?.id)?.id}
            />
          </div>
          {remainingCategoriesProductsLoaded ? <>
            {this.renderProductsByCategories()}
            <ProductsBanner
              title="Новинки"
              products={newProducts}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              index={7 + remainingCategoriesProducts.length + 1}
              width={windowWidth}
              loaded={newProductsLoaded}
              url="?NewProducts=true"
            /></> :
            <><Gap size={30} />  <div className="home-page-loader-wrap"><HomePageLoader /></div> </>
          }
        </div>
        <Gap size={40} />
        <PriceListBanner />

        <About isMobile={isMobile} isTablet={isTablet} />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  accountType: state.profile.accountType,
  userId: state.profile.userId,
  bonusBalance: state.profile.account?.bonusBalance,
  categories: state.categories.categories,
  loggedIn: state.profile.loggedIn,
  isMobile: state.api.isMobile,
  isTablet: state.api.isTablet,
  isDesktop: state.api.isDesktop,
  isPreloaded: state.api.preload,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchProductsPlain: searchProductsPlain,
      getRecomendedProducts: getRecomendedProducts
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
