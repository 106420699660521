import React from 'react';
import Button from '../../../commons/Button/Button';
import { ReactComponent as FilterIcon } from "../../../../images/filter.svg";
import { ReactComponent as SortIcon } from "../../../../images/sortIcon.svg";
import "./FixedButtons.scss";
import IconButton from '../../../commons/IconButton/IconButton';

const FixedButtons = ({showFilter, showSorting}) => {
  return (
    <div className='fixed-buttons'>
      <Button title="Фильтры" buttonSize="large" buttonType="primary" width="100%" icon={<FilterIcon />} onClick={() => showFilter(true)} />
      <div className='fixed-buttons-sort'>
        <IconButton icon={<SortIcon />} onClick={() => showSorting(true)} />
      </div>
    </div>
  )
}

export default FixedButtons;