import React from "react";
import Button from "../../../commons/Button/Button";

import { ReactComponent as InfoIcon } from "../../../../images/info.svg";
import "../Registration.scss";

const OrganisationInfoWarning = ({ onClick }) => {
  return (
    <div className="organisation-warning">
      <div className="header first">
        <h3 className="header-title">Реквизиты организации</h3>
      </div>
      <div className="organisation-warning-content">
        <div className="error-header">
          <h4 className="error-header-title">Завершите регистрацию</h4>
          <div className="error-header-icon">
            <InfoIcon />
          </div>
        </div>
        <div className="error-msg">Для того, чтобы заказывать товары на маркетплейсе, вам необходимо завершить регистрацию, указав банковские реквизиты вашей организации.</div>
        <Button title="Завершить регистрацию" onClick={() => onClick()} width="auto" buttonSize="medium" buttonType="primary" />
      </div>
    </div>
  );
};

export default OrganisationInfoWarning;
