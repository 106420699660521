import * as React from "react";
import "./categories.scss";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchCategoryTree, fetchCategoryByLevel, toggleCategoriesMenu } from "../../../actions/category";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Scrollbar from "../../commons/ScrollBar/ScrollBar";
import { ReactComponent as ArrowIcon } from "../../../images/right-arrow.svg";
import { contains } from "ramda";
import { fetchGetBackend } from "../../../helpers/rest";

class CategoriesMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      categoryId1: null,
      categoryId2: null,
      categoryId3: null,
      searchPromise: null,
      categoryTree: [],
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.handleFetchCategories(2)
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.setState = (state, callback) => {
      return;
    };
  }

  init() {
    const { categories } = this.state;
    if (categories.length > 0) {
      this.onHoverCategory(categories[0].id, 1, categories[0].rootPaths);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showCategoriesMenu, fixedHeader } = this.props;
    const { categories } = this.state;

    const headerId = fixedHeader ? "fixed-header" : "header";
    if (showCategoriesMenu !== prevProps.showCategoriesMenu) {
      if (showCategoriesMenu) {
        this.init();
        document.body.style.position = "static";
        document.body.style.overflow = "hidden";
        const headerRect = document.getElementById(headerId).getBoundingClientRect();
        const top = `${headerRect.top + headerRect.height}px`;
        this.wrapperRef.style.top = top;
        this.wrapperRef.style.height = `calc(100vh - ${top})`;
      } else {
        document.body.style.position = "relative";
        document.body.style.overflow = "visible";
        this.setState({
          categoryId1: null,
          categoryId2: null,
          categoryId3: null,
        });
      }
    }

    if (categories !== prevState.categories && showCategoriesMenu) {
      this.init();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const { toggleCategoriesMenu } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !event.target.getAttribute("data-menu-btn")) {
      toggleCategoriesMenu(false);
    }
  }

  handleFetchCategories = (level) => {
    const { fetchCategoryByLevel } = this.props;
    fetchCategoryByLevel(level, false).then((res) => {
  
      const filteredCategories = res.results.filter((item) => item.productsCount && item.id !== "8be99310-9b2a-4330-9c7b-a2157c6030a0" && item.id !== "bfc8072f-4152-45ad-9d7c-0fbc693da6c3"); //remove empty, bonus and retail categories
      this.setState({ categories: filteredCategories })
    })
  }

  onHoverCategory(categoryId, level, rootPaths) {
    const level1 = rootPaths && rootPaths[0].l1;
    if (level === 1) {
      this.setState({
        categoryId1: level1,
        categoryId2: categoryId,
        categoryId3: null,
      });
      if (this.state.searchPromise) {
        this.state.searchPromise.cancel();
      }

      const searchPromise = fetchCategoryTree(categoryId)
        .then((categoryTree) => {
          this.setState({ categoryTree });
        })
        .catch(() => { });
      this.setState({ searchPromise });
    } else {
      if (level === 2) {
        this.setState({
          categoryId3: null,
        });
      }
      this.setState({ [`categoryId${level}`]: categoryId });
    }
  }

  render() {
    const { showCategoriesMenu, toggleCategoriesMenu } = this.props;
    const { categoryId1, categoryId2, categoryId3, categoryTree, categories } = this.state;
  
    if (!showCategoriesMenu) {
      return null;
    }

    const getMenuClasses = (selected) =>
      classNames({
        "menu-item": true,
        selected: selected,
      });

    const getCategories = (parentCategoryId) => categoryTree.filter((c) => contains(parentCategoryId, c.parentCategoryIds));
    // const categories2 = categoryId1 ? getCategories(categoryId1) : null;
    const categories3 = categoryId2 ? getCategories(categoryId2) : null;
    return (
      <div ref={this.setWrapperRef} className="categories-menu">
        <div className="container">
          <div className="overlay"></div>

          <div className="scroll-wrapper">
            <div className="main categories">
              <Scrollbar scrollFix={true}>
                {!!categories?.length &&
                  categories.map((category, index) => (
                    <div key={`category1_item_${index}`} className={getMenuClasses(category.id === categoryId2)}>
                      <Link
                        to={`/catalog/customerProducts/${categoryId1}/${category.id}`}
                        onClick={() => toggleCategoriesMenu(false)}
                        onMouseEnter={() => this.onHoverCategory(category.id, 1, category.rootPaths)}
                      >
                        <div className="category-wrapper">
                          <img className="category-img" src={category.gallery[0][category.id === categoryId1 ? "url" : "previewUrl"]} alt={category.title} />
                          <span>{category.title}</span>
                        </div>
                        <ArrowIcon />
                      </Link>
                    </div>
                  ))}
              </Scrollbar>
            </div>

            {/* {categoryId1 && <div className="level2 categories last">
              <Scrollbar scrollFix={true}>
                {categories2.map((category, index) =>
                  <div key={`category2_item_${index}`} className={getMenuClasses(category.id === categoryId2)}>
                    <Link to={`/catalog/customerProducts/${categoryId1}/${categoryId2}/${category.id}`} onClick={() => toggleCategoriesMenu(false)} onMouseEnter={() => this.onHoverCategory(category.id, 2)}>
                      <span>{category.title}</span>
                      <ArrowIcon />
                    </Link>
                  </div>
                )}
              </Scrollbar>
            </div>} */}
            {categoryId2 && (
              <div className="categories last">
                <Scrollbar scrollFix={true}>
                  {categories3.map((category, index) => (
                    <div key={`category3_item_${index}`} className={getMenuClasses(category.id === categoryId3)}>
                      <Link
                        to={`/catalog/customerProducts/${categoryId1}/${categoryId2}/${category.id}`}
                        onClick={() => toggleCategoriesMenu(false)}
                        onMouseEnter={() => this.onHoverCategory(category.id, 3, category.rootPaths)}
                      >
                        <span>{category.title}</span>
                        <ArrowIcon />
                      </Link>
                    </div>
                  ))}
                </Scrollbar>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categoryTree: state.categories.categoryTree,
  showCategoriesMenu: state.categories.showCategoriesMenu,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleCategoriesMenu: toggleCategoriesMenu,
      fetchCategoryByLevel: fetchCategoryByLevel,
      fetchCategoryTree: fetchCategoryTree
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesMenu);
