import * as React from "react";
import Input from "../../commons/RamInput/Input";
import { ReactComponent as PhoneIcon } from "../../../images/phone.svg";

let inputEnd = 4;
const PHONE_MASK = "+\\7 (999) 999-99-99";
class PhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.phoneRef = React.createRef();
  }

  onPhoneFocus = () => {
    if (!this.phoneRef.current) return;
    const input = this.phoneRef.current;

    setTimeout(() => {
      input.selectionStart = inputEnd;
      input.selectionEnd = inputEnd;
    }, 100);
  };

  handleMobileNumberChange = (value) => {
    const { onPhoneChange } = this.props;

    onPhoneChange(value);
  };

  setInputRef = (element) => {
    if (!this.phoneRef.current) {
      this.phoneRef.current = element;
    }
  };

  beforeMaskedValueChange = (newState) => {
    let { value } = newState;
    let selection = newState.selection;
    const reg = /(\(\d)/gi;

    const valueLength =
      Math.max(
        ...value
          .split("")
          .map((item, i) => {
            if (item !== "+" && item !== "_" && item !== "(" && item !== ")" && item !== " " && item !== "-") return i;
            return null;
          })
          .filter((item) => item)
      ) + 1;
    const valueIsEntered = reg.test(value);

    inputEnd = valueIsEntered ? valueLength : newState.selection?.end;
    selection = { start: inputEnd, end: inputEnd };

    return {
      value,
      selection,
    };
  };

  render() {
    const { mobileNumber, title, autofocus, hasError, name, errorText } = this.props;

    return (
      <div className="PhoneInput">
        <Input
          autofocus={autofocus}
          type="tel"
          leftImg={<PhoneIcon />}
          title={title || "Телефон"}
          onChange={this.handleMobileNumberChange}
          onClick={this.onPhoneFocus}
          value={mobileNumber}
          mask={PHONE_MASK}
          customRef={this.setInputRef}
          onFocus={this.onPhoneFocus}
          style={{ width: "100%" }}
          beforeMaskedValueChange={this.beforeMaskedValueChange}
          hasError={hasError}
          errorText={errorText}
          name={name}
        />
      </div>
    );
  }
}

export default PhoneInput;
