import * as React from "react";
import { ReactComponent as Checkbox } from "../../images/checkbox.svg";
import Input from "../commons/RamInput/Input";
import { ReactComponent as SearchIcon } from "../../images/search.svg";
import { pickBy } from "ramda";
import "./Filter.scss";
import FilterBox from "../commons/FilterBox/FilterBox";

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
    };
  }

  componentDidMount() {
    const { selected } = this.props;
    this.setState({ checkedValues: selected || [] });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.props;
    if (selected !== prevProps.selected) {
      this.setState({ checkedValues: selected || [] });
    }
  }

  toggleCheckbox = (value) => {
    const { setActiveProductList, filterKey } = this.props;
    let { checkedValues } = this.state;
    const index = checkedValues.indexOf(value);
    if (index !== -1) {
      checkedValues.splice(index, 1);
    } else {
      checkedValues.push(value);
    }

    setActiveProductList(filterKey, checkedValues);
    this.setState({ checkedValues });
  };

  renderCheckBoxFilter = () => {
    const { title, filter, filterText } = this.props;
    const { checkedValues } = this.state;
    const selectedValues = filterText ? pickBy((val, key) => val.toLowerCase().indexOf(filterText.toLowerCase()) !== -1, filter) : filter;
    const valuesKeys = Object.keys(selectedValues);

    return (
      <div>
        {valuesKeys.map((value, index) => (
          <div key={`filter_item_${title}_${index}`} className={"checkbox-item"} onClick={() => this.toggleCheckbox(value)}>
            {checkedValues.length && checkedValues?.includes(value) ? <Checkbox /> : <span className={"checkbox"} />}
            <span className={"checkbox-value"}>{filter[value]?.includes('["') ? JSON.parse(filter[value]).join(", ") : filter[value]}</span>
          </div>
        ))}
      </div>
    );
  };

  onChangeSearch = (text) => {
    const { setSearchFilter } = this.props;
    setSearchFilter(text);
  };

  render() {
    const { title, filter, searchActive = false, filterText, mobileFilter } = this.props;

    const { checkedValues } = this.state;
    if (!Object.keys(filter).length) {
      return null;
    }

    return (
      <FilterBox mobileFilter={mobileFilter} title={title} selectedText={checkedValues.map((v) => filter[v]).join(", ")}>
        {searchActive && (
          <div className="filter-input">
            <Input placeholder="Поиск" leftImg={<SearchIcon />} value={filterText} onChange={(v) => this.onChangeSearch(v)} />
          </div>
        )}
        <div className={"content"}>
          <div className={"filter-list"}>
            <div className={"filter-items-container"}>{this.renderCheckBoxFilter()}</div>
          </div>
        </div>
      </FilterBox>
    );
  }
}
export default Filter;
