import { fetchGetBackend, fetchPostBackend, cancelableFetch, renewToken, fetchPatchBackend, fetchDeleteBackend } from "../helpers/rest";
import { saveAuthData, AUTH_DATA, resetAuthData, loadAuthData } from "../helpers/cookies";
import { reloadVirtualCardItems } from "../helpers/virtualCard";
import { removeCart, loadVirtualCart, cleanRegistration, fetchCartItems, fetchDeliveryAddress } from "./cart";
import { clearFavoriteProducts, clearFavoriteSuppliers } from "./product";
import { emptyPromise } from "../helpers/utils";
import { setSystemErrorMessage } from "./api";

const { baseUrl } = global.config;

export function login(jwt) {
  return (dispatch) => {
    const companyType = jwt["http://rusagro.market/claims/companytype"];
    const isBusinessAccount = companyType !== "naturalperson";
    return dispatch(setAccountType(isBusinessAccount ? "business" : "private")).then(() =>
      dispatch({
        type: "LOGIN",
        jwt,
      })
    );
  };
}

export function restore(refresh_token, pathname) {
  return (dispatch) => {
    if (!!refresh_token) {
      return renewToken(refresh_token).then((accessToken) => {
        window.location.href = pathname;
        return emptyPromise(true);
      });
    } else {
      const jwt = loadAuthData().jwt;
      if (jwt) {
        dispatch(fetchUserInfo())
          .then(() => dispatch(login(jwt)))
          .catch(logout);
      }
    }
    return emptyPromise(false);
  };
}

export function fetchUserInfo() {
  return (dispatch) => {
    return fetchGetBackend("/connect/userinfo", true).then((userInfo) => {
      const accountId = userInfo["http://rusagro.market/claims/accountid"];
      const companyType = userInfo["http://rusagro.market/claims/companytype"];
      const isBusinessAccount = companyType !== "naturalperson";

      dispatch(getAccount(accountId));
      accountId && dispatch(getDefferedPaymentLimits(accountId));
      dispatch(setAccountType(isBusinessAccount ? "business" : "private"))
      return dispatch(receiveUserInfo(userInfo));
    });
  };
}

export function logout() {
  return (dispatch) => {
    resetAuthData();
    dispatch(removeCart());
    dispatch(loadVirtualCart());
    dispatch(cleanRegistration());
    dispatch(clearFavoriteProducts());
    dispatch(clearFavoriteSuppliers());
    dispatch({
      type: "LOGOUT",
    });
  };
}

export function setSection(name) {
  return {
    type: "SET_SECTION",
    name: name.slice(1),
  };
}

export function fetchFakeLogin() {
   return (dispatch) => {
    const loginData = {
      grant_type: "password",
      client_id: "marketplace.spa",
      client_secret: process.env.REACT_APP_LOCAL_CLIENT_SECRET || "",
      username: "+79111912224",//"+79260000000", //"georgianna61@gmail.com", //"+79836586376", //"+71111112223"
      password: process.env.REACT_APP_LOCAL_PASSWORD || "",
      scope: `dev.marketplace openid email phone profile offline_access role`,
    
      response_type: "id_token",
    };
    const data = new FormData();
    Object.entries(loginData).forEach((v) => data.append(v[0], v[1]));
    return fetch(`${baseUrl}/connect/token`, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        const jwt = saveAuthData(json["access_token"], json["refresh_token"]);
        dispatch(login(jwt));
      });
  };
}

function receiveUserInfo(userInfo) {
  return {
    type: "SET_USER_INFO",
    userInfo,
  };
}

function receiveUserData(user) {
  return {
    type: "SET_USER_DATA",
    user,
  };
}

function receiveAccountData(account) {
  return {
    type: "SET_ACCOUNT_DATA",
    account,
  };
}

export function setAccountNotVerifiedModalVisible(visible) {
  return {
    type: "SET_ACCOUNT_NOT_VERIFIED_MODAL_VISIBLE",
    visible,
  };
}

export function setAccountType(accountType) {

  return (dispatch, getState) => {
    dispatch({
      type: "SET_ACCOUNT_TYPE",
      accountType,
    });

    return reloadVirtualCardItems(accountType);
  };
}

export function getDefferedPaymentLimits(accountId) {
  return (dispatch, getState) => {
    const acctId = accountId || getState().profile.accountId;

    return fetchGetBackend(`/api/orders/v1.0/order/deferredpaymentlimits/${acctId}`, true).then((res) => {
      dispatch(setDefferedPaymentInfo(res));
    }).catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function setDefferedPaymentInfo(deferredPaymentInfo) {
  return {
    type: "SET_DEFFERED_PAYMENT_INFO",
    deferredPaymentInfo,
  };
}

export function setAccountTypeSelectVisible(accountTypeSelectVisible) {
  return {
    type: "SET_TYPE_SELECT_VISIBLE",
    accountTypeSelectVisible,
  };
}

export function getUser() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchGetBackend(`/api/buyer/v2.0/user-profile/users/${userId}`, true).then((json) => {
      dispatch(receiveUserData(json));
    }).catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function getAccount(accountId) {
  return (dispatch, getState) => {
    const acctId = accountId || getState().profile.accountId;
    if (!acctId) {
      return emptyPromise(null);
    }
    return fetchGetBackend(`/api/buyer/v2.0/business-profile/companies/${acctId}`, true).then((json) => {
      dispatch(receiveAccountData(json));
      return json;
    }).catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function updateUser(data) {

  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const patchData = Object.entries(data).map((v) => ({ op: "replace", path: `/${v[0]}`, value: v[1] }));
    return fetchPatchBackend(`/api/buyer/v2.0/user-profile/users/${userId}`, patchData, true)
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function updateAccount(data) {
  return (dispatch, getState) => {
    const accountId = getState().profile.accountId;
    const patchData = Object.entries(data).map((v) => ({ op: "replace", path: `/${v[0]}`, value: v[1] }));
    return fetchPatchBackend(`/api/buyer/v2.0/business-profile/companies/${accountId}`, patchData, true).catch((err) => dispatch(setSystemErrorMessage(err)));
    ;
  };
}

export function searchCompany(query) {
  return cancelableFetch(`/api/public/v2.0/business-profile/company-hints/${query}`).then((res) => res.hints);
}

export function searchBankAccount(query) {
  return cancelableFetch(`/api/public/v2.0/business-profile/bank-hints/${query}`).then((res) => res.hints);
}

export function sendValidationCode(requestId, type, value) {
  const isPhoneAuth = type === "phone";
  const tokenType = isPhoneAuth ? "ChangePhoneNumber" : "ChangeEmail";

  const generatorData = isPhoneAuth ? {
    requestId,
    tokenType: tokenType,
    phoneNumber: value,
  } : {
    requestId,
    tokenType: tokenType,
    email: value

  };
  return fetchPostBackend(`/api/public/v2.0/notifier/internal/token-generator/tokens.generate`, generatorData, true);
}

export function validateCode(requestId, code) {
  const validateData = {
    requestId,
    code: code
  };
  return fetchPostBackend(`/api/public/v2.0/notifier/internal/token-generator/tokens.validate`, validateData, true);

}

export const deleteUserPhoto = () => {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchPostBackend(`/api/buyer/v2.0/user-profile/users/${userId}/icon.reset`, null, true).catch((err) => dispatch(setSystemErrorMessage(err)));
    ;
  };
};

export function fetchMyCompanies() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const filterParam = encodeURIComponent(`CompanyUserId==${userId}`);
    return fetchGetBackend(`/api/buyer/v2.0/business-profile/companies?Filter=${filterParam},CompanyType!=NaturalPerson`, true).then((json) => {
      dispatch({
        type: "SET_MY_COMPANIES",
        myCompanies: json,
      });
    }).catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

function getMyCompanyIdByInn(inn) {
  const filterParam = encodeURIComponent(`Inn==${inn}`);
  return fetchGetBackend(`/api/buyer/v2.0/business-profile/companies?Filter=${filterParam}`, true).then((json) => {
    return emptyPromise(json.totalCount > 0 ? json.results[0].id : null);
  });
}

//

export function attachUserToCompany(inn) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return getMyCompanyIdByInn(inn)
      .then((companyId) => fetchPostBackend(`/api/buyer/v2.0/business-profile/companies/${companyId}/users.attach`, { userId }, true))
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function fetchMyCompanyById(companyId) {
  return (dispatch) => {
    return fetchGetBackend(`/api/buyer/v2.0/business-profile/companies/${companyId}`, true)
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  }
}

export function addMyCompany(companyData) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    return fetchPostBackend(`/api/buyer/v2.0/business-profile/companies`, { companyOwner: { userId }, ...companyData }, true)
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function setMyCompanyDefaultId(id) {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_MY_COMPANY_DEFAULT",
      myCompanyDefaultId: id,
    });
  };
}

export function setMyCompanyToDefault(companyId) {

  return (dispatch, getState) => {

    const userId = getState().profile.userId;
    return fetchPostBackend(`/api/buyer/v2.0/business-profile/companies/${companyId}/users.set-default-company`, { userId }, true).then((res) => {

      dispatch(setMyCompanyDefaultId(companyId));
      renewToken(AUTH_DATA.refreshToken)
      dispatch(fetchUserInfo()).then(() => {
        dispatch(fetchDeliveryAddress());
        return dispatch(fetchCartItems());
      });
    }).catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function getDefaultMyCompany() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;

    return fetchGetBackend(`/api/buyer/v2.0/business-profile/company-users/${userId}/companies.get-default`, true).then((json) => dispatch(setMyCompanyDefaultId(json["companyId"])))
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function getEdoProviderOptions() {
  return (dispatch, getState) => {
    return fetchGetBackend(`/api/public/v2.0/mdm/references/ProviderType`, true);
  };
}

export function getEdoInfoList(companyId) {
  return (dispatch, getState) => {
    return fetchGetBackend(`/api/buyer/v2.0/edo/edo-infos/?Filter=companyId==${companyId}`, true).then((res) => {
      dispatch({
        type: "SET_MY_COMPANY_EDO_INFO",
        edoInfo: res,
      });
    }).catch((err) => dispatch(setSystemErrorMessage(err)));

  };
}

export function getEdoInfo(edoInfoId) {
  return (dispatch, getState) => {
    return fetchGetBackend(`/api/buyer/v2.0/edo/edo-infos/${edoInfoId}`, true)
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function addEdoInfo(data) {
  return (dispatch, getState) => {
    return fetchPostBackend(`/api/buyer/v2.0/edo/edo-infos`, data, true)
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function updateEdoInfo(edoInfoId, data) {
  return (dispatch, getState) => {
    const patchData = Object.entries(data).map((v) => ({ op: "replace", path: `/${v[0]}`, value: v[1] }));

    return fetchPatchBackend(`/api/buyer/v2.0/edo/edo-infos/${edoInfoId}`, patchData, true)
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

export function deleteEdoInfo(edoInfoId) {
  return (dispatch, getState) => {
    return fetchDeleteBackend(`/api/buyer/v2.0/edo/edo-infos/${edoInfoId}`, true)
      .catch((err) => dispatch(setSystemErrorMessage(err)))
  };
}

export function updateMyCompany(companyId, companyData) {
  return (dispatch, getState) => {
    const patchData = Object.entries(companyData).map((v) => ({ op: "replace", path: `/${v[0]}`, value: v[1] }));

    return fetchPatchBackend(`/api/buyer/v2.0/business-profile/companies/${companyId}`, patchData, true)
      .catch((err) => dispatch(setSystemErrorMessage(err)));
  };
}

