import * as React from "react";
import "./MyCompanies.scss";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import ItemStatus from "../../Cart/SupplierItems/ItemStatus/ItemStatus";
import CheckBoxItem from "../../commons/CheckBoxItem/CheckBoxItem";
import moment from "moment";
import EditWrapper from "../EditWrapper/EditWrapper";
import { setMyCompanyToDefault } from "../../../actions/profile";
import Pagination from "../../commons/Pagination/Pagination";
import { withRouter } from "react-router";

const MyCompany = ({ company, setMain, isMain }) => {
  const { status, legalName, fullName, inn, kpp, createdDate, id } = company;
  const isCompany = useSelector((state) => state.profile.isCompany);
  const user = useSelector((state) => state.profile.user);
  const userName = fullName || `${user?.firstName || ""} ${user?.middleName || ""} ${user?.lastName || ""}`;

  return (
    <Link className="my-company-item" to={`?companyId=${id}`}>
      <div className="myc-data">
        <div className="myc-header">
          <div>
            <CheckBoxItem checked={isMain} onClick={setMain} />
            <div className="myc-name">
              <h3>{isCompany ? legalName : userName}</h3>
              <div className="myc-date">{`На платформе с ${moment(createdDate).format("D MMMM YYYY")}`}</div>
            </div>
          </div>
          <ItemStatus status={status} />
        </div>
        <div className="myc-info">
          <EditWrapper caption="ИНН" value={inn} />
          <EditWrapper caption="КПП" value={kpp} />
        </div>
      </div>
    </Link>
  );
};

const MyCompaniesList = (props) => {
  const { myCompanies, setMyCompanyToDefault, myCompanyDefaultId } = props;
  const totalCount = myCompanies.length;
  const offset = 0;
  return (
    <div className="my-companies-list">
      {myCompanies.map((c) => (
        <MyCompany company={c} key={`my_company_${c.id}`} isMain={myCompanyDefaultId === c.id} setMain={() => setMyCompanyToDefault(c.id)} />
      ))}
      <Pagination totalCount={totalCount} offset={offset} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  myCompanies: state.profile.myCompanies.results,
  myCompanyDefaultId: state.profile.myCompanyDefaultId,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setMyCompanyToDefault: setMyCompanyToDefault }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyCompaniesList));
