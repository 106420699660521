import { store } from "../index";
import ym from "react-yandex-metrika";

const toEcommerceRecord = (data) => ({
  ecommerce: {
    currencyCode: "RUB",
    ...data,
  },
});

const getVariantData = () => {
  const accountType = store.getState().profile.accountType;
  return { variant: accountType };
};

const getProductData = (product) => {
  const { id, title, price, owner, categories } = product;
  const { title1, title2, title3 } = categories?.length ? categories[0] : ["<todo: categories>"];

  return {
    id,
    name: title,
    price,
    brand: owner || "<todo: owner>",
    category: [title1, title2, title3].filter((s) => !!s).join("/"),
  };
};

const pushEcommerce = (data) => {
  window.dataLayer.push(toEcommerceRecord(data));
};

// add ?_ym_debug=1 - TO DEBUG
const analytics = {
  productViewDetails: (product) => {
    //     "ecommerce": {
    //         "currencyCode": "RUB",
    //         "detail": {
    //             "products": [
    //                 {
    //                     "id": "Идентификатор товара",
    //                     "name" : "Название товара",
    //                     "price": 477.60,
    //                     "brand": "Название поставщика + Идентификатор поставщика",
    //                     "category": "Категории через слэш, например: Рыба и морепродукты/Рыба/Рыба охлажденная",
    //                     "variant" : accountType
    //                 }
    //             ]
    //         }
    //     }

    pushEcommerce({
      detail: {
        products: [
          {
            ...getProductData(product),
            ...getVariantData(),
          },
        ],
      },
    });
  },
  productUpdateCart: (product, quantityBefore, loggedIn) => {
    // "ecommerce": {
    //     "currencyCode": "RUB",
    //     "add": {
    //         "products": [
    //             {
    //                 "id": "Идентификатор товара",
    //                 "name": "Название товара",
    //                 "price": 654.32,
    //                 "brand": "Название поставщика + Идентификатор поставщика",
    //                 "category": "Категории через слэш, например: Рыба и морепродукты/Рыба/Рыба охлажденная",
    //                 "quantity": 23
    //             }
    //         ]
    //     }
    // }
    // const quantityActual = isNil(product.quantity) ? product.minimumDeliveryQuantity : product.quantity;

    const quantity = Math.abs(quantityBefore - product.quantity);

    pushEcommerce({
      [quantityBefore < product.quantity ? "add" : "remove"]: {
        products: [
          {
            ...getProductData(product),
            quantity,
            // loggedIn,
          },
        ],
      },
    });
  },
  cartPurchase: (cartItems) => {
    //   "ecommerce": {
    //     "currencyCode": "RUB",
    //     "purchase": {
    //         "actionField": {
    //             "id" : "id заказа",
    //             “goal_id” : 225494776
    //         },
    //         "products": [
    //             {
    //                 "id": "Идентификатор товара",
    //                 "name": "Название товара",
    //                 "price": 1345.26,
    //                 "brand": "Название поставщика + Идентификатор поставщика",
    //                 "category": "Рыба и морепродукты/Рыба/Рыба охлажденная",
    //                 "variant": "Тип юзера"
    //                 "quantity": 1
    //             },
    //             {
    //                 "id": "25314",
    //                 "name": "Толстовка Яндекс женская",
    //                 "price": 1543.62,
    //                 "brand": "Название поставщика + Идентификатор поставщика",
    //                 "category": "Рыба и морепродукты/Рыба/Рыба охлажденная",
    //                 "variant": "Тип юзера",
    //                 "quantity": 3
    //             }
    //         ]
    //     }
    // }

    const varianData = getVariantData();

    const products = cartItems.items.map((p) => ({
      ...getProductData(p),
      ...varianData,
      quantity: p.quantity,
    }));

    pushEcommerce({
      purchase: {
        actionField: {
          goal_id: 225494776,
          id: cartItems.id,
        },
        products,
      },
    });
  },

  reachGoal: (goalName) => {
    ym("reachGoal", goalName);
  },

  logError: (error, info) => {
    console.warn("--ERROR from EB:", error, info);
  },
};

export default analytics;
