import React from "react";
import "./ItemsUnavailableModal.scss";
import Modal from "../../commons/Modal/Modal";
import Button from "../../commons/Button/Button";
import { useHistory } from "react-router-dom";

const ItemsUnavailableModal = ({ onClose, visible, onSubmit, cartRedirect, activeStep }) => {
    const history = useHistory();
    const title = activeStep === 1 ? "В корзину" : "В Каталог";

    const handleRedirect = () => {
        if (activeStep === 1) {
            cartRedirect();
            onClose();
        } else history.push("/catalog/customerProducts/");
    };

    return (
        <div className="items-unavailable-modal">
            <Modal onClose={() => onClose()} visible={visible} closeByClickOutside={true}>
                <h2 className="items-unavailable-modal-title">Продолжить оформление заказа ?</h2>
                <p className="items-unavailable-modal-text">
                    Некоторые товары в вашей корзине закончились на складе: Вы можете найти им замену в каталоге или продолжить оформление заказа.
                </p>

                <div className="items-unavailable-modal-buttons">
                    <Button title="Продолжить" buttonSize="medium" buttonType="primary" onClick={onSubmit} />
                    <Button title={title} buttonSize="medium" buttonType="secondary" onClick={handleRedirect} />
                </div>
            </Modal>
        </div>
    );
};

export default ItemsUnavailableModal;
