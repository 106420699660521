import React, { useState } from "react";
import "./CookiesAcceptModal.scss";
import * as CookiesImg from "../../../images/cookie.png";

import Modal from "../../commons/Modal/Modal";
import Button from "../../commons/Button/Button";
import { loadCookiesAccepted, saveCookiesAccepted } from "../../../helpers/cookies";

const CookiesAcceptModal = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(loadCookiesAccepted());

  const closeClick = () => {
    saveCookiesAccepted();
    setCookiesAccepted(true);
  };

  if (cookiesAccepted) return null;

  return (
    <div className="cookies-accept-modal">
      <Modal onClose={closeClick} visible={!cookiesAccepted} preventClickOutside>
        <div className="disclaimer">
          <img src={CookiesImg} alt="Accept cookies" />
          <div>Продолжая пользоваться сайтом, вы соглашаетесь с использованием cookie</div>
        </div>

        <Button title="Ок" buttonSize="medium" buttonType="primary" onClick={closeClick} />
      </Modal>
    </div>
  );
};

export default CookiesAcceptModal;
