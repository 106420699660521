import * as React from "react";
import { ReactComponent as UserIcon } from "../../../images/user.svg";

const LoginButton = () => (
  <a className="login-button" href={global.config.authUrl}>
    <UserIcon />
    {<span className="login-text">Войти</span>}
  </a>
);

export default LoginButton;
