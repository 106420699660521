import * as React from "react";
import { ReactComponent as MenuIcon } from "../../../images/menu.svg";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";

import classNames from "classnames";
import "./CatalogButton.scss";

const CatalogButton = ({ categoriesMenuActive, toggleShowCategoriesMenu, accountType }) => (
  <div
    href="#"
    className={classNames({
      "item-link catalog-btn": true,
      active: categoriesMenuActive,
      [accountType]: true,
    })}
    onClick={(e) => toggleShowCategoriesMenu()}
    data-menu-btn={true}
  >
    {!categoriesMenuActive ? <MenuIcon /> : <CloseIcon />}
    <span data-menu-btn={true}>Каталог</span>
  </div>
);

export default CatalogButton;
