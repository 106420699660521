import React, { useState, useEffect } from "react";
import "./Notifications.scss";
import NotificationPopupButton from "../NotificationPopupButton/NotificationPopupButton";
import Button from "../../commons/Button/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, useHistory } from "react-router-dom";
import { getNotifications, readNotification, setNotificationsPageSize } from "../../../actions/notifications";
import { Link } from "react-router-dom";
import moment from "moment";
import { isBusinessAccount } from "../../../helpers/products";

function Notifications({ getNotifications, readNotification, accountId, userId, accountType, notifications, setNotificationsPageSize, notificationsPageSize }) {
  const [coords, setCoords] = useState(null);
  const [itemId, setItemId] = useState("");
  const notificationsData = notifications && notifications.results;
  const dataLength = notifications && notifications.totalCount === notificationsData.length;
  const history = useHistory();
    
  useEffect(() => {
    getNotifications(notificationsPageSize)
  }, [notificationsPageSize, getNotifications]);

  //Обработчики
  const handleHoverOn = (e, id) => {
    setCoords(e.target.getBoundingClientRect());
    setItemId(id);
  };

  const handleHoverOff = () => {
    setTimeout(setCoords, 700, null);
  };

  const handleRead = (e, id = itemId) => {
    e.preventDefault();
    e.stopPropagation();
    readNotification(id).then(() =>
      getNotifications({
        from: 0,
        size: notificationsPageSize,
        userId: isBusinessAccount(accountType) ? accountId : userId,
        onlyUnread: false,
      })
    );
  };

  const onTitleClick = (e, id) => {
    e.stopPropagation();
    e.preventDefault()
    
    const orderURL = `/profile/orders?orderId=${id}`;
    history.push(orderURL);
  };

  const handleShowMore = () => {
    setNotificationsPageSize(notificationsPageSize + 10);
  };
  

  //Собираем список уведомлений
  const notificationElements = notificationsData.map((item) => {
    const isRead = item.status.code == "Read";
    return (
      <li className="notifications__item" key={item.id} onClick={(e) => handleRead(e, item.id)}>
        <header className="notifications__item-header">
          {!isRead && (
            <div className="notifications__item-indicator-wrap">
              <div className="notifications__item-indicator" onPointerOver={(e) => handleHoverOn(e, item.id)} onPointerOut={handleHoverOff}></div>
            </div>
          )}
          <h3 className="notifications__item-title">
            <div className="notifications__item-link" onClick={(e) => onTitleClick(e, item.payload.OrderId)}>
              <span className="notifications__item-order">{`Заказ № ${item.payload.Number}`}</span>
            </div>
            {item.header}
          </h3>
        </header>
        <p className="notifications__item-text">
          {item.text}
          {/* {item.createdDate && <span className="notifications__item-paydate">{timeSinceOreder(item.createdDate)}</span>} */}
        </p>
        <p className="notifications__item-date">{moment(item.createdDate).fromNow()}</p>
      </li>
    );
  });

  return (
    <>
      <ul className="notifications">
        {notificationElements}
        {coords && <NotificationPopupButton text="Прочитать" handler={handleRead} coords={coords} />}
      </ul>
      <div className="notifications__button-wrap">{!dataLength && <Button title="Показать ещё" buttonSize="small" buttonType="secondary" onClick={() => handleShowMore()} />}</div>
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotifications: getNotifications,
      readNotification: readNotification,
      setNotificationsPageSize: setNotificationsPageSize,
    },
    dispatch
  );

export default connect(
  (state) => ({
    categories: state.categories.categories,
    loggedIn: state.profile.loggedIn,
    accountType: state.profile.accountType,
    isMobile: state.api.isMobile,
    isTablet: state.api.isTablet,
    notifications: state.notifications.notifications,
    notificationsPageSize: state.notifications.notificationsPageSize,
    userId: state.profile.userId,
    accountId: state.profile.accountId,
  }),
  mapDispatchToProps
)(withRouter(Notifications));
