import * as React from "react";
import * as emptyOrdersPng from "../../../images/empty-data.png";

import "./OrdersInfo.scss";
import Button from "../../commons/Button/Button";

import { ReactComponent as MarketplaceIcon } from "../../../images/marketplace.svg";

import { Link } from "react-router-dom";
const DEFAULT_HEADER = "У вас пока нет заказов";
const DEFAULT_SUBHEADER = "Вы можете начать свой выбор с главной страницы, посмотреть акции или воспользоваться поиском, если ищете что-то конкретное.";

const EmptyOrders = ({ header = DEFAULT_HEADER, subheader = DEFAULT_SUBHEADER }) => {
  return (
    <div className="empty">
      <div className="icon">
        <img src={emptyOrdersPng} alt={header} />
      </div>
      <h3>{header}</h3>
      <div className="sub-header">{subheader}</div>
      <Link to="/catalog/customerProducts" className="big ">
        <Button title="В каталог товаров" icon={<MarketplaceIcon />} buttonSize="medium" buttonType="primary" width="235px" />
      </Link>
    </div>
  );
};

export default EmptyOrders;
