import React from "react";
import "./OrderDocsDownload.scss";
import { ReactComponent as FileIcon } from "../../../../images/file-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../../images/download.svg";
import analytics from "../../../../helpers/analytics";

const OrderDocsDownload = ({ title, date, fileType, url }) => {
  return (
    <li className="order-docs-download">
      <div className="order-docs-download-content">
        <div className="order-docs-download-icon">
          <FileIcon />
        </div>
        <div className="order-docs-download-info">
          <div className="order-docs-download-header">
            <h4 className="order-docs-download-title">{title}</h4>
            <span className="order-docs-download-filetype">{fileType}</span>
          </div>

          <p className="order-docs-download-date">{date}</p>
        </div>
      </div>
      <a className="order-docs-download-link" onClick={() => analytics.reachGoal("pay_tochka")} href={url} target="_blank" rel="noopener noreferrer">
     
        <DownloadIcon />
      </a>
    </li>
  );
};

export default OrderDocsDownload;
