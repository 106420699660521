import React, { useRef, useEffect } from "react";
import "./PopupModal.scss";
import close from "../../../images/close.svg";
import classNames from "classnames";

function PopupModal({ onClose, empty, children, title, extraHeader }) {
  const modalRef = useRef();
  const handleClickOutside = (e) => {
    if (!modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div className="popup-modal-wrapper">
      <div ref={modalRef} className={classNames("popup-modal", { empty })}>
        <header className="popup-modal__header">
          <div className="popup-modal__header-content">
            <h2 className="popup-modal__title">{title}</h2>
            {extraHeader}
          </div>
          <img className="popup-modal__closeIcon" src={close} alt="Иконка закрыть" onClick={onClose} />
        </header>
        <div className="popup-modal__content">{children}</div>
        <div className="popup-modal__overlay" />
      </div>
    </div>
  );
}

export default PopupModal;
