import * as React from "react";
import "./ProfileDropDown.scss";
import { Link } from "react-router-dom";
import { ReactComponent as ExpandIcon } from "../../../images/expand.svg";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as ProfileIcon } from "../../../images/profile.svg";
import { ReactComponent as HelpIcon } from "../../../images/help.svg";
import { ReactComponent as OrdersIcon } from "../../../images/orders.svg";
import { ReactComponent as ExitIcon } from "../../../images/power-off.svg";
import { setSection } from "../../../actions/profile";

import NoAvatar from "./NoAvatar";
import { logout } from "../../../actions/profile";

class ProfileDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          link_to: "/profile/dashboard",
          text: "Личный кабинет",
          icon: <ProfileIcon />,
        },
        { link_to: "/profile/orders", text: "Заказы", icon: <OrdersIcon /> },
        { link_to: "/help", text: "Помощь", icon: <HelpIcon /> },
      ],
    };
  }

  doLogout = () => {
    const { logout } = this.props;
    logout();
    this.props.history.push("/");
  };

  renderItems = () => {
    const items = this.state.items.map((item, i) => {
      return (
        <div key={`profile_dd_${i}`} name={item.name} className="dropdown-item">
          <span className="icon-wrapper">{item.icon}</span>
          <Link to={item.link_to}>{item.text}</Link>
        </div>
      );
    });

    return (
      <div>
        {items}
        <div className="dropdown-item">
          <span className="icon-wrapper">
            <ExitIcon />
          </span>
          <div onClick={() => this.doLogout()}>Выход</div>
        </div>
      </div>
    );
  };

  render() {
    const { user, isMobile } = this.props;

    if (!user) {
      return null;
    }
    const { firstName, lastName, middleName, iconUrl } = user;
    const name = `${firstName} ${middleName || ""} ${lastName || ""}`;

    if (isMobile) {
      // to="/profile/dashboard"
      return (
        <Link className="profile-btn" to="/profile/dashboard">
          <div className="avatar-btn">
            {iconUrl && <img src={iconUrl} className="avatar" alt="Фото профиля" />}
            {!iconUrl && <NoAvatar firstName={firstName} lastName={lastName} short={true} />}
          </div>
        </Link>
      );
    }

    return (
      <div className="profile-btn">
        <div className="dropdown">
          <div className="dropdown-btn avatar-btn">
            {iconUrl && <img src={iconUrl} className="avatar" alt="Фото профиля" />}
            {!iconUrl && <NoAvatar firstName={firstName} lastName={lastName} short={true} />}
            {/* <img src={avatar} className="avatar" /> */}
            <ExpandIcon />
          </div>
          <div className="dropdown-content">
            <div className="info">
              <div className="profile-name">{name}</div>
            </div>
            {this.renderItems()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.profile.data.status,
  user: state.profile.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: logout,
      setSection: setSection,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileDropDown));
