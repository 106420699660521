import { without } from "ramda";

const initialState = {
  loading: [],
  lastUrl: null,
  windowWidth: 0,
  isMobile: false,
  isBannersDataLoaded: false,
  preload: false,
  minimalOrderSum: 7000,
  banners: {},
  systemErrors: null,

};

export default function api(state = initialState, action) {
  const { loading } = state;

  switch (action.type) {
    case "API_FETCH_START":
      //avoid show progress on cyclic requests
      if (action.url !== state.lastUrl) loading.push(action.url);

      return {
        ...state,
        lastUrl: action.url,
        loading,
      };
    case "API_FETCH_COMPLETE":
      return {
        ...state,
        loading: without([action.url], loading),
      };
    case "SET_WINDOW_WIDTH":
      const isMobile = action.width < 768;
      const isDesktop = action.width >= 1024;
      const isTablet = !isMobile && !isDesktop;
      return {
        ...state,
        windowWidth: action.width,
        isMobile,
        isTablet,
        isDesktop,
      };

    case "API_PRELOAD":
      return {
        ...state,
        preload: action.preload,
      };
    case "API_FETCH_MINIMAL_OREDER_SUM":
      return {
        ...state,
        minimalOrderSum: action.minimalOrderSum,
      };
    case "API_FETCH_BANNERS":
      return {
        ...state,
        isBannersDataLoaded: true,
        banners: action.banners,
      };
    case "SET_SYSTEM_ERROR_MESSAGE":
      return {
        ...state,
        systemErrors: action.errors,
      };
    default:
      return state;
  }
}
