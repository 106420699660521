import React from "react";
import { ReactComponent as ArrowIcon } from "../../../images/right-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { addressToString } from "../../../helpers/stringOperations";
import { setAddressListModalVisible, setAddressModalVisible } from "../../../actions/cart";
import "./Address.scss";
import moment from "moment";

const AddressPanel = ({ date, registration, deliveryType = "DoorDelivery", resetValidation }) => {
  const pickupPoint = useSelector((state) => state.cart.pickupPoint?.address);
  const { addressData, ...info } = registration;
  const dispatch = useDispatch();
  const isDeliveryToDoor = deliveryType === "DoorDelivery";
  const deliveryDate = isDeliveryToDoor && `${formatDayName(date)} с 08:00 до 20:00`;
  const pickupDate = date ? `${formatDayName(moment(date?.date).format("dddd"))}, ${moment(date?.date).format("DD MMMM")} c ${moment.utc(date?.start).format("LT")} до ${moment.utc(date?.end).format("LT")}` : "Выберите дату и временной интервал для самовывоза"
  const deliveryDateTotal = isDeliveryToDoor ? deliveryDate : pickupDate;
  const activeAddress = { ...addressData, ...info };

  function formatDayName(day) {
    return day?.charAt(0).toUpperCase() + day?.slice(1);
  }

  const handleClick = () => {
    resetValidation();
    if (isDeliveryToDoor) {
      !addressData ? dispatch(setAddressModalVisible(true)) : dispatch(setAddressListModalVisible(true));
    } else {
      !pickupPoint ? dispatch(setAddressModalVisible(true)) : dispatch(setAddressListModalVisible(true));
    }

  };

  const renderAddress = () => {
    if (isDeliveryToDoor) {
      return !addressData ? "Выберите адрес доставки" : addressToString(activeAddress);
    } else {
      return addressToString(pickupPoint);
    }
  }

  return (
    <div className="address-panel" onClick={() => handleClick()}>
      <div className="address-panel-content">
        <div className="delivery-address">
          <span>{renderAddress()}</span>
        </div>
        <span className="delivery-date">{deliveryDateTotal} </span>
      </div>

      <div className="edit-address">
        <ArrowIcon />
      </div>
    </div>
  );
};

export default AddressPanel;
