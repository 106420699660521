import * as React from 'react';
import { ReactComponent as PhoneIcon } from '../../../images/phone.svg';
import InputWithHint from "../../commons/InputWithHint/InputWithHint";
import ConfirmationDialog from '../ConfirmationBar/ConfirmationDialog';
import { ReactComponent as UnconfirmedIcon } from '../../../images/exclamation.svg';
import { ReactComponent as ConfirmedIcon } from '../../../images/confirm.svg';
class PhoneChangeInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dialogVisible: false }
    }

    render() {
        const { title, value, onChange, confirmed, userPhone, onSubmitPhoneOrEmail } = this.props;
        const { dialogVisible } = this.state;
        const confirmationLabel = confirmed ? 'Телефон подтвержен' : 'Телефон пока не подтвержен';
        const icon = confirmed ? <ConfirmedIcon /> : <UnconfirmedIcon />;
        const customClass = confirmed ? "confirmed" : "unconfirmed";

        const onClose = (reset = false) => {

            this.setState({ dialogVisible: false });
            reset && onChange(userPhone);
        };

        const onShow = () => {
            this.setState({ dialogVisible: true });

        };
        return (<div className="special">

            <InputWithHint
                leftImg={<PhoneIcon />}
                // text={confirmationLabel}
                title={title}
                // customClass={customClass}
                value={value || "+7"}
                onFocus={() => onShow()}
                hintSide="top"
            // rightImg={icon} 
            />
            {dialogVisible && <ConfirmationDialog value={value} type="phone" onConfirm={onChange} onClose={(reset) => onClose(reset)} visible={dialogVisible} onSubmitPhoneOrEmail={onSubmitPhoneOrEmail} />}
        </div>
        )
    }
}

export default PhoneChangeInput;