import * as React from "react";
import "./Favorites.scss";
import EmptyFavoritesIcon from "../../../images/empty-data.png";

const EmptyProducts = ({ suppliers }) => {
  return (
    <div className="favorites centered">
      <div className="empty">
        <img src={EmptyFavoritesIcon} className="icon" alt="В Избранном пока ничего нет" />
        <h2>В Избранном пока ничего нет</h2>
        <p className="sub-header">Добавляйте {suppliers ? "поставщиков" : "товары"} в Избранное с помощью ❤️️</p>
      </div>
    </div>
  );
};

export default EmptyProducts;
