import {cancelableFetch} from "../helpers/rest";
import {getPriceListParams} from "../helpers/products";

export function clearSearchResults() {
  return {
    type: "CLEAR_SEARCH_RESULTS",
  };
}

export function setSearchText(searchText) {
  return {
    type: "SET_SEARCH_TEXT",
    searchText,
  };
}

function receiveSearch(result) {
  return {
    type: "RECEIVE_SEARCH_RESULTS",
    result,
  };
}

export function search(searchText) {
  return (dispatch, getState) => {
    dispatch(setSearchText(searchText));
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";

    return cancelableFetch(`/api/${prefix}/v2.0/catalog/products/display-window.search-in-header?query=${encodeURIComponent(searchText)}`, "GET", null, isLoggedIn).then((json) =>
      dispatch(receiveSearch(json))
    );
  };
}
