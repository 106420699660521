import { fetchGetBackend, fetchPostBackend } from "../helpers/rest";

function receiveUserSubscriptions(subscriptions) {
  return {
    type: "RECEIVE_SUBSCRIPTIONS",
    subscriptions,
  };
}

export function subscribeProduct(productId, userPhone = null, userEmail = null) {

  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const user = getState().profile.user;
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";
    const { email, phoneNumber } = user;
    const subsData = {
      subscriberId: userId,
      notificationType: "ProductDelivered",
      channels: ["Email", "Sms"],
      phoneNumber: userPhone || phoneNumber,
      email: userEmail || email,
      payload: { ProductId: productId },
      companyId: null
    };

    return fetchPostBackend(`/api/${prefix}/v2.0/notifier/internal/site/subscriptions.subscribe`, subsData, true);
  };
}

export function unSubscribeProduct(productId) {

  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";

    const subsData = {
      subscriberId: userId,
      notificationType: "ProductDelivered",
      payload: { ProductId: productId },
      companyId: null
    };

    return fetchPostBackend(`/api/${prefix}/v2.0/notifier/internal/site/subscriptions.unsubscribe
    `, subsData, true);
  };
}

export function checkProductSubscribtion(productId) {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const accountId = getState().profile.accountId;
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";

    return fetchGetBackend(`/api/${prefix}/v2.0/notifier/internal/site/subscriptions.check?SubscriberId=${userId}&NotificationType=ProductDelivered&ProductId=${productId}&CompanyId=${accountId}`, true);
  };
}


export function getUserSubscriptions() {
  return (dispatch, getState) => {
    const userId = getState().profile.userId;
    const isLoggedIn = getState().profile.loggedIn;
    const prefix = isLoggedIn ? "buyer" : "public";

    return fetchGetBackend(`/api/${prefix}/v2.0/notifier/internal/site/subscriptions?Filter=SubscriberId==${userId}`, true)
      .then((json) => dispatch(receiveUserSubscriptions(json)))
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
