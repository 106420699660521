import React, { useState } from "react";
import "./ProductsBanner.scss";
import Card from "../../Product/Card/Card";
import CardSkeleton from "../../Product/Card/Skeleton/Card";
import SliderButton from "../../commons/SliderButton/SliderButton";
import { Link, useHistory } from "react-router-dom";
import Button from '../../commons/Button/Button';
import classNames from "classnames";
import peppercoin from "../../../images/peppercoin.png"
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as ArrowIcon } from "../../../images/right-arrow.svg";

const ProductsBanner = ({ title, products, isMobile, isTablet, isDesktop, index, width, loaded, isBonusProducts = false, bonusBalance, url, promotedProducts }) => {
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();
  const showSliderButtons = !isMobile && !isTablet && products?.length > 4;

  const handleRedirect = () => {
    const params = promotedProducts ? "?HasPromotion=true" : url ? `/${url}` : "";
    history.push(`/catalog/customerProducts${params}`);
  }

  const handleHoverOn = () => {
    setIsHovered(true);
  };
  const handleHoverOff = () => {
    setTimeout(() => setIsHovered(false), 200);
  };

if(loaded && products?.length === 0) {
  return null;
}

  return (
    <div className={classNames("products-banner block", { container: !isMobile })} onPointerEnter={handleHoverOn} onPointerLeave={handleHoverOff}>
      <div className="title-bar">
        <div className="title-bar-left">
          <Link className="products-banner-title" to={`/catalog/customerProducts/${url ? url : ""}`} > {title} {isMobile ? <ArrowIcon/> : null} </Link>
          {isBonusProducts && <div className="products-banner-bonus-tag">
            <span>{`Доступно: ${bonusBalance || 0}`}</span>
            <img className="products-banner-bonus-icon" src={peppercoin} />

          </div>}
        </div>

        {!isBonusProducts && !isMobile && <div className='products-banner-button'>
          <Button title="Показать ещё" buttonSize="small" buttonType="subtle" width="100%" onClick={handleRedirect} />
        </div>
        }

      </div>
      <div className={`${"products-banner-items"} ${isBonusProducts ? "bonus-products" : ""}`}>
        <div className={`products-banner-items-button ${isHovered ? "showed" : ""}`}>   <SliderButton buttonType="prev" buttonColor="gray" index={index} hide={!showSliderButtons} /></div>
        <Swiper
          spaceBetween={5}
          slidesPerView={2.1}
          threshold={2}
          navigation={{
            prevEl: `.slider-btn-prev_${index}`,
            nextEl: `.slider-btn-next_${index}`,
          }}
          breakpoints={{
            440: {
              width: 440,
              slidesPerView: 2.5,
              spaceBetween: 5,
            },
            768: {
              width: 768,
              slidesPerView: 3.1,
              spaceBetween: 10,
            },
            1024: {
              width: 1140,
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >

          {!loaded &&
            [1, 2, 3, 4].map((i) => (
              <SwiperSlide key={`cart_${i}_skeleton`}>
                <CardSkeleton isMobile={isMobile} isTablet={isTablet} />
              </SwiperSlide>
            ))}
          {loaded &&
            products?.map((product, idx) => (
              <SwiperSlide key={`cart_${title}_${idx}`}>
                <Card product={product} isMobile={isMobile} isTablet={isTablet} bonusProduct={isBonusProducts} />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className={`products-banner-items-button next ${isHovered ? "showed" : ""}`}><SliderButton buttonType="next" buttonColor="gray" index={index} hide={!showSliderButtons} /></div>

      </div>
    </div>
  );
};

export default ProductsBanner;
