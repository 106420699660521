import React from 'react';
import { ReactComponent as BellIcon } from "../../../images/notification.svg";
import { ReactComponent as BellFilledIcon } from "../../../images/notification_filled.svg";
import Button from "../../commons/Button/Button";
import "./SubscribeProductButton.scss";

const SubscribeProductButton = ({ isSubscribed, onClick, title = "", loading, hasHint = true }) => {
  const comingSoonIcon = isSubscribed ? <BellFilledIcon /> : <BellIcon />;
  const buttonClass = title ? "subsc-product-button" : "subsc-product-button no-title";
  const hintText = isSubscribed ? "Отписаться от уведомлений по товару" : "Получить уведомление о поставке товара";

  return (
    <div className={buttonClass}>
      <Button title={title} buttonSize="large" buttonType="secondary" onClick={onClick} icon={comingSoonIcon} loading={loading} />
      {hasHint && <span className="subsc-product-button-hint">{hintText}</span>}
    </div>
  )
}

export default SubscribeProductButton