import React from 'react';
import Address from "../Address/Address";
import DeliveryArea from "../Registration/DeliveryArea/DeliveryArea";
import AddressDetails from "../Address/AddressDetails";
import Input from "../../commons/RamInput/Input";
import PhoneInput from "../../commons/RamInput/PhoneInput";
import EmailInput from "../../commons/RamInput/EmailInput";
import { ReactComponent as UserIcon } from "../../../images/user.svg";

const DeliveryAddressContent = ({ addressData, isLogged, showContacts, registration, validContacts, onChange }) => {
  return (
    <><Address />
      <DeliveryArea showIcon={false} />
      {!!addressData && <AddressDetails isMobile={true} />}

      {isLogged && showContacts && (
        <>
          <h3 className="address-modal-caption">Получатель</h3>
          <div className="address-modal-input">
            <Input
              title="Имя"
              leftImg={<UserIcon />}
              value={registration.recipientFullName}
              onChange={onChange("recipientFullName")}
              hasError={!validContacts.recipientFullName}
              errorText="Введите имя"
            />
          </div>
          <div className="address-modal-input">
            <PhoneInput
              title="Телефон"
              mobileNumber={registration.recipientPhone}
              onPhoneChange={onChange("recipientPhone")}
              hasError={!validContacts.recipientPhone}
              errorText="Введите корректный номер телефона"
            />
          </div>
          <div className="address-modal-input">
            <EmailInput
              title="E-mail"
              value={registration.recipientEmail}
              onChange={onChange("recipientEmail")}
              hasError={!validContacts.recipientEmail}
              errorText="Введите корректный адрес e-mail"
            />
          </div>
        </>
      )}
    </>
  )
}

export default DeliveryAddressContent