import * as React from "react";
import { ReactComponent as RefreshIcon } from "../../../images/refresh.svg";
import { getUUID } from "../../../helpers/utils";
import "./SecurityCodeInput.scss";
import { sendValidationCode, validateCode } from "../../../actions/profile";

export default class SecurityCodeInput extends React.Component {
  state = {
    invalidCode: "",
    isCodeValidation: false,
    expTime: null,
    code: [null, null, null, null],
  };

  codeInputs = {};
  interval = null;

  componentDidMount() {
    this.codeInputs[0].focus();
    this.initTimer();

    this.handleSendCode();
  }

  initTimer = () => {
    const expTime = new Date();

    expTime.setSeconds(expTime.getSeconds() + 6);
    this.setState({ expTime });

    this.interval = setInterval(this.intervalHandler, 500);
  };

  componentDidUpdate(prevProps, prevState) {
    const { invalidCode: invalidCodePrev } = prevState;
    const { invalidCode } = this.state;

    if (!invalidCodePrev && invalidCode) {
      this.codeInputs[0].focus();
      this.cleanCode();
      // this.handleSendCodeAgain();
    }
  }

  setRef = (key) => (ref) => {
    if (ref) {
      this.codeInputs[key] = ref;
    }
  };

  intervalHandler = () => {
    const { expTime } = this.state;

    if (new Date() - expTime > 0) {
      clearInterval(this.interval);
      this.setState({ expTime: null });
    } else {
      this.forceUpdate();
    }
  };

  cleanCode = () => {
    this.setState({ code: [null, null, null, null] });
  };

  validateCode = async () => {
    const { requestId, code } = this.state;
    const { onSuccess } = this.props;

    this.setState({ isCodeValidation: true });
    validateCode(requestId, code.join(""))
      .then((result) => {
        
        if (result.isValid) {
          onSuccess && onSuccess(code);
        } else {
          this.setState({ invalidCode: true, isCodeValidation: false });
        }
      })
      .catch((error) => {
        this.setState({ invalidCode: error.message, isCodeValidation: false });
      });
  };

  handleSendCodeAgain = (cleanInvalid) => {
    this.cleanCode();
    if (!!cleanInvalid) {
      this.setState({ invalidCode: false });
      this.initTimer();
    }
    this.handleSendCode();
  };

  handleSendCode = async () => {
    const { value, authType = "phone" } = this.props;
    const requestId = getUUID();
    this.codeInputs[0].focus();
    this.setState({ isCodeSending: true, requestId });
    sendValidationCode(requestId, authType, value).then(() => {
      this.setState({ isCodeSending: false });
    });
  };

  handleCodeChange = (key) => (e) => {
    const code = this.state.code;
    const number = e.target.value[e.target.value.length - 1];

    if (/[0-9]/.test(number)) {
      e.target.value = number;
      code[key] = number;
      this.setState({ code, invalidCode: "" });

      if (key < 3) {
        this.codeInputs[key + 1].focus();
      } else {
        this.validateCode();
      }
    } else {
      e.target.value = "";
    }
  };

  handleCodeKeyPress = (key) => (e) => {
    const { isCodeValidation, code } = this.state;

    if (e.keyCode === 8 && !isCodeValidation) {
      if (e.target.value.length) {
        e.target.value = "";
        code[key] = "";
      } else {
        this.codeInputs[key - 1].value = "";
        code[key - 1] = "";
      }
      this.setState({ code });

      if (!e.target.value.length && key > 0) {
        this.codeInputs[key - 1].focus();
      }
    }
  };

  handleChangeLogin = () => {
    const { onChangeLogin } = this.props;

    onChangeLogin && onChangeLogin();
  };

  render() {
    const { invalidCode, isCodeValidation, expTime, code } = this.state;
    const timer = expTime ? Math.floor((expTime - new Date()) / 1000) : null;

    return (
      <div className="code-input-form">
        <div className="code-inputs">
          {code.map((codeEl, key) => (
            <input
              autoComplete="new-password"
              disabled={isCodeValidation}
              key={key}
              className={codeEl ? "not-empty" : ""}
              value={codeEl !== null ? codeEl : ""}
              ref={this.setRef(key)}
              onKeyUp={this.handleCodeKeyPress(key)}
              onChange={this.handleCodeChange(key)}
            />
          ))}
        </div>
        {!!invalidCode && <div className="invalid-code">Неверный код</div>}
        {timer ? (
          <div className="send-code-again-timer">
            {`Запросить код повторно можно через`}
            <span>{`${timer > 0 ? timer : 0} секунд`}</span>
          </div>
        ) : (
          <div className="send-again-btn" onClick={this.handleSendCodeAgain}>
            <RefreshIcon /> <span>Выслать код повторно</span>
          </div>
        )}
      </div>
    );
  }
}
