import React from "react";
import "./MenuBar.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

const MenuBar = ({ activeSection, hasOrganisation, items, caption }) => {
  return (
    <div className="profile-menu-bar">
      {caption && <h3>{caption}</h3>}
      {items.map((item, i) => {
        if (item.name === "organisation" && !hasOrganisation) {
          return null;
        }
        return (
          <div
            key={`item_${i}`}
            className={classNames({
              active: activeSection === item.name,
              "menu-item": true,
            })}
          >
            <Link className="btn-link" to={`/${item.name}`}>
              {item.caption}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default MenuBar;
