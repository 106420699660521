import * as React from "react";
import "./Supplier.scss";
// import { withRouter } from "react-router";
import { stringToColour, removeLegalPrefix, getAbbreviation, declOfNum } from "../../../../helpers/stringOperations";
import { Link } from "react-router-dom";
import { ReactComponent as CartIcon } from "../../../../images/shop.svg";
// import { ReactComponent as StarFullIcon } from "../../../../images/star-full.svg";
import FavoriteButton from "../../FavoriteButton/FavoriteButton";
import { isNil } from "ramda";

const Supplier = ({ supplier, history, reviewsCount = 0, products = {} }) => {
  if (!supplier) return null;

  const { productTotalCount, tradeName, legalName, iconUrl, id } = supplier;
  const name = tradeName || removeLegalPrefix(legalName);
  const acronym = getAbbreviation(name);
  const productsCount = !isNil(productTotalCount) ? productTotalCount : products?.length || 0;
  return (
    <Link className="product-supplier" to={`/catalog/suppliers/${id}`}>
      <div className="top">
        {!iconUrl && (
          <div className="avatar empty" style={{ backgroundColor: stringToColour(acronym) }}>
            {acronym}
          </div>
        )}
        {iconUrl && <img className="avatar" src={iconUrl} alt={tradeName} />}
        <FavoriteButton supplier={supplier} />
      </div>
      <h4>{name}</h4>
      <div className="part-bottom">
        <div className="products-count">
          <CartIcon />
          <div className="count-wrapper">
            <div className="count">{productsCount}</div>
            <div className="label">{declOfNum(productsCount, ["Товар", "Товара", "Товаров"])}</div>
          </div>
        </div>
        {/* <div className="rating">
          <StarFullIcon />
          {reviewsCount} из 5
        </div> */}
      </div>
    </Link>
  );
};

export default Supplier;

// export default withRouter(Supplier);
