import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddEdoOperatorModal.scss";
import DialogModal from "../../../Profile/DialogModal/DialogModal";
import SimpleModal from "../../../Profile/SimpleModal/SimpleModal";
import { getEdoProviderOptions, addEdoInfo, updateEdoInfo, deleteEdoInfo } from "../../../../actions/profile";
import DropDown from "../../../commons/DropDown/DropDown";
import CheckBox from "../../../commons/CheckBox/CheckBox";
import { Gap } from "../../../commons/Gap";

const CompleteModal = ({ result, onClose }) => {
  const header = "Обмен по ЭДО";
  const message = "Оператор ЭДО выбран";
  return <SimpleModal visible={!!result} onClose={onClose} header={header} message={message} />;
};

const AddEdoOperatorModal = ({ visible, onClose, company }) => {
  const dispatch = useDispatch();

  const [edoOperator, setEdoOperator] = useState(null);
  const [useEdo, setUseEdo] = useState(null);
  const [edoOptions, setEdoOptions] = useState(null);
  const [saveCompleteResult, setSaveCompleteResult] = useState(null);
  const [errorText, setErrorText] = useState("");
  const edoInfo = useSelector((state) => state.profile.edoInfo?.results[0]);


  useEffect(() => {
    setEdoOperator(edoInfo?.providerType.code)
    setUseEdo(company?.useEdo)
    dispatch(getEdoProviderOptions()).then((res) => {
      const data = Object.entries(res.values).map((item) => {
        return {
          text: item[1],
          value: item && item[0],
        };
      });
      setEdoOptions(data);
    });
  }, [visible]);


  const finishAction = (result) => {
    setSaveCompleteResult(result);
    onClose();
  };

  const handleUseEdo = () => {
    setUseEdo(!useEdo)
  }

  const onSubmitCompany = () => {
    const data = {
      companyId: company.id,
      providerType: edoOperator,
    }
    if (edoInfo && edoInfo.id && !useEdo) {
      dispatch(deleteEdoInfo(edoInfo.id)).then(() => finishAction(true));
    } else if (edoInfo && edoInfo.id) {
      delete data.companyId;
      dispatch(updateEdoInfo(edoInfo.id, data)).then(() => finishAction(true));
    } else {
      dispatch(addEdoInfo(data)).then(() => finishAction(true)).catch((err) => {
        if (err.errors) {
          setErrorText(err.errors[0].value)
        } else setErrorText(err.message)
      });
    }
  };

  const onSelect = (value) => {
    setEdoOperator(value)
  };

  const header = "Обмен по ЭДО";
  const submitButtonTitle = "Сохранить";

  return (
    <div className="add-my-company">
      <DialogModal visible={visible} onClose={onClose} onSubmit={onSubmitCompany} header={header} buttonOkTitle={submitButtonTitle} >
        <CheckBox checked={useEdo} label="Обмениваться документами по ЭДО" onChange={handleUseEdo} />
        <Gap size={20} />
        <DropDown
          title="Выберите оператора ЭДО"
          value={edoOperator}
          onChange={(value) => onSelect(value)}
          selectValues={edoOptions}
          hasError={!!errorText}
          errorText={errorText}
        />
      </DialogModal>
      <CompleteModal result={saveCompleteResult} onClose={() => setSaveCompleteResult(null)} />
    </div>
  );
};

export default AddEdoOperatorModal;
