import React from "react";
import "./Tooltip.scss";

function Tooltip({ text, linkText, url }) {
  return (
    <div className="tooltip">
      <div className="tooltip-content">
        <p className="tooltip-text">
          {text}
        {url && <a className="tooltip-link" href={url} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>}
          .
        </p>
      </div>
    </div>
  );
}

export default Tooltip;
