const env = process.env.REACT_APP_BRANCH || "dev";
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const ymAccountId = process.env.REACT_APP_YM_ACCOUNT_ID || 86537010;

const baseUrl = process.env.REACT_APP_BASE_URL || `https://${env}-mp.ram-it.pro`;
const authUrl = process.env.REACT_APP_AUTH_MP_URL || "https://dev-lk.ram-it.pro/auth/marketplace";
const authUrlForSupplier = process.env.REACT_APP_AUTH_LK_URL || "https://dev-lk.ram-it.pro/auth/automation";
const documentLinkUrl = "https://rusagro.market"

const hostname = new URL(baseUrl).hostname;

const businessAccountsOnly = !!JSON.parse(process.env.REACT_APP_BUSINESS_ACCOUNT_ONLY || false);

const isLocalHost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

const mainEmailForLetters = "b.savkov@rusagromarket.ru";

export default global.config = {
  env,
  clientSecret,
  isProd: env === "prod",
  authUrl: authUrl,
  authUrlForSupplier: authUrlForSupplier,
  documentLinkUrl,
  baseUrl,
  domain: hostname.match(/\./g).length >= 2 ? `.${hostname.split(".").slice(-2).join(".")}` : hostname,
  completeRegistrationURI: "/profile/dashboard",
  businessAccountsOnly,
  isLocalHost,
  ymAccountId,
  pagination: {
    maxPages: 6,
    pageSize: 42,
  },
  mainEmailForLetters
};
