import React from 'react';
import PickUpPoint from './PickUpPoint';
import "./PickUpPointsList.scss";
import { Gap } from '../../commons/Gap';

const MOCK_PICK_UP_POINTS = [
  {
    id: 1,
    title: "ОРЦ Новосибирск",
    address: "Населённый пункт 3307 километр, улица 3307 км, 30кВ1",
    link: "https://2gis.ru/novosibirsk/firm/70000001045915289/82.540245%2C55.017537?m=82.543593%2C55.017513%2F16"
  }
]

const PickUpPointsList = ({ selectedPoint, selectPoint, pointsList }) => {
  const renderPickUpPoints = pointsList?.results?.map((item) => {
    const isSelected = selectedPoint?.id === item.id;
    return <PickUpPoint key={item.id} id={item.id} name={item.name} address={item.address} link={MOCK_PICK_UP_POINTS[0].link} selected={isSelected} selectPoint={selectPoint} />
  })

  return (<>
    <ul className='pick-up-points-list'>{renderPickUpPoints}</ul>
    <Gap size={10} />
  </>
  )
}

export default PickUpPointsList