import * as React from "react";

// const stringToColour = str => {
//   var hash = 0;
//   for (var i = 0; i < str.length; i++) {
//     hash = str.charCodeAt(i) + ((hash << 5) - hash);
//   }
//   var colour = "#";
//   for (var i2 = 0; i2 < 3; i2++) {
//     var value = (hash >> (i2 * 8)) & 0xff;
//     colour += ("00" + value.toString(16)).substr(-2);
//   }
//   return colour;
// }

const NoAvatar = (props) => {
  const { firstName, lastName, short } = props;

  const letters =
    (firstName || " ").charAt(0).toUpperCase() + (lastName || " ").charAt(0).toUpperCase();
  // stringToColour(letters)
  return (
    <div
      style={{
        backgroundColor: "#33AEFF",
      }}
      className="avatar empty"
    >
      {short ? letters.charAt(0) : letters}
    </div>
  );
};

export default NoAvatar;
