import * as React from 'react'
import './Price.scss';
import peppercoin from "../../../../images/peppercoin.png"
import { separateThousands, truncNumber, decimalPartNumber } from '../../../../helpers/stringOperations';
import classNames from 'classnames';

const Price = (props) => {
    const { product, decimal = true, unit = true, bonusProduct = false, galleryView = false, promotedProduct = false } = props;
    
    let measurement = product.currency ? product.currency.shortTitle : "₽";
    measurement += unit ? product.saleUnit ? `/${product.saleUnit}` : '/кг' : "";
  
    return (
        <>
       {!!promotedProduct && <span className="product-old-price">{`${separateThousands(truncNumber(product.oldPrice))} ${measurement}`}</span>}
        <div className="product-price">
            {separateThousands(truncNumber(product.price))}
            {decimal && <span className={`decimal ${galleryView ? "galleryView" : ""}`}>{decimalPartNumber(product.price)}</span>}
            <span className={classNames({
                "measurement": true,
                "decimal": decimal,

            })}>
                {!bonusProduct && measurement}
                {bonusProduct && <img className='product-price-bonus-icon' src={peppercoin} alt="Иконка перецкойна" />}
            </span></div>
            </>
    );
}

export default Price;