import * as React from "react";
import "./FavoriteButton.scss";
import classNames from "classnames";
import { ReactComponent as HearIcon } from "../../../images/heart.svg";
import { ReactComponent as HearFilledIcon } from "../../../images/heart-filled.svg";
import Hint from "../../commons/Hint/Hint";
import { useSelector, useDispatch } from "react-redux";
import { addFavoriteProduct, addFavoriteSupplier, removeFavoriteProduct, removeFavoriteSupplier } from "../../../actions/product";
import { redirectToLogin } from "../../../helpers/cookies";

const FavoriteButton = ({ disabled, squared, product, supplier, hasHint = true }) => {
  const dispatch = useDispatch();

  const productMode = !!product;

  const isFavorited = useSelector(
    (state) => (productMode ? state.products.favoriteProductIds.indexOf(product.id) : state.products.favoriteSupplierIds.indexOf(supplier.id)) !== -1
  );
  const loggedIn = useSelector((state) => state.profile.loggedIn);

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation()
    
    if (!loggedIn) {
      redirectToLogin();
      return;
    }
    if (disabled) return;

    if (productMode) {
      return dispatch(!isFavorited ? addFavoriteProduct(product) : removeFavoriteProduct(product));
    } else {
      return dispatch(!isFavorited ? addFavoriteSupplier(supplier) : removeFavoriteSupplier(supplier));
    }
  };

  return (
    <div
      className={classNames("favorites-button", {
        squared,
        active: isFavorited,
        disabled,
      })}
      onClick={(e) => onClick(e)}
    >
      <Hint text="Добавить в избранное" icon={isFavorited ? <HearFilledIcon /> : <HearIcon />} disabled={hasHint} />
    </div>
  );
};

export default FavoriteButton;
