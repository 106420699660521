import * as React from "react";
import "./SideBar.scss";
import classNames from "classnames";
import {ReactComponent as CloseIcon} from "../../../images/close.svg";
import {getWindowHeight} from "../../../helpers/utils";
class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const {active, toggleSideBar} = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && active) {
      toggleSideBar();
    }
  }

  updateStyle = () => {
    const vh = getWindowHeight();
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  componentDidMount() {
    this.updateStyle();
    window.addEventListener("resize", this.updateStyle);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateStyle);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const {active} = this.props;
    if (active !== prevProps.active) {
      if (active) {
        document.body.style.position = "static";
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden";
      } else {
        document.body.style.position = "relative";
        document.body.style.overflow = "visible";
        document.documentElement.style.overflow = "visible";
      }
    }
  }

  render() {
    const {active, children, toggleSideBar, specialClass = "default", noCloseButton = false} = this.props;

    return (
      <div className={classNames({"sidebar-menu": true, active: active, [specialClass]: true})} ref={this.setWrapperRef}>
        {!noCloseButton && (
          <div className="close-btn" onClick={() => toggleSideBar()}>
            <CloseIcon />
          </div>
        )}
        <div className="content">{children}</div>
      </div>
    );
  }
}
export default SideBar;
