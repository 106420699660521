import * as React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchFakeLogin } from "../../actions/profile";

class Fake extends React.Component {
  componentDidMount() {
    const { fetchFakeLogin, history } = this.props;
    fetchFakeLogin().then(() => history.push("/"));
  }

  render() {
    return (<div>
      Fake login. Redirecting back...
    </div>);
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchFakeLogin: fetchFakeLogin,
}, dispatch);

export default connect(
  () => ({}),
  mapDispatchToProps
)(Fake);