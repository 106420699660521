import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./OrganisationInfo.scss";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";
import { ReactComponent as RoundCheckIcon } from "../../../images/round-check.svg";
import InputWithHint from "../../commons/InputWithHint/InputWithHint";
import Button from "../../commons/Button/Button";
import { getAccount, updateAccount } from "../../../actions/profile";
import FillableDropDown from "../../commons/FillableDropDown/FillableDropDown";
import { searchCompany, searchBankAccount } from "../../../actions/profile";
import { setWaitForAccountAccomplishFlag } from "../../../actions/cart";
import { validationMessages } from "../../../constants";
import classNames from "classnames";
import { pickAll, isNil, isEmpty } from "ramda";
import EditWrapper from "../EditWrapper/EditWrapper";
import PhoneInput from "../../commons/RamInput/PhoneInput";
import { REQUIRED_FIELDS, REQUIRED_FIELDS_SELF_EMP } from "../../../constants";
import { Gap } from "../../commons/Gap";
import { setSystemErrorMessage } from "../../../actions/api";

class OrganisationInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      editAccount: null,
      updateAccountData: {},
      editMode: true,
      isActive: false,
      errors: {},
    };
  }

  componentDidMount() {
    this.init();
  }

  init(forced = false) {
    const { accountId, account, getAccount } = this.props;
    if (accountId) {
      if (!account || forced) {
        getAccount().then((respAccount) => {

          this.setEditAccount(respAccount);
        });
      } else {
        this.setEditAccount(account);
      }
    }
  }

  setEditAccount = (account) => {
    const { editMode } = this.state;
    const selfEmployedPerson = account?.companyType.code === "SelfEmployedPerson";
    const reqFields = selfEmployedPerson ? REQUIRED_FIELDS_SELF_EMP : REQUIRED_FIELDS;
    const editAccount = pickAll(reqFields, account);
    const isActive = account.status && account.status.code === "Active";
    const newEditMode = !isActive || editMode;
    const selfEmployedCeo = account.legalName.replace("ИП ", "");
    this.setState({ editAccount: { ...editAccount, ceoFullName: selfEmployedPerson ? selfEmployedCeo : account.ceoFullName }, isActive, editMode: newEditMode });
  };

  handleFieldChange = (fieldName) => (value) => {
    const { editAccount, updateAccountData, errors } = this.state;
    editAccount[fieldName] = value;
    updateAccountData[fieldName] = value
    delete errors[fieldName];

    this.setState({ editAccount, updateAccountData, errors });
  };

  companySearchRequest = (value) => {
    const { accountId } = this.props;

    return searchCompany(value, accountId);
  };

  bankAccountSearchRequest = (value) => {
    return searchBankAccount(value);
  };

  validate = () => {
    const { account } = this.props;
    const selfEmployedPerson = account?.companyType.code === "SelfEmployedPerson";
    const reqFields = selfEmployedPerson ? REQUIRED_FIELDS_SELF_EMP : REQUIRED_FIELDS;
    const { editAccount } = this.state;

    const errors = {};
    reqFields.forEach((name) => {
      if (isNil(editAccount[name]) || isEmpty(editAccount[name])) {
        errors[name] = validationMessages.required;
      }
    });

    this.setState({ errors });
    return isEmpty(errors);
  };

  save = (e) => {
    const { updateAccount, waitForAccountAccomplishFlag, setWaitForAccountAccomplishFlag, history, setSystemErrorMessage } = this.props;
    const { updateAccountData } = this.state;

    e.preventDefault();
    if (!this.validate()) return;

    updateAccount(updateAccountData).then((res) => {
      if (res?.isUpdated) {
        this.init(true);
        this.setEditMode(false);
        setSystemErrorMessage()

        if (waitForAccountAccomplishFlag) {
          setWaitForAccountAccomplishFlag(false);

          history.push("/cart?finalizeOrder=true"); //finalize order
        }
      }

    });
  };

  cancel = () => {
    this.init(true);
    this.setEditMode(false);
  };

  onChangeCompany = (selectName, data) => {
    const fillableFields = ["inn", "kpp", "legalName", "legalAddress"];
    fillableFields.forEach((name) => {
      if (data[name] !== undefined) {
        this.handleFieldChange(name)(data[name]);
      }
    });
  };

  onChangeBankAccount = (selectName, data) => {
    const fillableFields = ["bankAccount", "correspondentAccount", "bankFullName", "bic"];

    fillableFields.forEach((name) => {
      if (data[name] !== undefined) {
        this.handleFieldChange(name)(data[name]);
      }
    });
  };

  setEditMode = (editMode) => {
    this.setState({ editMode: editMode, errors: {} });
  };

  render() {
    const { accountId, account, isMobile, systemErrors } = this.props;
    const { editMode, editAccount, isActive, errors } = this.state;
    const { companyType } = account;
    const selfEmployedPerson = companyType.code === "SelfEmployedPerson";

    if (!accountId) {
      return "У пользователя отсутсвует организация";
    }

    if (!editAccount) {
      return null;
    }

    const { legalName, legalAddress, inn, kpp, bankAccount, correspondentAccount, bankFullName, bic, ceoFullName, phoneNumber } = editAccount;
    const saveButtonCaption = isActive ? "Сохранить" : "Завершить регистрацию";
    const cancelButtonCaption = isActive ? "Отмена" : "Завершить позже";

    const renderCompanyDropDownText = (item) => (
      <div>
        <div className="main">{item.legalName}</div>
        <div>
          <span>ИНН: {item.inn}</span>
          {item.kpp && <span>, КПП: {item.kpp}</span>}
        </div>
      </div>
    );

    const renderBankDropDownText = (item) => (
      <div>
        <div className="main">{item.bankFullName}</div>
        <div>{item.bic}</div>
      </div>
    );
    return (
      <form className="organisation">
        <header className="organisation-header">
          <h2 className="organisation-title">Информация о компании</h2>
          {!editMode && (
            <span className="saved-label">
              <RoundCheckIcon />
              Данные сохранены
            </span>
          )}
        </header>

        {editMode && (
          <div className="description">
            <div className="description-icon">
              <InfoIcon />
            </div>

            <span>Указываются строго в соответствии с учредительными документами организации, которая является плательщиком и получателем заказа.</span>
          </div>
        )}
        <div className="organisation-rows">
          <div
            className={classNames("row-line", {
              w100: isMobile,
              w50: !isMobile,
            })}
          >
            <EditWrapper editMode={editMode} caption="ИНН" value={inn}>
              <FillableDropDown
                // text={hintText}
                title="ИНН*"
                value={inn}
                selectName="inn"
                searchRequest={this.companySearchRequest}
                onChange={this.onChangeCompany}
                renderItemText={renderCompanyDropDownText}

                errors={errors}
                max="10"
                withHint={false}
                hasError={!!systemErrors?.find((item) => item.key === "inn")}
                errorText={systemErrors?.find((item) => item.key === "inn")?.value}
              />
            </EditWrapper >
            {!selfEmployedPerson && <EditWrapper editMode={editMode} caption="КПП" value={kpp}>
              <InputWithHint
                // text={hintText}
                title="КПП*"
                value={kpp}
                onChange={this.handleFieldChange("kpp")}
                errors={errors}
                name="kpp"
                max={9}
                hasError={!!systemErrors?.find((item) => item.key === "kpp")}
                errorText={systemErrors?.find((item) => item.key === "kpp")?.value}
              />
            </EditWrapper>}
          </div>

          {
            <div
              className={classNames("row-line", {
                w100: isMobile,
                w50: !isMobile,
              })}
            >
              <EditWrapper editMode={editMode} caption="Юридическое наименование" value={legalName}>
                <InputWithHint
                  // text={hintText}
                  title="Юридическое наименование*"
                  value={legalName}
                  onChange={this.handleFieldChange("legalName")}
                  errors={errors}
                  name="legalName"
                  hasError={!!systemErrors?.find((item) => item.key === "legalName")}
                  errorText={systemErrors?.find((item) => item.key === "legalName")?.value}

                />
              </EditWrapper>

              <EditWrapper editMode={editMode} caption="ФИО Ген. директора" value={ceoFullName}>
                <InputWithHint
                  // text={hintText}
                  title="ФИО Ген. директора*"
                  value={ceoFullName}
                  onChange={this.handleFieldChange("ceoFullName")}
                  errors={errors}
                  name="ceoFullName"
                  hasError={!!systemErrors?.find((item) => item.key === "ceoFullName")}
                  errorText={systemErrors?.find((item) => item.key === "ceoFullName")?.value}
                />
              </EditWrapper>
            </div>
          }

          <div
            className={classNames("row-line", {
              w100: isMobile,
              w50: !isMobile,
            })}
          >
            <EditWrapper editMode={editMode} caption="Адрес местонахождения" value={legalAddress}>
              <InputWithHint
                // text={hintText}
                title="Адрес местонахождения*"
                value={legalAddress}
                onChange={this.handleFieldChange("legalAddress")}
                name="legalAddress"
                errors={errors}
                hasError={!!systemErrors?.find((item) => item.key === "legalAddress")}
                errorText={systemErrors?.find((item) => item.key === "legalAddress")?.value}
              />
            </EditWrapper>

            <EditWrapper editMode={editMode} caption="Телефон организации" value={phoneNumber}>
              <PhoneInput title="Телефон организации*" mobileNumber={phoneNumber} onPhoneChange={this.handleFieldChange("phoneNumber")} name="phoneNumber"
                hasError={!!systemErrors?.find((item) => item.key === "phoneNumber")}
                errorText={systemErrors?.find((item) => item.key === "phoneNumber")?.value}

              />
            </EditWrapper>
          </div>

          <h3 className="row-line-title">Банковские реквизиты</h3>
          <div
            className={classNames("row-line", {
              w100: isMobile,
              w50: !isMobile,
            })}
          >
            <EditWrapper editMode={editMode} caption="Расчетный счет" value={bankAccount}>
              <InputWithHint
                // text={hintText}
                title="Расчетный счет*"
                value={bankAccount}
                onChange={this.handleFieldChange("bankAccount")}
                name="bankAccount"
                errors={errors}
                max="20"
                hasError={!!systemErrors?.find((item) => item.key === "bankAccount")}
                errorText={systemErrors?.find((item) => item.key === "bankAccount")?.value}
              />
            </EditWrapper>
            <EditWrapper editMode={editMode} caption="БИК банка" value={bic}>
              <FillableDropDown
                // text={hintText}
                title="БИК банка*"
                value={bic}
                selectName="bic"
                searchRequest={this.bankAccountSearchRequest}
                onChange={this.onChangeBankAccount}
                renderItemText={renderBankDropDownText}
                name="bic"
                errors={errors}
                max="9"
                withHint={false}
                hasError={!!systemErrors?.find((item) => item.key === "bic")}
                errorText={systemErrors?.find((item) => item.key === "bic")?.value}
              />
            </EditWrapper>
          </div>
          <div
            className={classNames("row-line", {
              w100: isMobile,
              w50: !isMobile,
            })}
          >
            <EditWrapper editMode={editMode} caption="Корреспондентский счет" value={correspondentAccount}>
              <InputWithHint
                title="Корреспондентский счет*"
                value={correspondentAccount}
                onChange={this.handleFieldChange("correspondentAccount")}
                errors={errors}
                max="20"
                hasError={!!systemErrors?.find((item) => item.key === "correspondentAccount")}
                errorText={systemErrors?.find((item) => item.key === "correspondentAccount")?.value}
              />
            </EditWrapper>
            <EditWrapper editMode={editMode} caption="Название банка" value={bankFullName}>
              <FillableDropDown
                // text={hintText}
                title="Название банка*"
                value={bankFullName}
                selectName="bankFullName"
                searchRequest={this.bankAccountSearchRequest}
                onChange={this.onChangeBankAccount}
                renderItemText={renderBankDropDownText}
                errors={errors}
                withHint={false}
                hasError={!!systemErrors?.find((item) => item.key === "bankFullName")}
                errorText={systemErrors?.find((item) => item.key === "bankFullName")?.value}

              />
            </EditWrapper>
          </div>
          {editMode && (
            <div className="attention">
              <div>
                <b>Будьте внимательны!</b> Введенные реквизиты будут транслироваться в Счет-фактуру и Товарную накладную. Изменение ИНН не разрешается во избежание ошибок в
                бухгалтерских документах. Если вы хотите сделать заказ от имени другой организации, просим вас оформить новую регистрацию юрлица.
              </div>
              <div>
                Если у вашей фирмы сменился ИНН — мы изменим его на сайте после получения сканкопии информационного письма о смене реквизитов на фирменном бланке вашей компании.
              </div>
            </div>
          )}
        </div>
        {editMode && (
          <div className="action-buttons">
            <div className="edit-buttons">
              <Button title={saveButtonCaption} width={isActive ? "149px" : "246px"} onClick={(e) => this.save(e)} buttonSize="medium" buttonType="primary" />
              {isActive && (
                <div className="cancel-button" onClick={() => this.cancel()}>
                  {cancelButtonCaption}
                </div>
              )}
            </div>
          </div>
        )}
        {/* {!editMode && (
          <div className="action-buttons active">
            <div className="edit-buttons">
              <Button title="Редактировать данные" width="230px" onClick={() => this.setEditMode(true)} buttonSize="medium" buttonType="primary" />
            </div>
          </div>
        )} */}
        {!editMode && (
          <div className="attention last">
            <div>
              Реквизиты будут транслироваться в Счет-фактуру и Товарную накладную. Изменение ИНН не разрешается во избежание ошибок в бухгалтерских документах. Если вы хотите
              сделать заказ от имени другой организации, просим вас оформить новую регистрацию юрлица.
            </div>
            <Gap size={10} />
            <div>
              Если у вашей фирмы сменился ИНН — мы изменим его на сайте после получения сканкопии информационного письма о смене реквизитов на фирменном бланке вашей компании.
            </div>
          </div>
        )}
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.profile.account,
  accountId: state.profile.accountId,
  waitForAccountAccomplishFlag: state.cart.waitForAccountAccomplishFlag,
  isMobile: state.api.isMobile,
  systemErrors: state.api.systemErrors?.errors
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccount: getAccount,
      updateAccount: updateAccount,
      searchCompany: searchCompany,
      searchBankAccount: searchBankAccount,
      setWaitForAccountAccomplishFlag: setWaitForAccountAccomplishFlag,
      setSystemErrorMessage: setSystemErrorMessage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationInfo);
