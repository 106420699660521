import React from "react";
import "./About.scss";
import { ReactComponent as WarehouseIcon } from "../../../images/warehouse.svg";
import { ReactComponent as TelegaIcon } from "../../../images/telega.svg";
import { ReactComponent as LocationIcon } from "../../../images/location-blue.svg";
import Faq from "./Faq/Faq";
import classNames from "classnames";
const { env } = global.config;

const About = ({ isMobile, isTablet }) => {
  const isDacnhik = env === "dachnik";

  const renderInfo = (icon, title, description, bgColor) => (
    <div className="info-item">
      <div
        className={classNames("info-icon", {
          [`${bgColor}`]: bgColor,
        })}
      >
        {icon}
      </div>
      <h3>{title}</h3>
      <div className="desc">{description}</div>
    </div>
  );

  return (
    <div className="about">
      <div className={classNames("about-area", { container: !isMobile })}>
        <h2>{isDacnhik ? "Наши преимущества" : "О сервисе"}</h2>
        <div className="about-info">
          {renderInfo(
            <WarehouseIcon />,
            isDacnhik ? "Обеспечиваем свежесть и качество" : "Гарантия качества и свежести",
            isDacnhik ? "Мы гарантируем быструю и бережную доставку непосредственно от проверенных садоводов и огородников. Поэтому клиенты получают только качественные и свежие овощи и фрукты." : "Мы контролируем соблюдение «холодной цепочки» на всех этапах хранения и доставки за счет собственной логистической экосистемы ОРЦ."
          )}
          {renderInfo(
            <TelegaIcon />,
           isDacnhik ? "Доставляем заказ от нескольких садоводов и огородников" : "Сборный заказ от нескольких поставщиков",
           isDacnhik ? "Благодаря нам, клиенты могут приобрести продукцию сразу у нескольких садоводов и огородников: оформляется один заказ, который мы доставим до покупателя по частям." : `С единой минимальной суммой заказа и доставкой по цене ниже, чем у сторонних логистических операторов.`,
            "green"
          )}
          {renderInfo(<LocationIcon />,
           isDacnhik ? "Работаем во всех регионах России" : "Доставка по Сибирскому региону",
           isDacnhik ? "Мы осуществляем доставку свежих овощей и фруктов на дом или в офис во всех регионах России." : "Мы осуществляем доставку в радиусе 500км от Новосибирска.", "maxblue")}
        </div>
        {/* <AppPromo /> */}
        <div className="faq ">
          <h2 className="faq-title">Частые вопросы</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
};

export default About;
