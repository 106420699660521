import * as React from "react";
import "./Card.scss";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Actions from "./Actions/Actions";
import Package from "./Package/Package";
import { withRouter } from "react-router";
import Price from "./Price/Price";
import { Link } from "react-router-dom";
import { getGalleryMainImage } from "../../../helpers/utils";
import FavoriteButton from "../FavoriteButton/FavoriteButton";
import { find, propEq } from "ramda";
import { subscribeProduct, unSubscribeProduct } from "../../../actions/subscriptions";
import CardTag from "./CardTag/CardTag";
import SubscribeProductButton from "../SubscribeProductButton/SubscribeProductButton";
import moment from "moment";
import { PROMOTED_PRODUCTS } from "../../../constants";

const { env } = global.config;
const isDacnhik = env === "dachnik";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItem: null,
      isSubscribed: false,
      isSubscribing: false
    };
  }

  componentDidMount() {
    const { product, subscriptions } = this.props;
    this.setCartItem();
    const subscribedProduct = subscriptions?.filter((item) => item.notificationType === "ProductDelivered").find((item) => item.payload.ProductId === product.id);
    this.setState({ isSubscribed: subscribedProduct });
  }

  componentDidUpdate(prevProps, prevState) {
    const { cartItems } = this.props;
    if (prevProps.cartItems !== cartItems) {
      this.setCartItem();
    }

  }

  onCardClick = (e) => {
    e.stopPropagation();
    const { history, product } = this.props;
    const productUri = `/catalog/product/${product.urlSegment}`;
    history.push(productUri);
  };

  setCartItem() {
    const { product, cartItems } = this.props;
    const cartItem = cartItems ? find(propEq("productId", product.id), cartItems.items) || null : null;

    this.setState({
      cartItem,
    });
  }

  handleSubscribeProduct = () => {
    const { subscribeProduct, unSubscribeProduct, product, loggedIn } = this.props;
    const { isSubscribed } = this.state;

    if (!loggedIn) {
      window.location.href = global.config.authUrl;
    } else {
      this.setState({ isSubscribing: true });
      isSubscribed ? unSubscribeProduct(product.id).then((res) => {
        res?.isUpdated && this.setState({ isSubscribing: false, isSubscribed: false });
      }).catch(() => this.setState({ isSubscribing: false }))
        : subscribeProduct(product.id).then((res) => {
          res?.isUpdated && this.setState({ isSubscribing: false, isSubscribed: true });
        }).catch(() => this.setState({ isSubscribing: false }));
    }
  }

  renderCards = (product, preview) => {
    const productUri = `/catalog/product/${product.urlSegment}`;
    const ownerUri = `/catalog/suppliers/${product.ownerId}`;
    const { isMobile, isTablet, simple, bonusProduct } = this.props;
    const { cartItem, isSubscribed, isSubscribing } = this.state;
    const bottomClass = cartItem ? "bottom column-view" : "bottom";
    const productIsComingSoon = product.status.code === "ComingSoon";
    const formatedDate = product?.tags && moment(product?.tags[0]?.data?.EndDate).format("DD MMMM");
    const formatedDateMobile = product?.tags && moment(product?.tags[0]?.data?.EndDate).format("DD MMM");
    const cardCategory = product?.categories && product?.categories[0]?.categoryId1 === "4a0f042a-23a7-472b-a914-6dc7cc5e147c" || product?.categories && product?.categories[0]?.categoryId1 === "f17cb926-7f2b-4af2-abd3-653c3d4969d5";
    const discountAmount = product?.tags && Math.trunc(product?.tags[0]?.data?.DiscountPercent);
    const price = product?.prices?.filter((item) => item.isSystem).map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value;
    const promotedPrice = product?.prices?.filter((item) => !item.isSystem).map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value;
    const promotedProduct = product?.tags && product.tags.length > 0 && !!promotedPrice;
    const hasCustomTag = product?.tags && product?.tags[0]?.type?.code === "Custom";
    const customTagTitle = product?.tags && product?.tags[0]?.title;

    const imageClass = () => {
      if (productIsComingSoon) {
        return " image coming-soon";
      } else if (cartItem) {
        return "image small";
      } else return "image"

    }

    return (
      <div className="product-card" >
        <FavoriteButton product={product} hasHint={simple} />
        <div>
          <Link to={productUri}>
            {preview && (
              <div className="product-card-image-wrap">
                <img className={imageClass()} src={preview} alt={product.title} onClick={this.onCardClick} />
                <div className="product-card-tags">
                  {cardCategory && <CardTag text="Опт" type="bulk" />}
                  {!!productIsComingSoon && <CardTag text="Ожидается" date={!isMobile ? formatedDate : formatedDateMobile} type="coming-soon" />}
                  {!!hasCustomTag && <div className="product-card-tags-column"> <CardTag text={customTagTitle} /> </div>}
                  {!!promotedProduct && !!discountAmount && <div className="product-card-tags-column">
                    {<CardTag text={`-${discountAmount}%`} date={formatedDate} type="promotion" />}
                    {/* {<CardTag text="Только самовывозом" type="pickup" />} */}
                  </div>}
                </div>

              </div>
            )}
            {/* {!preview && <div className={imageClass} ></div>} */}
          </Link>
          <div>
            <Link to={productUri} className="title">
              {product.title}
            </Link>
            {!isMobile && (
              <Link className="owner" to={ownerUri}>
                {product.tradeName || product.owner}
              </Link>
            )}
          </div>
        </div>
        <div className={bottomClass}>
          <Link className="product-card-info" to={productUri}>
            <Price product={{ price: promotedProduct ? promotedPrice : price, oldPrice: price, saleUnit: product.saleUnit.shortTitle, packFactor: product?.packFactor }} galleryView bonusProduct={bonusProduct} promotedProduct={promotedProduct} />
            {!isDacnhik && <Package product={product} isMobile={isMobile} galleryView={true} />}

          </Link>
          {!productIsComingSoon ?
            <Actions product={product} isTablet={isTablet} buttonSize="medium" buttonType="secondary" galleryView bonusProduct={bonusProduct} />
            :
            <SubscribeProductButton isSubscribed={isSubscribed} onClick={this.handleSubscribeProduct} loading={isSubscribing} />
          }
        </div>
      </div>
    );
  };

  render() {
    const { product } = this.props;
    const preview = getGalleryMainImage(product?.gallery?.map((item) => item));

    return this.renderCards(product, preview);
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.profile.loggedIn,
    viewType: state.products.viewType,
    cartItems: state.cart.cartItems,
    subscriptions: state.subscriptions.subscriptions?.results
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      subscribeProduct: subscribeProduct,
      unSubscribeProduct: unSubscribeProduct
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Card));
