import React from "react";
import "./SliderButton.scss";
import { ReactComponent as ArrowIcon } from "../../../images/right-arrow.svg";
import classNames from "classnames";

function SliderButton({ buttonType, buttonColor, index, hide }) {
  return (
    <div
      className={classNames(`slider-btn slider-btn-${buttonType} ${buttonColor}`, {
        [`slider-btn-${buttonType}_${index}`]: index,
        [`swiper-btn-hidden`]: hide,
      })}
    >
      <ArrowIcon />
    </div>
  );
}

export default SliderButton;
