import React from "react";
import "./ReviewRating.scss";
import ReviewStars from "../ReviewStars/ReviewStars";

const ReviewRating = ({ rating, reviewsCount, size }) => {
  // const underlayWidth = { width: `${(rating / 5) * 100}%` };
  return (
    <div className="review-rating">
      <div className="review-rating-stars">
        <ReviewStars rating={rating} size={size} color="white" />
      </div>

      <span className="rating-txt">{`${rating.toFixed(1)} из 5`}</span>
      {reviewsCount ? <span className="reviews">{reviewsCount} отзыва</span> : ""}
    </div>
  );
};

export default ReviewRating;
