import * as React from "react";
import "./ProductPage.scss";
import { bindActionCreators } from "redux";
import { fetchProductByIdOrUrl, searchProductsPlain, setSearchFilterAndSearch, getActiveSupplierInfo, reserveUserId } from "../../../actions/product";
import { subscribeProduct, checkProductSubscribtion, unSubscribeProduct } from "../../../actions/subscriptions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Price from "../Card/Price/Price";
import Actions from "../Card/Actions/Actions";
import Package from "../Card/Package/Package";
import Tabs from "./Tabs/Tabs";
import Supplier from "./Supplier/Supplier";
import ThumbsGallery from "./ThumbsGallery/ThumbsGallery";
import Delivery from "./Delivery/Delivery";
import ProductsGallery from "./ProductsGallery/ProductsGallery";
import ZoomModal from "../../commons/ZoomModal/ZoomModal";
import SwiperCore, { Navigation, A11y } from "swiper";
import { PROMOTED_PRODUCTS, TITLE_TAG_DELIVERY_ZONE_CAPTION } from "../../../constants";
import Button from "../../commons/Button/Button";
import Tooltip from "../../commons/Tooltip/Tooltip";
import queryString from "query-string";


//Временные данные
import { mockData } from "../../../mockSupplierCardData";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import NoresidentModal from "../NoresidentModal/NoresidentModal";
import { Loader } from "../../commons/Loader/Loader";
import SupplierItemUnavailable from "../../Cart/SupplierItems/SupplierItemUnavailable/SupplierItemUnavailable";
import { Gap } from "../../commons/Gap";
import StockInfo from "./StockInfo/StockInfo";
import FavoriteButton from "../FavoriteButton/FavoriteButton";
import SubscribeProductButton from "../SubscribeProductButton/SubscribeProductButton";
import CardTag from "../Card/CardTag/CardTag";
import moment from "moment";
import { v4 as uuid } from 'uuid';
import FoundCheaperModal from "./FoundCheaperModal/FoundCheaperModal";

// install Swiper components
SwiperCore.use([Navigation, A11y]);

const { env } = global.config;
const isDacnhik = env === "dachnik";

class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "productInfo",
      categoriesByLevel: null,
      isLoading: true,
      relatedProducts: [],
      lastProducts: [],
      zoom: false,
      galleryIndex: 0,
      galleryItems: [],
      showTooltip: false,
      showNoresidentModal: false,
      isSubscribed: false,
      isSubscribing: false,
      pageAccessedByReload: false,
      leasedObjects: null,
      showFoundCheaperModal: false
    };
  }

  componentDidMount() {
    const { fetchProductByIdOrUrl, setSearchFilterAndSearch, match, getActiveSupplierInfo, history, location } = this.props;
    const { urlSegment } = match.params;
    const searchById = queryString.parse(location.search).byId;

    const pageByReload = (
      (window.performance.navigation && window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
    );

    this.setState({ pageAccessedByReload: pageByReload })

    fetchProductByIdOrUrl(urlSegment, searchById).then((res) => {
      this.setState({ isLoading: false });

      setSearchFilterAndSearch({ ownerIds: [res?.ownerId] });
      getActiveSupplierInfo(res?.ownerId);
    }).catch((err) => {
      history.push("/");
    });




  }

  componentDidUpdate(prevProps, prevState) {
    const { currentProduct, searchProductsPlain, checkProductSubscribtion, match, fetchProductByIdOrUrl, loggedIn } = this.props;
    const { urlSegment } = match.params;

    document.title = currentProduct ? `${currentProduct.title} оптом ${TITLE_TAG_DELIVERY_ZONE_CAPTION} и области` : "";
    if (this.props.location !== prevProps.location) {
      if (urlSegment !== prevProps.urlSegment) {
        fetchProductByIdOrUrl(urlSegment).then(() => this.setState({ isLoading: false }));
      }
    }

    if (this.props.loggedIn !== prevProps.loggedIn) {
      fetchProductByIdOrUrl(urlSegment).then(() => this.setState({ isLoading: false }));
    }

    if (currentProduct?.length && currentProduct !== prevProps.currentProduct) {
      if (currentProduct && currentProduct?.categories && currentProduct?.categories[0]) {
        const { categoryId1, categoryId2, categoryId3, title1, title2, title3 } = currentProduct && currentProduct?.categories && currentProduct?.categories[0];
        const categoriesByLevel = {
          1: { id: categoryId1, title: title1 },
          2: { id: categoryId2, title: title2 },
          3: { id: categoryId3, title: title3 },
        };
        searchProductsPlain({ categoryIds: [currentProduct.categories[0].categoryId3] }).then((json) => this.setState({ relatedProducts: json.results }));
        checkProductSubscribtion(currentProduct.id).then((res) => {
          if (res) {
            this.setState({ isSubscribed: res.isCreated })
          }
        })
        this.setState({ categoriesByLevel });
      }

    }

  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }


  reserveUserId() {
    const { reserveUserId, reservedUserId, match } = this.props;
    const { productId } = match.params;
    const uniqueId = uuid();
    reserveUserId(uniqueId);

  }

  changeTab = (currentTab) => {
    this.setState({ currentTab });
  };

  backButtonClick = () => {
    this.props.history.goBack();
  };

  renderAricle = (articleNumber) => (
    <div className="article">
      <span className="label">Артикул:</span>
      <span className="label bold">{articleNumber}</span>
    </div>
  );

  renderNotes = () => (
    <div className="notes">
      <InfoIcon />
      <span>Точное кол-во товара в транспортной упаковке может быть другим. </span>
    </div>
  );

  handleShowFoundCheaper = () => {
    const { showFoundCheaperModal } = this.state;
    this.setState({ showFoundCheaperModal: !showFoundCheaperModal })
  }

  handleRedirect = (e, tagFilter) => {
    e.preventDefault();
    e.stopPropagation();
    const { history } = this.props;

    if (tagFilter) {
      history.push(`/catalog/customerProducts?TagsId=${tagFilter}`);
    } else {
      history.push(`/catalog/customerProducts?HasPromotion=true`);

    }
  }

  renderRightSide = (currentProduct, currentSupplier) => {
    const { leasedObjects } = this.state;
    const { env } = global.config;
    const isDacnhik = env === "dachnik";
    const stockBalance = currentProduct?.stockBalance;
    const saleUnit = currentProduct?.saleUnit?.shortTitle;
    const leasingCellNumber = currentProduct.leasedObjectNumber;
    const productIsComingSoon = currentProduct.status.code === "ComingSoon";
    const showStockInfo = !!leasedObjects && !productIsComingSoon && !isDacnhik;


    return (
      <div className="side">
        <Delivery status={currentProduct.status.code} />
        {showStockInfo && <>
          <Gap size={20} />
          <StockInfo stockBalance={stockBalance} saleUnit={saleUnit} leasingCellNumber={leasingCellNumber} buildingData={leasedObjects} />
        </>}
        <Gap size={20} />
        <Button title="Нашли дешевле ?" buttonSize="large" buttonType="subtle" onClick={this.handleShowFoundCheaper} />
        {/* <div className="notes temp">
        <ColdIcon />
        <span>Замороженные продукты перевозятся в термоконтейнерах </span>
      </div> */}
        {/* {!this.props.isMobile && !this.props.isTablet && (
        <div className="owner">
          <span>Продавец:</span>
          {currentSupplier && <Link to={`/catalog/suppliers/${currentSupplier.id}`}>{currentSupplier.tradeName || removeLegalPrefix(currentSupplier.legalName)}</Link>}
        </div>
      )} */}
      </div>
    );
  };

  handleZoomModal = () => {
    this.setState({ zoom: !this.state.zoom });
  };

  handleShowTooltip = () => {
    this.setState({ showTooltip: !this.state.showTooltip });
  };

  handleShowNoresidentModal = () => {
    this.setState({ showNoresidentModal: !this.state.showNoresidentModal });
  };

  handleSubscribeProduct = () => {
    const { subscribeProduct, unSubscribeProduct, currentProduct, loggedIn } = this.props;
    const { isSubscribed } = this.state;

    if (!loggedIn) {
      window.location.href = global.config.authUrl;
    } else {
      this.setState({ isSubscribing: true });
      isSubscribed ? unSubscribeProduct(currentProduct.id).then((res) => {
        res?.isUpdated && this.setState({ isSubscribing: false, isSubscribed: false });
      }).catch(() => this.setState({ isSubscribing: false }))
        : subscribeProduct(currentProduct.id).then((res) => {
          res?.isUpdated && this.setState({ isSubscribing: false, isSubscribed: true });
        }).catch(() => this.setState({ isSubscribing: false }));
    }
  }

  zoomCard = (index, items) => {
    this.handleZoomModal();
    this.setState({ galleryIndex: index });
    this.setState({ galleryItems: items });
  };

  render() {
    const { currentProduct, currentSupplier, products, isDesktop, isMobile, isTablet, currentSupplierInfo, loggedIn, history } = this.props;
    const { isLoading, relatedProducts, showTooltip, showNoresidentModal, isSubscribed, isSubscribing, showFoundCheaperModal } = this.state;
    if (!currentProduct || isLoading) {
      return <Loader />;
    }
    const { title, gallery, deliveryDate, isBonusItem, tags, prices } = currentProduct;
    const { categoriesByLevel } = this.state;
    const isTabletOrMobile = !isDesktop;
    const noresident = currentSupplier && currentSupplier.tradeName === "Овощная база";
    const productIsActive =
      currentProduct.status.code !== "Draft" && currentProduct.status.code !== "Blocked" && currentProduct.status.code !== "Hidden" && currentProduct.status.code !== "OutOfStock";
    const productIsComingSoon = currentProduct.status.code === "ComingSoon";
    const comingSoonTitle = isSubscribed ? "Отписаться" : "Подписаться";
    const formatedDate = moment(deliveryDate).format("DD MMM");
    const clearGallery = gallery.map((item) => item);
    const productLink = history.location.pathname;
    const price = prices.map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value || this.props.oldPrice;
    const promotedPrice = prices?.filter((item) => !item.isSystem).map((item) => item.segments).flat().find((item) => item.minQuantity === 1)?.value || this.props.price;
    const promotedProduct = tags.length > 0 && !!promotedPrice;
    const discountAmount = Math.trunc(tags[0]?.data?.DiscountPercent);
    const tagFilter = tags[0]?.data?.Filter;
    const formatedPromotionDate = moment(tags[0]?.data?.EndDate).format("DD MMMM");
    const hasCustomTag = tags[0]?.type?.code === "Custom";
    const customTagTitle = tags[0]?.title;


    return (
      <div className="product-page">
        <div className="main">
          {!isMobile && !isTablet && (
            <section className="container product-page-breadcrumbs">
              <Breadcrumbs categoriesByLevel={categoriesByLevel} title={currentProduct.title} />
            </section>
          )}

          <div className="inner">
            <div className="product-page-gallery">
              <ThumbsGallery items={clearGallery} isMobile={isMobile} isTablet={isTablet} zoomCard={this.zoomCard} noresident={true} />
              {noresident && (
                <div className="product-page-gallery-tag" onClick={this.handleShowTooltip}>
                  <div className="product-page-gallery-tag-content">
                    <span className="product-page-gallery-tag-text">Нерезидент</span>
                    <InfoIcon />
                    {showTooltip && <Tooltip text="Нерезеденты ОРЦ – это поставщик, который не хранит товар на нашем складе и имеет" linkText="ряд ограничений" url="" />}
                  </div>
                </div>
              )}
              {productIsComingSoon &&
                <div className="product-page-coming-soon-tag" onClick={(e) => this.handleRedirect(e, tagFilter)}> <CardTag text="Ожидается" date={formatedDate} type="coming-soon" /></div>
              }
              {hasCustomTag && <div className="product-page-tags-column" onClick={(e) => this.handleRedirect(e, tagFilter)}> <CardTag text={customTagTitle} /> </div>}
              {promotedProduct && discountAmount && <div className="product-page-tags-column" onClick={(e) => this.handleRedirect(e)}>
                {<CardTag text={`-${discountAmount}%`} date={formatedPromotionDate} type="promotion" />}
                {/* {<CardTag text="Только самовывозом" type="pickup" />} */}
              </div>}
            </div>
            <NoresidentModal product={currentProduct} onClose={this.handleShowNoresidentModal} visible={showNoresidentModal} isMobile={isMobile} />

            <div className="desc">
              {/* reviews disabled */}
              {/* <ReviewRating rating={4} reviewsCount={mockData.reviews.length} size="small" /> */}
              {/* --- */}
              <div className="title">{title}</div>
              {/* {this.renderAricle(articleNumber)} */}
              {price && productIsActive && <Price product={{ price: promotedProduct ? promotedPrice : price, oldPrice: price, saleUnit: currentProduct.saleUnit.shortTitle, packFactor: currentProduct?.packFactor }} bonusProduct={isBonusItem} promotedProduct={promotedProduct} />}
              {!!currentProduct?.minimumDeliveryQuantity && !isDacnhik && <Package product={currentProduct} isMobile={isTabletOrMobile} />}

              {price &&
                productIsComingSoon &&
                (!isMobile ? (
                  <div className="product-page-buttons flex">
                    <SubscribeProductButton isSubscribed={isSubscribed} title={comingSoonTitle} onClick={this.handleSubscribeProduct} loading={isSubscribing} />
                    <FavoriteButton product={currentProduct} squared={true} hasHint={false} />

                  </div>
                ) : (
                  <div className="fixed-button">
                    <SubscribeProductButton isSubscribed={isSubscribed} title={comingSoonTitle} hasHint={false} onClick={this.handleSubscribeProduct} loading={isSubscribing} />
                    <FavoriteButton product={currentProduct} squared={true} hasHint={true} />
                  </div>
                ))}

              {price &&
                !noresident &&
                productIsActive &&
                !productIsComingSoon &&
                (!isMobile ? (
                  <div className="product-page-buttons">
                    <Actions product={currentProduct} buttonSize="large" buttonType="primary" favoriteButton="right" bonusProduct={isBonusItem} />
                  </div>
                ) : (
                  <div className="fixed-button">
                    <Actions product={currentProduct} buttonSize="large" buttonType="primary" favoriteButton="right" bonusProduct={isBonusItem} />
                  </div>
                ))}

              {price &&
                noresident &&
                !showNoresidentModal &&
                productIsActive &&
                !productIsComingSoon &&
                (!isMobile ? (
                  <div className="product-page-buttons">
                    <Button title="Оставить заявку" buttonSize="large" buttonType="primary" onClick={this.handleShowNoresidentModal} />
                  </div>
                ) : (
                  <div className="fixed-button">
                    <Button title="Оставить заявку" buttonSize="large" buttonType="primary" width="265px" onClick={this.handleShowNoresidentModal} />
                  </div>
                ))}

              {price && productIsActive && this.renderNotes()}
              {price && !productIsActive && <SupplierItemUnavailable text="Товар недоступен к заказу" />}
              {!price && productIsActive && <SupplierItemUnavailable text="Товар не доступен для частных лиц" />}
              {isMobile && (!price || !productIsActive) && <Gap size={20} />}

              {(isMobile || isTablet) && this.renderRightSide(currentProduct, currentSupplier)}
            </div>
            {!isMobile && !isTablet && this.renderRightSide(currentProduct, currentSupplier)}
          </div>
        </div>
        {!!currentProduct?.categories?.length && (
          <section className="details">
            <div className="details-content container">
              <Tabs product={currentProduct} isMobile={isMobile} zoomCard={this.zoomCard} currentSupplierInfo={currentSupplierInfo} loggedIn={loggedIn} />
              <Supplier supplier={currentSupplier} products={products} reviewsCount={mockData.reviews.length} />
            </div>
          </section>
        )}
        {!!relatedProducts?.length && <ProductsGallery title="Похожие товары" products={relatedProducts} isDesktop={isDesktop} index="1" />}
        {!!relatedProducts?.length && <ProductsGallery title="Вы недавно смотрели" products={relatedProducts} isDesktop={isDesktop} index="2" />}
        {this.state.zoom && <ZoomModal index={this.state.galleryIndex} items={this.state.galleryItems} close={this.handleZoomModal} isDesktop={isDesktop} />}
        <FoundCheaperModal onClose={this.handleShowFoundCheaper} visible={showFoundCheaperModal} productId={currentProduct.id} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentProduct: state.products.currentProduct,
    currentSupplier: state.products.currentSupplier,
    reservedUserId: state.products.reservedUserId,
    currentSupplierInfo: state.products.currentSupplierInfo,
    isDesktop: state.api.isDesktop,
    isMobile: state.api.isMobile,
    isTablet: state.api.isTablet,
    products: state.products.products.results,
    loggedIn: state.profile.loggedIn,
    userId: state.profile.userId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProductByIdOrUrl: fetchProductByIdOrUrl,
      setSearchFilterAndSearch: setSearchFilterAndSearch,
      searchProductsPlain: searchProductsPlain,
      getActiveSupplierInfo: getActiveSupplierInfo,
      reserveUserId: reserveUserId,
      subscribeProduct: subscribeProduct,
      checkProductSubscribtion: checkProductSubscribtion,
      unSubscribeProduct: unSubscribeProduct,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductPage));
