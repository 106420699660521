import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { isNil } from "ramda";
import SimpleModal from "../SimpleModal/SimpleModal";

const UserJoinRequestDialog = () => {
  let location = useLocation();
  let history = useHistory();

  const query = queryString.parse(location.search);
  const { IsAgreed, ErrorMessage, PersonName } = query;
  const agreed = !isNil(IsAgreed) && IsAgreed.toLowerCase() === "true";

  const closeClick = () => {
    history.push("/");
  };

  const header = agreed ? "Заявка одобрена" : "Заявка не одобрена";

  const message = agreed ? (
    <span>
      Вы успешно добавили пользователя <strong>{PersonName}</strong> к аккаунту Вашей компании!
    </span>
  ) : (
    <span>{ErrorMessage}</span>
  );

  return <SimpleModal visible={!isNil(IsAgreed)} onClose={closeClick} header={header} message={message} />;
};

export default UserJoinRequestDialog;
