import * as React from "react";
import { ReactComponent as PhoneIcon } from "../../../images/phone.svg";
import { Link } from "react-router-dom";
import "./TopBar.scss";
import classNames from "classnames";
import AccountType from "../AccountType/AccountType";
import { useDispatch } from "react-redux";
import { toggleCategoriesMenu } from "../../../actions/category";
import AddressButton from "../../Cart/Address/AddressButton";
import { ReactComponent as Logo } from "../../../images/new_logo.svg";
import dachnik_logo from "../../../images/dachnik_logo.png"
import { CONTACTS } from "../../../constants";

const TopBar = ({ column = false, isMobile, accountType, onSelectAccountType, loggedIn }) => {
  const dispatch = useDispatch();
  const { env } = global.config;

  const contacts = {
    phone: env !== "dachnik" ? CONTACTS.PHONE.nsk : CONTACTS.PHONE.dachnik,
    phone_text: env !== "dachnik" ? CONTACTS.PHONE_TEXT.nsk : CONTACTS.PHONE_TEXT.dachnik
  }

  const handleCloseCategoryMenu = () => {
    dispatch(toggleCategoriesMenu(false));
  };


  return (
    <div className={classNames({ "top-bar": true, column: column })}>
      <div className="items">
        <a className="logo" href={"/"}>
          {env !== "dachnik" ? <Logo /> : <img src={dachnik_logo} alt="Логотип Дачник" />}
        </a>
        {isMobile && <AddressButton />}
        {!isMobile && <AccountType accountType={accountType} onSelectAccountType={onSelectAccountType} loggedIn={loggedIn} />}
        {!isMobile && <AddressButton />}
      </div>
      <div className="links">


        <Link to="/landing" className="top-link" onClick={handleCloseCategoryMenu}>
          <span>Стать поставщиком</span>
        </Link>
        <Link to="/help" className="top-link" onClick={handleCloseCategoryMenu}>
          <span>Помощь</span>
        </Link>
       
        <a className="top-link phone" href={`tel: ${contacts.phone}`}>
          <PhoneIcon /> <span>{contacts.phone_text}</span>
        </a>
      </div>
    </div>
  );
};

export default TopBar;

