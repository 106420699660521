import * as React from "react";
import "./Pagination.scss";
import classNames from "classnames";
import { ReactComponent as ArrowRightIcon } from "../../../images/round-arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../images/round-arrow-left.svg";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { uniq } from "ramda";
import { withRouter } from "react-router";

function range(start, stop) {
  var a = [start],
    b = start;
  while (b < stop) {
    a.push((b += 1));
  }
  return a;
}
const maxCount = global.config.pagination.maxPages;
class Pagination extends React.Component {
  render() {
    const { totalCount, offset, extraItemsCount = 0 } = this.props;
    const pageSize = global.config.pagination.pageSize;

    if (totalCount < pageSize) return null;

    const totalPages = Math.ceil(totalCount / pageSize);
    const pageNumber = Math.ceil(offset / pageSize) + 1;
    let itemsRange = [];
    itemsRange = range(1, totalPages);
    if (totalPages <= maxCount) {
      itemsRange = range(1, totalPages);
    } else {
      const halfCount = maxCount / 2;
      let rangesLeft = range(1, halfCount + 2);
      let rangesRight = range(totalPages - halfCount, totalPages);

      if (pageNumber > halfCount + 1) {
        rangesLeft = pageNumber === totalPages ? [1, -1] : [1, -1].concat(range(pageNumber - halfCount + 1, pageNumber));
      }

      if (pageNumber < totalPages - halfCount) {
        rangesRight = range(pageNumber, pageNumber + halfCount - 1).concat([-10, totalPages]);
      }

      itemsRange = uniq(rangesLeft.concat(rangesRight));
    }

    const query = queryString.parse(this.props.location.search);
    const pathname = this.props.location.pathname;
    const getPageUrl = (i) => {
      query.offset = pageSize * (i - 1);
      const stringified = queryString.stringify(query);
      return `${pathname}?${stringified}`;
    };
    const renderLeftArrow = (pageNumber) => {
      if (pageNumber === 1) {
        return <ArrowLeftIcon className="arrow disabled" />;
      } else {
        return (
          <Link to={getPageUrl(pageNumber - 1)}>
            <ArrowLeftIcon className="arrow" />
          </Link>
        );
      }
    };
    const renderRightArrow = (pageNumber) => {
      if (pageNumber === totalPages) {
        return <ArrowRightIcon className="arrow disabled" />;
      } else {
        return (
          <Link to={getPageUrl(pageNumber + 1)}>
            <ArrowRightIcon className="arrow" />
          </Link>
        );
      }
    };

    const actualPageNumber = pageNumber + extraItemsCount / pageSize;
    // const renderRightArrow = (pageNumber) => (<ArrowRightIcon className={classNames({
    //     'arrow': true,
    //     'disabled': pageNumber === totalPages
    // })} />);
    return (
      <div className="pagination">
        {renderLeftArrow(actualPageNumber)}

        <div className="pages">
          {itemsRange.map((i) =>
            i > 0 ? (
              <span key={`page_${i}`}>
                <Link
                  to={getPageUrl(i)}
                  className={classNames({
                    selected: actualPageNumber === i,
                  })}
                >
                  {i}
                </Link>
              </span>
            ) : (
              <span key={`page_${i}`} className="more">
                …
              </span>
            )
          )}
        </div>
        {renderRightArrow(actualPageNumber)}
      </div>
    );
  }
}
export default withRouter(Pagination);
