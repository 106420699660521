import React from 'react'; //{ useRef, useState }
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { useSelector } from 'react-redux';
import classNames from 'classnames';
import LoadingBar from 'react-top-loading-bar'
import './LoadingBar.scss'

class MyLoadingBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.loaderRef = React.createRef();

    }
    isLoading() {
        const { productsLoading, categoriesLoading, cartLoading, apiLoading } = this.props;
        return productsLoading || categoriesLoading || cartLoading || apiLoading;
    }
    componentDidMount() {
        this.setState({ loading: this.isLoading() })
    }

    componentDidUpdate(prevProps, prevState) {
        const { productsLoading, categoriesLoading, cartLoading, apiLoading } = this.props;
        const loading = this.isLoading();

        if (productsLoading !== prevProps.productsLoading ||
            categoriesLoading !== prevProps.categoriesLoading ||
            cartLoading !== prevProps.cartLoading ||
            apiLoading !== prevProps.apiLoading) {
            this.setState({ loading })
        }

        if (loading !== prevState.loading) {
            if (loading) {
                this.loaderRef.continuousStart()
            } else {
                this.loaderRef.complete()
            }
        }
    }
    render() {
        const { loading } = this.state;

        return (<div className={classNames({ "loading-bar": true, "visible": loading })}>
            <LoadingBar
                color="radial-gradient(20.54% 884285.12% at 84.34% -0.05%, #2051A3 0%, rgba(32, 81, 163, 0.31) 0.01%, #2051A3 100%)"
                height={4}
                onRef={ref => (this.loaderRef = ref)} />
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        categoriesLoading: state.categories.isLoading,
        productsLoading: state.products.isLoading,
        cartLoading: state.cart.isLoading,
        apiLoading: state.api.loading.length > 0


    })
};

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyLoadingBar);

// const MyLoadingBar = () => {
//     const [isLoading, setIsLoading] = useState(false);

//     const categoriesLoading = useSelector(state => state.categories.isLoading);
//     const productsLoading = useSelector(state => state.products.isLoading);
//     const isReduxLoading = categoriesLoading || productsLoading;
//     const loadertEl = useRef(null);

//     if (isLoading != isReduxLoading) {
//         setIsLoading(isReduxLoading);
//         if (isReduxLoading) {
//             loadertEl.current.continuousStart()
//         } else {
//             loadertEl.current.complete()
//         }

//     }
//     // color='#2051A3'

//     return (<div className={classNames({ "loading-bar": true, "visible": isReduxLoading })}>
//         <LoadingBar
//             height={5}
//             onRef={ref => (loadertEl.current = ref)} />
//     </div>)
// }

// export default MyLoadingBar;