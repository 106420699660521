import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleModal from "../SimpleModal/SimpleModal";
import { setAccountNotVerifiedModalVisible } from "../../../actions/profile";

const AccountNotVerifiedModal = () => {
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.profile.accountNotVerifiedModalVisible);

  const closeClick = () => dispatch(setAccountNotVerifiedModalVisible(false));

  const header = "Аккаунт ожидает подтверждения";

  const message = (
    <span>Владельцу организации, к которой вы присоединились, необходимо подтвердить вашу регистрацию. После подтверждения вам будет доступно оформление заказа.</span>
  );

  return <SimpleModal visible={visible} onClose={closeClick} header={header} message={message} />;
};

export default AccountNotVerifiedModal;
