import React from "react";
import "./MobileSorting.scss";
import Modal from "../../../commons/Modal/Modal";
import Button from "../../../commons/Button/Button";
import CheckBox from "../../../commons/CheckBox/CheckBox";
import { productsSortItems } from "../../../../constants";

const MobileSorting = ({ showMobileSorting, onClose, setSorting, sortingName }) => {
  const onChange = (name) => {
    setSorting(name);
  };

  const renderCheckboxes = () => {
    return Object.keys(productsSortItems).map((sortType, index) => {
      const checked = sortType === sortingName;
      return (
        <CheckBox key={`sort__item_${index}`} checked={checked} onChange={() => onChange(sortType)}>
          {" "}
          {productsSortItems[sortType].text}
        </CheckBox>
      );
    });
  };

  return (
    <div className="mobile-sorting">
      <Modal visible={showMobileSorting} onClose={() => onClose()}>
        <h2 className="caption">Сортировать</h2>
        <ul className="mobile-sorting-list">{renderCheckboxes()}</ul>

        <div className="mobile-sorting-footer">
          <Button buttonSize="large" buttonType="primary" title="Готово" onClick={() => onClose()} width="100%" />
        </div>
      </Modal>
    </div>
  );
};

export default MobileSorting;
