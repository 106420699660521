import * as React from "react";
import "../Card.scss";
import FavoriteButton from "../../FavoriteButton/FavoriteButton";
const CardSkeleton = ({ viewType, isMobile }) => {
  return (
    <div className="product-card skeleton">
      <FavoriteButton disabled product={{ id: null }} />
      <div className="image" />
      <div className="title"></div>
      <div className="bottom">
        <div className="price-wrap">
          <div className="price"></div>
          <div className="package"></div>
        </div>
        <div className="actions"></div>
      </div>
    </div>
  );
};

export default CardSkeleton;
