import React from 'react';
import { useSelector } from 'react-redux';
import "./CardTag.scss";

const CardTag = ({ text, date, type = "" }) => {
  const isMobile = useSelector(state => state.api.isMobile);
  const tagClass = date ? "card-tag coming-soon" : "card-tag";
  const showPrefix = !isMobile || (isMobile && type !== "coming-soon" );
  
  return (
    <div className={tagClass}>
      <span className={`card-tag-text ${type}`}>{text}</span>
      {!!date && <span className="card-tag-date">{showPrefix && "до"} {date}</span>}
    </div>
  )
}

export default CardTag;