import React from "react";
import "./NotificationPopupButton.scss";
import classNames from "classnames";

function NotificationPopupButton({ text, handler, coords }) {
  //Координаты для кнопки
  const buttonCoords = coords
    ? {
        top: `${coords.y - 47}px`,
        left: `${coords.x - 17}px`,
      }
    : {};

  return (
    <button
      className={classNames("notification-popup-button", {
        read: coords,
        more: !coords,
      })}
      style={buttonCoords}
      onClick={(e) => handler(e)}
    >
      {text}
    </button>
  );
}

export default NotificationPopupButton;
