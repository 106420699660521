import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSearchFilterAndSearch, clearFilterCategory } from "../../../../actions/product";
import "./SideBar.scss";
import CategoriesList from "../../SideBar/CategoriesList/CategoriesList";
import PropertyFilter from "../../SideBar/PropertyFilter/PropertyFilter";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      attributes: {},
      filterCountriesOfOrigin: [],
      categoryTree: {},
    };
  }

  componentDidMount() {
    const { currentSupplier, search, clearFilterCategory } = this.props;
    clearFilterCategory();
    search({ ownerIds: [currentSupplier.id], categoryIds: [], title: "" });
  }

  render() {
    const { categoryTree } = this.state;
    const { search, products } = this.props;

    const categoriesByLevel = {};
    const categories = products.map((product) => product.categories).flat();
    const categoriesToShow = categories
      .map((category) => {
        return {
          id: category.categoryId3,
          level: 3,
          title: category.title3,
        };
      })
      .reduce((acc, item) => {
        let oldItem = acc.find((oldItem) => oldItem.title === item.title);
        if (!oldItem) {
          acc.push(item);
        }
        return acc;
      }, []);

    return (
      <div className="supplier-sidebar">
        {!!categoryTree && <CategoriesList flat={true} categoriesByLevel={categoriesByLevel} categoriesToShow={categoriesToShow} />}
        <PropertyFilter title="Страна" filterKey="countriesOfOrigin" search={(data) => search(data)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countriesOfOrigin: state.products.availableFilters.countriesOfOrigin,
    categoryIds: state.products.availableFilters.categoryIds,
    currentSupplier: state.products.currentSupplier,
    products: state.products.products.results,
    filterCategoryIds: state.products.filterCategoryIds,
    categoriesByLevel: state.categories.categoriesByLevel,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearchFilterAndSearch: setSearchFilterAndSearch,
      clearFilterCategory: clearFilterCategory,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
