import * as React from "react";
import "./Package.scss";
import { inclinePackTypes } from "../../../../helpers/stringOperations";

const Package = ({ product, isMobile, galleryView = false }) => {
  return (
    <div className="package">
      От {product.minimumDeliveryQuantity}
      {product.saleUnit.shortTitle}
      {product.transportPacking && (
        !isMobile || !galleryView ?
          <span className="unit"> {inclinePackTypes(product.transportPacking.code)}
            {!!product.packFactor ? ` ${product.packFactor}шт` : ""}
          </span>
          :

          !!product.packFactor ?
            <span className="unit">{` ${product.packFactor}шт`}
            </span> : ""
      )}
    </div>
  );
};

export default Package;
