import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import "./FillableDropDown.scss";
import classNames from "classnames";
import InputWithHint from "../InputWithHint/InputWithHint";
import Input from "../RamInput/Input";

class FillableDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            isOpen: false,
            searchResults: [],
            searchPromise: null,
            searchItem: null,
            hasError: false,
            timeOut: null,
            selectedIndex: 0,
            itemsCount: 0,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.addressInputRef = this.props.customRef || React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleClickOutside(event) {
        const {isOpen} = this.state;
        if (isOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setOpen(false);
        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    setOpen(isOpen = true) {
        this.setState({isOpen});
    }

    onChangeInput(value) {
        const {searchRequest, onChange, selectName} = this.props;
        this.setOpen();

        if (this.state.searchPromise) {
            this.state.searchPromise.cancel();
        }

        onChange(selectName, {[selectName]: value});
        this.setState({searchItem: null});
        if (value) {
            const searchPromise = searchRequest(value)
                .then((results) => {
                    
                    this.setState({searchResults: results, itemsCount: results.length, selectedIndex: 0});
                })
                .catch(() => {});

            this.setState({searchPromise});
        } else {
            this.setState({searchResults: [], searchItem: null, hasError: false});
        }
    }

    selectItem(item) {
        const {onChange, selectName} = this.props;
        this.setState({searchItem: item});
        onChange(selectName, item);
        this.setOpen(false);
    }

    onKeyDown(e) {
        const {itemsCount, selectedIndex, searchResults} = this.state;

        if (e.keyCode === "38") {
            // up
            if (selectedIndex > 0) {
                this.setState({selectedIndex: selectedIndex - 1});
            }
        } else if (e.keyCode === "40") {
            //down
            if (selectedIndex < itemsCount - 1) {
                this.setState({selectedIndex: selectedIndex + 1});
            }
        } else if (e.keyCode === "13") {
            //enter
            this.selectItem(searchResults[selectedIndex]);
        }
    }

    onHover(i) {
        this.setState({selectedIndex: i});
    }

    render() {
        const {isOpen, searchResults, selectedIndex} = this.state;
        const {title, value, text, max, renderItemText, withHint = true, leftImg, rightImg, onBlur, onFocus, hasError, disabled = false, errors, selectName, errorText} = this.props;
        const renderSearchResultItem = (item, i) => {
            return (
                <div
                    key={`sr_item_${i}`}
                    className={classNames({
                        "fillable-item": true,
                        selected: selectedIndex === i,
                    })}
                    onClick={() => this.selectItem(item)}
                    onMouseEnter={() => this.onHover(i)}
                >
                    {renderItemText(item)}
                </div>
            );
        };

        return (
            <div className="fillable-dropdown-wrap" ref={this.setWrapperRef}>
                {withHint ? (
                    <InputWithHint
                        customRef={this.addressInputRef}
                        title={title}
                        text={text}
                        value={value}
                        onChange={(value) => this.onChangeInput(value)}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        rightImg={rightImg}
                        disabled={disabled}
                        errors={errors}
                        hasError={hasError}
                        errorText={errorText}
                        name={selectName}
                        max={max}
                    />
                ) : (
                    <Input
                        customRef={this.addressInputRef}
                        hasError={hasError}
                        errorText={errorText}
                        title={title}
                        leftImg={leftImg}
                        rightImg={rightImg}
                        value={value}
                        disabled={disabled}
                        onChange={(value) => this.onChangeInput(value)}
                        onBlur={() => onBlur && onBlur()}
                        onFocus={() => onFocus && onFocus()}
                        max={max}
                    />
                )}
                {isOpen && <div className="fillable-dropdown">{searchResults.map((item, i) => renderSearchResultItem(item, i))}</div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FillableDropDown);
