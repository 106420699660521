import * as React from "react";
import "./NotificationButton.scss";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";

const NotificationButton = (props) => {
  const styles = props.shown ? { display: "flex" } : null;
  const indicator = props.notificationCount > 99 ? "99" : props.notificationCount;
  return (
    <Link
      to={props.link_to || "#"}
      className={classNames({ "notification-btn": true, [props.accountType]: true })}
      onClick={props.onClick}
      onPointerEnter={props.onPointerEnter}
      onPointerLeave={props.onPointerLeave}
    >
      {props.icon}
      {indicator > 0 && <sub>{indicator}</sub>}
      <div className="notification-btn-underlay" style={styles} />
    </Link>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton);
