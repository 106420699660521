import React from "react";
import whoCanOrderImage from "../../src/images/help_images/who_can_order.png";
import orderStatusImage from "../../src/images/help_images/order_status.png";
import readyForOrderImage from "../../src/images/help_images/ready_for_order.png";
import pickup from "../../src/images/help_images/pickup.png";
import prepayment from "../../src/images/help_images/prepayment.png";
import leftTillOrder from "../../src/images/help_images/left_till_order.png";
import howToPayImage from "../../src/images/help_images/how_to_pay.png";
import { ReactComponent as TgIcon } from "../../src/images/tg.svg";
import { ReactComponent as Logo } from "../../src/images/new_logo.svg";
// import { ReactComponent as WhatsappIcon } from "../../src/images/whatsapp.svg";
import logo_leaves from "../../src/images/logo_leaves.png";
import { Gap } from "../../src/components/commons/Gap";
import { CONTACTS, COMPANY_DETAILS } from "../constants";

const {  baseUrl, authUrl, documentLinkUrl } = global.config;
const url = baseUrl;

const contacts = {
  phone: CONTACTS.PHONE.nsk,
  phone_text: CONTACTS.PHONE_TEXT.nsk
}
const companyDetails = COMPANY_DETAILS[0];

export const helpJson = {
  dataBuyers: [
    {
      orderNumber: 1,
      section: "Общая информация",
      question: "О разработчике",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            <span className="helpQARoot__answer-accent">{`ООО «РАМ-ИТ» `}</span>
            разрабатывает и поддерживает «Серверный программный комплекс интегрированной с логистическими центрами онлайн-платформы сбора заказов, хранения и доставки продуктов питания».
          </p>
          <Gap size={20} />
          <a className="helpQARoot__answer-link underline" href="https://rusagro.market/legal-mp" target="_blank" rel="noopener noreferrer">
            Свидетельство о гос. регистрации программы для ЭВМ.</a>
          <Gap size={20} />
          <span> ООО «РАМ-ИТ»</span>
          <span> ОГРН 1197746527384</span>
          <span> ИНН 9731050677 КПП 773101001</span>
          <span> 121205, г.Москва, Территория инновационного центра СКОЛКОВО</span>
          <span> Большой б-р, дом 42, строение 1, эт 3, пом 1238 раб 13</span>

          <Gap size={20} />
          <p className="helpQARoot__answer-text">
            <span className="helpQARoot__answer-accent">{`Маркетплейс rusagro.market `}</span>
            – сервис удаленного оформления и доставки сборных оптовых заказов свежих продуктов питания, построенный на собственной логистической экосистеме ОРЦ.
          </p>
          <Gap size={20} />
          <p className="helpQARoot__answer-text">
            Для покупателей, это удобный онлайн-инструмент для экономии времени на закупки товаров с доставкой или самовывозом.
          </p>
          <Gap size={20} />
          <ul className="helpQARoot__answer-list none-list-style">
            <li className="helpQARoot__answer-item">
              - Единая корзина с товарами от всех поставщиков
            </li>
            <li className="helpQARoot__answer-item">
              - Финансовые инструменты для предоставления отсрочки платежа
            </li>
            <li className="helpQARoot__answer-item">
              - Сплитование платежей – единый счет от всех поставщиков
            </li>
            <li className="helpQARoot__answer-item">
              - Один аккаунт для множества юр.лиц
            </li>
            <li className="helpQARoot__answer-item">
              - Индивидуальная страница продавца
            </li>
            <li className="helpQARoot__answer-item">
              - Бонусные программы и алгоритмы рекомендаций
            </li>
          </ul>
          <Gap size={20} />
          <p className="helpQARoot__answer-text">
            Для поставщиков, это дополнительный канал сбыта, ориентированный на розницу и мелкий опт
          </p>
          <Gap size={20} />
          <ul className="helpQARoot__answer-list none-list-style">
            <li className="helpQARoot__answer-item">
              - Управление товарами
            </li>
            <li className="helpQARoot__answer-item">
              - Управление и сборка заказов
            </li>
            <li className="helpQARoot__answer-item">
              - Аналитика
            </li>
            <li className="helpQARoot__answer-item">
              - Заказ сервисных и транспортных услуг
            </li>
            <li className="helpQARoot__answer-item">
              - Работа с документами и договорами
            </li>
            <li className="helpQARoot__answer-item">
              - Поддержка оператора через чат
            </li>
          </ul>
          <Gap size={20} />
          <p className="helpQARoot__answer-text">
            CRM система для B2B e-commerce маркетплейса оптовой торговли продуктами питания РусАгроМаркет интегрирована с такими системами как 1С.Торговля,
            Меркурий, Честный знак, WMS, Битрикс24, Яндекс.Маршрутизация.
          </p>
          <Gap size={30} />
          <p className="helpQARoot__answer-text">
            <span className="helpQARoot__answer-accent">{`ООО «РАМ-ИТ» `}</span>
            разработал и поддерживает следующие модули:
          </p>
          <Gap size={20} />
          <ul className="helpQARoot__answer-list none-list-style">
            <li className="helpQARoot__answer-item">
              - Видеоролик "Маркетплейс и цифровые продукты"
            </li>
            <li className="helpQARoot__answer-item">
              - Доработка Платформа «Автоматизация процессов управления услугами»
            </li>
            <li className="helpQARoot__answer-item">
              - Программный продукт «Автоматизированная система "Администраторский функционал"»
            </li>
            <li className="helpQARoot__answer-item">
              - Программный продукт «Автоматизированная система "Логистика"»
            </li>
            <li className="helpQARoot__answer-item">
              - Программный продукт «Автоматизированная система "Помощь и редизайн"»
            </li>
            <li className="helpQARoot__answer-item">
              - Программный продукт «Автоматизированная система "Сервисы и транспорт"»
            </li>
            <li className="helpQARoot__answer-item">
              - Программный продукт «Автоматизированная система "Финансы"»
            </li>
            <li className="helpQARoot__answer-item">
              - Доработка Программный продукт «Автоматизированная система управления доступами"»
            </li>
            <li className="helpQARoot__answer-item">
              - Программный продукт- Мобильное приложение покупателя
            </li>
            <li className="helpQARoot__answer-item">
              - Серверный программный комплекс «Online Marketplace»
            </li>
            <li className="helpQARoot__answer-item">
              - Серверный программный комплекс интегрированный с логистическими центрами онлайн-платформы сбора заказов, хранения и доставкой продуктов питания. Версия 2
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль для обработки заказов 1С и синхронизации цен и остатков по товара
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль для получения заявок на поставку продуктов питания из торговой сети "Фасоль"
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль для сбора данных по потребностям товара из торговой сети Магнит
            </li>
            <li className="helpQARoot__answer-item">
              - Корпоративный сайт rusagromarket.ru
            </li>
            <li className="helpQARoot__answer-item">
              - CRM система для сбора заявок из МСП
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль аналитики для работы с данными маркетплейсов
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль сбора статистики по количеству проездов транспорта через камеру
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль синхронизации для 1С УТ и СБИС
            </li>
            <li className="helpQARoot__answer-item">
              - Презентация о возможностях маркетплейс 2022
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль для работы с товарами Честного знака
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль для получения заявок на поставку продуктов питания из торговой сети "Фасоль". Версия 2
            </li>
            <li className="helpQARoot__answer-item">
              - Презентация концепции по трансграничному маркетплейсу
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль для сбора данных по потребностям товара из торговой сети МарияРа
            </li>
            <li className="helpQARoot__answer-item">
              - Модуль 1С УТ заявок на поставку продукции на ответственное хранение
            </li>
          </ul>
          {/* <Gap size={20} />
          <p className="helpQARoot__answer-text">
            Неисключительная лицензия на ПО передана:
          </p> */}

          {/* <Gap size={10} />
          <ul className="helpQARoot__answer-list none-list-style">
            <li className="helpQARoot__answer-item"> ООО «Рам-Дистрибуция» -  <a className="helpQARoot__answer-link" href="https://rusagro.market" target="_blank" rel="noopener noreferrer">
              rusagro.market
            </a></li>
            <li className="helpQARoot__answer-item"> ООО «РМ-ФИНАНС»  -  <a className="helpQARoot__answer-link" href="https://dachnikrusagro.market" target="_blank" rel="noopener noreferrer">
              dachnikrusagro.market
            </a></li>

          </ul> */}

        </div>
      ),
    },
    {
      orderNumber: 2,
      section: "Общая информация",
      question: "О платформе",
      answer:
        (
          <div className="helpQARoot__answer">
            <h3 className="helpQARoot__answer-title">О Компании</h3>
            <p className="helpQARoot__answer-text">
              rusagro.market – комплекс эффективных решений для производителей, поставщиков и оптовых покупателей свежих продуктов питания. Выступая связующим звеном между производителем, поставщиками и оптовыми покупателями, Маркетплейс предоставляет сервис удаленного оформления и доставки сборных оптовых заказов, обеспечивая эффективное перераспределение свежих продуктов на региональном уровне.
            </p>
            <Gap size={30} />
            <h3 className="helpQARoot__answer-title">Документация</h3>
            <ul className="helpQARoot__answer-list">
              <li className="helpQARoot__answer-item">Описание процессов жизненного цикла  <a className="helpQARoot__answer-link" href={`${documentLinkUrl}/life_cycle/`} target="_blank" rel="noopener noreferrer">
                {`${documentLinkUrl}/life_cycle`}
              </a></li>
              <li className="helpQARoot__answer-item">Описание функциональных характеристик  <a className="helpQARoot__answer-link" href={`${documentLinkUrl}/functional_description/`} target="_blank" rel="noopener noreferrer">
                {`${documentLinkUrl}/functional_description`}
              </a></li>
              <li className="helpQARoot__answer-item">Руководство покупателя  <a className="helpQARoot__answer-link" href={`${documentLinkUrl}/buyer_guide/`} target="_blank" rel="noopener noreferrer">
                {`${documentLinkUrl}/buyer_guide`}
              </a></li>
              <li className="helpQARoot__answer-item">Руководство поставщика  <a className="helpQARoot__answer-link" href={`${documentLinkUrl}/supplier_guide`} target="_blank" rel="noopener noreferrer">
                {`${documentLinkUrl}/supplier_guide`}
              </a></li>
            </ul>

            <Gap size={30} />
            <h3 className="helpQARoot__answer-title">Установка экземпляра ПО</h3>
            <p className="helpQARoot__answer-text">
              Программное обеспечение распространяется в виде интернет-сервиса.
            </p>
            <p className="helpQARoot__answer-text">
              Специальные действия по установке ПО не требуются.
            </p>
            <Gap size={30} />
            <h3 className="helpQARoot__answer-title"> Стоимость ПО</h3>
            <p className="helpQARoot__answer-text">
              ПО предоставляется в рамках лицензионного договора на индивидуальных условиях.
            </p>
            <p className="helpQARoot__answer-text">
              Подробнее по номеру <a href={`tel: ${contacts.phone}`} className="helpQARoot__answer-link phone">
                {` ${contacts.phone_text}`}
              </a>
            </p>
          </div>
        ),
    },

    {
      orderNumber: 3,
      section: "Общая информация",
      question: "Как мы работаем ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Поставщики размещают свои товары на сервисе, пройдя модерацию, они отображаются в нашем
            <a className="helpQARoot__answer-link" href={url} target="_blank" rel="noopener noreferrer">
              {` каталоге `}
            </a>
            и далее вы можете сделать по ним заказ.
          </p>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Как это происходит?</h3>
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">
              <a className="helpQARoot__answer-link" href={authUrl} target="_blank" rel="noopener noreferrer">
              {`Вы регистрируетесь. `}
              </a>
              Для юридических лиц указываются реквизиты компании и данные представителя.
            </li>
            <li className="helpQARoot__answer-item">Выбираете товар(ы) в каталоге и добавляете в корзину. </li>
            <li className="helpQARoot__answer-item">Переходите к оформлению заказа, выбираете тип оплаты, указываете адрес.</li>
            <li className="helpQARoot__answer-item">Поставщики подтверждают заказ и выставляют статусы по товарам.</li>
            <li className="helpQARoot__answer-item">Вы получаете уведомления о готовности отгрузки товаров.</li>
            <li className="helpQARoot__answer-item">При оплате заказа по предоплате, вы получите заказ на следующий день после поступления денежных средств по заказу. При выборе отсрочки платежа по заказу доставка осуществляется также на следующий день после оформления заказа.</li>
          </ul>
          <Gap size={20} />
          <a className="helpQARoot__answer-link" href="/help/2/0">*Подробнее об условиях доставки</a>
        </div>
      ),
    },
    {
      orderNumber: 4,
      section: "Общая информация",
      question: "Какие соглашения действуют в сервисе?",
      answer: (
        <div className="helpQARoot__answer">
          <h3 className="helpQARoot__answer-title">Пользователи соглашаются:</h3>
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">
              <a href={`${documentLinkUrl}/license_agreement/`} target="_blank" rel="noopener noreferrer">
                Пользовательское соглашение маркетплейс покупатель
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${documentLinkUrl}/license_agreement_supplier/`} target="_blank" rel="noopener noreferrer">
                Пользовательское соглашение маркетплейс поставщик
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${documentLinkUrl}/privacy_policy`} target="_blank" rel="noopener noreferrer">
                Политика конфиденциальности
              </a>
            </li>
            <li className="helpQARoot__answer-item">
              <a href={`${documentLinkUrl}/privacy_agreement`} target="_blank" rel="noopener noreferrer">
                Согласие на обработку персональных данных
              </a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderNumber: 5,
      section: "Общая информация",
      question: "Реквизиты",
      answer: (
        <div className="helpQARoot__answer">
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Полное наименование организации </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.fullTitle}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Сокращенное наименование организации </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.shortTitle}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Местонахождение </h3>
              <span className="helpQARoot__requisites-text">
                {companyDetails.address}
              </span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Адрес для корреспонденции </h3>
              <span className="helpQARoot__requisites-text">
                {companyDetails.postalAddress}
              </span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">ИНН </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.inn}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">КПП </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.kpp}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">ОКПО </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.okpo}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">ОКВЭД </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.okved}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">ОГРН </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.ogrn}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Подписант (должность) </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.ceo}</span>
            </li>
          </ul>
          <Gap size={10} />
          <h2 className="helpQARoot__requisites-caption">Банковские реквизиты</h2>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Наименование банка </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.bankTitle}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Местонахождение банка </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.bankAddress}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Номер расчетного счета </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.bankAccount}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">БИК </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.bic}</span>
            </li>
          </ul>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Корреспондентский счет </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.corrAccount}</span>
            </li>
          </ul>
          <Gap size={10} />

          <h2 className="helpQARoot__requisites-caption">Контакты</h2>
          <ul className="helpQARoot__requisites-row">
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">Телефон</h3>
              <span className="helpQARoot__requisites-text">{companyDetails.tel}</span>
            </li>
            <li className="helpQARoot__requisites">
              <h3 className="helpQARoot__requisites-title">E-mail </h3>
              <span className="helpQARoot__requisites-text">{companyDetails.email}</span>
            </li>
          </ul>
        </div>
      ),
    },

    // {
    //   orderNumber: 6,
    //   section: "Общая информация",
    //   question: "Контакты",
    //   answer: null,
    //   link: "/contacts",
    // },

    {
      orderNumber: 1,
      section: "Оформление заказа",
      question: "Кто может сделать заказ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            На данный момент наши клиенты –  юридические лица.
            Для оформления заказа пройдите, пожалуйста, быструю
            <a className="helpQARoot__answer-link" href={authUrl} target="_blank" rel="noopener noreferrer">
              {` регистрацию. `}
            </a>
            Мы всегда рады помочь и ответить на Ваши вопросы по телефону
            <a className="helpQARoot__answer-link" href={`tel: +79628404000`}>
              {`  +7 962 840 40 00.`}
            </a>

          </p>

          <Gap size={20} />
          <div className="helpQARoot__answer-contacts">
            <a className="helpQARoot__answer-contact-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.TELEGRAM}>
              <TgIcon />
            </a>
          </div>

        </div>
      ),
    },
    // {
    //   orderNumber: 2,
    //   section: "Оформление заказа",
    //   question: "Какие есть способы оформления заказа?",
    //   answer: (
    //     <div className="helpQARoot__answer">
    //       <p className="helpQARoot__answer-text">
    //         Вы оформляете заказ на сайте или в приложениях для
    //         <a className="helpQARoot__answer-link" href="https://play.google.com/store/apps/details?id=ru.ramcorp.customer" target="_blank" rel="noopener noreferrer">
    //           {` Android `}
    //         </a>
    //         и
    //         <a
    //           className="helpQARoot__answer-link"
    //           href="https://apps.apple.com/ru/app/%D1%80%D1%83%D1%81%D0%B0%D0%B3%D1%80%D0%BE%D0%BC%D0%B0%D1%80%D0%BA%D0%B5%D1%82-%D0%BF%D0%BE%D0%BA%D1%83%D0%BF%D0%B0%D1%82%D0%B5%D0%BB%D1%8C/id1543718141"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //         >
    //           {` IOS`}
    //         </a>
    //         . Мы всегда готовы помочь и ответить на ваши вопросы, для этого позвоните по номеру:
    //         <a className="helpQARoot__answer-link" href={`tel: ${contacts.phone}`}>
    //           {` ${contacts.phone_text}`}
    //         </a>
    //         .
    //       </p>
    //       <Gap size={30} />
    //       <div className="helpQARoot__answer-contacts">
    //         <a className="helpQARoot__answer-contact-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.TELEGRAM}>
    //           <TgIcon />
    //         </a>
    //         {/* <a className="helpQARoot__answer-contact-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.WHATSAPP}>
    //           <WhatsappIcon />
    //         </a> */}
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   orderNumber: 2,
    //   section: "Оформление заказа",
    //   question: "Как происходит подтверждение и завершение заказа?",
    //   answer: (
    //     <div className="helpQARoot__answer">
    //       <p className="helpQARoot__answer-text">После поступления денежных средств по вашему заказу.</p>
    //       <p className="helpQARoot__answer-text">После того как вы оформили заказ, мы передаем всем поставщикам информацию с деталями заказа – статус заказа «В обработке».</p>
    //      <Gap size={30}/>
    //       <p className="helpQARoot__answer-text">Поставщики проверяют наличие товаров, в случае необходимости корректируют точный вес товара в заказе.</p>
    //      <Gap size={30}/>
    //       <h3 className="helpQARoot__answer-title">Поставщик должен подтвердить заказ в течение следующего времени:</h3>
    //       <ul className="helpQARoot__answer-list">
    //         <li className="helpQARoot__answer-item">Если заказ поступил с 00:00 до 15:00 поставщик должен подтвердить заказ до 16:00.</li>
    //         <li className="helpQARoot__answer-item">Если заказ поступил с 15:00-00:00 поставщик должен подтвердить заказ до 11:00 следующего дня.</li>
    //       </ul>
    //      <Gap size={30}/>
    //       <p className="helpQARoot__answer-text">
    //         Когда все поставщики в заказе выполнят обработку товаров, мы отправим вам уведомление на почту и в личный кабинет маркетплейс, статус заказа меняется на «Ожидает
    //         оплаты».
    //       </p>
    //      <Gap size={30}/>
    //       <h3 className="helpQARoot__answer-title">Далее вам нужно произвести оплату ранее выбранным способом:</h3>
    //       <ul className="helpQARoot__answer-list">
    //         <li className="helpQARoot__answer-item">Если способ оплаты «банковская карта», то заказ необходимо оплатить в течение 40 мин.</li>
    //         <li className="helpQARoot__answer-item">Если способ оплаты «безналичная оплата», то заказ необходимо оплатить до 15:00 следующего дня.</li>
    //       </ul>
    //      <Gap size={30}/>
    //       <p className="helpQARoot__answer-text">
    //         После поступления денежных средств на счет, статус заказа меняется на «Ожидает отгрузки», все поставщики в заказе передают товары на комплектацию в нашу логистику.
    //       </p>
    //      <Gap size={30}/>
    //       <p className="helpQARoot__answer-text">
    //         После того как товары переданы курьеру на доставку, статус заказа меняется на «Доставка», курьер связывается с вами и уточняет удобное время.
    //       </p>
    //      <Gap size={30}/>
    //       <p className="helpQARoot__answer-text">После принятия вами товаров со всеми сопровождающими документами, статус заказа меняется на «Вручен».</p>
    //     </div>
    //   ),
    // },
    
    {
      orderNumber: 3,
      section: "Оформление заказа",
      question: "Какая минимальная сумма заказа?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Минимальная сумма заказа 7 000 рублей.
          </p>
          <p className="helpQARoot__answer-text">
            Индикатор минимальной суммы в корзине отображается синим цветом если сумма не набрана и зеленым если набрана.
          </p>
          <Gap size={10} />
          <div className="helpQARoot__answer-imgs">
            <img className="helpQARoot__answer-img half-width" src={leftTillOrder} alt="" />
            <img className="helpQARoot__answer-img half-width" src={readyForOrderImage} alt="" />
          </div>
        </div>
      ),
    },
    {
      orderNumber: 4,
      section: "Оформление заказа",
      question: "Если после подтверждения заказа сумма стала меньше минимальной, мне привезут заказ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Да, ваш заказ привезут, но сумма доставки будет пересчитана.
          </p>

        </div>
      ),
    },
    {
      orderNumber: 5,
      section: "Оформление заказа",
      question: "Как посмотреть статус заказа ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Посмотреть статус заказа можно в разделе <span className="helpQARoot__answer-accent">Заказы</span> в Личном кабинете.
          </p>
          <img className="helpQARoot__answer-img" src={orderStatusImage} alt="" />
          <h3 className="helpQARoot__answer-title">Обозначение статусов заказа:</h3>
          <span>
            <span className="helpQARoot__answer-accent">«В обработке»</span> – поставщик проверяет наличие или отсутствие товара.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Ожидает согласования»</span> – заказ подтвержден и ожидает согласования покупателем для формирования договор-счета на оплату.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Ожидает оплаты»</span> – поставщик ожидает оплаты товара покупателем.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Ожидает отгрузки»</span> – товар упакован и подготовлен к передаче перевозчику.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Доставка»</span> – товар находится у перевозчика в процессе осуществления доставки.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Вручен»</span> – покупатель принял все товары из заказа.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Вручен частично»</span> – заказ был частично вручен покупателю.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Не вручен»</span> – заказ был полностью не вручен покупателю.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Отменен»</span> – заказ был отменен по одной из причин: отсутствие товаров на складе, истекло время на оплату заказа, покупатель самостоятельно отменил заказ.
          </span>
          <span>
            <span className="helpQARoot__answer-accent">«Завершен»</span> – доставка товаров была произведена и получена оплата по заказу от покупателя.
          </span>
        </div>
      ),
    },
    {
      orderNumber: 6,
      section: "Оформление заказа",
      question: "Как отменить заказ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Вы можете бесплатно отменить заказ до присвоения заказу статуса «Доставка».
            Стоимость доставки заказа не возвращается, если он был отменен после получения статуса «Доставка».
          </p>
        </div>
      ),
    },

    {
      orderNumber: 1,
      section: "Доставка",
      question: "Какие сроки и время доставки ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">Дата доставки зависит от способа оплаты и времени поступления средств по вашему заказу.</p>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Способ оплаты «Банковская карта»</h3>
          <p className="helpQARoot__answer-text">
            Дата доставки определяется в зависимости от времени оформления заказа. Если заказ оформлен до 15:00, то дата доставки следующий день. Если после 15:00, то дата доставки
            через день.
          </p>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Способ оплаты «Безналичная оплата»</h3>
          <p className="helpQARoot__answer-text">Дата доставки на третий рабочий день от даты создания заказа.</p>
          <p className="helpQARoot__answer-text"> Доставка заказов осуществляется ежедневно с 08:00 до 20:00</p>
        </div>
      ),
    },
    {
      orderNumber: 2,
      section: "Доставка",
      question: "Стоимость доставки",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            В стоимость доставки заказов в
            <a href="/help/2/3" className="helpQARoot__answer-link">
              {` зоне покрытия `}
            </a>
            (г. Новосибирск и область) входит два вида услуг
            <span className="helpQARoot__answer-accent">{` «Складские услуги» `}</span>
            и
            <span className="helpQARoot__answer-accent">{` «Логистические услуги»`}</span>:
          </p>
          <Gap size={25} />
          <div className="helpQARoot__answer-table-container">
            <table className="helpQARoot__answer-table">
              <thead >
                <tr >
                  <th className="helpQARoot__answer-table-cell">Общий вес корзины/заказа</th>
                  <th className="helpQARoot__answer-table-cell">от 0,1 до 400 кг.</th>
                  <th className="helpQARoot__answer-table-cell">от 400 кг. до 1, 2 т.</th>
                  <th className="helpQARoot__answer-table-cell">от 1,2т до 2,7 т.</th>
                  <th className="helpQARoot__answer-table-cell">от  2,7 т. до 4,7 т</th>
                  <th className="helpQARoot__answer-table-cell">от 4,7 до 9,7 т</th>
                  <th className="helpQARoot__answer-table-cell">от 9,7 до 17 т</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="helpQARoot__answer-table-cell">Логистические услуги (доставка, возврат товара и документов)</td>
                  <td className="helpQARoot__answer-table-cell">8,74 р./ кг.</td>
                  <td className="helpQARoot__answer-table-cell">2 700 руб.</td>
                  <td className="helpQARoot__answer-table-cell">5 200 руб.</td>
                  <td className="helpQARoot__answer-table-cell">6 000 руб.</td>
                  <td className="helpQARoot__answer-table-cell">7 600 руб.</td>
                  <td className="helpQARoot__answer-table-cell">11 500 руб.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Gap size={25} />
          <span>
            * Если ваш адрес не входит в зону доставки, то вы можете оставить заявку на заказ по номеру телефона
            <a href={`tel: ${contacts.phone}`} className="helpQARoot__answer-link phone">
              {` ${contacts.phone_text}`}
            </a>

          </span>
          <Gap size={20} />
          <div className="helpQARoot__answer-contacts">
            <a className="helpQARoot__answer-contact-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.TELEGRAM}>
              <TgIcon />
            </a>
            {/* <a className="helpQARoot__answer-contact-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.WHATSAPP}>
              <WhatsappIcon />
            </a> */}
          </div>
        </div>
      ),
    },
    {
      orderNumber: 3,
      section: "Доставка",
      question: "Что будет, если я закажу сразу у нескольких поставщиков ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Вы получите один сборный заказ, с единой минимальной суммой заказа и доставкой по цене ниже, чем у сторонних логистических операторов.
          </p>
          <Gap size={30} />
          <span>* Сборный заказ - это объединение товаров нескольких поставщиков для перевозки в одном транспортном средстве.</span>
        </div>
      ),
    },
    {
      orderNumber: 4,
      section: "Доставка",
      question: "Какая зона доставки?",
      answer: (
        <div className="helpQARoot__answer">
          <h3 className="helpQARoot__answer-title">
            {`Если ваш адрес не входит в зону доставки, то вы можете оставить заявку на заказа по номеру телефона
            ${contacts.phone_text}.`}
          </h3>

          <span>* Объём минимального заказа определяется индивидуально, стоимость доставки зависит от состава заказа и региона доставки.</span>
          <Gap size={20} />
          <h3 className="helpQARoot__answer-title">На данный момент сервис доступен в г. Новосибирск, а также в следующих населенных пунктах:</h3>
          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">Новосибирская обл, рп Коченево</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Коченевский р-н, поселок Светлый</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Коченевский р-н, рп Чик</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Коченевский р-н, село Прокудское</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Коченевский р-н, деревня Буньково</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Коченевский р-н, деревня Чик</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Коченевский р-н, село Катково</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, село Криводановка</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, г Обь</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, село Красноглинное</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, село Толмачево</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, село Марусино</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, поселок Приобский</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, дп Кудряшовский</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, поселок Красный Восток</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, поселок 8 Марта</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, поселок Элитный</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, рп Краснообск</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, поселок Юный Ленинец</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, поселок Мичуринский</li>
            <li className="helpQARoot__answer-item">Новосибирская обл, Новосибирский р-н, поселок Красномайский</li>
          </ul>
          <Gap size={30} />
          {/* <img className="helpQARoot__answer-img" src={deliveryMapImage} /> */}
          <iframe
            className="helpQARoot__answer-iframe"
            title="Зона доставки"
            id="map_223302728"
            frameBorder="0"
            width="100%"
            height="600px"
            sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
          ></iframe>
        </div>
      ),
    },
    {
      orderNumber: 5,
      section: "Доставка",
      question: "Правила доставки",
      answer: null,
      link: `${documentLinkUrl}/deliveryrules`,
    },
    {
      orderNumber: 6,
      section: "Доставка",
      question: "Самовывоз",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">
            Если вы хотите самостоятельно забрать заказ, то на этапе оформления заказа выберите способ получения “Самовывоз” и выберите удобный слот для приезда по адресу:
            Новосибирская область, Обь городской округ, нп. 3307 км, 30кВ1.
          </p>
          <Gap size={10} />
          <p className="helpQARoot__answer-text">
            Контактный телефон
            <a className="helpQARoot__answer-link" href={`tel: +79628404000`}>
              {`  +7 962 840 40 00.`}
            </a>
          </p>
          <Gap size={20} />
          <div className="helpQARoot__answer-contacts">
            <a className="helpQARoot__answer-contact-icon" target="_blank" rel="noopener noreferrer" href={CONTACTS.TELEGRAM}>
              <TgIcon />
            </a>
          </div>
          <Gap size={30} />
          <img className="helpQARoot__answer-img" src={pickup} />

        </div>
      ),
    },
    {
      orderNumber: 7,
      section: "Доставка",
      question: "Какие документы приезжают с заказом? ",
      answer: (
        <div className="helpQARoot__answer">
          <ul className="helpQARoot__answer-list ">
            <li className="helpQARoot__answer-item">
              УПД
            </li>
            <li className="helpQARoot__answer-item">
              Трансортная накладная
            </li>
            <li className="helpQARoot__answer-item">
              Счёт-Договор
            </li>
          </ul>

        </div>
      ),
    },
   
    {
      orderNumber: 1,
      section: "Оплата",
      question: "Какие есть способы оплаты ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">При оформлении заказа вы можете выбрать один из следующих способов оплаты:</p>
          <Gap size={30} />
          <img className="helpQARoot__answer-img" src={howToPayImage} alt="" />
          <h3 className="helpQARoot__answer-title">Отсрочка платежа</h3>

          <ul className="helpQARoot__answer-list">
            <li className="helpQARoot__answer-item">Отсрочка платежа становится доступной после оплаты первого заказа по предоплате.</li>
            <li className="helpQARoot__answer-item">Покупатель также может направить заявку на отсрочку без оплаты первого заказа по предоплате, связавшись с оператором по номеру телефона
              <a className="helpQARoot__answer-link" href={`tel: +79628404000`}>
                {`  +7 962 840 40 00.`}
              </a>
            </li>
            <li className="helpQARoot__answer-item">Покупателю по умолчанию предоставляется лимит на 50 тысяч рублей и 14 календарных дней на оплату каждого заказа.
            </li>
            <li className="helpQARoot__answer-item">Оператор может предусмотреть индивидуальные условия по предоставлению отсрочки по договоренности.
            </li>
            <li className="helpQARoot__answer-item">Если превышен срок погашения оплаты хотя бы на один день, то способ оплаты по отсрочке платежа блокируется.
            </li>
          </ul>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Предоплата по счету </h3>
          <p>
          Оплата заказа производится по договор-счету, который будет выставлен после вашего согласования по кнопке “Заключить договор”.  Нажимая кнопку “Заключить договор” вы принимаете и соглашаетесь с условиями  Договор-счета.
          </p>          
          <Gap size={20} />
          <img className="helpQARoot__answer-img seventy-width" src={prepayment} alt="" />          
        </div>
      ),
    },
    {
      orderNumber: 2,
      section: "Оплата",
      question: "В какие сроки нужно оплатить заказ ?",
      answer: (
        <div className="helpQARoot__answer">
          <h3 className="helpQARoot__answer-title">Если способ оплаты «Банковская карта»</h3>
          <p className="helpQARoot__answer-text">
            Оплата заказа должна осуществляться в течение 40 минут с момента получения подтверждения заказа от поставщиков (статус заказа «Ожидает оплаты»).
          </p>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Если способ оплаты «Безналичная оплата»</h3>
          <p className="helpQARoot__answer-text">
            Заказ должен быть оплачен по счету в течение 1 банковского дня с момента получения подтверждения заказа от поставщиков (статус заказа «Ожидает оплаты»).
          </p>
          <Gap size={30} />
          <span>* При оформлении заказа в пятницу, оплата должна быть произведена не позднее 16:00 текущего дня.</span>
          <span>* Вы оплачиваете только те товары, которые есть в наличии.</span>
        </div>
      ),
    },
    {
      orderNumber: 3,
      section: "Оплата",
      question: "Как осуществляются возвраты ?",
      answer: (
        <div className="helpQARoot__answer">
          <p className="helpQARoot__answer-text">Вы всегда можете отказаться заказа полностью или частично, если вас не устроили сроки годности или качество товаров.</p>
          <Gap size={30} />
          <p className="helpQARoot__answer-text">
            Если вы хотите вернуть товар при получении, формируется акт расхождения. После составления акта, товары возвращается на ОРЦ, после чего вы получаете возврат денежных
            средств в течение 10 рабочих дней.
          </p>
          <Gap size={30} />
          <span>* Возврат и обмен товара ненадлежащего качества осуществляется в сроки, установленные действующим Законодательством.</span>
          <span>* Вы оплачиваете только те товары, которые есть в наличии.</span>
          <span>* Не подлежат возврату или обмену продовольственные товары надлежащего качества.</span>
          <span>* При отказе Покупателя от товара, денежные средства за него будут автоматически возвращены на банковскую карту, с которой указанный товар был оплачен.</span>
        </div>
      ),
    },
    {
      orderNumber: 4,
      section: "Оплата",
      question: "Эквайринг КИВИ",
      answer: (
        <div className="helpQARoot__answer">
          <Gap size={15} />
          <h3 className="helpQARoot__answer-title">Реквизиты:</h3>
          <p className="helpQARoot__answer-text">Полное наименование: КИВИ Банк (акционерное общество)</p>
          <p className="helpQARoot__answer-text">Сокращенное наименование: КИВИ Банк (АО)</p>
          <p className="helpQARoot__answer-text">Наименование на иностранном языке: QIWI Bank (Joint-stock company)</p>
          <Gap size={30} />
          <span>БИК 044525416</span>
          <span>ИНН/КПП 3123011520/772601001</span>
          <span>ОКПО 22316525</span>
          <span>ОКОНХ 96120, ОКВЭД 64.19</span>
          <span>ОГРН 1027739328440, дата внесения записи «07» октября 2002 г.</span>
          <Gap size={30} />
          <span>Лицензия № 2241 Дата выдачи лицензии — 22.01.15</span>
          <span>Договор № ПИ-И-02067/2021 от 02 ноября 2021 г.</span>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Контакты: </h3>
          <span>Местонахождение: 117648, г. Москва, мкр. Чертаново Северное, д. 1А, корп. 1</span>
          <span>Тел.: +7 (495) 231-36-45/46 Факс: 231-36-47</span>
          <span>Корр. счет: 30101810645250000416</span>
          <span>в ГУ Банка России по Центральному федеральному округу</span>
        </div>
      ),
    },
    {
      orderNumber: 5,
      section: "Оплата",
      question: "Платежный агрегатор",
      answer: (
        <div className="helpQARoot__answer">
          <Gap size={15} />
          <p className="helpQARoot__answer-text">Полное наименование: Общество с ограниченной ответственностью «РАМ-Дистрибуция»</p>
          <p className="helpQARoot__answer-text">Сокращенное наименование: ООО «РАМ-Дистрибуция»</p>
          <Gap size={20} />
          <span>ИНН 5433977525</span>
          <span>КПП 543301001</span>
          <span>ОГРН 1215400019845</span>
          <span>ОКПО 61993222</span>
          <span>БИК 044525999</span>
          <Gap size={20} />
          <span>Генеральный директор Харченко Михаил Михайлович</span>
          <Gap size={30} />
          <h3 className="helpQARoot__answer-title">Контакты: </h3>
          <span>Местонахождение: 633102, Новосибирская обл., Новосибирский м.р-н, с.п. Толмачевский сельсовет, платф. 3307 км, д. 30, к. В1, помещ. 110</span>
          <span>Тел.: + 7 (495) 545-35-40</span>
          <Gap size={30} />
          <span>
            Претензии просьба направлять на <a href="support@rusagro.market">support@rusagro.market</a> .
          </span>
          <span>Срок рассмотрения 14 дней. Ответ придет на электронный адрес, с которого было направлено обращение.</span>
        </div>
      ),
    },
    // {
    //   orderNumber: 5,
    //   section: "Оплата",
    //   question: "Отсрочка платежа",
    //   answer: (
    //     <div className="helpQARoot__answer">
    //       <Gap size={15} />
    //       <ul className="helpQARoot__answer-list">
    //         <li className="helpQARoot__answer-item">Отсрочка платежа становится доступной после оплаты первого заказа по предоплате.</li>
    //         <li className="helpQARoot__answer-item">Покупатель также может направить заявку на отсрочку без оплаты первого заказа по предоплате, связавшись с оператором по номеру телефона
    //           <a href={`tel: ${contacts.phone}`} className="helpQARoot__answer-link phone">
    //             {` ${contacts.phone_text}`}
    //           </a>
    //           .</li>
    //         <li className="helpQARoot__answer-item">Покупателю по умолчанию предоставляется лимит на 50 тысяч рублей и 14 дней на оплату каждого заказа.</li>
    //         <li className="helpQARoot__answer-item">Оператор может предусмотреть индивидуальные условия по предоставлению отсрочки по договоренности. </li>
    //         <li className="helpQARoot__answer-item">Если превышен срок погашения оплаты хотя бы на один день, то способ оплаты по отсрочке платежа блокируется.</li>

    //       </ul>
    //     </div>
    //   ),
    // },

    // {
    //   orderNumber: 1,
    //   section: "Юридические документы",
    //   question: "Пользовательское соглашение маркетплейс покупатель",
    //   answer: null,
    //   link: `${url}/license_agreement`,
    // },
    // {
    //   orderNumber: 2,
    //   section: "Юридические документы",
    //   question: "Пользовательское соглашение маркетплейс поставщик",
    //   answer: null,
    //   link: `${url}/privacy_policy/`,
    // },
    // {
    //   orderNumber: 3,
    //   section: "Юридические документы",
    //   question: "Пользовательское соглашение мобильное приложение покупатель",
    //   answer: null,
    //   link: `${url}/mobile_license_agreement/`,
    // },
    // {
    //   orderNumber: 4,
    //   section: "Юридические документы",
    //   question: "Политика конфиденциальности",
    //   answer: null,
    //   link: `${url}/privacy_policy`,
    // },
    // {
    //   orderNumber: 5,
    //   section: "Юридические документы",
    //   question: "Согласие на обработку персональных данных",
    //   answer: null,
    //   link: `${url}/privacy_agreement`,
    // },
    // {
    //   orderNumber: 6,
    //   section: "Юридические документы",
    //   question: "Смотреть все",
    //   answer: null,
    //   link: null,
    // },
  ],

  dataSuppliers: [
    {
      orderNumber: 1,
      section: "Поставщикам",
      question: "Тарифы площадки",
      answer: (
        <div className="helpQARoot__answer">
          <h3 className="helpQARoot__answer-title">Для старта продаж на rusagro.market мы предоставляем льготный тестовый период с 01.04.2023г. по 01.07.2023г. стоимость хранения до двух палето-мест включена в стоимость комиссии сервиса! </h3>
          <Gap size={10} />
          <h3 className="helpQARoot__answer-title">Комиссия за заказ на <img className="helpQARoot__answer-logo" src={logo_leaves} alt="Логотип РусагроМаркет" /> русагро.маркет: </h3>
          <span>Комиссия за складские услуги – 3,86 р/кг</span>
          <Gap size={5} />
          <span>Комиссия сервиса:</span>
          <ul className="helpQARoot__answer-list ">
            <li className="helpQARoot__answer-item">
              до 2 млн. руб. – 6%
            </li>
            <li className="helpQARoot__answer-item">
              от 2 млн. руб. до 5 млн.руб – 3%
            </li>
            <li className="helpQARoot__answer-item">
              более 5 млн.руб. – 2%
            </li>
          </ul>
          <Gap size={20} />
          <span>Если сумма в заказе меньше 2 млн. руб., то комиссия платформы будет составлять 6% за врученные или частично врученные товары в заказе.
          </span>
          <Gap size={20} />
          <span>Если сумма в заказе в диапазоне от 2 млн. до 5 млн руб., то на товары покупателю предоставляется скидка в размере 3%.
          </span>
          <Gap size={20} />
          <span>Если сумма в заказе больше 5 млн. руб., то на товары покупателю предоставляется скидка в размере 2%.
          </span>
          <Gap size={20} />

          <h3 className="helpQARoot__answer-title">Тарифы на ответственное хранение:</h3>
          <ul className="helpQARoot__answer-list ">
            <li className="helpQARoot__answer-item">
              Хранение в режиме от +2 до + 15 – 31,6 руб. палето-место/сутки
            </li>
            <li className="helpQARoot__answer-item">
              Хранение в режиме от -18 до - 22 – 45,6 руб. палето-место/сутки
            </li>

          </ul>
          <Gap size={20} />
          <span>* Цены приведены с учетом НДС</span>
        </div>
      ),
    },
  ],
};
