import * as React from "react";
import "./ActiveBar.scss";
import { ReactComponent as NotificationIcon } from "../../../images/notification.svg";
import { ReactComponent as CartIcon } from "../../../images/shopping-cart.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ProfileDropDown from "../ProfileDropDown/ProfileDropDown";
import NotificationButton from "../NotificationButton/NotificationButton";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import Search from "../Search/Search";
import CatalogButton from "../CatalogButton/CatalogButton";
import { toggleCategoriesMenu } from "../../../actions/category";
import LoginButton from "./LoginButton";
import classNames from "classnames";
import { checkAddressExist, setActiveStep } from "../../../actions/cart";
import { withRouter } from "react-router";
import MiniCart from "../../Cart/MiniCart/MiniCart";
import MyCompanies from "../MyCompanies/MyCompanies";

class ActiveBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postModalAction: null,
      showMiniCart: false,
      keepMiniCart: false,
      notificationsShown: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { addressData, addressModalVisible, history } = this.props;
    const { postModalAction } = this.state;
    if (addressModalVisible !== prevProps.addressModalVisible && !addressModalVisible) {
      if (addressData && postModalAction) {
        history.push("/cart");
        this.setState({ postModalAction: null });
      }
    }
  }

  toggleShowNotifications = () => {
    const {notificationsShown} = this.state;
    this.setState({notificationsShown: !this.state.notificationsShown})
  }

   toggleShowCategoriesMenu = () => {
    const { showCategoriesMenu, toggleCategoriesMenu } = this.props;

    toggleCategoriesMenu(!showCategoriesMenu);
  };

  setMiniCartVisible = (showMiniCart) => {
    setTimeout(() => {
      this.setState({ showMiniCart });
    }, 300);
  };

  keepMiniCartVisible = (keepMiniCart) => {
    this.setState({ keepMiniCart });
  };

  onCardClick = () => {
    const { checkAddressExist, history, setActiveStep } = this.props;
    setActiveStep(0);
    if (!checkAddressExist()) {
      this.setState({ postModalAction: "cart" });
    } else {
      history.push("/cart");
    }
  };

  render() {
    const { isMobile, isTablet, isDesktop, cartItems, loggedIn, showCategoriesMenu, accountType, shown, notificationCount, onClick, fixedHeader } = this.props;
    const { showMiniCart, keepMiniCart, notificationsShown } = this.state;
    return (
      <div className={classNames({ "active-bar": true, [accountType]: true })}>
        <div className="collapsable">
          <CatalogButton categoriesMenuActive={showCategoriesMenu} toggleShowCategoriesMenu={this.toggleShowCategoriesMenu} accountType={accountType} />
          <Search isMobile={isMobile} fixedHeader={fixedHeader} />
        </div>
        {isDesktop && (
          <div className="user-bar">
            {loggedIn && <MyCompanies accountType={accountType} isMobile={false} />}
            {loggedIn && (
              <NotificationButton icon={<NotificationIcon />} link_to="#" notificationCount={notificationCount} accountType={accountType} shown={notificationsShown} onClick={this.toggleShowNotifications} />
            )}
            <NotificationButton
              icon={<CartIcon />}
              notificationCount={cartItems ? cartItems.itemsCount : 0}
              accountType={accountType}
              onClick={() => this.onCardClick()}
              onPointerEnter={() => this.setMiniCartVisible(true)}
              onPointerLeave={() => this.setMiniCartVisible(false)}
            />
            {loggedIn && <ProfileDropDown />}
            {!loggedIn && <LoginButton />}
            {isDesktop && <MiniCart visible={showMiniCart || keepMiniCart} onPointer={(visible) => this.keepMiniCartVisible(visible)} />}
          </div>
        )}
        {notificationsShown && <NotificationPopup onClick={this.toggleShowNotifications} shown={notificationsShown} />}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
  showCategoriesMenu: state.categories.showCategoriesMenu,
  loggedIn: state.profile.loggedIn,
  accountType: state.profile.accountType,
  registration: state.cart.registration,
  isMobile: state.api.isMobile,
  isTablet: state.api.isTablet,
  isDesktop: state.api.isDesktop,
  addressData: state.cart.registration.addressData,
  addressModalVisible: state.cart.addressModalVisible,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleCategoriesMenu: toggleCategoriesMenu,
      checkAddressExist: checkAddressExist,
      setActiveStep: setActiveStep,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActiveBar));
