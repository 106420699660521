import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { ReactComponent as UploadIcon } from "../../../images/upload.svg";
import { ReactComponent as DeleteIcon } from "../../../images/delete.svg";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { deleteUserPhoto } from "../../../actions/profile";
import "./PhotoUploader.scss";
import { getHeaders } from "../../../helpers/rest";
import { useDispatch } from "react-redux";

const Input =
  (iconUrl, uploadMode, error, handleDeletePhoto) =>
    ({ accept, onFiles, files, getFilesFromEvent }) => {
      const errorText = error === "rejected_file_type" ? "Формат фото не поддерживается, доступные jpg, jpeg, png" : error === "error_file_size" ? "Размер фото превышает 1 Мб" : "";

      return (
        <div className="photo-panel">
          <div className="caption">Фото Профиля</div>
          <label>
            <div className="drop-zone-wrapper">
              <div className="drop-zone">
                <UploadIcon />
                <div className="uploader-caption">Нажмите, чтобы выбрать фото или перетяните</div>
                {/* <img src={uploadAreaImg} /> */}
                {/* <UploadArea className="area" /> */}
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept={accept}
                  onChange={(e) => {
                    getFilesFromEvent(e).then((chosenFiles) => {
                      onFiles(chosenFiles);
                    });
                  }}
                />
                <span className="drop-zone-error">{errorText}</span>
              </div>
            </div>
          </label>
          {iconUrl && <div className="caption">Текущие фото:</div>}
          {uploadMode && (
            <div className="spinner">
              <div className="preloader">
                <div></div>
              </div>
            </div>
          )}
          {iconUrl && !uploadMode &&

            <div className="photo-wrapper">
              <img src={iconUrl} className="photo" alt={"текущее фото"} />
              <div className="photo-delete">
                <DeleteIcon onClick={() => handleDeletePhoto()} />
              </div>
            </div>


          }
        </div>
      );
    };

const PhotoUploader = ({ iconUrl, userId, getUser, refresh }) => {
  const [error, setError] = useState("");
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const dispatch = useDispatch();

  const handleDeletePhoto = () => {
    dispatch(deleteUserPhoto()).then(() => refresh());
  }

  const onChangeStatus = (fileWithMeta, status) => {
    setError(status);
    console.log(fileWithMeta.file.size);

    if (status === "ready" && fileWithMeta.file.size < 1048576) {
      fileWithMeta.restart();
    }
    if (status === "preparing" && fileWithMeta.file.size >= 1048576) {
      fileWithMeta.remove();
    }
    if (status === "done") {
      getUser().then(() => fileWithMeta.remove());
    }
  };

  return (
    <Dropzone
      accept="image/*"
      getUploadParams={(data) => {
        const body = new FormData();
        body.append("file", data.file);
        return { body, url: `/api/buyer/v2.0/user-profile/users/${userId}/icon.upload`, headers: getHeaders(true, null, true) };
      }}
      onChangeStatus={onChangeStatus}
      maxFiles={1}
      maxSizeBytes={1048576}
      autoUpload={false}
      InputComponent={Input(iconUrl, false, error, handleDeletePhoto)}
      SubmitButtonComponent={null}
      PreviewComponent={Input(null, true, error)}
      getFilesFromEvent={getFilesFromEvent}
    />
  );
};

export default PhotoUploader;
