import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./PersonalInfo.scss";
import Input from "../../commons/RamInput/Input";
import PhoneChangeInput from "./PhoneChangeInput";
import EmailInput from "./EmailInput";
import DateInput from "./DateInput";
import DropDown from "../../commons/DropDown/DropDown";
import PhotoUploader from "../PhotoUploader/PhotoUploader";
import Button from "../../commons/Button/Button";
import { getUser, updateUser } from "../../../actions/profile";
import CheckBox from "../../commons/CheckBox/CheckBox";
import ConfirmationDialog from "../../Profile/ConfirmationBar/ConfirmationDialog";
import moment from "moment";
import { setSystemErrorMessage } from "../../../actions/api";
moment.locale('ru')


const GENDERS = [
  { text: "Женский", value: "Female" },
  { text: "Мужской", value: "Male" },
];

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      editUser: {},
      modalVisible: false,
    };
  }
  componentDidMount() {
    this.init();
  }

  init() {
    const { user } = this.props;

    const { firstName, middleName, lastName, email, phoneNumber,
      gender, dateOfBirth,
    } = user;

    const editUser = {
      firstName,
      middleName,
      lastName,
      email,
      phoneNumber,
      gender: gender ? gender.code : "NotSpecified",
      dateOfBirth: dateOfBirth !== "0001-01-01" ? moment(dateOfBirth).format("MM/DD/YYYY") : "",
    };

    this.setState({
      editUser,

    });
  }

  handleFieldChange = (fieldName) => (value) => {
    const { editUser } = this.state;
    editUser[fieldName] = value;
    this.setState({ editUser });
  };


  save = () => {
    const { updateUser, setSystemErrorMessage } = this.props;
    const { editUser } = this.state;
    const { dateOfBirth } = editUser;

    const data = { ...editUser }

    if (dateOfBirth && dateOfBirth !== "Invalid date") {
      data.dateOfBirth = moment(editUser.dateOfBirth).format("yyyy/MM/DD");
    } else {
      delete data.dateOfBirth;

    };

    updateUser(data)
      .then((res) => {
        res.isUpdated && this.setState({ modalVisible: true });
        res.isUpdated && setSystemErrorMessage();
      })
      .then(this.refresh)
  };

  refresh = () => {
    const { getUser } = this.props;

    return getUser().then(() => {
      this.init();
    });
  };

  cancel = () => {
    this.init();
  };

  render() {
    const { getUser, user, isMobile, systemErrors } = this.props;
    const { modalVisible, editUser } = this.state;
    const { firstName, lastName, email, phoneNumber,
      gender, dateOfBirth
      //  canSendEmailNotifications,
      //   canSendPushAndSmsNotifications 
    } = editUser;

    const onClose = () => {
      this.setState({ modalVisible: false });
    };

    return (
      <div className="personal">
        <div className="info">
          <Input title="Имя" value={firstName} onChange={this.handleFieldChange("firstName")} name="firstName"
            hasErrors={!!systemErrors?.errors?.find((item) => item.key === "firstName")}
            errorText={systemErrors?.errors?.find((item) => item.key === "firstName")?.value} />
          <Input title="Фамилия" value={lastName} onChange={this.handleFieldChange("lastName")} name="lastName"
            hasErrors={!!systemErrors?.errors?.find((item) => item.key === "lastName")}
            errorText={systemErrors?.errors?.find((item) => item.key === "lastName")?.value}
          />
          <DropDown title="Ваш пол" value={gender} onChange={this.handleFieldChange("gender")} selectValues={GENDERS}
            hasErrors={!!systemErrors?.errors?.find((item) => item.key === "gender")}
            errorText={systemErrors?.errors?.find((item) => item.key === "gender")?.value}

          />
          <DateInput handleFieldChange={this.handleFieldChange("dateOfBirth")} name="dateOfBirth" value={dateOfBirth} />
          <PhoneChangeInput title="Телефон" value={phoneNumber} onChange={this.handleFieldChange("phoneNumber")} userPhone={user.phoneNumber} onSubmitPhoneOrEmail={this.save}
          // confirmed={user.phoneNumberConfirmed}
          />
          <EmailInput title="E-mail" value={email} onChange={this.handleFieldChange("email")} userEmail={user.email} onSubmitPhoneOrEmail={this.save}
          // confirmed={user.emailConfirmed}
          />
          {/* <h3 className="notifications-title">Уведомления</h3> */}
          {/* <CheckBox
            style={{ marginBottom: "10px" }}
            checked={canSendEmailNotifications}
            label="Уведомления на почту"
            onChange={this.handleFieldChange("canSendEmailNotifications")}
          />
          <CheckBox checked={canSendPushAndSmsNotifications} label="Уведомления по смс" onChange={this.handleFieldChange("canSendPushAndSmsNotifications")} /> */}

          <div className="action-buttons">
            <Button title="Сохранить изменения" buttonSize="medium" buttonType="primary" width={!isMobile ? "224px" : "100%"} onClick={() => this.save()} />
          </div>
        </div>
        <PhotoUploader iconUrl={user.iconUrl} userId={user.id} getUser={getUser} refresh={this.refresh} />
        <ConfirmationDialog type="save" onClose={onClose} visible={modalVisible} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.profile.user,
  iconUrl: state.profile.data.iconUrl,
  isMobile: state.api.isMobile,
  systemErrors: state.api.systemErrors
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: getUser,
      updateUser: updateUser,
      setSystemErrorMessage: setSystemErrorMessage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
