import React from 'react';
import "./SuppliersLanding.scss";
import SuppliersLandingAbout from './SuppliersLandingAbout/SuppliersLandingAbout';
import SuppliersLandingApplication from './SuppliersLandingApplication/SuppliersLandingApplication';
import SuppliersLandingBanner from './SuppliersLandingBanner/SuppliersLandingBanner';
import SuppliersLandingCooperation from './SuppliersLandingCooperation/SuppliersLandingCooperation';
import SuppliersLandingFAQ from './SuppliersLandingFAQ/SuppliersLandingFAQ';
import SuppliersLandingFeatures from './SuppliersLandingFeatures/SuppliersLandingFeatures';
import SuppliersLandingHowBecome from './SuppliersLandingHowBecome/SuppliersLandingHowBecome';
import SuppliersLandingSmallBanner from './SuppliersLandingSmallBanner/SuppliersLandingSmallBanner';

const SuppliersLanding = () => {
  return (
    <div className='supplier-landing'>
      <SuppliersLandingBanner />
      <SuppliersLandingAbout />
      <SuppliersLandingFeatures />
      <SuppliersLandingHowBecome />
      <SuppliersLandingCooperation />
      <SuppliersLandingSmallBanner />
      <SuppliersLandingApplication />      
      <SuppliersLandingFAQ />
    </div>
  )
}

export default SuppliersLanding