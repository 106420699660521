import * as React from "react";
import "./MyCompanies.scss";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as PlusIcon } from "../../../images/plus.svg";
import MyCompaniesList from "./MyCompaniesList";
import { fetchMyCompanies, fetchMyCompanyById, updateMyCompany, getEdoInfoList } from "../../../actions/profile";
import queryString from "query-string";
import Button from "../../commons/Button/Button";
import { withRouter } from "react-router";
import EditMyCompany from "./EditMyCompany";
import AddMyCompanyModal from "../../Header/MyCompanies/AddMyCompanyModal/AddMyCompanyModal";
import AddEdoOperatorModal from "../../Header/MyCompanies/AddEdoOperatorModal/AddEdoOperatorModal";

class MyCompanies extends React.Component {
  state = { isLoading: true, company: null, companyId: null, pageSize: 10, pageOffset: 0, pageNumber: 1, addCompanyVisible: false, edoOperatorModalVisible: false };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const query = queryString.parse(this.props.location.search);
    const prevQuery = queryString.parse(prevProps.location.search);
    const { pageSize, pageOffset } = this.state;

    if (query.companyId !== prevQuery.companyId) {
      this.init();
    }
    if (prevState.pageSize !== pageSize) {
      this.loadMyCompanies();
    }
    if (prevState.pageOffset !== pageOffset) {
      this.loadMyCompanies();
    }
  }

  init() {
    const { getEdoInfoList, fetchMyCompanyById } = this.props;
    const companyId = queryString.parse(this.props.location.search)?.companyId;
    this.setState({ companyId });

    if (companyId) {
      getEdoInfoList(companyId);
      fetchMyCompanyById(companyId).then((company) => {
        this.setState({ company, isLoading: false });
      });
    } else {
      this.loadMyCompanies();
    }
  }

  loadMyCompanies = () => {
    const { fetchMyCompanies } = this.props;
    const { pageSize, pageOffset } = this.state;
    fetchMyCompanies(pageSize, pageOffset).finally(() => {
      this.setState({ isLoading: false });
    });
  };

  onEdit = (companyId, type) => {
    type === "bankInfo" && this.setState({ addCompanyVisible: true, companyId })
    type === "edo" && this.setState({ edoOperatorModalVisible: true, companyId })
  };

  onCloseAddCompanyModal = () => {
    this.setState({ addCompanyVisible: false });
    this.init();
  };

  onCloseEdoOperatorModal = () => {
    this.setState({ edoOperatorModalVisible: false });
    this.init();
  };

  render() {
    const { companyId, company, addCompanyVisible, edoOperatorModalVisible } = this.state;
    const { isMobile } = this.props;
    const editMode = companyId && company;
    return (
      <section className="my-companies-page">
        {!editMode && <Button title="Добавить " width="162px" buttonSize="medium" buttonType="ghost" icon={<PlusIcon />} onClick={() => this.onEdit(null)} />}
        {!editMode && <MyCompaniesList />}
        {editMode && <EditMyCompany company={company} onRefresh={() => this.init()} onEdit={(companyId, type) => this.onEdit(companyId, type)} isMobile={isMobile} />}
        <AddMyCompanyModal visible={addCompanyVisible} onClose={this.onCloseAddCompanyModal} company={companyId ? company : null} editMode={editMode} />
        <AddEdoOperatorModal visible={edoOperatorModalVisible} onClose={this.onCloseEdoOperatorModal} company={companyId ? company : null} />
    
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  myCompanies: state.profile.myCompanies.results,
  isMobile: state.api.isMobile,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMyCompanies: fetchMyCompanies, fetchMyCompanyById: fetchMyCompanyById, updateMyCompany: updateMyCompany, getEdoInfoList }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyCompanies));
