import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./ProfilePage.scss";
import MenuBar from "./MenuBar/MenuBar";
// import ProfileBar from "./ProfileBar/ProfileBar";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import OrdersInfo from "./OrdersInfo/OrdersInfo";
import OrganisationInfo from "./OrganisationInfo/OrganisationInfo";
import Dashboard from "./Dashboard/Dashboard";
import { getAccount, setSection, logout } from "../../actions/profile";
import { ReactComponent as ExitIcon } from "../../images/power-off.svg";
import BackButton from "../commons/BackButton/BackButton";
import FavoriteProducts from "./Favorites/FavoritesProducts";
import FavoritesSuppliers from "./Favorites/FavoritesSuppliers";
import Breadcrumbs from "../Product/Breadcrumbs/Breadcrumbs";
import { withRouter } from "react-router";
import PaymentDelayInfo from "./PaymentDelayInfo/PaymentDelayInfo";
import MyCompanies from "./MyCompanies/MyCompanies";

const { env } = global.config;
const isDacnhik = env === "dachnik";

const SECTION_TITLES = {
  "profile/dashboard": { title: "Личный кабинет", documentTitle: "Личный кабинет" },
  "profile/personal": { title: "Данные пользователя", documentTitle: "Мои данные" },
  "profile/my-companies": { title: "Мои компании", documentTitle: "Мои компании" },
  "profile/organisation": { title: "Организация", documentTitle: "Информация о компании" },
  "profile/orders": { title: "Заказы", documentTitle: "История заказов" },
  "profile/favorites/products": { title: "Избранные товары", documentTitle: "Избранные товары" },
  "profile/favorites/suppliers": { title: "Избранные поставщики", documentTitle: "Избранные поставщики" },
};

const MENU_ITEMS = [
  { name: "profile/dashboard", caption: "Главная" },
  { name: "profile/personal", caption: "Данные пользователя" },
  { name: "profile/my-companies", caption: "Мои компании" },
  { name: "profile/organisation", caption: "Организация" },
  { name: "profile/orders", caption: "Заказы" },
  // {name: 'returns', caption: 'Возвраты'},
];

const MENU_ITEMS_DACHNIK = [
  { name: "profile/dashboard", caption: "Главная" },
  { name: "profile/personal", caption: "Данные пользователя" },
  { name: "profile/orders", caption: "Заказы" },
  // {name: 'returns', caption: 'Возвраты'},
];

const FAVORITES_MENU_ITEMS = [
  { name: "profile/favorites/products", caption: "Товары" },
  { name: "profile/favorites/suppliers", caption: "Поставщики" },
];

const data = !isDacnhik ? MENU_ITEMS : MENU_ITEMS_DACHNIK;

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
    };
  }

  componentDidMount() {
    const { setSection, match } = this.props;
    const section = match.url || "profile/dashboard";
    setSection(section);
  }

  componentDidUpdate(prevProps, prevState) {
    const { setSection, match, section } = this.props;

    if (this.props.location !== prevProps.location) {
      setSection(match.url);
    }

    document.title = `${SECTION_TITLES[section].documentTitle} – rusagro.market`;
  }

  doLogout() {
    const { logout } = this.props;

    logout();
  }

  backButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    const { section, loggedIn, history, loaded, location, isMobile, isDesktop, isCompany } = this.props;

    if (loggedIn === false && location.pathname !== "/") {
      // history.push("/");
      return null;
    } else if (loggedIn === null || !loaded.has("user") || (isCompany && !loaded.has("account"))) {
      return null;
    }

    if (!section) return null;

    const sectionTitle = SECTION_TITLES[section].title;
    const categoriesByLevel = {
      1: { id: `profile/dashboard`, title: "Личный кабинет" },
    };

    const isDashboard = section.indexOf("dashboard") !== -1;
    const isFavorites = section.indexOf("favorites") !== -1;

    if (!isDashboard) {
      categoriesByLevel[2] = { id: section, title: sectionTitle };
    }
    const showHeader = !!location.search;

    return (
      <section className="container profile-page">
        {isDesktop && <Breadcrumbs categoriesByLevel={categoriesByLevel} baseUri={"/"} absoluteUrl />}
        {!showHeader && (
          <div>
            {isDesktop && <h2 className="profile-page-title">{sectionTitle}</h2>}
            {!isDesktop && !isDashboard && <BackButton onClick={() => this.backButtonClick()} />}
            {!isDesktop && !isFavorites && (
              <div className="profile-header">
                <div>{sectionTitle}</div>
                {isDashboard && (
                  <div className="exit-btn">
                    <div onClick={() => this.doLogout()}>Выйти</div>
                    <ExitIcon />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="profile-layout">
          {(isDesktop || (!isDesktop && isDashboard)) && (
            <div className="profile-sidebar">
              {/* <ProfileBar user={user} /> */}
              {isCompany && (
                <div className="profile-menu-payment-delay">
                  <PaymentDelayInfo />
                </div>
              )}
              <MenuBar activeSection={section} hasOrganisation={isCompany} items={data} />
              <MenuBar activeSection={section} caption="Избранное" items={FAVORITES_MENU_ITEMS} />
            </div>
          )}
          {!isDesktop && isFavorites && (
            <div className="mobile-favorites">
              <MenuBar activeSection={section} caption="Избранное" items={FAVORITES_MENU_ITEMS} />
            </div>
          )}
          <div className="profile-content">
            {section === "profile/dashboard" && <Dashboard isMobile={isMobile} />}

            {section === "profile/personal" && <PersonalInfo />}
            {section === "profile/organisation" && (isCompany ? <OrganisationInfo history={history} /> : "Организация отсутствует.")}
            {section === "profile/my-companies" && <MyCompanies />}
            {section === "profile/orders" && <OrdersInfo />}
            {section === "profile/favorites/products" && <FavoriteProducts />}
            {section === "profile/favorites/suppliers" && <FavoritesSuppliers />}
            {/* {isLogged && <Route path="/profile/profile/favorites/products" component={ProfilePage} />} */}
            {/* {isLogged && <Route path="/profile/profile/favorites/suppliers" component={ProfilePage} />} */}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.profile.account,
  section: state.profile.section,
  loaded: state.profile.loaded,
  user: state.profile.user,
  loggedIn: state.profile.loggedIn,
  isMobile: state.api.isMobile,
  isDesktop: state.api.isDesktop,
  isCompany: state.profile.isCompany,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSection: setSection,
      getAccount: getAccount,
      logout: logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfilePage));
