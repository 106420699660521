import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";

import "./SideCart.scss";

import classNames from "classnames";
import SupplierItems from "../SupplierItems/SupplierItems";
import { setActiveStep } from "../../../actions/cart";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import Summary from "../Summary/Summary";
import { getMinimumOrderSum, isEarlyOrder, groupItemsBySupplier } from "../../../helpers/orders";
import { redirectToLogin } from "../../../helpers/cookies";
import Scrollbar from "../../commons/ScrollBar/ScrollBar";
import { DELIVERY_TYPES } from "../../../constants";

const detectChangedItemId = (before, after) => {
  const snap_before = before.map((i) => i.productId);
  const snap_after = after.map((i) => i.productId);
  const newItemId = snap_after.find((i) => snap_before.indexOf(i) === -1);

  if (newItemId) return newItemId;
  return null;
  //disabled count change
  //   const diffItem = after.find((a) => before.find((b) => a.quantity > b.quantity && b.productId === a.productId));

  //   return diffItem ? diffItem.productId : null;
};

const SideCart = ({ history }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [lastChangedItemId, setLastChangedItemId] = useState(null);

  const [pointerVisible, setPointerVisible] = useState(false);

  const cartItemsPrev = useRef([]);
  const timer = useRef(null);

  const cartItems = useSelector((state) => state.cart.cartItems?.items);
  const isLoading = useSelector((state) => state.api.preload);

  const addressId = useSelector((state) => state.cart.registration.addressId);
  const pickupPoint = useSelector((state) => state.cart.pickupPoint);
  const deliveryType = useSelector((state) => state.cart.deliveryType);
  
  const loggedIn = useSelector((state) => state.profile.loggedIn);
  const earlyOrder = isEarlyOrder(useSelector((state) => state.order.orders.totalCount));
  const user = useSelector((state) => state.profile.user);

  const notActive = isLoading || location.pathname === "/cart" || cartItems.length === 0;

  useEffect(() => {
    if (!notActive) {
      const changedItemId = detectChangedItemId(cartItemsPrev.current, cartItems);
      setLastChangedItemId(changedItemId);

      if (changedItemId) {
        setVisible(true);
        if (timer.current) clearTimeout(timer.current);

        timer.current = setTimeout(() => setVisible(false), 3000);
      }
    }
    cartItemsPrev.current = cartItems;
  }, [cartItems, notActive]);

  useEffect(() => {
    if (visible && lastChangedItemId) {
      const itemId = `product_${lastChangedItemId}`;
      const divItem = document.getElementById(itemId);
      const bounds = divItem.getBoundingClientRect();
      const scrollX = scrollRef.current.scrollTop + bounds.top;
      scrollRef.current.centerAt(0, scrollX);
      //   console.log("-----scroll", scrollX, scrollRef.current.scrollTop, bounds);
    }
  }, [visible, lastChangedItemId]);

  if (notActive) return null;

  //TODO: MOVE to SubmitButton
  const minimunOrderSum = getMinimumOrderSum(user, earlyOrder);
  const { totalSum } = groupItemsBySupplier(cartItems);
  const isDeliveryToDoor = deliveryType === DELIVERY_TYPES[0];
  const noDeliveryAddress = loggedIn && isDeliveryToDoor && !addressId;
  const noPickupPointAddress = loggedIn && !isDeliveryToDoor && !pickupPoint;
  const hasMinimumOrder = minimunOrderSum && totalSum >= minimunOrderSum;
  const requirementsFailed = noDeliveryAddress || noPickupPointAddress || !hasMinimumOrder;
  // ---

  const goToCart = () => {
    if (!loggedIn) {
      redirectToLogin();
    } else {
      dispatch(setActiveStep(1));
      history.push("/cart");
    }
  };

  const onClose = () => {
    setVisible(false);
    setPointerVisible(false);
  };

  return (
    <div className={classNames("side-cart", { visible: visible || pointerVisible })} onPointerEnter={() => setPointerVisible(true)} onPointerLeave={() => setPointerVisible(false)}>
      <span className="close" onClick={() => onClose()}>
        <CloseIcon />
      </span>
      <div className="side-cart-wrapper">
        <div className="cart-items-wrapper">
          <Scrollbar scrollFix={true} customRef={scrollRef}>
            <SupplierItems mini={true} />
          </Scrollbar>
        </div>
        <Summary submitDisabled={requirementsFailed} nextStep={goToCart} />
      </div>
    </div>
  );
};

export default withRouter(SideCart);
