import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { declOfNum } from "../../../helpers/stringOperations";
import "./PaymentDelayInfo.scss";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";
import moment from "moment";
import { useHistory } from "react-router-dom";

const PaymentDelayInfo = ({ deferredPaymentInfo }) => {
  const history = useHistory();
  const limit = deferredPaymentInfo?.totalLimit || 0;
  const notPaidSum = deferredPaymentInfo?.notPaidSum?.toFixed(2) || 0;
  const balance = deferredPaymentInfo?.deferredPaymentsLimitBalance?.toFixed(2) || 0;
  const blocked = deferredPaymentInfo?.blockedSum?.toFixed(2) || 0;
  const beforeDate = moment(deferredPaymentInfo?.payBefore).format("DD MMMM");
  const hasNotPaidSum = notPaidSum > 0;
  const hideDisclaimer = deferredPaymentInfo?.canUseDeferredPayment;
  const daysBefore = ` ${deferredPaymentInfo?.daysForDeferredPayment || 14} ${declOfNum(deferredPaymentInfo?.daysForDeferredPayment || 14, ["день", "дня", "дней"])}`;

  const handleClick = () => {
    history.push("/help/3/5")
  };

  return (
    <>
      {!hideDisclaimer && <div className='payment-delay-info-disclaimer' onClick={handleClick}>
        <span>Отсрочка недоступна</span>
        <InfoIcon />
      </div>}
      <div className="payment-delay-info">
        <div className="payment-delay-info-content">
          <header className="payment-delay-info-header">
            <h3 className="payment-delay-info-title">Баланс отсрочки</h3>
          </header>

          <span className="payment-delay-info-sum">{balance} ₽</span>
          <span className="payment-delay-info-text">{blocked} ₽ заблокировано</span>
        </div>

        <div className="payment-delay-info-content">
          <header className="payment-delay-info-header">
            <h3 className="payment-delay-info-title">Задолженность</h3>
          </header>
          <span className="payment-delay-info-sum">{notPaidSum} ₽</span>
          {hasNotPaidSum && <span className="payment-delay-info-text">Ближайшая оплата заказа {beforeDate}</span>}
        </div>

        <div className="payment-delay-info-footer">
          <div className="payment-delay-info-footer-item">
            <h3 className="payment-delay-info-title">Лимит</h3>
            <span className="payment-delay-info-sum small">{limit} ₽</span>
          </div>
          <div className="payment-delay-info-footer-item">
            <h3 className="payment-delay-info-title">Срок</h3>
            <span className="payment-delay-info-sum small">{daysBefore}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  deferredPaymentInfo: state.profile.deferredPaymentInfo
});


export default connect(mapStateToProps)(withRouter(PaymentDelayInfo));
