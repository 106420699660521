import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import { ReactComponent as ArrowIcon } from "../../../../images/right-arrow.svg";
import classNames from "classnames";
import "./ThumbsGallery.scss";
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Thumbs } from "swiper/core";
import { getGalleryMainImageIndex, orderGallery } from "../../../../helpers/utils";
// install Swiper modules
SwiperCore.use([Pagination, Navigation, Thumbs]);

function ThumbsGallery({ items, isMobile, isTablet, zoomCard }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [swiper, setSwiper] = useState(null);

  const sliderImages = orderGallery(items);

  useEffect(() => {
    if (swiper) {
      const timer = setTimeout(() => {
        swiper.slideTo(getGalleryMainImageIndex(sliderImages));
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [swiper, sliderImages]);

  const sideSlides = sliderImages.map((item, i) => {
    return (
      <SwiperSlide
        className={classNames("thumbs-gallery__slider-side-slide", {
          "thumbs-gallery__slide-active": items?.length === 1,
        })}
        key={item?.id || i}
      >
        <img className="thumbs-gallery__slider-side-image" src={item?.previewUrl} alt="" />
      </SwiperSlide>
    );
  });
  const mainSlides = sliderImages.map((item, i) => {
    return (
      <SwiperSlide className="thumbs-gallery__slider-main-slide" key={item?.id || i} onClick={() => zoomCard(i, items)}>
        <img className="thumbs-gallery__slider-main-image" src={item?.middleUrl || item?.url} alt="" />
      </SwiperSlide>
    );
  });

  return (
    <div className="thumbs-gallery">
      <div className="thumbs-gallery__slider-side">
        {items.length > 5 && (
          <div className="swiper-btn-prev">
            <div className="swiper-bnt">
              <ArrowIcon />
            </div>
          </div>
        )}
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={items.length > 1 && 10}
          slidesPerView={5}
          freeMode={true}
          watchSlidesVisibility={true}
          watchSlidesProgress={true}
          threshold={2}
          direction={!isTablet ? "vertical" : "horizontal"}
          style={items.length === 1 ? { marginLeft: "0" } : { marginLeft: "auto" }}
          // initialSlide={2}
          breakpoints={{
            375: {
              width: 375,
              slidesPerView: 5,
            },

            1024: {
              width: 1024,
              slidesPerView: 5,
            },
          }}
        >
          {sideSlides}
        </Swiper>
        {items.length > 5 && (
          <div className="swiper-btn-next">
            <div className="swiper-bnt">
              <ArrowIcon />
            </div>
          </div>
        )}
      </div>

      <Swiper
        className="thumbs-gallery__slider-main"
        slidesPerView={1}
        thumbs={{ swiper: thumbsSwiper }}
        onSwiper={(s) => setSwiper(s)}
        pagination={{
          clickable: true,
        }}
        style={{ marginLeft: "0", marginRight: "0" }}
        navigation={{
          prevEl: ".swiper-btn-prev",
          nextEl: ".swiper-btn-next",
        }}
        allowTouchMove={true}
      >
        {mainSlides}
      </Swiper>
    </div>
  );
}

export default ThumbsGallery;
