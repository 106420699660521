import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./Favorites.scss";
import "../../Product/Product.scss";
import { fetchFavoriteSuppliers } from "../../../actions/product";
import EmptyProducts from "./EmptyProducts";
import Supplier from "../../Product/ProductPage/Supplier/Supplier";
import { removeLegalPrefix } from "../../../helpers/stringOperations";

const groupByAlphabet = (suppliers) =>
  suppliers
    .reduce((acc, curr) => {
      if (!curr.tradeName) curr.tradeName = removeLegalPrefix(curr.legalName);

      const idx = acc.findIndex((e) => e.alphabet === curr.tradeName[0]);

      if (idx === -1) {
        acc.push({ alphabet: curr.tradeName[0], record: [curr] });
      } else {
        acc[idx].record.push(curr);
        acc[idx].record.sort((r1, r2) => (r1.tradeName > r2.tradeName ? 1 : -1));
      }
      return acc;
    }, [])
    .sort((e1, e2) => (e1.alphabet > e2.alphabet ? 1 : -1));

class FavoritesSuppliers extends React.Component {
  render() {
    const { favoriteSuppliers } = this.props;

    if (favoriteSuppliers.length === 0) return <EmptyProducts suppliers />;

    const renderSuppliersByLetter = (alphabetRecord, i) => (
      <div className="alphabet-container" key={i}>
        <div className="letter">{alphabetRecord.alphabet}</div>
        <div className="suppliers-container">
          {alphabetRecord.record.map((supplier) => (
            <Supplier supplier={supplier} key={supplier.id} />
          ))}
        </div>
      </div>
    );

    const suppliersByAlphabet = groupByAlphabet(favoriteSuppliers);
    return <div className="favorite-suppliers">{suppliersByAlphabet.map((alphabetRecord, i) => renderSuppliersByLetter(alphabetRecord, i))}</div>;
  }
}

const mapStateToProps = (state) => ({
  favoriteSuppliers: state.products.favoriteSuppliers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchFavoriteSuppliers: fetchFavoriteSuppliers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesSuppliers);
