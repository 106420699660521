import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MyCompanies.scss";
import classNames from "classnames";
import { ReactComponent as CartIcon } from "../../../images/cart2.svg";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as PlusIcon } from "../../../images/plus-big.svg";
import PopupModal from "../../commons/PopupModal/PopupModal";
import CheckBoxItem from "../../commons/CheckBoxItem/CheckBoxItem";
import AddMyCompanyModal from "./AddMyCompanyModal/AddMyCompanyModal";
import { setMyCompanyToDefault, fetchMyCompanies } from "../../../actions/profile";

const MyCompanies = (props) => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [addCompanyVisible, setAddCompanyVisible] = useState(false);
  const myCompanies = useSelector((state) => state.profile.myCompanies.results);
  const myCompanyDefaultId = useSelector((state) => state.profile.myCompanyDefaultId);
  const isCompany = useSelector((state) => state.profile.isCompany);
  const user = useSelector((state) => state.profile.user);
  const sectionTitle = isCompany ? "Мои компании" : "Мои аккаунты";
  const userName = `${user?.firstName || ""} ${user?.middleName || ""} ${user?.lastName || ""}`;
  const userDeliveryAddress = useSelector((state) => state.cart.registration.address);

  const showAddCompanyModal = () => {
    setVisible(false);
    setAddCompanyVisible(true);
  };

  const onCloseAddCompanyModal = () => {
    dispatch(fetchMyCompanies());
    setAddCompanyVisible();
  };

  const setMainCompany = (companyId) => {
    dispatch(setMyCompanyToDefault(companyId));
  };

  const mainCompany = myCompanies.find((c) => c.id === myCompanyDefaultId);
  const mainCompanyName = mainCompany?.legalName || "Не указана";
  const mainLabel = isCompany ? mainCompanyName : userName;

  return (
    <div className={classNames("my-companies", { visible, mobile: props.isMobile })}>
      <div className={classNames("custom-button", { [props.accountType]: true })} onClick={() => setVisible(true)}>
        <div className="main-company-name">
          <CartIcon />
          <span>{mainLabel}</span>
        </div>
        <div className="arrow">
          <Arrow />
        </div>
      </div>
      {visible && (
        <PopupModal onClose={() => setVisible(false)} title={sectionTitle}>
          <div className="companies-content">
            <div className="add-button" onClick={() => showAddCompanyModal()}>
              <PlusIcon />
              <div>Добавить компанию</div>
            </div>
            <div className="companies-list">
              {myCompanies.map((c, k) => (
                <div className={classNames("company-item", { selected: myCompanyDefaultId === c.id })} key={`company_${k}`} onClick={(e) => setMainCompany(c.id)}>
                  <CheckBoxItem checked={myCompanyDefaultId === c.id} onClick={(e) => setMainCompany(c.id)} />
                  <div className="company-data">
                    <div className="company-name">{c.legalName || c.fullName || userName}</div>
                    <div className="company-address">{c.legalAddress || userDeliveryAddress || "Адрес доставки отсутствует"}</div>
                  </div>
                </div>
              ))
              }
            </div>
          </div>
        </PopupModal>
      )}
      <AddMyCompanyModal visible={addCompanyVisible} onClose={onCloseAddCompanyModal} />
    </div>
  );
};

export default MyCompanies;
