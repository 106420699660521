import React, { useState, useEffect } from "react";
import { ReactComponent as DeliveryIcon } from "../../../../images/truck.svg";
import { ReactComponent as CalendarIcon } from "../../../../images/time-blue.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getNextDeliveryTime } from "../../../../actions/product";
import moment from "moment";
import "./Delivery.scss";

const Delivery = ({ getNextDeliveryTime, status }) => {
  const [deliveryDate, setDeliveryDate] = useState(null);
  const productIsComingSoon = status === "ComingSoon";
  const { env } = global.config;
  const isDacnhik = env === "dachnik";

  useEffect(() => {
    getNextDeliveryTime().then((res) => setDeliveryDate(moment(res.deliveryDate).format("dddd, D MMMM")));
  }, [getNextDeliveryTime]);

  return (
    <div className="delivery">
      {!isDacnhik && <div className="item">
        <DeliveryIcon />
        <div className="info">
          Доставка
          <div className="sub-info">г. Новосибирск</div>
        </div>
      </div>}
      {!productIsComingSoon && <div className="item">
        <CalendarIcon />
        <div className="info">
          Ближайшая доставка:
          <div className="sub-info">{deliveryDate}</div>
        </div>
      </div>}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNextDeliveryTime: getNextDeliveryTime,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
