import * as React from 'react';
import InputWithHint from "../../commons/InputWithHint/InputWithHint";
import ConfirmationDialog from '../ConfirmationBar/ConfirmationDialog';

import { ReactComponent as EmailIcon } from '../../../images/email.svg';
import { ReactComponent as UnconfirmedIcon } from '../../../images/exclamation.svg';
import { ReactComponent as ConfirmedIcon } from '../../../images/confirm.svg';
class EmailInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dialogVisible: false }
    }

    render() {
        const { title, value, onChange, confirmed, userEmail, onSubmitPhoneOrEmail } = this.props;
        const { dialogVisible } = this.state;
        const confirmationLabel = confirmed ? 'E-mail подтвержен' : 'E-mail пока не подтвержен';
        const icon = confirmed ? <ConfirmedIcon /> : <UnconfirmedIcon />;
        const customClass = confirmed ? "confirmed" : "unconfirmed";
        
        const onClose = (reset = false) => {
           
            this.setState({ dialogVisible: false });
            reset &&  onChange(userEmail);
        };

        const onShow = () => {
            this.setState({ dialogVisible: true });

        };
        return (<div className="special">
            <InputWithHint
                // text={confirmationLabel}
                leftImg={<EmailIcon />}
                // customClass={customClass}
                title={title}
                value={value}
                onFocus={() => onShow()}
                hintSide="top"
                // rightImg={icon} 
                />
            {dialogVisible && <ConfirmationDialog value={value} onConfirm={onChange} type="email" onClose={(reset) => onClose(reset)} visible={dialogVisible} onSubmitPhoneOrEmail={onSubmitPhoneOrEmail} />}

        </div>);
        // return (<Input leftImg={<EmailIcon />} title={title} value={value} onChange={onChange} />)
    }
}

export default EmailInput;