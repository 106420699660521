import * as React from "react";
import "./Search.scss";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as SearchIcon } from "../../../images/search.svg";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { search, clearSearchResults } from "../../../actions/search";
import classNames from "classnames";
import SearchDropDown from "./SearchDropDown";

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { searchText: "", searchPromise: null, searchBarActive: false, isFocused: false };
    this.searchInputRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;

    const query = queryString.parse(this.props.location.search);
    const searchText = query.search;
    if (searchText) {
      this.setState({ searchText });
      const searchPromise = search(searchText).catch((error) => {
        // console.log("user aborted");
      });
      this.setState({ searchPromise });
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.searchText) {
      this.toggleSearchDropDown(false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.toggleSearchBar(false);
    }
  }

  handleSearchChange = (e) => {
    const { search, clearSearchResults } = this.props;
    const searchText = e.target.value;

    this.setState({ searchText });

    if (searchText.length > 1) {
      if (this.state.searchPromise) {
        this.state.searchPromise.cancel();
      }

      const searchPromise = search(searchText).catch((error) => {
        // console.log("user aborted");
      });
      this.setState({ searchPromise });
    } else {
      clearSearchResults();
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.searchByText();
    }
  };

  handleFocus = () => {
    this.setState({isFocused: true});
  }

  handleBlur = () => {
    this.setState({isFocused: false});
  }
 
  searchByText() {
    const { searchText } = this.props;

    this.props.history.push(encodeURI(`/catalog/customerProducts/?search=${searchText}`));
    this.toggleSearchBar(false);
    this.searchInputRef.current.blur();
    // this.reset();
  }

  toggleSearchBar(searchBarActive) {
    this.setState({ searchBarActive });
  }

  toggleSearchDropDown(searchDropDownActive) {
    this.setState({ searchDropDownActive });
  }

  reset = () => {
    const { clearSearchResults } = this.props;
    clearSearchResults();
    this.setState({ searchText: "" });
  };

  onClearClick = () => {
    this.reset();
  };

  render() {
    const { searchText, isMobile = false, fixedHeader } = this.props;

    const { searchBarActive, searchDropDownActive, isFocused } = this.state;

    const dropDownVisible = searchText && (searchBarActive || searchDropDownActive);

    return (
      <div
        className={classNames({
          "search-bar": true,
          active: searchBarActive || searchDropDownActive,
        })}
        ref={this.setWrapperRef}
        onFocus={() => this.toggleSearchBar(true)}
      >
        <SearchIcon />
        <input
          ref={this.searchInputRef}
          type="text"
          placeholder="Искать на маркете..."
          value={this.state.searchText}
          onChange={this.handleSearchChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
        {dropDownVisible && (
          <div className="close-btn" onMouseEnter={() => this.toggleSearchDropDown(true)} onMouseLeave={() => this.toggleSearchDropDown(false)} onClick={() => this.onClearClick()}>
            <CloseIcon />
          </div>
        )}
        <SearchDropDown
          dropDownVisible={dropDownVisible}
          isMobile={isMobile}
          showAll={() => this.searchByText()}
          toggleSearchBar={(v) => this.toggleSearchBar(v)}
          toggleSearchDropDown={(v) => this.toggleSearchDropDown(v)}
          fixedHeader={fixedHeader}
          focus={isFocused}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchText: state.search.searchText,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSearchResults: clearSearchResults,
      search: search,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
