import React from "react";
import { Link } from "react-router-dom";
import "./HelpCard.scss";
import cn from "classnames";

const HelpCard = ({ list, categoryIndex }) => {
  return (
    <div
      className={cn("helpCardRoot", {
        "helpCardRoot--supplier": list[0].type === "suppliers",
      })}
    >
      <p className="helpCardRoot__title">{list[0].section}</p>
      <ul className="helpCardRoot__list">
        {list.map((item, index) => (
          <li key={index} className="helpCardRoot__list-item">
            {item.link ? (
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.question}
              </a>
            ) : (
              <Link to={`/help/${categoryIndex}/${index}`}>{item.question}</Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HelpCard;
