import { store } from "../index";
import { startFetch, doneFetch } from "../actions/api";
import { login, logout } from "../actions/profile";
import { saveAuthData, AUTH_DATA, loadAuthData } from "./cookies";
import { emptyPromise, waitFor, getCurrentTimestamp } from "./utils";
import analytics from "./analytics";
const { env, baseUrl, clientSecret } = global.config;


const getScope = () => {
  if (env !== "prod") {
    return `${env}.marketplace`
  } else return "api.marketplace"
}

const JWT_CREDENTIALS = {
  grant_type: "refresh_token",
  client_id: "marketplace.spa",
  client_secret: clientSecret,
  scope: `${getScope()} openid email phone profile offline_access role`,
};

const tryGetJson = async (resp) => {
  return new Promise((resolve, reject) => {
    if (resp) {
      //
      resp
        .json()
        .then((json) => {
          if (resp.status === 200) {
            resolve(json);
          } else {
            analytics.logError(json, resp);
            return reject(json);
          }
        })
        .catch(() => {
          const message = resp.error || resp.statusText;
          analytics.logError(message, resp);
          throw reject({ message });
        });
    } else {
      resolve(null);
    }
  });
};

export async function renewToken(refreshToken) {
  console.log("-----renewToken start", refreshToken);
  const loginData = {
    ...JWT_CREDENTIALS,
    refresh_token: refreshToken,
  };
  const data = new FormData();
  Object.entries(loginData).forEach((v) => data.append(v[0], v[1]));

  return fetch(`${baseUrl}/connect/token`, {
    method: "POST",
    body: data,
  })
    .then((response) => {
      if (response.status !== 200) {
        store.dispatch(logout());
        return Promise.reject(response.error || response.statusText);
      }

      return response.json();
    })
    .then((json) => {
      const jwt = saveAuthData(json["access_token"], json["refresh_token"]);
      return store.dispatch(login(jwt)).then(() => {
        console.log("-----renewToken complete", json["refresh_token"]);

        return emptyPromise(json["access_token"]);
      });
    })
    .catch((e) => {
      store.dispatch(logout());
      return Promise.reject(e.error || e.statusText);
    });
}

export const getHeaders = (auth = false, newAccessToken = null, autoContentType = false) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (autoContentType) {
    delete headers["Content-Type"];
  }

  if (auth) {
    const accessToken = newAccessToken || AUTH_DATA.accessToken;
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return headers;
};

async function wrapHeaders(auth = false) {
  if (auth) {
    const authData = AUTH_DATA.jwt ? AUTH_DATA : loadAuthData();
    const { refreshToken, jwt, renewInProgressFlag } = authData;
    const now = getCurrentTimestamp();
    const jwtIsExpired = () => authData.jwt.exp <= now;
    if (!jwt) {
      return Promise.reject("Not authorized!");
    }
    if (jwtIsExpired() && (await waitFor(() => !renewInProgressFlag))) {
      if (refreshToken !== authData.refreshToken) {
        console.log("--token already refreshed");
        return emptyPromise(getHeaders(true, authData.accessToken));
      }

      authData.renewInProgressFlag = true;
      console.log("--doRefresh");

      return renewToken(refreshToken)
        .then((accessToken) => emptyPromise(getHeaders(true, accessToken)))
        .finally(() => (authData.renewInProgressFlag = false));
    }
  }

  return emptyPromise(getHeaders(auth));
}

export const fetchBackend = (url, method = "POST", body = null, headers, asBlob = false) => {
  const fullUrl = `${baseUrl}${url}`;

  const req = {
    credentials: "same-origin",
    method: method,
    headers: headers,
  };
  if (body) {
    req.body = JSON.stringify(body);
  }

  store.dispatch(startFetch(fullUrl));

  return fetch(fullUrl, req)
    .then((res) => {
      // if (res.status === 401) {
      //   store.dispatch(logout());
      //   window.location.href = "/";
      //   return Promise.reject({ code: "PermissionDenied" });
      // }

      return asBlob ? res.blob() : tryGetJson(res);
    })
    .finally(() => store.dispatch(doneFetch(fullUrl)));
};

export const fetchPostBackend = (url, body = null, auth = false, asBlob = false) => wrapHeaders(auth).then((headers) => fetchBackend(url, "POST", body, headers, asBlob));

export const fetchGetBackend = (url, auth = false, authData) => wrapHeaders(auth, authData).then((headers) => fetchBackend(url, "GET", null, headers));
export const fetchGetBlobBackend = (url, auth = false, authData) => wrapHeaders(auth, authData).then((headers) => fetchBackend(url, "GET", null, headers, true));

export const fetchPatchBackend = (url, body = null, auth = false) => wrapHeaders(auth).then((headers) => fetchBackend(url, "PATCH", body, headers));
export const fetchDeleteBackend = (url, auth = true) => wrapHeaders(auth).then((headers) => fetchBackend(url, "DELETE", null, headers));

export function cancelableFetch(url, method = "GET", body = null, auth = false) {
  const fullUrl = `${baseUrl}${url}`;

  const reqInit = {
    credentials: "same-origin",
    method,
    headers: getHeaders(auth),
  };

  if (body) {
    reqInit.body = JSON.stringify(body);
  }

  var abortController = new AbortController();
  var signal = abortController.signal;
  var cancel = abortController.abort.bind(abortController);
  var wrapResult = function (result) {
    if (result instanceof Promise) {
      var promise = result;

      promise.then = function (onfulfilled, onrejected) {
        var nativeThenResult = Object.getPrototypeOf(this).then.call(this, onfulfilled, onrejected);
        return wrapResult(nativeThenResult);
      };
      promise.cancel = cancel;
    }
    return result;
  };

  startFetch(fullUrl);
  var req = window
    .fetch(fullUrl, Object.assign({ signal: signal }, reqInit))
    .then((res) => res.json())
    .catch((res) => {
      throw res.error;
    })
    .finally(() => store.dispatch(doneFetch(fullUrl)));

  return wrapResult(req);
}

export const downloadBlobAsFile = async (blob, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
};
export const fetchData = async (filePath) => {
  try {
    const res = await fetch(filePath);
    return await res.json();
  } catch (err) {
    return err;
  }
};

export const fetchMinimalOrderSum = () => {
  fetchGetBackend(`/api/orders/v1.0/servicesettings/get-actual-settings`, false, null)
}

export const fetchBanners = () => {
  return fetchGetBackend(`/api/public/v2.0/cms/banners`, false, null)
}
