import * as React from "react";
import "./CartPage.scss";
import Button from "../commons/Button/Button";
import EmptyCartIcon from "../../images/empty-data.png";
import { Link } from "react-router-dom";

const EmptyCart = () => {
  return (
    <div className="empty">
      <img src={EmptyCartIcon} className="icon" alt="В корзине пока ничего нет" />
      <h2>В корзине пока ничего нет</h2>
      <p className="sub-header">Вы можете начать свой выбор с главной страницы, посмотреть акции или воспользоваться поиском, если ищете что-то конкретное.</p>
      <Link to="/catalog/customerProducts" className="big " alt="В каталог товаров">
        <Button title="В каталог товаров" buttonSize="large" buttonType="primary" width="auto" />
      </Link>
    </div>
  );
};

export default EmptyCart;
