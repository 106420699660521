import * as React from "react";
import "./Button.scss";
import classNames from "classnames";
import { Loader } from "../Loader/Loader";

const Button = ({ disabled, buttonSize, buttonType, title, onClick, icon, width, loading = false }) => {
  return (
    <button
      className={classNames(`round-buttons ${buttonSize} ${buttonType}`, {
        [`${buttonType}-disabled`]: disabled,
        progress: loading,
      })}
      onClick={(e) => !disabled && !loading && onClick && onClick(e)}
      style={{
        width: width && width,
      }}
      disabled={disabled}
    >
      {loading && (
        <div className="button-loader">
          <Loader />
        </div>
      )}
      {!loading && icon && icon}
      {!loading && <span> {title}</span>}
    </button>
  );
};
export default Button;
