import React from 'react';
import './Breadcrumbs.scss';
import { Link } from 'react-router-dom';
import cn from 'classnames';

const Breadcrumbs = ({ items }) => {
  if (!items || items.length < 2) {
    return null;
  }

  return (
    <div className="breadcrumbs">
      {items.map((item, index) => (
        <Link to={item.to} key={item.id} className={cn('breadcrumbs__link')}>
          {item.text}
        </Link>
      ))}
    </div>
  );
};

export default Breadcrumbs;
